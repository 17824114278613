import {LOGIN, LOGOUT, SET_CHARACTER, SET_RELIC, SET_USER, WELCOME} from "../constants";
import {headers, headersWithImage, headersWithToken} from "../headers";
import { editProfileApi, loginAPi, profileApi, signUpAPi, forgetPasswordAPi, resetPasswordAPi, submitInquiryApi } from "../apis";
import axios from "axios";
import {setAlert} from "../Alert/action";
import {responseError} from "../errorHandler";
import {setAwards} from "../UserCharacter/action";

export const setLogin = (token , user) => {
    return {
        type : LOGIN,
        token : token,
        user : user
    }
}

export const logout = () => {
    return {
        type : LOGOUT,
    }
}

export const setCharacter = (id) => {
    return {
        type : SET_CHARACTER,
        payload : id,
    }
}

export const setWelcome = () => {
    return {
        type : WELCOME,
    }
}

export const setUser = (user) => {
    return {
        type : SET_USER,
        payload : user,
    }
}

export const setRelics = (data) => {
    return {
        type : SET_RELIC,
        payload : data,
    }
}

export const loginRequest = (data) => {
    return async (dispatch) => new Promise(async(resolve, reject)=>{
        try{
            let response = await axios.post(loginAPi,data,headers());
            dispatch(setLogin(response.data.response.data.token,response.data.response.data.user));
            dispatch(setAlert([{message : response.data.message,color : 'success'}]));
            resolve(true);
        }catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
            reject(false);
        }
    })
}
export const forgetPasswordRequest = (data) => {
    return async (dispatch) => new Promise(async (resolve, reject) => {
        try {
            console.log('sdd');
            let response = await axios.post(forgetPasswordAPi, { email: data }, headers());
            dispatch(setAlert([{ message: response.data.message, color: 'success' }]));
            resolve(true);
        } catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
            reject(false);
        }
    })
}

export const resetPasswordRequest = (data) => {
    return async (dispatch) => new Promise(async (resolve, reject) => {
        try {
            let response = await axios.post(resetPasswordAPi, data, headers());
            dispatch(setAlert([{ message: response.data.message, color: 'success' }]));
            resolve(true);
        } catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
            reject(false);
        }
    })
}
export const submitInquiryRequest = (data) => {
    return async (dispatch) => new Promise(async (resolve, reject) => {
        try {
            let response = await axios.post(submitInquiryApi, data, headers());
            dispatch(setAlert([{ message: response.data.message, color: 'success' }]));
            resolve(true);
        } catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
            reject(false);
        }
    })
}

export const signUpRequest = (data) => {
    return async (dispatch) => new Promise(async(resolve, reject)=>{
            try{
                let response = await axios.post(signUpAPi,data,headers());
                dispatch(setAlert([{message : response.data.message,color : 'success'}]));
                resolve(true);
            }catch (e) {
                let error = responseError(e);
                dispatch(setAlert(error));
                reject(false);
            }
    })
}

export const updateProfileRequest = (data) => {
    return async (dispatch,getState) => new Promise(async(resolve, reject)=>{
            try{
                let token = getState().Auth.token;
                let response = await axios.post(editProfileApi,data,headersWithImage(token));
                dispatch(setUser(response.data.response.data));
                dispatch(setAlert([{message : response.data.message,color : 'success'}]));
                resolve(true);
            }catch (e) {
                let error = responseError(e);
                dispatch(setAlert(error));
                reject(false);
            }
    })
}

export const getProfileRequest = () => {
    return async (dispatch,getState) => {
        try{
            let token = getState().Auth.token;

            let response = await axios.get(profileApi, headersWithToken(token));
            // dispatch(setAlert([{message : response.data.message,color : 'success'}]));
            dispatch(setUser(response.data.response.data?.user));
            dispatch(setAwards(response.data.response.data?.awards));
            dispatch(setRelics(response.data.response.data?.relic));
        }catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    }
}
