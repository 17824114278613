import React, {useEffect} from 'react';
import {connect} from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const Alert = ({config , alerts}) => {
    useEffect(()=>{
        alerts.map((val)=>{
            if(val.color === "success"){
                toast.success(val.message,{...config});
            }
            if(val.color === "info"){
                toast.info(val.message,{...config});
            }
            if(val.color === "danger"){
                toast.error(val.message,{...config});
            }
            if(val.color === "warning"){
                toast.warning(val.message,{...config});
            }
        });
    },[alerts]);
    return (
        <>
            <ToastContainer />
        </>
);
}

const mapStateToProps = (state) => {
    return {
        color : state.Alert.color,
        alerts : state.Alert.alerts,
    }
}

const mapDispatchToProps = () => {

}

export default connect(mapStateToProps)(Alert);