import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import '../../assets/css/myprofile.css';
import {
    getCharacterDetailRequest,
} from "../../redux/UserCharacter/action";

const Pets = (props) => {


    const [type , setType] = useState(1);
    const get = async (id,type) => {
        // props.setLoader(true);
        await props.getCharacterDetail(id,type);
        // props.setLoader(false);
    }

    useEffect(()=>{
        get(props?.id,type);
    },[props.id]);



    return (
        <div className="PetsDv">
            <h3>pet</h3>
            <ul className="petsList">
                {
                    props.pets?.items.map((val,key)=>(
                        <li key={key}>
                            <div className="imgBox">
                                <figure><img src={val?.shop?.image_url} alt=""/></figure>
                            </div>
                        </li>
                    ))
                }
                {
                    props.pets?.items.length < 5 &&
                    [...Array(4 - props.pets?.items.length)].map((val,key)=>(
                        <li key={key}>
                            <div className="imgBox">
                                {/*<figure><img src="img/pet1.png" alt=""/></figure>*/}
                            </div>
                        </li>
                    ))
                }
            </ul>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        character: state.Site.characters,
        weapons : state.UserCharacter.details?.characteristics.find((val)=>val?.slug === "weapons"),
        pets : state.UserCharacter.details?.characteristics.find((val)=>val?.slug === "pets")
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getCharacterDetail: (id,item_for) => dispatch(getCharacterDetailRequest(id,item_for)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Pets);