import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import Profile from "../../assets/img/profile.jpg";
import {
  acceptRequest,
  rejectRequest,
  resetTime,
  resetTrade,
  setNewTradeRequest,
} from "../../redux/TradeRequest/action";
import {
  getSecondsFromStartEnd,
  onInputChangeHandler,
  showLoader,
  startTimer,
} from "../../redux/helpers";
import { pusher } from "../../redux/pusherConfig";
import { addPlayerRequest, joinGuildRequest } from "../../redux/Guild/action";
import { toast } from "react-toastify";
import axios from "axios";
import { headersWithToken } from "../../redux/headers";
import { getGuildPlayerApi } from "../../redux/apis";
import { removeGemGlyphsItemRequest } from "../../redux/Inventory/action";
import { createAuctionItemRequest } from "../../redux/Auction/action";
import PDLBorder from "../PDLBorder";
import Logo from "../Logo";
import Classes from "../Classes";
import GameGridBg from "../../assets/img/gamegridbg.png";
import PlayerTop from "../../assets/img/playertop.png";
import PlayerBottom from "../../assets/img/playerbottom.png";
import moment from "moment/moment";
import {CountDownTimer} from "../../pages/Auction/ItemDetails";

function AuctionPreview(props) {

  const currency = useSelector((state) => state?.Site?.currency);
  const {open , hide , notification} = props;
  console.log(notification);
  return (
      <Modal
          show={open}
          onHide={hide}
          size="xs"
          className="characterModal"
          aria-labelledby="contained-modal-title-vcenter"
          centered
      >
        <Modal.Body>
          <section className="fram-sec">
            <div className=""
                 // style={{background: `url(${GameGridBg})`}}
            >
              {/*<PDLBorder />*/}
              {/* <!-- LOGO --> */}
              {/*<Logo />*/}
              {/* <!-- LOGO --> */}

              <section className="inventory-detailed">
                <div className="container-fluid mx-2">
                  <div className="row align-items-center justify-content-center">
                    <div className="col-lg-3">
                      <div className="forTop-border py-1">
                        <figure><img className="w-100" src={PlayerTop} alt=""/></figure>
                      </div>
                      <div className="Player-statusDv">
                        <div className="head">
                          <h3 className="title">
                            Item Status
                          </h3>
                        </div>
                        <ul className="status-list">
                          <li>
                            <h4>Level</h4>
                            <span>{notification?.auction?.item?.item?.level}</span>
                          </li>

                          <li>
                            <h4>Health</h4>
                            <span>{notification?.auction?.item?.health}</span>
                          </li>

                          <li>
                            <h4>Base</h4>
                            <span>{notification?.auction?.item?.base}</span>
                          </li>

                          <li>
                            <h4>Damage</h4>
                            <span>{notification?.auction?.item?.damage}</span>
                          </li>

                          <li>
                            <h4>Armor Pen</h4>
                            <span>{notification?.auction?.item?.armor_pen}</span>
                          </li>

                          <li>
                            <h4>Armor Pen Resist</h4>
                            <span>{notification?.auction?.item?.armor_pen_resist}</span>
                          </li>
                          <li>
                            <h4>Quantity</h4>
                            <span>{notification?.auction?.qty}</span>
                          </li>
                        </ul>
                      </div>
                      <div className="forBottom-border py-1">
                        <figure><img className="w-100" src={PlayerBottom} alt=""/></figure>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="product-details">
                        <div className="heading text-white h4">
                          {notification?.auction?.item?.item?.title}
                        </div>
                        <div className="image-with-btn">
                          <div className="img-box">
                            <figure><img src={notification?.auction?.item?.item?.image_url} alt=""/></figure>
                          </div>
                        </div>
                        <div className="description">
                          <p>{notification?.auction?.item?.item?.description}</p>
                        </div>
                        {/*<div className="title">*/}
                        {/*  <h3>place your bid</h3>*/}
                        {/*</div>*/}
                        <div className=" button-group">
                          {
                            currency?.filter((val) => val?.slug === notification?.auction?.item?.item?.currency_slug)
                                ?.map((val, key) => (
                                    <figure className="" key={key}>
                                      <img
                                          src={val?.icon_url}
                                          width={'100%'}
                                          className={"img-fluid"}
                                      />
                                    </figure>
                                ))
                          }
                          {/*<div className={""}>*/}
                          {/*{*/}
                          {/*    item?.status === 0 &&*/}
                          {/*    <p className={"text-success"}>Auction not started yet</p>*/}
                          {/*}*/}
                          {/*{*/}
                          {/*    item?.status === 2 &&*/}
                          {/*    <p className={"text-danger"}>Auction time is completed</p>*/}
                          {/*}*/}
                          {/*{*/}
                          {/*    item?.status === 1 &&*/}
                          {/*    <>*/}
                          {/*      <input type="text"*/}
                          {/*             placeholder="00.00"*/}
                          {/*             onChange={(e) => setAmount(e.target.value)}*/}
                          {/*             className="" value={amount}/>*/}
                          {/*      <button type={"button"} onClick={placeBid} className="btn">*/}
                          {/*        {*/}
                          {/*          loading ?*/}
                          {/*              <i className={"fa fa-spin fa-spinner"}></i>*/}
                          {/*              :*/}
                          {/*              'Bid'*/}
                          {/*        }*/}
                          {/*      </button>*/}
                          {/*    </>*/}
                          {/*}*/}
                          {/*</div>*/}
                        </div>
                        {
                            <div>
                              <p className={"text-danger mt-3"}>Minimum bid is {notification?.auction?.min_amount}</p>
                            </div>
                        }
                        <div>
                          <button className="btn customButton" type={"button"} onClick={hide}>Close</button>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <h4 className={"text-center h6"}>Auction : { (notification?.auction?.status === 0)? 'Pending' : ( (notification?.auction?.status === 1)? 'In-Progress':'Ended') }</h4>

                      <div className="forTop-border py-1">
                        <figure><img className="w-100" src={PlayerTop} alt=""/></figure>
                      </div>
                      <div className="Player-statusDv" style={{
                        maxHeight: '30rem',
                        overflow: 'scroll',
                      }}>
                        <div className="head">
                          <h3 className="title">
                            Bidding
                          </h3>
                        </div>
                        <ul className="status-list">
                          {
                            notification?.auction?.bids?.map((val, key) => (
                                <li key={key}>
                                  <h4>{val?.user?.name}
                                    <small className={"badge badge-success"} style={{fontSize: '60%'}}>
                                      { (val?.user_id === notification?.auction?.win_user_id) && 'winner' }
                                    </small>
                                  </h4>
                                  <span
                                      className={"d-flex align-item-center justify-content-between"}>
                                                        {
                                                          currency?.filter((val) => val?.slug === notification?.auction?.item?.currency_slug)?.map((val, key) => (
                                                              <figure className="" key={key}>
                                                                <img src={val?.icon_url} width={'30px'}
                                                                     height={'30px'} className={"img-fluid"}/>
                                                              </figure>
                                                          ))
                                                        }
                                                        {parseInt(val?.amount).toLocaleString()}
                                                    </span>
                                </li>
                            ))
                          }

                        </ul>
                      </div>
                      <div className="forBottom-border py-1">
                        <figure><img className="w-100" src={PlayerBottom} alt=""/></figure>
                      </div>
                    </div>

                  </div>
                </div>
              </section>

            </div>
          </section>
        </Modal.Body>
      </Modal>
  )
}

const mapStateToProps = (state) => {
  return {

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuctionPreview);
