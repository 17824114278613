import Login from "../pages/Auth/Login";
import Signup from "../pages/Auth/Signup";
import Profile from "../pages/Profile/Profile";
import Forgot from "../pages/Auth/Forgot";
import Recover from "../pages/Auth/Recover";
import Home from "../pages/Home/Home";
import RuleBook from "../pages/Pages/RuleBook";
import Welcome from "../pages/Home/Welcome";
import PrivacyPolicy from "../pages/Pages/PrivacyPolicy";
import TermsOfCondition from "../pages/Pages/TermsOfCondition";
import NotFound from "../pages/NotFound/NotFound";
import Create from "../pages/Character-Creation/Create";
import Shop from "../pages/Shop/Shop";
import ItemDetails from "../pages/Shop/ItemDetails";
import Inventory from "../pages/Inventory/Inventory";
import FishInventory from "../pages/FishInventory/Inventory";
import FishItemsDetails from "../pages/FishInventory/ItemDetails";
import ItemsDetails from "../pages/Inventory/ItemDetails";
import CraftItemsDetails from "../pages/Craft/ItemDetails";
import EnhanceItemsDetails from "../pages/Enhance/ItemDetails";
import Details from "../pages/Character-Creation/details/Details";
import SearchUsers from "../pages/Trade/SearchUsers";
import Trade from "../pages/Trade/Trade";
import Gift from "../pages/Gift/Gift";
import Guilds from "../pages/Guild/Guilds";
import GuildMake from "../pages/Guild/GuildMake";
import GuildView from "../pages/Guild/GuildView";
import Edit from "../pages/Character-Creation/Edit";
import EditProfile from "../pages/Profile/Edit";
import Support from "../pages/Pages/Support";
import ContactUs from "../pages/Pages/ContactUs";
import Archive from "../pages/Pages/Archive";
import Heros from "../pages/Pages/Heros";
import WallOfFames from "../pages/Pages/WallOfFames";
import Characters from "../pages/Characters/index";
import CharactersView from "../pages/Characters/View";
import GuildNotification from "../pages/Guild/Notification";
import Notification from "../pages/Notification/Notification";
import Portalis from "../pages/Guild/Portalis";
import Hall from "../pages/Guild/Hall";
import HallView from "../pages/Characters/HallView";
import Craft from "../pages/Craft/Craft";
import MyAuction from "../pages/Auction/MyAuction";
import AuctionItemDetails from "../pages/Auction/ItemDetails";
import Auction from "../pages/Auction/Auction";
import NewAuction from "../pages/Auction/NewAuction";
import MyAuctionView from "../pages/Auction/MyAuctionView";
import MyBids from "../pages/Auction/MyBids";
import Pets from "../pages/Inventory/Pets";
import AuctionInventory from "../pages/AuctionInventory/Inventory";
import AuctionInventoryDetail from "../pages/AuctionInventory/ItemDetails";
import Armor from "../pages/Guild/Armor";
import Gallery from "../pages/Pages/Gallery";
import Room from "../components/Room";

export const localRoutes = [
	{
		name: "Home",
		path: "/",
		component: <Home />,
		isHeader: true,
		isFooter: true,
	},
	{
		name: "Rule Book",
		path: "/rulebook",
		component: <RuleBook />,
		isHeader: true,
		isFooter: true,
	},
	{
		name: "Privacy Policy",
		path: "/privacy-policy",
		component: <PrivacyPolicy />,
		isHeader: true,
		isFooter: true,
	},
	{
		name: "The Archive History Pdl",
		path: "/archive-history",
		component: <Archive />,
		isHeader: true,
		isFooter: true,
	},
	{
		name: "Heroes And Villains",
		path: "/heroes-villains",
		component: <Heros />,
		isHeader: true,
		isFooter: true,
	},
	{
		name: "Gallery",
		path: "/gallery",
		component: <Gallery />,
		isHeader: true,
		isFooter: true,
	},
	{
		name: "Seasons",
		path: "/seasons",
		component: <WallOfFames />,
		isHeader: true,
		isFooter: true,
	},
	{
		name: "Terms of Condition",
		path: "/terms-of-condition",
		component: <TermsOfCondition />,
		isHeader: true,
		isFooter: true,
	},
	{
		name: "Support",
		path: "/support",
		component: <Support />,
		isHeader: true,
		isFooter: true,
	},
	{
		name: "ContactUs",
		path: "/contact-us",
		component: <ContactUs />,
		isHeader: true,
		isFooter: true,
	},
	{
		name: "Login",
		path: "/login",
		component: <Login />,
		isHeader: false,
		isFooter: false,
	},
	{
		name: "SignUp",
		path: "/signup",
		component: <Signup />,
		isHeader: false,
		isFooter: false,
	},
	{
		name: "Forgot",
		path: "/forgot",
		component: <Forgot />,
		isHeader: false,
		isFooter: false,
	},
	{
		name: "Recover",
		path: "/recover",
		component: <Recover />,
		isHeader: false,
		isFooter: false,
	},
	{
		name: "Help",
		path: "/help",
		component: "",
		isHeader: true,
		isFooter: true,
	},
	{
		name: "Characters",
		path: "/character/:slug",
		component: <Home />,
		isHeader: true,
		isFooter: true,
	},
	{
		name: "Page Not Found",
		path: "/*",
		component: <NotFound />,
		isHeader: true,
		isFooter: true,
	},
];

export const authRoutes = [
	{
		name: "Welcome",
		path: "/welcome",
		component: <Welcome />,
		isHeader: true,
		isFooter: true,
	},
	{
		name: "Shop",
		path: "/shop",
		component: <Shop />,
		isHeader: true,
		isFooter: true,
	},
	{
		name: "Inventory",
		path: "/inventory",
		component: <Inventory />,
		isHeader: true,
		isFooter: true,
	},
	{
		name: "Inventory Details",
		path: "/inventory/:id",
		component: <ItemsDetails />,
		isHeader: true,
		isFooter: true,
	},
	{
		name: "Auction Inventory",
		path: "/auction-inventory",
		component: <AuctionInventory />,
		isHeader: true,
		isFooter: true,
	},
	{
		name: "Auction Inventory Details",
		path: "/auction-inventory/:id",
		component: <AuctionInventoryDetail />,
		isHeader: true,
		isFooter: true,
	},
	{
		name: "Fish-Inventory",
		path: "/fish-inventory",
		component: <FishInventory />,
		isHeader: true,
		isFooter: true,
	},
	{
		name: "Fish Inventory Details",
		path: "/fish-inventory/:id",
		component: <FishItemsDetails />,
		isHeader: true,
		isFooter: true,
	},
	{
		name: "Enhance",
		path: "/enhance/:id",
		component: <EnhanceItemsDetails />,
		isHeader: true,
		isFooter: true,
	},

	{
		name: "NewAuction",
		path: "/auction",
		component: <NewAuction />,
		isHeader: true,
		isFooter: true,
	},
	{
		name: "Auction",
		path: "/old-auction",
		component: <Auction />,
		isHeader: true,
		isFooter: true,
	},
	{
		name: "My Auction",
		path: "/auction/my",
		component: <MyAuction />,
		isHeader: true,
		isFooter: true,
	},
	{
		name: "My Auction",
		path: "/my-bids",
		component: <MyBids />,
		isHeader: true,
		isFooter: true,
	},
	{
		name: "Create Auction",
		path: "/auction/create",
		component: <Craft />,
		isHeader: true,
		isFooter: true,
	},
	{
		name: "Place A Bid",
		path: "/auction/bid/:id",
		component: <Craft />,
		isHeader: true,
		isFooter: true,
	},
	{
		name: "View Auction Item",
		path: "/auction/view/:id",
		component: <AuctionItemDetails />,
		isHeader: true,
		isFooter: true,
	},
	{
		name: "View My Auction Item",
		path: "/auction/my/:id",
		component: <MyAuctionView />,
		isHeader: true,
		isFooter: true,
	},

	{
		name: "Crafting",
		path: "/crafting",
		component: <Craft />,
		isHeader: true,
		isFooter: true,
	},
	{
		name: "Craft Item",
		path: "/craft-item/:id",
		component: <CraftItemsDetails />,
		isHeader: true,
		isFooter: true,
	},

	{
		name: "Guilds List",
		path: "/guild",
		component: <Guilds />,
		isHeader: true,
		isFooter: true,
	},
	{
		name: "Guild Make",
		path: "/guild/make",
		component: <GuildMake />,
		isHeader: true,
		isFooter: true,
	},
	{
		name: "Guild Notification",
		path: "/guild/notification",
		component: <GuildNotification />,
		isHeader: true,
		isFooter: true,
	},
	{
		name: "Notification",
		path: "/notification",
		component: <Notification />,
		isHeader: true,
		isFooter: true,
	},

	{
		name: "View Guild",
		path: "/guild/:id",
		component: <GuildView />,
		isHeader: true,
		isFooter: true,
	},
	{
		name: "Guild Hall",
		path: "/guild/:id/hall",
		component: <Hall />,
		isHeader: true,
		isFooter: true,
	},
	{
		name: "Guild Armor",
		path: "/guild/:id/armor",
		component: <Armor />,
		isHeader: true,
		isFooter: true,
	},
	{
		name: "Portalis Guild",
		path: "/guild/:slug/:id",
		component: <Portalis />,
		isHeader: true,
		isFooter: true,
	},

	{
		name: "Gifts",
		path: "/gift",
		component: <Gift />,
		isHeader: true,
		isFooter: true,
	},
	{
		name: "New Trade",
		path: "/trade/:id",
		component: <Trade />,
		isHeader: true,
		isFooter: true,
	},
	{
		name: "Search User",
		path: "/user/search/trade",
		component: <SearchUsers />,
		isHeader: true,
		isFooter: true,
	},
	{
		name: "Item Details",
		path: "/shop/:id",
		component: <ItemDetails />,
		isHeader: true,
		isFooter: true,
	},
	{
		name: "Profile",
		path: "/profile",
		component: <Profile />,
		isHeader: true,
		isFooter: true,
	},
	{
		name: "Edit Profile",
		path: "/edit-profile",
		component: <EditProfile />,
		isHeader: true,
		isFooter: true,
	},
	{
		name: "My_Pets",
		path: "/my-pets",
		component: <Pets />,
		isHeader: true,
		isFooter: true,
	},

	{
		name: "All Characters",
		path: "/characters",
		component: <Characters />,
		isHeader: true,
		isFooter: true,
	},
	{
		name: "Character Creation",
		path: "/character-creation",
		component: <Create />,
		isHeader: true,
		isFooter: true,
	},
	{
		name: "Edit Character",
		path: "/character/edit/:id",
		component: <Edit />,
		isHeader: true,
		isFooter: true,
	},
	{
		name: "Character Detail",
		path: "/character/detail/:id",
		component: <Details />,
		isHeader: true,
		isFooter: true,
	},
	{
		name: "Character View",
		path: "/character/view/:id",
		component: <CharactersView />,
		isHeader: true,
		isFooter: true,
	},
	{
		name: "Character View",
		path: "/character/hall/:id",
		component: <HallView />,
		isHeader: true,
		isFooter: true,
	},
	{
		name: "Page Not Found",
		path: "/*",
		component: <NotFound />,
		isHeader: true,
		isFooter: true,
	},
	{
		name: "Room",
		path: "/room",
		component: <Room />,
		isHeader: true,
		isFooter: true,
	},
];
