import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import PDLBorder from "../../components/PDLBorder";
import Logo from "../../components/Logo";
import '../../assets/css/character-creation.css';
import AuthHeader from "../../components/AuthHeader";
import characterCreation from '../../assets/img/character-creation.png';
import {
    createMyCharacterRequest,
    editMyCharacterRequest,
    getCharacterDetailRequest,
    setMyCharacter
} from "../../redux/UserCharacter/action";
import {useNavigate, useParams} from "react-router-dom";
import {setAlert} from "../../redux/Alert/action";
import {setLoader} from "../../redux/Site/action";

const Edit = (props) => {

    const navigator = useNavigate();
    const Param = useParams();
    const [loading, setLoading] = useState(null);
    const [image, setImage] = useState(null);
    const [form, setForm] = useState({
        name: props.details?.character?.name,
        character_id: props.details?.character?.character_id,
        logo: null,
    });
    useEffect(()=>{
        if(props.details?.character){
            setForm({
                name: props.details?.character?.name,
                character_id: props.details?.character?.character_id,
                logo: null,
            });
            setImage(props.details?.character?.logo_url);
        }
        console.log(props.details);
    },[props.details])

    const onChangeHandler = (event) => {
        let {name, value} = event.target;

        setForm((preVal) => {
            return {
                ...preVal,
                [name]: value,
            }
        });
    }

    const onChangeFile = (event) => {
        let files = event.target.files[0];
        let nameArr = files.name.split('.');
        let fileType = nameArr[nameArr.length-1];
        if(
            fileType === 'jpg' || fileType === 'JPG' || 
            fileType === 'png' || fileType === 'PNG' || 
            fileType === 'jpeg' || fileType === 'JPEG'
        ){
            setForm((preVal) => {
                return {
                    ...preVal,logo: files,
                }
            });
            let output = document.getElementById('output');
            output.src = URL.createObjectURL(files);
            setImage(output.src);
            output.onload = function () {
                URL.revokeObjectURL(output.src) // free memory
            }
        }
        else{
            props.setAlert([{message: `Only jpg,png,jpeg is allowed!`,color :'warning'}]);
            return true;
        }
    }


    const onSubmitHandler = async (event) => {
        event.preventDefault();
        let formData = new FormData();
        formData.append('name', form.name);
        formData.append('logo', form.logo);
        formData.append('character_id', form.character_id);
        setLoading(true);
        props.editMyCharacter(Param?.id,formData)
            .then(() => {
                setLoading(false);
                // navigator(`/character/detail/${Param?.id}`, { replace: true });
                navigator(`/characters`, { replace: true });
            })
            .catch((e) => {
                setLoading(false);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const get = async (id,type) => {
        props.setLoader(true);
        await props.getCharacterDetail(id,type);
        props.setLoader(false);
    }

    useEffect(() => {
        get(Param?.id,1);
        return () => {

        }
    }, []);

    return (
        <section className="fram-sec">
            <div className="frame-wrapper">

                {/* Border*/}
                <PDLBorder/>
                {/*Logo*/}
                <Logo/>

                {/*Menus*/}
                <div className="row">
                    {/*<div className="col-md-7">*/}

                    {/*</div>*/}
                    <div className="col-md-12">
                        <div className="Menus text-right">
                            <AuthHeader/>
                        </div>
                    </div>
                </div>
                {/*Menus*/}

                <section className="character">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3"></div>
                            <div className="col-lg-6">
                                <div className="character-creation text-center">
                                    <h1 className={"pdlStyleParagraph"}>Edit Character</h1>
                                    {/* <figure>
                                        <img src={characterCreation} className="img-fluid" alt=""/>
                                    </figure> */}
                                </div>
                                <div className="character-form-wrapper">
                                    <form onSubmit={onSubmitHandler}>
                                        <div className="from-group row">
                                            <div className="col-md-6">
                                                <select defaultValue={form.character_id} onChange={onChangeHandler} name="character_id"
                                                        className={"form-control inputCustomHeight character-name"}>
                                                    {/*<option value="" hidden>Select Character</option>*/}
                                                    {
                                                        props.character.map((val, key) => {
                                                            return <option selected={(form.character_id == val.id) && true} key={key} value={val.id}>{val.title}</option>
                                                        })
                                                    }
                                                </select>

                                            </div>
                                            <div className="col-md-6">
                                                <input type="text" onChange={onChangeHandler} required={true}
                                                       name={"name"} defaultValue={form.name}
                                                       className="inputCustomHeight form-control"
                                                       placeholder="Type Character Name"/>
                                            </div>
                                        </div>

                                        <div className="form-group row mt-4">
                                            <div className="col-md-4">
                                                <div className="input-images">
                                                    <div className="image-uploader" id={"output"} style={
                                                        image && {
                                                            backgroundImage: `url('${image}')`,
                                                        }
                                                    }>
                                                        {/*<label htmlFor="customFile">*/}
                                                        {/*    <div className="upload-text">*/}
                                                        {/*        /!*<i className="material-icons">cloud_upload</i>*!/*/}
                                                        {/*        <span>Upload logo</span>*/}
                                                        {/*    </div>*/}
                                                        {/*</label>*/}
                                                        {/*<input type="file"*/}
                                                        {/*       onChange={onChangeFile}*/}
                                                        {/*       id="customFile"*/}
                                                        {/*       name="logo"/>*/}
                                                        {/*<div className="uploaded"></div>*/}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group mt-4">
                                            {
                                                loading ?
                                                    <button type={"button"} className="btn">
                                                        <i className={"fa fa-spinner fa-spin"}></i>
                                                    </button>
                                                    :
                                                    <button type={"submit"} className="btn">submit</button>
                                            }
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-lg-3"></div>
                        </div>
                    </div>
                </section>

            </div>
        </section>
    );
}

const mapStateToProps = (state) => {
    return {
        islogin: state.Auth.islogin,
        character: state.Site.characters,
        details: state.UserCharacter.details,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setLoader: (bool) => dispatch(setLoader(bool)),
        editMyCharacter: (id,data) => dispatch(editMyCharacterRequest(id,data)),
        setAlert: (data) => dispatch(setAlert(data)),
        getCharacterDetail: (id,item_for) => dispatch(getCharacterDetailRequest(id,item_for)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Edit);