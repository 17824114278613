import React, { useEffect, useMemo, useState } from 'react';
import { connect, useDispatch, useSelector } from "react-redux";
import PDLBorder from "../../components/PDLBorder";
import Logo from "../../components/Logo";
import AuthHeader from "../../components/AuthHeader";
import { setLoader } from "../../redux/Site/action";
import { useParams } from "react-router-dom";
import NotFound from "../NotFound/NotFound";
import '../../assets/css/place-bid.css';
import { forHumans, getDayHourMinSec, getSecondsFromStartEnd, showLoader } from "../../redux/helpers";
import { placeABidRequest, setViewItemStatus, viewAuctionRequest } from "../../redux/Auction/action";
import { useCountdown } from "../../hooks/useCountdown";
import PlayerTop from '../../assets/img/playertop.png';
import PlayerBottom from '../../assets/img/playerbottom.png';
import { setAlert } from "../../redux/Alert/action";
import moment from "moment";
import { NumericFormat } from 'react-number-format';


// export const CountDownTimer = ({ time, item }) => {
//     const dispatch = useDispatch();
//     const [countDown, setCountDown] = useState(time);

//     // useEffect(() => {
//     //     const interval = setInterval(() => {
//     //         setCountDown((preVal) => {
//     //             return preVal - 1;
//     //         });
//     //     }, 1000);

//     //     if (countDown <= 0) {
//     //         dispatch(setViewItemStatus((item?.status === 0) ? 1 : (item?.status === 1) ? 2 : 2));
//     //         clearInterval(interval);
//     //     }

//     //     return () => {
//     //         clearInterval(interval);
//     //     };
//     // }, [countDown]);

//     // useEffect(() => {
//     //     setCountDown(time);
//     // }, [item?.id])
//     useEffect(() => {
//         let interval;

//         if (countDown > 0) {
//             interval = setInterval(() => {
//                 setCountDown(prevCount => prevCount - 1);
//             }, 1000);
//         } else {
//             // Dispatch action to update item status when countdown reaches zero
//             dispatch(setViewItemStatus((item?.status === 0) ? 1 : (item?.status === 1) ? 2 : 2));
//         }

//         return () => {
//             clearInterval(interval);
//         };
//     }, [countDown, dispatch, item]);

//     useEffect(() => {
//         // Reset countdown when item id changes
//         setCountDown(time);
//     }, [time, item]);

//     useMemo(() => {
//         if (item?.status === 0) {
//             setCountDown(getSecondsFromStartEnd(`${item?.end_date} ${item?.end_time}`, `${item?.start_date} ${item?.start_time}`))
//         } else if (item?.status === 1) {
//             setCountDown(getSecondsFromStartEnd(`${item?.start_date} ${item?.start_time}`, `${item?.end_date} ${item?.end_time}`))
//         }
//     }, [item?.status])

//     return (
//         <span className="time d-flex flex-row justify-content-center">
//             {forHumans(countDown)}
//         </span>
//     );
// }


export const CountDownTimer = ({ time, item }) => {
    const dispatch = useDispatch();
    const [countDown, setCountDown] = useState(time);

    useEffect(() => {
        const interval = setInterval(() => {
            setCountDown((preVal) => {
                return preVal - 1;
            });
        }, 1000);

        if (countDown <= 0) {
            dispatch(setViewItemStatus((item?.status === 0) ? 1 : (item?.status === 1) ? 2 : 2));
            clearInterval(interval);
        }

        return () => {
            clearInterval(interval);
        };
    }, [countDown]);

    useEffect(() => {
        setCountDown(time);
    }, [item?.id])

    useMemo(() => {
        if (item?.status === 0) {
            setCountDown(getSecondsFromStartEnd(`${item?.end_date} ${item?.end_time}`, `${item?.start_date} ${item?.start_time}`))
        } else if (item?.status === 1) {
            setCountDown(getSecondsFromStartEnd(`${item?.start_date} ${item?.start_time}`, `${item?.end_date} ${item?.end_time}`))
        }
    }, [item?.status])

    return (
        <span className="time d-flex flex-row justify-content-center">
            {forHumans(countDown)}
        </span>
    );
}

const ItemDetails = (props) => {

    const { id } = useParams();
    const dispatch = useDispatch();
    const [amount, setAmount] = useState(0);
    const [loading, setLoading] = useState(false);
    const item = useSelector((state) => state?.Auction?.item);
    const auth_user = useSelector((state) => state?.Auth?.user);
    const currency = useSelector((state) => state?.Site?.currency);
    const [time, setTime] = useState(
        getSecondsFromStartEnd(
            `${item?.start_date} ${item?.start_time}`,
            (item?.status === 1) ? `${item?.end_date} ${item?.end_time}` : `${item?.start_date} ${item?.start_time}`
        ) || 0
    );

    const get = async () => {
        showLoader('flex');
        await dispatch(viewAuctionRequest(id));
        showLoader('none');
    }


    const placeBid = async () => {
        if (parseInt(amount) < 10) {
            dispatch(setAlert([{
                message: 'Minimum bid amount required is : 10', color: 'warning'
            }]));
            return false;
        }
        if (parseInt(amount) < parseInt(item?.min_amount.replace(/,/g, ''))) {
            dispatch(setAlert([{
                message: 'Minimum bid amount is :' + item?.min_amount, color: 'warning'
            }]));
            return false;
        }
        setLoading(true);
        await dispatch(placeABidRequest({ auction_id: id, amount: amount }))
            .then(() => {
                setLoading(false);
                setAmount(0);
            })
            .catch(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        get();

        return () => {

        }
    }, [id]);

    if (!item) return <NotFound />


    return (
        <section className="fram-sec craft">
            <div className="frame-wrapper">

                {/* Border*/}
                <PDLBorder />
                {/*Logo*/}
                <Logo />

                {/*Menus*/}
                <div className="row">
                    {/*<div className="col-md-7">*/}

                    {/*</div>*/}
                    <div className="col-md-12">
                        <div className="Menus text-right">
                            <AuthHeader />
                        </div>
                    </div>
                </div>
                {/*Menus*/}
                <section className="inventory-detailed">
                    <div className="container-fluid mx-2">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-3">
                                <div className="forTop-border py-1">
                                    <figure><img className="w-100" src={PlayerTop} alt="" /></figure>
                                </div>
                                <div className="Player-statusDv">
                                    <div className="head">
                                        <h3 className="title">
                                            Item Status
                                        </h3>
                                    </div>
                                    <ul className="status-list">
                                        <li>
                                            <h4>Level</h4>
                                            <span>{item?.shop?.level}</span>
                                        </li>

                                        <li>
                                            <h4>Health</h4>
                                            <span>{item?.inventory?.health}</span>
                                        </li>

                                        <li>
                                            <h4>Base</h4>
                                            <span>{item?.inventory?.base}</span>
                                        </li>

                                        <li>
                                            <h4>Damage</h4>
                                            <span>{item?.inventory?.damage}</span>
                                        </li>

                                        <li>
                                            <h4>Armor Pen</h4>
                                            <span>{item?.inventory?.armor_pen}</span>
                                        </li>

                                        <li>
                                            <h4>Armor Pen Resist</h4>
                                            <span>{item?.inventory?.armor_pen_resist}</span>
                                        </li>
                                        <li>
                                            <h4>Quantity</h4>
                                            <span>{item?.qty}</span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="forBottom-border py-1">
                                    <figure><img className="w-100" src={PlayerBottom} alt="" /></figure>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="product-details">
                                    <div className="heading">
                                        {
                                            item?.status === 2 ?
                                                <>
                                                    <h4 className={"end-date"}>
                                                        Auction Ended
                                                    </h4>
                                                </>
                                                :
                                                <>
                                                    {
                                                        (item?.status === 0 || item?.status === 1) &&
                                                        <h4 className={"end-date d-flex"}>
                                                            Auction {item?.status === 0 ? 'Start' : 'Ends'} In
                                                                <br />
                                                            {
                                                                item?.status === 0 ?
                                                                    <>
                                                                        <CountDownTimer
                                                                            item={item}
                                                                            time={
                                                                                getSecondsFromStartEnd(`${item?.end_date} ${item?.end_time}`, `${item?.start_date} ${item?.start_time}`)
                                                                            }
                                                                        />
                                                                    </>
                                                                    :
                                                                    <CountDownTimer
                                                                        item={item}
                                                                        time={
                                                                            getSecondsFromStartEnd(`${moment().format('DD.MM.YYYY HH:mm:ss')}`, `${item?.end_date} ${item?.end_time}`)
                                                                        }
                                                                    />
                                                            }
                                                        </h4>
                                                    }
                                                </>
                                        }
                                    </div>
                                    <div className="image-with-btn">
                                        <div className="img-box">
                                            <figure><img src={item?.shop?.image_url} alt="" /></figure>
                                        </div>
                                    </div>
                                    <div className="description">
                                        <p>{item?.shop?.description}</p>
                                    </div>


                                    {auth_user.id != item.owner_id ?
                                        <>
                                            {console.log('auth_user:', auth_user.id, 'owner_id:', item.owner_id)}
                                            <div className="title">
                                                <h3>place your bid</h3>
                                            </div>
                                            <div className=" button-group">
                                                {
                                                    currency?.filter((val) => val?.slug === item?.currency_slug)
                                                        ?.map((val, key) => (
                                                            <figure className="" key={key}>
                                                                <img
                                                                    src={val?.icon_url}
                                                                    width={'100%'}
                                                                    className={"img-fluid"}
                                                                />
                                                            </figure>
                                                        ))
                                                }
                                                {
                                                    item?.status === 0 &&
                                                    <p className={"text-success"}>Auction not started yet</p>
                                                }
                                                {
                                                    item?.status === 2 &&
                                                    <p className={"text-danger"}>Auction time is completed</p>
                                                }
                                                {
                                                    item?.status === 1 &&
                                                    <>
                                                        <NumericFormat
                                                            value={amount}
                                                            thousandSeparator
                                                            onValueChange={(values, sourceInfo) => {
                                                                // console.log(values?.value, sourceInfo);
                                                                setAmount(values?.value);
                                                            }}
                                                        />;
                                                        {/*<input type="text"*/}
                                                        {/*       placeholder="00.00"*/}
                                                        {/*       onChange={(e) => setAmount(e.target.value)}*/}
                                                        {/*       className="" value={amount}/>*/}
                                                        <button type={"button"} onClick={placeBid} className="btn">
                                                            {
                                                                loading ?
                                                                    <i className={"fa fa-spin fa-spinner"}></i>
                                                                    :
                                                                    'Bid'
                                                            }
                                                        </button>
                                                    </>
                                                }
                                                {/*</div>*/}
                                            </div>
                                            {
                                                item?.status === 1 &&
                                                <div>
                                                        <p className={"text-danger mt-3"}>Minimum bid is {item?.min_amount}</p>
                                                </div>
                                            }
                                        </>
                                        : <>
                                            <p className='text-warning'>You are the owner of this auction</p>
                                        </>
                                    }
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="forTop-border py-1">
                                    <figure><img className="w-100" src={PlayerTop} alt="" /></figure>
                                </div>
                                <div className="Player-statusDv" style={{
                                    maxHeight: '30rem',
                                    overflow: 'scroll',
                                }}>
                                    <div className="head">
                                        <h3 className="title">
                                            Bidding
                                        </h3>
                                    </div>
                                    <ul className="status-list">
                                        {
                                            item?.bids?.map((val, key) => (
                                                <li key={key}>
                                                    <h4>{val?.user?.name}</h4>
                                                    <span
                                                        className={"d-flex align-item-center justify-content-between"}>
                                                        {
                                                            currency?.filter((val) => val?.slug === item?.currency_slug)?.map((val, key) => (
                                                                <figure className="" key={key}>
                                                                    <img src={val?.icon_url} width={'30px'}
                                                                        height={'30px'} className={"img-fluid"} />
                                                                </figure>
                                                            ))
                                                        }
                                                        {/* {parseInt(val?.amount).toLocaleString()} */}
                                                        {val?.amount}
                                                    </span>
                                                </li>
                                            ))
                                        }

                                    </ul>
                                </div>
                                <div className="forBottom-border py-1">
                                    <figure><img className="w-100" src={PlayerBottom} alt="" /></figure>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </div>
        </section>
    );
}

const mapStateToProps = (state) => {
    return {
        item: state.Auction.item,
        loader: state.Site.loader,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setLoader: (bool) => dispatch(setLoader(bool)),
        // getItem: (id) => dispatch(getItemRequest(id)),
        // buyItem : (data) => dispatch(buyItemRequest(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemDetails);