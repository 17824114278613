import React, {useEffect, useState} from 'react';
import {connect, useDispatch, useSelector} from "react-redux";
import PDLBorder from "../../components/PDLBorder";
import Logo from "../../components/Logo";
import AuthHeader from "../../components/AuthHeader";
import PlayerBottom from '../../assets/img/playerbottom.png';
import PlayerTop from '../../assets/img/playertop.png';
import { createCraftItemRequest, getItemRequest} from "../../redux/Craft/action";
import {setLoader} from "../../redux/Site/action";
import {Link, useNavigate, useParams} from "react-router-dom";
import NotFound from "../NotFound/NotFound";
import craftingText from '../../assets/img/crafting-txt.png';
import '../../assets/css/style.css';
import '../../assets/css/craft-item.css';
import '../../assets/css/inventory-detail.css';
import Loader from "../../components/Loader";
import {showLoader} from "../../redux/helpers";


const CraftItemsDetails = (props) => {

    const {id} = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading , setLoading ] = useState(false);
    const item = useSelector((state )=> state?.Craft?.item);

    const get = async () => {
        showLoader('flex');
        // dispatch(setLoader(true));
        await dispatch(getItemRequest(id));
        // props.setLoader(false);
        showLoader('none');
        // dispatch(setLoader(false));
    }


    const buy = async () => {
        setLoading(true);
        await dispatch(createCraftItemRequest({ id : id}))
        // setLoading(false);
        // createCraftItemRequest({item_id : id})
            .then(() => {
                setLoading(false);
                // console.log("/inventory");
                navigate('/inventory');
            })
            .catch(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        get();
        return () => {}
    }, [id]);

    if(!item) return <NotFound />

    // if(item) return <NotFound content={"Item is not available for purchase."} />

    return (
        <section className="fram-sec craft">
            <div className="frame-wrapper">

                {/* Border*/}
                <PDLBorder/>
                {/*Logo*/}
                <Logo/>

                {/*Menus*/}
                <div className="row">
                    {/*<div className="col-md-7">*/}

                    {/*</div>*/}
                    <div className="col-md-12">
                        <div className="Menus text-right">
                            <AuthHeader/>
                        </div>
                    </div>
                </div>
                {/*Menus*/}

                <section className="crafted-items">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-8">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="spacer"></div>
                                        <div className="forTop-border py-1">
                                            <figure>
                                                <img className="w-100" src={PlayerTop} alt="" />
                                            </figure>
                                        </div>

                                        <div className="inventroy-Box">
                                            <div className="head">
                                                <h3 className=" pdlStyleHeading">
                                                    Item Status
                                                </h3>
                                            </div>
                                            <ul className="inventories pl-0">
                                                <li className={"d-flex flex-row justify-content-between align-items-center"}>
                                                    <h4 className={"pdlStyleParagraph"}>Level</h4>
                                                    <span className={"pdlStyleParagraph"}>{item?.level}</span>
                                                </li>
                                                <br/>
                                                <li className={"d-flex flex-row justify-content-between align-items-center"}>
                                                    <h4 className={"pdlStyleParagraph"}>Health</h4>
                                                    <span className={"pdlStyleParagraph"}>{item?.health}</span>
                                                </li>
                                                <br/>
                                                <li className={"d-flex flex-row justify-content-between align-items-center"}>
                                                    <h4 className={"pdlStyleParagraph"}>Base</h4>
                                                    <span className={"pdlStyleParagraph"}>{item?.base}</span>
                                                </li>
                                                <br/>

                                                <li className={"d-flex flex-row justify-content-between align-items-center"}>
                                                    <h4 className={"pdlStyleParagraph"}>Damage</h4>
                                                    <span className={"pdlStyleParagraph"}>{item?.damage}</span>
                                                </li>
                                                <br/>

                                                <li className={"d-flex flex-row justify-content-between align-items-center"}>
                                                    <h4 className={"pdlStyleParagraph"}>Armor Pen</h4>
                                                    <span className={"pdlStyleParagraph"}>{item?.armor_pen}</span>
                                                </li>
                                                <br/>

                                                <li className={"d-flex flex-row justify-content-between align-items-center"}>
                                                    <h4 className={"pdlStyleParagraph"}>Armor Pen Resist</h4>
                                                    <span className={"pdlStyleParagraph"}>{item?.armor_pen_resist}</span>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="forBottom-border py-1">
                                            <figure>
                                                <img className="w-100" src={PlayerBottom} alt="" />
                                            </figure>
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="crafted-box">
                                            <div className="heading-wrapper">
                                                <div className="title">
                                                    <figure>
                                                        <img src={craftingText} alt="" className="img-fluid"/>
                                                    </figure>
                                                </div>
                                                <h4>Create this item</h4>
                                            </div>
                                            <div className="craft-img">
                                                <figure>
                                                    <img src={item?.image_url} alt="" className="img-fluid" />
                                                </figure>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="required_items">
                                    <div className="title">
                                        {
                                            item?.craft_items?.length > 0 ?
                                                <h4>Required elements</h4>
                                                :
                                                <h4>No required items found!</h4>
                                        }
                                    </div>
                                    <ul className="items-boxes">
                                        {
                                            item?.craft_items?.map((val,key)=>(
                                                <li key={key}>
                                                    <div className="item-box" onClick={()=>navigate(`/shop/${val?.item?.id}`)}
                                                         style={{
                                                             position: 'relative'
                                                         }}>
                                                        <img src={val?.item?.image_url} width={'100%'} alt=""/>
                                                        <div className="count">
                                                            <span className="large">{val?.qty}</span>
                                                        </div>
                                                        {
                                                            !( item?.myItems?.find((value) => (parseInt(value?.item_id) === parseInt(val?.item?.id)) && ( parseInt(value?.qty) >= parseInt(val?.qty)) ) ) &&
                                                                <div style={{
                                                                    position: 'absolute',
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    flexDirection: 'row',
                                                                    background: 'rgb(88 88 88 / 50%)',
                                                                    left: 0,
                                                                    right: 0,
                                                                    top: 0,
                                                                    bottom: 0,
                                                                }}>
                                                                    <h1 className={"text-white"}>
                                                                        <i className={"fa fa-lock"}
                                                                           style={{color: '#cbcbcb'}}></i>
                                                                    </h1>
                                                                </div>
                                                        }
                                                    </div>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                    {
                                        item?.craft_items?.length > 0 &&
                                        <div className="button-group">
                                            <button type={"button"} onClick={buy} className="created">
                                                {
                                                    loading ?
                                                        <i className={"fa fa-spin fa-spinner"}></i>
                                                        :
                                                        'Create'
                                                }
                                            </button>
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </section>
);
}

const mapStateToProps = (state) =>
    {
        return {
            item: state.Shop.item,
            loader: state.Site.loader,
        }
    }

const mapDispatchToProps = (dispatch) =>
    {
        return {
            setLoader: (bool) => dispatch(setLoader(bool)),
            // getItem: (id) => dispatch(getItemRequest(id)),
            // buyItem : (data) => dispatch(buyItemRequest(data)),
        }
    }

export default connect(mapStateToProps, mapDispatchToProps)(CraftItemsDetails);