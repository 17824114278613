import React, {useEffect, useState} from 'react';
import {connect, useSelector} from "react-redux";
import Logo from "../../components/Logo";
import '../../assets/css/guild-armor.css';
import AuthHeader from "../../components/AuthHeader";
import {Link, useParams} from "react-router-dom";
import {
    addItemInTrade,
} from "../../redux/Trade/action";
import {getImageFromStorage, showLoader} from "../../redux/helpers";
import PDLBorder from "../../components/PDLBorder";
import {acceptGuildRequest, cancelGuildRequest, getGuildRequest} from "../../redux/Guild/action";
import {getItemsRequest} from "../../redux/Inventory/action";
import guildText from '../../assets/img/guild-text.png';
import ItemWrapper from "../../components/ItemWrapper";
import SendInventoryModal from "../../components/Modals/SendInventoryModal";
import inventorybg from "../../assets/img/inventorybg.png";
import armoryText from "../../assets/img/armory-text.png";
import GuildMenu from "./GuildMenu";


const Armor = (props) => {

    const params = useParams();
    const guild = useSelector(state => state?.Guild?.guild);
    const [open, setOpen] = useState(false);
    const {selected_character} = useSelector((state) => state?.Auth);
    const [inventoryId, setInventoryId] = useState(0);

    const get = async () => {
        showLoader('flex');
        await props.getGuild(params.id);
        await props.getItems();
        showLoader('none');
    }

    useEffect(() => {
        get();
    }, [params.id])

    return (
        <div className={"fram-sec"}>
            <div className="frame-wrapper-armor" style={
                {
                    backgroundImage: `url('${(guild?.armory_image) ? getImageFromStorage(guild?.armory_image) : inventorybg }')`
                }}>
                {/* Border*/}
                <PDLBorder/>
                {/*Logo*/}
                <Logo/>

                {/*Menus*/}
                <div className="row">
                    {/*<div className="col-md-7">*/}

                    {/*</div>*/}
                    <div className="col-md-12">
                        <div className="Menus text-right">
                            <AuthHeader/>
                        </div>
                    </div>
                </div>

                <section className="guild_sec">
                    <div className="container">

                        <GuildMenu/>

                        <div className=" d-flex flex-row justify-content-center">
                            {/*<div className="button-group">*/}
                            {/*    <Link to={`/guild/${params.id}`} className="btnn">Back</Link>*/}
                            {/*</div>*/}
                            <div className="title">
                                <figure className={"d-flex justify-content-center "}>
                                    <img src={armoryText} width={'25%'} alt="" className="img-fluid"/>
                                </figure>
                            </div>
                        </div>
                        <div className="all-guild">
                            <div className="row">
                                {
                                    guild?.inventory?.map((val, key) => (
                                        <ItemWrapper
                                            key={key}
                                            className="col-lg-2 col-md-2"
                                            item={{
                                                title: val?.item?.title,
                                                health: val?.health,
                                                base: val?.base,
                                                damage: val?.damage,
                                                armor_pen: val?.armor_pen,
                                                armor_pen_resist: val?.armor_pen_resist,
                                            }}>
                                            <div className="guild-box" onClick={() => {
                                                if (
                                                    guild?.leader_id === selected_character ||
                                                    guild?.co_leader_id === selected_character
                                                ) {
                                                    setInventoryId(val?.id);
                                                    setOpen(true);
                                                }
                                                // console.log("clicked",val?.leader_id,val?.co_leader_id,selected_character);
                                            }}>
                                                {/*<Link to={'/character/hall/'+val?.character_id}>*/}
                                                <div className="img-box">
                                                    <figure className={"m-0"}>
                                                        <img src={val?.shop?.image_url} alt="" className="img-fluid"/>
                                                    </figure>
                                                    <div className="count">
                                                        <span className="large">{val?.qty}</span>
                                                    </div>
                                                </div>
                                                <div className="txt">
                                                    <h4>{val?.shop?.title}</h4>
                                                </div>
                                                {/*</Link>*/}
                                            </div>
                                        </ItemWrapper>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </section>

                <SendInventoryModal open={open} setOpen={setOpen} inventoryId={inventoryId}/>

            </div>
        </div>
    );
}


const mapStateToProps = (state) => {
    return {
        user: state.Auth.user,
        guild: state.Guild.guild,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getGuild: (id) => dispatch(getGuildRequest(id)),
        acceptGuild: (data) => dispatch(acceptGuildRequest(data)),
        cancelGuild: (data) => dispatch(cancelGuildRequest(data)),
        getItems: (url = null, search = null) => dispatch(getItemsRequest(url, search)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Armor);