import {LOGIN, LOGOUT, RESET_ALERT, SET_ALERT, SET_USER} from "../constants";
import {headers} from "../headers";
import {loginAPi, signUpAPi} from "../apis";
import axios from "axios";

export const alert = (alertArr) => {
    return {
        type : SET_ALERT,
        payload : alertArr
    }
}


export const resetError  = () =>{
    return {
        type : RESET_ALERT,
    }
}

export const setAlert = (alertArr) => {
    return (dispatch) => {
        dispatch(alert(alertArr));
        setTimeout(()=>{
            dispatch(resetError())
        },10000);
    }
}