import {
    ADD_PET_ITEM_TO_FAVOURITE,
    SET_ITEM,
    SET_ITEMS,
} from "../constants";
import {headers, headersWithToken} from "../headers";
import {addFavoritePetApi, buyItemAPi, getItemAPi, getItemsAPi, getPetsInventoryApi} from "../apis";
import axios from "axios";
import {setAlert} from "../Alert/action";
import {responseError} from "../errorHandler";

export const setItems = (data) => {
    return {
        type: SET_ITEMS,
        payload: data
    }
}

export const getItemsRequest = (url  = null,search=null) => {
    return async (dispatch, getState) => {
        try {
            let token = getState().Auth.token;
            let link = url? url :  getItemsAPi+(search? search : '');
            let response = await axios.get(link, headersWithToken(token));
            dispatch(setItems(response.data.response.data));
        } catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    }
}

export const getPetsItemsRequest = (url  = null,search=null) => {
    return async (dispatch, getState) => {
        try {
            let token = getState().Auth.token;
            let link = url? url :  getPetsInventoryApi + (search? search : '');
            let response = await axios.get(link, headersWithToken(token));
            dispatch(setItems(response.data.response.data));
        } catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    }
}

export const setItem = (data) => {
    return {
        type: SET_ITEM,
        payload: data
    }
}

export const getItemRequest = (id) => {
    return async (dispatch, getState) => {
        try {
            let token = getState().Auth.token;
            let response = await axios.get(getItemAPi(id), headersWithToken(token));
            dispatch(setItem(response.data.response.data?.shop));
        } catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    }
}

export const buyItemRequest = (data) => {
    return async (dispatch, getState) => new Promise(async (resolve, reject)=>{
        try {
            let token = getState().Auth.token;
            let response = await axios.post(buyItemAPi,data, headersWithToken(token));
            dispatch(setAlert([{message : 'item buy successfully' ,color : 'success'}]));
            return resolve(true);
        } catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
            return reject(false);
        }
    });
}

export const setFavouritePet = (data) => {
    return {
        type: ADD_PET_ITEM_TO_FAVOURITE,
        payload: data
    }
}
export const addFavoritePetRequest = ( data  ) => {
    return async (dispatch, getState) => {
        try {
            await dispatch(setFavouritePet(data));
            let token = getState().Auth.token;
            let response = await axios.post(addFavoritePetApi,data, headersWithToken(token));
            // await dispatch(()=>({
            //     type : ADD_PET_ITEM_TO_FAVOURITE,
            //     payload : data
            // }));
        } catch (e) {
            await dispatch(setFavouritePet({
                ...data,
                is_pet_favourite : (data?.is_pet_favourite === 1)? 0 : 1
            }));
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    }
}