import {
    FILTER_INVENTORY_ITEM,
    RESET_NEW_GEMS_GLYPHS,
    RETURN_BAGS_ITEMS,
    SET_BAGS_ITEMS,
    SET_BIONIC_ITEMS,
    SET_CHARACTER_IN_INVENTORY_ITEM, SET_GALLRY_ITEMS,
    SET_GEMS_GLYPHS,
    SET_INVENTORY_ITEM,
    SET_INVENTORY_ITEMS,
    SET_NEW_GEMS_GLYPHS
} from "../constants";

const initState = {
    items : null,
    item : null,
    bags : [],
    gemsGlyphs : [],
    match_inventory : [],
    gallry : [],
}


const Inventory = (state = initState , action) => {
    switch (action.type) {

        case SET_INVENTORY_ITEMS :
            return {
                ...state,
                items : action.payload,
            }
        case SET_INVENTORY_ITEM :
            return {
                ...state,
                item : action.payload,
                match_inventory : action.match_inventory,
            }
        case SET_GEMS_GLYPHS :
            return {
                ...state,
                gemsGlyphs : action.payload,
            }
        case SET_GALLRY_ITEMS :
            return {
                ...state,
                gallry : action.payload,
            }
        case SET_BIONIC_ITEMS :
            return {
                ...state,
                items: {
                    ...state.items,
                    data : state.items?.data?.filter((val)=>val?.id != action.payload),
                }
            }
        case SET_NEW_GEMS_GLYPHS :
            return {
                ...state,
                item: {
                    ...state.item,
                    power_items : action.payload,
                }
            }
        case SET_CHARACTER_IN_INVENTORY_ITEM:
            return {
                ...state,
                item: {
                    ...state.item,
                    character_id : action.payload,
                }
            }
        case RESET_NEW_GEMS_GLYPHS :
            return {
                ...state,
                item: {
                    ...state.item,
                    ...action.payload?.stats,
                    power_items: state.item.power_items.filter((val)=>val?.id !== action.payload?.id),
                }
            }
        case SET_BAGS_ITEMS :
            return {
                ...state,
                bags: [ ...action.payload ],
            }
        case RETURN_BAGS_ITEMS :
            return {
                ...state,
                bags: [...state.bags.filter((val)=> parseInt(val?.id) !== parseInt(action.payload))]
            }

        default: return state;
    }
}

export default Inventory;