import React, {useEffect} from 'react';
import PDLBorder from "../../components/PDLBorder";
import Logo from "../../components/Logo";
import AuthHeader from "../../components/AuthHeader";
import WelcomeImage from '../../assets/img/welcome.png';
import WelcomeBg from '../../assets/img/welcome-bg.png';
import WelcomeWrapper from '../../assets/img/welcome-wrapper.png';
import {connect} from "react-redux";
import {useNavigate} from "react-router-dom";
import '../../assets/css/welcome.css';

const Welcome = (props) => {

    const navigate = useNavigate();

    const continueWelcome = () => {
        localStorage.setItem('welcome',true);
        navigate('/',{replace : true});
    }

    useEffect(()=>{

        return () => {

        }
    },[]);

    return (
        <>
            <section className="fram-sec">
                <div className="frame-wrapper" style={{
                    backgroundImage : `url('${WelcomeBg}')`,
                    backgroundSize: '100% 100%',
                    backgroundRepeat: 'no-repeat',
                    padding: '3rem 2rem',
                    margin: '4.5rem 2rem',
                    position: 'relative',
                }}>
                    {/* Border*/}
                    <PDLBorder />
                    {/*Logo*/}
                    <Logo />

                    {/*Menus*/}
                    <div className="row">
                        {/*<div className="col-md-7">*/}

                        {/*</div>*/}
                        <div className="col-md-12">
                            <div className="Menus text-right">
                                <AuthHeader/>
                            </div>
                        </div>
                    </div>
                    {/*Menus*/}

                    {/*Main Content Start Here*/}
                    <div className="main-content">
                        <div className="container-fluid">
                            <div className="row playrow">
                                <div className="col-lg-3">

                                </div>
                                <div className="col-lg-6">
                                    <div className="welcome-wrapper text-center" style={{
                                        backgroundImage : `url('${WelcomeWrapper}')`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: '100% 100%',
                                        padding: '7rem 2rem',
                                    }}>
                                        <figure className="mb-5">
                                            <img src={WelcomeImage} className="img-fluid" alt="" />
                                        </figure>
                                        <p className="welcome-des"  style={{
                                            color: '#F5D58E',
                                            fontFamily : 'montserrat',
                                            fontSize : '16px'
                                        }}
                                            dangerouslySetInnerHTML={{ __html: props.setting?.home_second_text}}
                                        />
                                        <button type={"button"} onClick={continueWelcome} className="form-submit">Continue....</button>
                                    </div>
                                </div>
                                <div className="col-lg-3">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        setting: state.Site.setting,
    }
}

export default connect(mapStateToProps)(Welcome);