import React, {useEffect, useState} from 'react';
import {connect, useSelector} from "react-redux";
import Logo from "../../components/Logo";
import '../../assets/css/guild.css';
import AuthHeader from "../../components/AuthHeader";
import {Link, useNavigate, useParams} from "react-router-dom";
import {
    addItemInTrade,
} from "../../redux/Trade/action";
import {showLoader} from "../../redux/helpers";
import PDLBorder from "../../components/PDLBorder";
import GuildImage from '../../assets/img/notificationText.png';
import Macrus from '../../assets/img/macrus.png';
import Member from '../../assets/img/member.png';
import {
    acceptGuildRequest,
    cancelGuildRequest,
    getListGuildRequest,
    getNotificationGuildRequest
} from "../../redux/Guild/action";
import JoinRequest from '../../components/Modals/JoinRequest';
import Navigation from "../../components/navigation";
import {getMyCharactersRequest} from "../../redux/UserCharacter/action";
import {
    deleteAllNotificationsRequest,
    deleteNotificationRequest,
    getNotificationsRequest
} from "../../redux/Site/action";
import AuctionPreview from "../../components/Modals/AuctionPreview";

const Notification = (props) => {

    const [show, setShow] = useState(false);
    const [deleteAll, setDeleteAll] = useState(false);
    const [data, setData] = useState(null);

    const params = useParams();
    const get = async () => {
        showLoader('flex');
        await props.getNotifications();
        showLoader('none');
    }
    const deleteAllNotification = async () => {
        setDeleteAll(true);
        await props?.deleteAllNotifications();
        setDeleteAll(false);
    }

    const acceptGuildRequest = async (id) => {
        showLoader('flex');
        await props.acceptGuild({notification_id: id});
        await props.getNotifications();
        showLoader('none');
    }
    const rejectGuildRequest = async (id) => {
        showLoader('flex');
        await props.cancelGuild({notification_id: id});
        await props.getNotifications();
        showLoader('none');
    }

    useEffect(() => {
        get();
        return () => {
        }
    }, [])

    console.log(props.notification);

    return (
        <section className="fram-sec">
            <div className="frame-wrapper">
                {/* Border*/}
                <PDLBorder/>
                {/*Logo*/}
                <Logo/>

                {/*Menus*/}
                <div className="row">
                    {/*<div className="col-md-7">*/}

                    {/*</div>*/}
                    <div className="col-md-12">
                        <div className="Menus text-right">
                            <AuthHeader/>
                        </div>
                    </div>
                </div>


                <section className="guild">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="guild-title text-center">
                                    <figure>
                                        <img src={GuildImage} width={'25%'} alt=""/>
                                    </figure>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">

                                <div className="row ">
                                    <div className="col-sm-12">
                                        <div className="tab-content" id="nav-tabContent">
                                            <div className="tab-pane fade show active" id="nav-home" role="tabpanel"
                                                 aria-labelledby="nav-home-tab">

                                                <div className="guild-wrapper">
                                                    {
                                                        (props.notification && props.notification?.length > 0) ?
                                                            <div
                                                                className="row flex-column justify-content-center align-items-center pt-5">
                                                                {
                                                                    props.notification?.map((val, key) => {
                                                                        // if( val?.type === "request_send" || val?.type === "request_receive"){
                                                                        return <div key={key}
                                                                                    className="col-lg-5 my-1">
                                                                            {/*<a href="discord.php">*/}
                                                                            <div
                                                                                className="row guild-card-wrapper align-items-center guild-main-wrapper mt-0 px-3">
                                                                                {/*<div className="row">*/}
                                                                                <div className="col-md-2 my-auto">
                                                                                    <div
                                                                                        className="guild-img-wrapper d-flex justify-content-center">
                                                                                        <i className={"fa fa-1x fa-bell"}
                                                                                           style={{color: '#E3BF4B'}}></i>
                                                                                    </div>
                                                                                </div>
                                                                                <div onClick={() => {
                                                                                    if (val?.type === 0) {
                                                                                        setData(val);
                                                                                        setShow(true);
                                                                                    }
                                                                                }} className="col-md-9">
                                                                                    <div
                                                                                        className="guild-content-wrapper">
                                                                                        <div
                                                                                            className="guild-description mt-3">
                                                                                            <p className={"pdlFont pdlColor"}
                                                                                               style={{color: '#E3BF4B'}}>
                                                                                                {val?.message}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-1">
                                                                                    <i onClick={() => props?.deleteNotification(val?.id)}
                                                                                       className={"fa fa-close text-danger"}></i>
                                                                                </div>
                                                                                {/*</div>*/}
                                                                            </div>
                                                                            {/*</a>*/}
                                                                        </div>
                                                                        // }
                                                                    })
                                                                }
                                                            </div>
                                                            :
                                                            <div className="row pt-5 justify-content-center">
                                                                <div className="col-lg-4">
                                                                    <p className={"pdlStyleParagraph text-center"}>No
                                                                        Guilds Notification Found!!</p>
                                                                </div>
                                                            </div>
                                                    }
                                                </div>
                                                {
                                                    (props.notification && props.notification?.length > 0) &&
                                                    <div className="row align-items-center justify-content-center">
                                                        <button type={"button"} onClick={deleteAllNotification}
                                                                className={"btn btn-danger text-danger "}>
                                                            {
                                                                !deleteAll ? 'Delete All' :
                                                                    <i className={"fa fa-spin fa-spinner"}></i>
                                                            }
                                                        </button>
                                                    </div>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>

            </div>
            {
                data &&
                <AuctionPreview
                    notification={data}
                    open={show}
                    hide={() => setShow(false)}
                />
            }
        </section>
    );
}


const mapStateToProps = (state) => {
    return {
        notification: state?.Site?.notifications,
        user: state.Auth.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        deleteNotification: (id) => dispatch(deleteNotificationRequest(id)),
        deleteAllNotifications: () => dispatch(deleteAllNotificationsRequest()),
        acceptGuild: (data) => dispatch(acceptGuildRequest(data)),
        cancelGuild: (data) => dispatch(cancelGuildRequest(data)),
        getNotifications: () => dispatch(getNotificationsRequest()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Notification);