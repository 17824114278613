import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import Logo from "../../components/Logo";
import '../../assets/css/play.css';
import AuthHeader from "../../components/AuthHeader";
import PDLBorder from "../../components/PDLBorder";
import PlayerTop from "../../assets/img/playertop.png";
import PlayerBottom from "../../assets/img/playerbottom.png";
import {getSearchUserRequest, tradeMakeRequest} from "../../redux/Trade/action";
import {showLoader} from "../../redux/helpers";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";


const SearchUsers = (props) => {

    const [name,setName] = useState('');
    const params = useParams();
    const location = useLocation();
    const navigator = useNavigate();
    console.log(location);

    const onSearchHandler = async (event) => {
        event.preventDefault();
        showLoader('flex');
        await props.getSearchUser(name);
        showLoader('none');
    }
    const newTradeRequest = async (receiver_id) => {
        showLoader('flex');
        props.tradeMake({
            receiver_id : receiver_id,
        })
            .then((res)=>{
                console.log("res",res);
                showLoader('none');
                navigator('/trade/'+res,{
                    state : location?.state?.id,
                })
            })
            .catch((e)=>{
                console.log("catch",e);
                showLoader('none');
            })
    }


    return (
        <section className="fram-sec ">
            <div className="frame-wrapper">

                {/* Border*/}
                <PDLBorder/>
                {/*Logo*/}
                <Logo/>

                {/*Menus*/}
                <div className="row">
                    {/*<div className="col-md-7">*/}

                    {/*</div>*/}
                    <div className="col-md-12">
                        <div className="Menus text-right">
                            <AuthHeader/>
                        </div>
                    </div>
                </div>

                {/*Menus*/}
                <section className="inventory-detailed ">
                    <div className="container">
                        <form onSubmit={onSearchHandler}>

                        <div className="row justify-content-center align-content-center">
                            <div className=" col-8 d-flex">
                                    <input type="text" required={true} name="name" defaultValue={name} onChange={(e)=>setName(e.target.value)}
                                           className={"customInput form-control inputCustomHeight "} id=""/>

                                    <button type={'submit'} className="btn customButton">
                                        <i className={"fa fa-search"}></i>
                                    </button>
                            </div>
                        </div>
                        </form>

                        <div className="row my-5 justify-content-center align-items-center">
                            <div className="col-lg-8">
                                <div className="forTop-border py-1">
                                    <figure><img className="w-100" src={PlayerTop} alt=""/></figure>
                                </div>
                                <div className="Player-statusDv">
                                    {
                                        props.users.length > 0 ?
                                            <ul className="status-list">
                                                {
                                                    props.users.map((val, key) => {
                                                        return <span key={key}>
                                                    <li key={key} className={"py-2"}>
                                                        <img src={val?.profile_url}
                                                             style={{
                                                                 width : '50px',
                                                                 height : '50px',
                                                                 objectFit : 'cover',
                                                                 borderRadius : '100%',
                                                             }}
                                                             alt=""/>
                                                        <h4>{val?.name}</h4>
                                                        <span>
                                                                <button onClick={() => newTradeRequest(val?.id)}
                                                                        className="btn customButton"
                                                                        type={"button"}>Trade Now
                                                                </button>
                                                        </span>
                                                    </li>
                                                    <br/>
                                                </span>
                                                    })
                                                }

                                            </ul>
                                            :
                                            <ul className="status-list">
                                                    <li className={"justify-content-center"}>
                                                        <h4>No user found</h4>
                                                    </li>
                                                    <br/>
                                            </ul>

                                    }
                                </div>
                                <div className="forBottom-border py-1">
                                    <figure><img className="w-100" src={PlayerBottom} alt=""/></figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </section>
    );
}

const mapStateToProps = (state) => {
    return {
        users : state.Trade.users,
    }
}

const mapDispatchToProps = (dispatch) =>{
    return {
        getSearchUser : (name) => dispatch(getSearchUserRequest(name)),
        tradeMake : (data) => dispatch(tradeMakeRequest(data)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(SearchUsers);