import React, {useEffect, useState} from 'react';
import {connect, useSelector} from "react-redux";
import Logo from "../../components/Logo";
import '../../assets/css/guild.css';
import AuthHeader from "../../components/AuthHeader";
import {Link, useNavigate, useParams} from "react-router-dom";
import {
    addItemInTrade,
} from "../../redux/Trade/action";
import {showLoader} from "../../redux/helpers";
import PDLBorder from "../../components/PDLBorder";
import GuildImage from '../../assets/img/Guild.png';
import Macrus from '../../assets/img/macrus.png';
import Member from '../../assets/img/member.png';
import {getListGuildRequest} from "../../redux/Guild/action";
import JoinRequest from '../../components/Modals/JoinRequest';
import Navigation from "../../components/navigation";
import {getMyCharactersRequest} from "../../redux/UserCharacter/action";

const Guilds = (props) => {

    const params = useParams();
    const { selected_character } = useSelector((state) => state?.Auth);
    const [open , setOpen ] = useState(false);
    const [search , setSearch ] = useState(null);
    const [joinId , setJoinId ] = useState(0);
    const [guildId,setGuildId] = useState(0);

    const get = async (url = null) => {
        showLoader('flex');
        await props.getListGuild(url);
        // await props.getMyCharacter();
        showLoader('none');
    }

    const onSearchSubmitHandler = async (e) => {
        e.preventDefault();
        if(search){
            showLoader('flex');
            await props.getListGuild(null,search);
            showLoader('none');
        }

    }

    useEffect(() => {
        get();
        return () => {
        }
    }, [])

    return (
        <section className="fram-sec">
            <div className="frame-wrapper">
                {/* Border*/}
                <PDLBorder/>
                {/*Logo*/}
                <Logo/>

                {/*Menus*/}
                <div className="row">
                    {/*<div className="col-md-7">*/}

                    {/*</div>*/}
                    <div className="col-md-12">
                        <div className="Menus text-right">
                            <AuthHeader/>
                        </div>
                    </div>
                </div>


                <section className="guild">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="guild-title text-center">
                                    <figure>
                                        <img src={GuildImage} alt="" />
                                    </figure>
                                </div>
                            </div>
                        </div>
                        <div className="row pt-5">
                            <div className="col-sm-12">
                                {/* <ul className="nav nav-tabs justify-content-center" id="myTab" role="tablist">
                                    <li className="nav-item">
                                        <Link className="nav-link" to={'/'} id="contact-tab">Guild Treasure</Link>
                                    </li>
                                </ul> */}

                                <div className="row pt-5">
                                    <div className="col-sm-12">
                                        <div className="tab-content" id="nav-tabContent">
                                            <div className="tab-pane fade show active" id="nav-home" role="tabpanel"
                                                 aria-labelledby="nav-home-tab">

                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <div className="search-wrapper">
                                                            {/*<input type="search" placeholder="Search guild  here"*/}
                                                            {/*       className="form-control" />*/}
                                                            <form onSubmit={onSearchSubmitHandler}>
                                                                <div className="form-group d-flex justify-content-center align-content-center align-items-center ">
                                                                    <input type="text" required={true}
                                                                           value={search}
                                                                           onChange={(e)=>setSearch(e.target.value)}
                                                                           className="form-control text-white"
                                                                           placeholder="Search guild here" />
                                                                    {
                                                                        (search) &&
                                                                        <i onClick={()=>{get();setSearch('');}} className={"text-danger fa fa-times"}></i>
                                                                    }
                                                                </div>
                                                            </form>
                                                                {/*<img src="img/guild-search.png" className="guld-sear"*/}
                                                                {/*     alt="" />*/}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="guild-wrapper">
                                                        {
                                                            (props.guilds?.data && props.guilds?.data.length > 0)?
                                                                <div className="row pt-5">
                                                                    {
                                                                        props.guilds?.data?.map((val,key)=>{
                                                                            return <div key={key} className="col-lg-5">
                                                                                {/*<a href="discord.php">*/}
                                                                                <div className="guild-card-wrapper">
                                                                                    <div className="row">
                                                                                        <div className="col-md-4 my-auto">
                                                                                            <div className="guild-img-wrapper">
                                                                                                <figure>
                                                                                                    <img src={val?.logo_url}
                                                                                                         className="guild-img img-fluid"
                                                                                                         alt="" />
                                                                                                </figure>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-8">
                                                                                            <div className="guild-content-wrapper">
                                                                                                <div className="guild-name-and-join">
                                                                                                    {
                                                                                                        (
                                                                                                            val?.member.filter((val)=> val?.character_id === selected_character).length > 0
                                                                                                            ||
                                                                                                            (
                                                                                                                val?.leader_id === selected_character ||
                                                                                                                val?.co_leader_id === selected_character
                                                                                                            )
                                                                                                        )?
                                                                                                            <>
                                                                                                                {
                                                                                                                    val?.member.find( (val) => val?.is_requested == 0)?
                                                                                                                        <>
                                                                                                                            <h5 className="guild-title">{val?.name}</h5>
                                                                                                                            <button type={"button"} className="btn">Pending</button>
                                                                                                                        </>
                                                                                                                        :
                                                                                                                        <>
                                                                                                                            <Link to={'/guild/'+val?.id}>
                                                                                                                                <h5 className="guild-title">{val?.name}</h5>
                                                                                                                            </Link>
                                                                                                                            <button type={"button"} className="btn">Joined</button>
                                                                                                                        </>
                                                                                                                }
                                                                                                            </>
                                                                                                            :
                                                                                                            <>
                                                                                                                <h5 className="guild-title">{val?.name}</h5>
                                                                                                                <button type={"button"}
                                                                                                                    onClick={() => {
                                                                                                                        setOpen(true);
                                                                                                                        setJoinId(val?.id);
                                                                                                                        setGuildId(val?.id)
                                                                                                                    }}
                                                                                                                    className="btn">
                                                                                                                    join
                                                                                                                </button>
                                                                                                            </>
                                                                                                    }
                                                                                                </div>
                                                                                                <div className="guild-description mt-3">
                                                                                                    <p>{val?.description}</p>
                                                                                                </div>
                                                                                                <div className="marcus-and-member">
                                                                                                    <h5><img src={Macrus}
                                                                                                             className="mr-3"
                                                                                                             alt="" /> {val?.leader?.name}</h5>
                                                                                                    <h5><img src={Member}
                                                                                                             className="mr-3"
                                                                                                             alt="" />Members <span
                                                                                                        className="total-member">: {val?.total_members}</span>
                                                                                                    </h5>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {/*</a>*/}
                                                                            </div>
                                                                        })
                                                                    }
                                                                </div>
                                                                :
                                                                <div className="row pt-5 justify-content-center">
                                                                    <div className="col-lg-4">
                                                                        <p className={"pdlStyleParagraph text-center"}>No Guilds Found!!</p>
                                                                    </div>
                                                                </div>
                                                        }
                                                </div>

                                                {
                                                    (props.guilds?.data && props.guilds?.data.length > 0) &&
                                                    <Navigation get={get} links={props.guilds?.links || []}/>
                                                }

                                            </div>
                                            <div className="tab-pane fade" id="nav-profile" role="tabpanel"
                                                 aria-labelledby="nav-profile-tab">...
                                            </div>
                                            <div className="tab-pane fade" id="nav-contact" role="tabpanel"
                                                 aria-labelledby="nav-contact-tab">...
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>


            </div>

            <JoinRequest open={open} joinId={joinId} guildId={guildId} setOpen={setOpen} />
        </section>
    );
}


const mapStateToProps = (state) => {
    return {
        guilds : state.Guild.list,
        user : state.Auth.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getMyCharacter : () => dispatch(getMyCharactersRequest()),
        getListGuild : (url = null , search = null) => dispatch(getListGuildRequest(url , search)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Guilds);