import React from 'react';
import {connect} from "react-redux";
import {Navigate, useLocation} from "react-router-dom";


const ProtectedRoute = ({ children , islogin }) => {
    const location = useLocation();
    if (!islogin) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }
    return children;
}

const mapStateToProps = (state) => {
    return {
        islogin: state.Auth.islogin,
    }
}

export default connect(mapStateToProps)(ProtectedRoute);