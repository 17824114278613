import React, {useEffect, useState} from 'react';
import {Route, Routes, useNavigate} from "react-router-dom";
import {connect} from "react-redux";
import {Button, Modal} from "react-bootstrap";
import Profile from '../../assets/img/profile.jpg';
import {acceptRequest, rejectRequest, resetTime, resetTrade, setNewTradeRequest} from "../../redux/TradeRequest/action";
import {showLoader, startTimer} from "../../redux/helpers";
import {pusher} from "../../redux/pusherConfig";
import {addPlayerRequest, joinGuildRequest} from "../../redux/Guild/action";
import {toast} from "react-toastify";
import axios from "axios";
import {headersWithToken} from "../../redux/headers";
import {getGuildPlayerApi} from "../../redux/apis";

function JoinRequest(props) {

    const [character , setCharacter] = useState([]);
    const [characterId , setCharacterId] = useState(null);
    const [isloading , setIsloading] = useState(false);
    const navigator = useNavigate();


    const closeModal = () => {
        props.setOpen(false);
    }
    
    const getPlayers = async () => {
        try{
            let x = await  axios.get(getGuildPlayerApi(props.guildId),headersWithToken(props.token));
            // console.log(x);
            setCharacter(x.data.response.data);
        }catch (e) {
            toast.error(e.message);
        }
    }

    const addPlayer = async () =>{
        if(characterId){
            setIsloading(true);
            props.addPlayer({guild_id : props.guildId,character_id : characterId})
                .then(()=>{
                    setIsloading(false);
                    props.setOpen(false);
                })
                .catch((e)=>{
                    setIsloading(false);
                })
        }else{
            toast.warning("please select character");
        }
    }



    useEffect(()=>{
        getPlayers();
        return () => {

        }
    },[props.open]);

    return (
        <Modal
            show={props.open}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Add New Player
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row justify-content-center">
                    {
                        character.map((val,key)=>{
                            return <div key={key} className={"col-2"}>
                                <div onClick={()=>setCharacterId(val?.id)} className={"p-2"} style={
                                    (characterId === val?.id) ?
                                        {
                                            borderStyle: 'solid',
                                            borderWidth: '3px',
                                            borderImageSource: 'linear-gradient( to right, #b88425, #c5973f, #d2a957, #e0bc6f, #edcf88, #efd28b, #f1d58f, #f3d892, #eacb80, #e1bf6f, #d9b25d, #d0a64c )',
                                            borderImageSlice: 1,
                                        }
                                        :
                                        {
                                            borderStyle: 'solid',
                                            borderWidth: '3px',
                                            borderImageSource: 'linear-gradient( to right, #ddd, #ddd, #ddd, #ddd, #ddd, #ddd, #ddd, #ddd, #ddd, #ddd, #ddd, #ddd )',
                                            borderImageSlice: 1,
                                        }
                                }>
                                    <img width={'100%'} src={val?.logo_url} alt=""/>
                                </div>
                                <div className={"text-center"}>
                                    {val?.name}
                                </div>

                            </div>
                        })
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className={"btn btn-success"} onClick={closeModal}>Cancel</Button>
                {
                    isloading ?
                        <Button className={"btn btn-success"} type={"button"}>
                            <i className={"fas fa-spinner fa-spin"}></i>
                        </Button>
                        :
                        <Button type={"button"} onClick={addPlayer} className={"btn btn-success"}>Add Player</Button>
                }
            </Modal.Footer>
        </Modal>
    );
}

const mapStateToProps = (state) => {
    return {
        token : state.Auth.token,
        characters : state.UserCharacter.characters,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        joinGuild : (data) => dispatch(joinGuildRequest(data)),
        addPlayer : (data) => dispatch(addPlayerRequest(data)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(JoinRequest);
