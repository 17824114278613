import {site_web_url} from "./apis";
import moment from 'moment';
import momentTz from 'moment-timezone';
import React from "react";


export const showLoader = (type) => {
    let x = document.getElementById('loader-full');
    x.style.display = type;
}

export const getImageFromStorage = (val) => {
    return site_web_url+'storage/'+val;
}

export const onInputChangeHandler = (e,setState) => {
    let { name , value } = e.target;
    // console.log("Change",name,value);
    setState((preVal)=>{
        return {
            ...preVal,
            [name] : value
        }
    })
}

export const onDirectChangeHandler = (name,value,setState) => {
    setState((preVal)=>{
        return {
            ...preVal,
            [name] : value
        }
    })
}

export const onImageChangeHandler = (e,setState) => {
    let { name } = e.target;
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {

        setState((preVal)=>{
            return {
                ...preVal,
                [name] : file
            }
        });

    };
    reader.readAsDataURL(file);
};

export const getDayHourMinSec = (countDown) => {
    // calculate time left
    // const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
    // const hours = Math.floor(
    //     (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    // );
    // const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
    // const seconds = Math.floor((countDown % (1000 * 60)) / 1000);
    return [
        (Math.floor(countDown / 31536000)).toString().padStart(2,'0'),
        (Math.floor((countDown % 31536000) / 86400)).toString().padStart(2,'0'),
        (Math.floor(((countDown % 31536000) % 86400) / 3600)).toString().padStart(2,'0'),
        (Math.floor((((countDown % 31536000) % 86400) % 3600) / 60)).toString().padStart(2,'0'),
        ((((countDown % 31536000) % 86400) % 3600) % 60).toString().padStart(2,'0'),
    ];

    // return [days, hours, minutes, seconds];
};

// export const getSecondsFromStartEnd = ( start , end ) => {
//     // console.log("getSecondsFromStartEnd",start,end);
//     // // moment(moment).tz();
//     // let startMoment = momentTz().tz('America/New_York').format('YYYY-MM-DD HH:mm:ss');
//     // console.log( "startMoment",startMoment);
//     // // let endMoment = momentTz(end).format('YYYY-MM-DD HH:mm:ss');
//     // // .tz('America/New_York');
//     // let endMoment = moment(end);
//     // console.log( "endMoment", endMoment );
//     // // console.log("endMoment", endMoment.diff(startMoment) / 1000);
//     // const durationInSeconds = Math.abs(endMoment.diff(startMoment) / 1000);

//     // // console.log( "endMoment",new Date( endMoment ) );
//     // // let diffsecond = moment(endMoment).diff(startMoment, 'seconds');
//     // // console.log("Get Seconds",diffsecond);
//     // // let diffsecond = Math.abs(endMoment.diff(startMoment) / 1000);
//     // console.log(start, end, durationInSeconds);
//     // return durationInSeconds;

//     const currentTime = moment().tz('America/New_York');
//     const endDateTime = moment.tz(end, 'America/New_York'); // Change 'America/New_York' to your desired US time zone

//     // Calculate remaining time in seconds
//     const remainingTimeInSeconds = Math.max(0, endDateTime.diff(currentTime, 'seconds'));

//     // Calculate remaining time in seconds
//     // setRemainingSeconds(remainingTimeInSeconds);
//     return remainingTimeInSeconds;

//     // Call the function initially

// }

export const getSecondsFromStartEnd = (start, end) => {
    console.log("getSecondsFromStartEnd", start, end);
    // moment(moment).tz();
    let startMoment = momentTz().tz('America/New_York').format('YYYY-MM-DD HH:mm:ss');
    // let startMoment = moment(start);
    console.log("startMoment", startMoment);
    // let endMoment = momentTz(end).format('YYYY-MM-DD HH:mm:ss');
    // .tz('America/New_York');
    let endMoment = moment(end).tz('America/New_York');
    console.log("endMoment", endMoment);
    console.log("endMoment", endMoment.diff(startMoment) / 1000);
    // console.log( "endMoment",new Date( endMoment ) );
    // let diffsecond = moment(endMoment).diff(startMoment, 'seconds');
    // console.log("Get Seconds",diffsecond);

    return Math.abs(endMoment.diff(startMoment) / 1000);
}


export const forHumans = ( seconds ) => {
    var levels = [
        [Math.floor(seconds / 31536000), 'years'],
        [Math.floor((seconds % 31536000) / 86400), 'days'],
        [Math.floor(((seconds % 31536000) % 86400) / 3600), 'hours'],
        [Math.floor((((seconds % 31536000) % 86400) % 3600) / 60), 'minutes'],
        [(((seconds % 31536000) % 86400) % 3600) % 60, 'seconds'],
    ];
    return <div className={"d-flex flex-row "}>
        <div className={"font-size-12px"}>
            <span className={""}>DD</span>
            <br/>
            <span className={""}>{levels[1][0]?.toString()?.padStart(2,'0')}</span>
        </div>
        <span className={"font-size-12px"}>-</span>
        <div className={"d-flex flex-column justify-content-center align-items-center font-size-12px"}>
            <span className={""}>HH</span>
            <span className={""}>{levels[2][0]?.toString()?.padStart(2,'0')}</span>
        </div>
        <span className={"font-size-12px"}>-</span>
        <div className={"d-flex flex-column justify-content-center align-items-center font-size-12px"}>
            <span className={""}>MM</span>
            {/*<br/>*/}
            <span className={""}>{levels[3][0]?.toString()?.padStart(2,'0')}</span>
        </div>
        <span className={"font-size-12px"}>-</span>
        <div className={"d-flex flex-column justify-content-center align-items-center font-size-12px"}>
            <span className={""}>SS</span>
            {/*<br/>*/}
            <span>{levels[4][0]?.toString()?.padStart(2,'0')}</span>
        </div>
    </div>

    var returntext = '';

    for (var i = 0, max = levels.length; i < max; i++) {
        if ( levels[i][0] === 0 ) continue;
        returntext += ' ' + levels[i][0] + ' ' + (levels[i][0] === 1 ? levels[i][1].substr(0, levels[i][1].length-1): levels[i][1]);
    };
    return returntext.trim();
}