import {
    LOGIN,
    LOGOUT, SET_ALL_READ_NOTIFICATIONS,
    SET_CATEGORY,
    SET_CHARACTERS, SET_CURRENCY, SET_GALLERY_SETTING,
    SET_LOADER, SET_NOTIFICATIONS,
    SET_PAGES, SET_SESSION,
    SET_SITE_ARCHIVE,
    SET_SITE_BOX_LIST,
    SET_SITE_FAMES,
    SET_SITE_FAMES_CATEGORY,
    SET_SITE_HAPPEN,
    SET_SITE_HEROS,
    SET_SITE_SETTING,
    SET_SITE_SLIDER,
    SET_SITE_BURN_ARCADE,
    SET_USER, SET_USER_CHARACTER,
    WELCOME, DELETE_NOTIFICATIONS, DELETE_ALL_NOTIFICATIONS
} from "../constants";
import {headers, headersWithToken} from "../headers";
import {
    archiveHistoriesApi, boxListApi, burnArcadeAPi,
    categoryAPi,
    charactersAPi, currencyApi, deleteAllNotificationApi, deleteNotificationApi, famesApi, famesSessionApi, galleryAPi,
    herosVillansAPi,
    loginAPi, notificationApi,
    pagesAPi, readNotificationApi,
    settingAPi,
    signUpAPi,
    sliderAPi, userCharacterApi, whatHappenNowApi
} from "../apis";
import axios from "axios";
import {setAlert} from "../Alert/action";
import {responseError} from "../errorHandler";

export const setLoader = (bool) => {
    return {
        type : SET_LOADER,
        payload : bool
    }
}

export const setPages = (data) => {
    return {
        type : SET_PAGES,
        payload : data
    }
}

export const getPagesRequest = () => {
    return async (dispatch) => {
        try{
            let response = await axios.get(pagesAPi,headers());
            dispatch(setPages(response.data.response.data));
        }catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    }
}

export const setNotifications = (data) => {
    return {
        type : SET_NOTIFICATIONS,
        payload : data
    }
}
export const deleteNotifications = (id) => {
    return {
        type : DELETE_NOTIFICATIONS,
        payload : id
    }
}
export const deleteAllNotifications = () => {
    return {
        type : DELETE_ALL_NOTIFICATIONS,
        payload : []
    }
}
export const readAllNotifications = () => {
    return {
        type : SET_ALL_READ_NOTIFICATIONS,
        payload : []
    }
}

export const getNotificationsRequest = () => {
    return async (dispatch,getState) => {
        try{
            let token = getState().Auth.token;
            let response = await axios.get(notificationApi,headersWithToken(token));
            // console.log("getNotificationsRequest",response);
            dispatch(setNotifications(response.data.response.data));
        }catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    }
}
export const deleteNotificationRequest = (id) => {
    return async (dispatch,getState) => {
        try{
            dispatch(deleteNotifications(id));
            let token = getState().Auth.token;
            await axios.get(deleteNotificationApi(id),headersWithToken(token));
            dispatch(setAlert([{message : 'Notification removed successfully.',color:'success'}]));
        }catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    }
}
export const deleteAllNotificationsRequest = () => {
    return async (dispatch,getState) => {
        try{
            let token = getState().Auth.token;
            let response = await axios.get(deleteAllNotificationApi,headersWithToken(token));
            dispatch(deleteAllNotifications());
            dispatch(setAlert([{message : 'Notifications removed successfully.',color:'success'}]));
        }catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    }
}
export const readAllNotificationsRequest = () => {
    return async (dispatch,getState) => {
        try{
            let token = getState().Auth.token;
            dispatch(readAllNotifications());
            let response = await axios.get(readNotificationApi,headersWithToken(token));
            // console.log("getNotificationsRequest",response);
            // dispatch(setNotifications(response.data.response.data));
        }catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    }
}

export const setCharacters = (data) => {
    return {
        type : SET_CHARACTERS,
        payload : data
    }
}

export const getCharactersRequest = () => {
    return async (dispatch) => {
        try{
            let response = await axios.get(charactersAPi,headers());
            dispatch(setCharacters(response.data.response.data));
        }catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    }
}

export const setCategory = (data) => {
    return {
        type : SET_CATEGORY,
        payload : data
    }
}

export const getCategoryRequest = () => {
    return async (dispatch) => {
        try{
            let response = await axios.get(categoryAPi,headers());
            dispatch(setCategory(response.data.response.data));
        }catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    }
}

export const setSetting = (data) => {
    return {
        type : SET_SITE_SETTING,
        payload : data
    }
}

export const getSettingRequest = () => {
    return async (dispatch) => {
        try{
            let response = await axios.get(settingAPi,headers());
            dispatch(setSetting(response.data.response.data));
        }catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    }
}


export const setSlider = (data) => {
    return {
        type : SET_SITE_SLIDER,
        payload : data
    }
}

export const getSliderRequest = () => {
    return async (dispatch) => {
        try{
            let response = await axios.get(sliderAPi,headers());
            dispatch(setSlider(response.data.response.data));
        }catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    }
}
export const setBurnAcrade = (data) => {
    return {
        type : SET_SITE_BURN_ARCADE,
        payload : data
    }
}

export const getBurnAcradeRequest = () => {
    return async (dispatch) => {
        try{
            let response = await axios.get(burnArcadeAPi,headers());
            dispatch(setBurnAcrade(response.data.response.data));
        }catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    }
}

export const setHeros = (data) => {
    return {
        type : SET_SITE_HEROS,
        payload : data
    }
}
export const getHerosRequest = () => {
    return async (dispatch) => {
        try{
            let response = await axios.get(herosVillansAPi,headers());
            dispatch(setHeros(response.data.response.data));
        }catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    }
}

export const setGallery = (data) => {
    return {
        type : SET_GALLERY_SETTING,
        payload : data
    }
}
export const getGalleryRequest = () => {
    return async (dispatch) => {
        try{
            let response = await axios.get(galleryAPi,headers());
            dispatch(setGallery(response.data.response.data));
        }catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    }
}

export const setArchive = (data) => {
    return {
        type : SET_SITE_ARCHIVE,
        payload : data
    }
}

export const getArchiveRequest = () => {
    return async (dispatch) => {
        try{
            let response = await axios.get(archiveHistoriesApi,headers());
            dispatch(setArchive(response.data.response.data));
        }catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    }
}


export const setHappenNow = (data) => {
    return {
        type : SET_SITE_HAPPEN,
        payload : data
    }
}

export const getHappenNowRequest = () => {
    return async (dispatch) => {
        try{
            let response = await axios.get(whatHappenNowApi,headers());
            dispatch(setHappenNow(response.data.response.data));
        }catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    }
}

export const setBoxList = (data) => {
    return {
        type : SET_SITE_BOX_LIST,
        payload : data
    }
}

export const getBoxListRequest = () => {
    return async (dispatch) => {
        try{
            let response = await axios.get(boxListApi,headers());
            dispatch(setBoxList(response.data.response.data));
        }catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    }
}


export const setFames = (fames) => {
    return {
        type : SET_SITE_FAMES,
        payload : fames,

    }
}
export const setFamesCategory = (famesCategory) => {
    return {
        type : SET_SITE_FAMES_CATEGORY,
        payload : famesCategory,
    }
}

export const setSessions = (sessions) => {
    return {
        type : SET_SESSION,
        payload : sessions,
    }
}

export const getFameSessionRequest = () => {
    return async (dispatch,getState) => {
        try{
            let token = getState().Auth.token;
            let response = await axios.get(famesSessionApi,headersWithToken(token));
            dispatch(setSessions(response.data.response.data));
        }catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    }
}

export const setCurrency = (currency) => {
    return {
        type : SET_CURRENCY,
        payload : currency,
    }
}

export const getCurrencyRequest = () => {

    return async (dispatch) => {

        try{

            let response = await axios.get(currencyApi,headers());

            dispatch(setCurrency(response.data.response.data));

        }catch (e) {

            let error = responseError(e);

            dispatch(setAlert(error));

        }

    }

}

export const setUserCharacter = (data) => {
    return {
        type : SET_USER_CHARACTER,
        payload : data,
    }
}
export const getUserCharacterRequest = (id) => {
    return async (dispatch) => {
        try{
            let response = await axios.get(userCharacterApi(id),headers());
            dispatch(setUserCharacter(response.data.response.data));
        }catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    }
}

export const getFamesRequest = (year = '') => {
    return async (dispatch) => {
        try{
            let link = famesApi + ((year === '')? '' : `?year=${year}` );
            let response = await axios.get(link,headers());
            dispatch(setFames(response.data.response.data?.fames));
            dispatch(setFamesCategory(response.data.response.data?.category));
            // dispatch(setSessions(response.data.response.data?.session));

        }catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    }
}

