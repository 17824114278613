import {
    SET_AUCTION_ITEMS,
    SET_AUCTION_LIST_ITEMS,
    SET_MY_AUCTION_ITEMS, SET_VIEW_AUCTION_ITEM_BIDS,
    SET_VIEW_AUCTION_ITEM_STATUS,
    SET_VIEW_AUCTION_ITEMS
} from "../constants";

const initState = {
    items : null,
    list_items: [],
    myItems : [],
    item : null,
}


const Auction = (state = initState , action) => {
    switch (action.type) {

        case SET_AUCTION_ITEMS :
            return {
                ...state,
                items : action.payload,
            }
        case SET_AUCTION_LIST_ITEMS:
            return {
                ...state,
                list_items: action.payload,
            }
        case SET_MY_AUCTION_ITEMS :
            return {
                ...state,
                myItems : action.payload,
            }
        case SET_VIEW_AUCTION_ITEMS :
            return {
                ...state,
                item : action.payload,
            }
        case SET_VIEW_AUCTION_ITEM_STATUS :
            return {
                ...state,
                item : {
                    ...state?.item,
                    status : action.payload,
                },
            }
        case SET_VIEW_AUCTION_ITEM_BIDS :
            return {
                ...state,
                item : {
                    ...state?.item,
                    bids : [
                        ...action.payload,
                        // ...state?.item?.bids
                    ],
                },
            }
        default: return state;
    }
}

export default Auction;