import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import PDLBorder from "../../components/PDLBorder";
import Logo from "../../components/Logo";
import '../../assets/css/my-auction.css';
import AuthHeader from "../../components/AuthHeader";
import auctionText from '../../assets/img/my-bids.png';
import craftShade from '../../assets/img/craft-shade.png';
import {getItemsRequest} from "../../redux/Craft/action";
import Loader from "../NotFound/Loader";
import {useNavigate} from "react-router-dom";
import {setLoader} from "../../redux/Site/action";
import {getSecondsFromStartEnd, showLoader} from "../../redux/helpers";
import {myAuctionRequest, myBidsAuctionsRequest} from "../../redux/Auction/action";
import moment from "moment";
import AuctionItem from "./AuctionItem";
import {useCountdown} from "../../hooks/useCountdown";


const MyBids = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const items = useSelector((state) => state?.Auction?.myItems)
        .sort( ( a , b ) => a?.time_left - b?.time_left );
    const loader = useSelector((state) => state.Site.loader);


    const get = async () => {
        showLoader('flex');
        await dispatch(myBidsAuctionsRequest());
        showLoader('none');
    }

    // const [days, hours, minutes, seconds] = useCountdown(getSecondsFromStartEnd(
    //     "2023-03-10 06:00:00",
    //     "2023-03-10T14:30:00-05:00"
    // ));


    useEffect(() => {

        get();

        return () => {
        }
    }, []);


    if (loader) return <Loader/>

    return (
        <>
            <div className="frame-wrapper">

                {/* Border*/}
                <PDLBorder/>
                {/*Logo*/}
                <Logo/>

                {/*Menus*/}
                <div className="row">
                    {/*<div className="col-md-7">*/}

                    {/*</div>*/}
                    <div className="col-md-12">
                        <div className="Menus text-right">
                            <AuthHeader/>
                        </div>
                    </div>
                </div>
                {/*Menus*/}

                <section className="my_auction py-4">
                    <div className="container">
                        <div className="heading_wrapper text-center pb-5">
                            <figure><img src={auctionText} width={'15%'} alt="" className="img-fluid"/></figure>
                        </div>
                        {
                            items?.length > 0 ?
                                <ul className="all-auction-items">
                                    {
                                        items?.map((val, key) => (
                                            <AuctionItem key={key} val={val}/>
                                        ))
                                    }
                                </ul>
                                :
                                <div className={"row mt-5 justify-content-center"}>
                                    <div className="profile-creation text-center animate__animated animate__fadeInDown">
                                        <h3>Items Not Found</h3>
                                    </div>
                                </div>
                        }

                    </div>
                </section>
            </div>
        </>
    );
}

export default MyBids;