import React, {useEffect, useState} from 'react';
import WeaponStop from '../../../assets/img/weaponstop.png';
import WeaponsBottom from '../../../assets/img/weaponsbottom.png';
import { getCharacterDetailRequest} from "../../../redux/UserCharacter/action";
import {setLoader} from "../../../redux/Site/action";
import Slider from 'react-slick';
import {connect} from "react-redux";
import BoxImage from "../../../components/BoxImage";


const Legends = (props) => {

    const settings = {
        dots: false ,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
    };

    return (
        <>
            <div className="weaponsDv-withBorder">
                <div className="forTop-border">
                    <figure><img src={WeaponStop} alt="" /></figure>
                </div>
                <div className="weaponsDv">
                    <div className="title">
                        <h4>weapons</h4>
                    </div>
                    <Slider {...settings} className="weapons-Slider text-white">
                        {
                            props?.weapons.map((val,key)=>{
                                return  <div onClick={()=>props.onUnEquipItem(val?.id)} key={key} className="weapons-items">
                                    <div className="imgbox">
                                        <BoxImage image={val?.shop?.image_url}/>
                                    </div>
                                    <div className="contentDv">
                                        <ul className="lists">
                                            <li>H : {val?.health}</li>
                                            <li>D : {val?.damage}</li>
                                            <li>B : {val?.base}</li>
                                            <li>AP : {val?.armor_pen}</li>
                                            <li>APR : {val?.armor_pen_resist}</li>
                                        </ul>
                                    </div>
                                </div>
                            })
                        }
                        {
                            props.noOfWeapons.map((val,key)=>{
                                return <div key={key} className="weapons-items">
                                    <div className="imgbox">
                                        <figure>
                                            <img src=""
                                                 className="animate__animated animate__bounceIn animate__delay-1s"
                                                 alt=""/></figure>
                                    </div>
                                    <div className="contentDv">
                                        <ul className="lists">
                                            <li>H : 0</li>
                                            <li>D : 0</li>
                                            <li>B : 0</li>
                                            <li>AP : 0</li>
                                            <li>APR : 0</li>
                                        </ul>
                                    </div>
                                </div>
                            })
                        }
                    </Slider>
                </div>
                <div className="forBottom-border">
                    <figure><img src={WeaponsBottom} alt="" /></figure>
                </div>
            </div>

        </>
    )
        ;
}

const filterNoWeapons = (weapons) => {
    let weaponCategory =  weapons.filter((val)=> val?.placement === "bottom" && val?.slug === "weapons");

    let noOfWeapon = [];
    weaponCategory.map((val)=>{
        noOfWeapon = [...noOfWeapon,...val?.items];
    });

    let arr = [];

    if((5 - parseInt(noOfWeapon.length)) <= 5){
        for (let i = 1 ; i<= (5 - parseInt(noOfWeapon.length)) ; i++ ){
            arr.push(i);
        }
    }
    return arr;
}

const filterWeapons = (weapons) => {
    let filtweapons =  weapons.filter((val)=> val?.placement === "bottom" && val?.slug === "weapons");
    console.log((filtweapons.length > 0)? filtweapons[0]?.items : []);
    return (filtweapons.length > 0)? filtweapons[0]?.items : [];
}

const mapStateToProps = (state) => {
    return {
        islogin: state.Auth.islogin,
        details: state.UserCharacter.details,
        weapons : filterWeapons(state.UserCharacter.details?.characteristics || []),
        noOfWeapons : filterNoWeapons(state.UserCharacter.details?.characteristics || []),
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setLoader: (bool) => dispatch(setLoader(bool)),
        getCharacterDetail: (id) => dispatch(getCharacterDetailRequest(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Legends);


/*

 */