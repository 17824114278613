import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import Character1 from '../assets/img/character1.png';
import '../assets/css/warrior2.css';
import PlayLogo from "./PlayLogo";
import {Link} from "react-router-dom";
import PlayBg from "../assets/img/playbg.png";
import warriorBg from "../assets/img/warriorbg.png";
import PDLBorder from "./PDLBorder";
import Logo from "./Logo";
import AuthHeader from "./AuthHeader";
import {filterItemsByCategoryRequest, getItemsRequest} from "../redux/Inventory/action";
import {showLoader} from "../redux/helpers";



const SearchInventory = (props) => {
    const [search , setSearch] = useState(null);

    const onSearchHandler = async (event) => {
        event.preventDefault();
        showLoader('flex');
        if(props.component === "character"){
            await props.itemRequest('',null,search);
        }else{
            await props.itemRequest(null,search);
        }
        showLoader('none');
    }

    const get = async () => {
        showLoader('flex');
        if(props.component === "character"){
            await props.itemRequest();
        }else{
            await props.itemRequest();
        }
        showLoader('none');
    }

    return (
        <>
            <form className={"col-lg-12"} onSubmit={onSearchHandler}>
                <div className="form-group d-flex justify-content-center align-content-center ">
                    <input type="text" required={true}
                           onChange={(e)=>setSearch(e.target.value)}
                           className="form-control pdlCustomInput"
                           value={search}
                           placeholder="Search item here" />
                    {
                        search &&
                        <a
                            // type={"button"}
                            onClick={()=>{setSearch('');get();}}
                            // className="btn m-0 customButton"
                            className="p-1"
                        >
                            <i className={"text-danger fa fa-times"}></i>
                        </a>
                    }
                </div>
            </form>
        </>
);
}

const mapStateToProps = (state) => {
    return {
        category: state.Site.category,

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getItems: (url = null,search=null) => dispatch(getItemsRequest(url,search)),
        filterItemsByCategory: (id) => dispatch(filterItemsByCategoryRequest(id)),
    }
}

export default connect(mapStateToProps)(SearchInventory);