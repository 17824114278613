import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Profileback from "../../assets/img/profileback.png";
import profiletitle from "../../assets/img/profiletitle.png";
import classtitle from "../../assets/img/classtitle.png";
import plus from "../../assets/img/plus.png";
import PDLBorder from "../../components/PDLBorder";
import Logo from "../../components/Logo";
import AuthHeader from "../../components/AuthHeader";
import charc2 from "../../assets/img/charc2.png";
import "../../assets/css/myprofile.css";
import "../../assets/css/warrior.css";
import {
  createMyCharacterRequest,
  getCharacterDetailRequest,
  getMyCharactersRequest,
} from "../../redux/UserCharacter/action";
import { Link, useNavigate } from "react-router-dom";
import CharacterDescription from "./CharacterDescription";
import Pets from "./Pets";
import Weapons from "./Weapons";
import { setLoader } from "../../redux/Site/action";
import { getProfileRequest, setCharacter } from "../../redux/Auth/action";
import { getPetsItemsRequest } from "../../redux/Shop/action";
import { Button, Modal } from "react-bootstrap";
import Classes from "../../components/Classes";

const Profile = (props) => {
  const [activeCharacter, setActiveCharacter] = useState(0);
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const onChangeCharacterHandler = async (id) => {
    props.setLoader(true);
    setActiveCharacter(id);
    props.setCharacter(id);
    setTimeout(() => {
      props.setLoader(false);
    }, 5000);
  };

  const get = async () => {
    props.setLoader(true);
    await dispatch(getPetsItemsRequest());
    // await props.getCharacterDetail(props.selected_character,1);
    await props.getMyCharacter();
    await props.getProfile();
    props.setLoader(false);
  };

  const carrerKills = () => {
    let kills = 0;
    if (props.myCharacters.length > 0) {
      props.myCharacters.map((val, key) => {
        kills += parseInt(val?.kills);
      });
    }
    return kills.toLocaleString();
  };

  useEffect(() => {
    get();
    return () => {};
  }, []);

  useEffect(() => {
    if (props.myCharacters.length > 0) {
      props.setCharacter(props.myCharacters[0]?.id);
      setActiveCharacter(props.myCharacters[0]?.id);
    }
  }, [props.myCharacters]);

  useEffect(() => {
    setActiveCharacter(props.selected_character);
  }, [props.selected_character]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <section className="fram-sec profile-frame-section">
        <div
          className="frame-wrapper"
          style={{
            backgroundImage: `url('${Profileback}')`,
            backgroundSize: "100% 100%",
            backgroundRepeat: "no-repeat",
            padding: "3rem 2rem",
            margin: "4.5rem 2rem",
            position: "relative",
          }}
        >
          {/* Border*/}
          <PDLBorder />
          {/*Logo*/}
          <Logo />

          {/*Menus*/}
          <div className="row">
            {/*<div className="col-md-7">*/}

            {/*</div>*/}
            <div className="col-md-12">
              <div className="Menus text-right">
                <AuthHeader />
              </div>
            </div>
          </div>
          {/*Menus*/}

          {/*Main Content Start Here*/}
          <div className="ProfileContent-Dv">
            <div className="container-fluid">
              <div className="main-Title pb-5 text-center">
                <figure>
                  <img src={profiletitle} alt="" />
                </figure>
              </div>
              <div className="row">
                {/*<div className="col-lg-6">*/}
                {/*    <div className="row">*/}
                <div className="col-md-3">
                  <div className="classesDv">
                    <div className="head">
                      <figure>
                        <img src={classtitle} alt="" className="title" />
                      </figure>
                    </div>
                    <ul className="classes-list">
                      {props.character.map((val, key) => {
                        return (
                          <li key={key}>
                            <Button
                              variant="primary"
                              className="border-0 p-0 m-0"
                              onClick={handleShow}
                            >
                              <div className="img-box">
                                <figure className="m-0">
                                  <img src={val?.front_image_url} alt="" />
                                </figure>
                              </div>
                              <div className="classText">
                                <figure>
                                  <h5
                                    className="char-name pdlStyleHeading"
                                    style={{
                                      fontSize: "13px",
                                    }}
                                  >
                                    {val?.title}
                                  </h5>
                                </figure>
                              </div>
                            </Button>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>

                <div className="col-md-3">
                  {/*{*/}
                  {/*    (activeCharacter !== 0 && (typeof activeCharacter === "number")) &&*/}
                  {/*    <CharacterDescription*/}
                  {/*    data={props.myCharacters.find((val)=>val?.id  === activeCharacter)}*/}
                  {/*    />*/}
                  {/*}*/}
                  {/*<!-- Character Div Start Here -->*/}
                  <div className="CharacterDv">
                    {/*<div className="head pb-3">*/}
                    {/*    <figure><img src="img/charactertitle.png" alt=""/></figure>*/}
                    {/*</div>*/}
                    <div className="heading-all">
                      {/*<h3>alts</h3>*/}
                    </div>
                    <ul className="AboutPlayers">
                      {props.myCharacters.map((val, key) => {
                        return (
                          <li key={key} className="playersCharacter">
                            <div
                              className="player-img-of-first-li"
                              onClick={() => onChangeCharacterHandler(val?.id)}
                            >
                              <figure>
                                <img src={val?.logo_url} style={{width:'100%'}} alt="" />
                              </figure>
                              {activeCharacter === val?.id && (
                                <i
                                  className={" fa fa-star"}
                                  style={{
                                    color: "#E3BF4B",
                                    position: "absolute",
                                    top: 11,
                                    right: 20,
                                  }}
                                ></i>
                              )}
                            </div>
                            <div className="content-of-first-li ">
                              <div className="playerName">
                                <Link to={"/character/detail/" + val?.id}>
                                  {/*<h5>Player Name</h5>*/}
                                  <span
                                    className="plyertxt"
                                    style={{
                                      color: "#E3BF4B",
                                    }}
                                  >
                                    {val?.name}
                                  </span>
                                </Link>
                              </div>
                              <div className="playerLevel">
                                {/*<h5>Player Level</h5>*/}
                                <span>Level : {val?.level}</span>
                              </div>
                            </div>
                          </li>
                        );
                      })}

                      {[...new Array(6 - props.myCharacters.length)].map(
                        (val, key) => (
                          <li className="playersCharacter" key={key}>
                            <Link to={"/character-creation"}>
                              <div className="player-img">
                                <figure>
                                  <img src={plus} alt="" />
                                </figure>
                              </div>
                              <div className="content">
                                <div className="content">
                                  <div className="Fornew">
                                    <h6>
                                      Create new
                                      <br /> character
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                  {/*  <!-- Character Div Start Here -->  */}
                </div>
                {/*</div>*/}

                {/*</div>*/}

                <div className="vertical-line"></div>

                {/*<div className="col-lg-6">*/}
                {/*<div className="row">*/}
                <div className="col-md-2">
                  <div className="GuildsDv">
                    <div className="head">
                      <h3>Main</h3>
                    </div>
                    {props.myCharacters
                      .filter((val) => val?.id === activeCharacter)
                      .map((val, key) => (
                        <React.Fragment key={key}>
                          <div className="discoo">
                            <div className="imgBox">
                              <figure>
                                <img
                                  className={"characterProfilePic"}
                                  src={val?.logo_url}
                                  alt=""
                                  style={{height:'100px',objectFit:'contain'}}
                                />
                              </figure>
                            </div>
                            {/*{'/character/detail/'+val?.id}*/}
                            <div className="contentDv ">
                              <div className="two-span p-0">
                                <h4 className="name h6">{val?.name}</h4>
                              </div>
                              <div className="two-span p-0">
                                <h4 className="name">
                                  {val?.character?.title}
                                </h4>
                              </div>
                              <div className="two-span p-0">
                                <h4 className="name">Level : {val?.level}</h4>
                              </div>
                              <div className="two-span p-0">
                                <h4 className="name">
                                  Character Kills :{" "}
                                  {parseInt(
                                    props.myCharacters.find(
                                      (val) => val?.id === activeCharacter
                                    )?.kills
                                  )?.toLocaleString()}
                                </h4>
                              </div>
                            </div>
                          </div>
                          {val?.guild_members ? (
                            <div
                              className="discoo"
                              onClick={() =>
                                navigation(
                                  "/guild/" + val?.guild_members?.guild?.id
                                )
                              }
                            >
                              <div className="imgBox">
                                <figure>
                                  <img
                                    className={"characterProfilePic"}
                                    src={val?.guild_members?.guild?.logo_url}
                                    alt=""
                                  />
                                </figure>
                              </div>
                              <div className="contentDv">
                                <h3 className="name">
                                  {val?.guild_members?.guild?.name}
                                </h3>
                                <span>
                                  {val?.guild_members?.guild?.description}
                                </span>
                                <ul className="about">
                                  <li>
                                    <img src="img/macrus.png" alt="" />{" "} Name :
                                    <ruby className="total">
                                      { val?.guild_members?.guild?.leader?.name || ""}
                                    </ruby>
                                  </li>
                                  <li>
                                    <img src="img/member.png" alt="" /> Members
                                    :{" "}
                                    <ruby className="total">
                                      {val?.guild_members?.guild?.total_members}
                                    </ruby>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          ) : (
                            <div className="discoo">
                              <div className="imgBox">
                                <figure>
                                  {/*<img className={"characterProfilePic"} src={val?.guild_members?.guild?.logo_url} alt=""/>*/}
                                </figure>
                              </div>
                              <div className="contentDv">
                                <h3
                                  className="name"
                                  style={{ fontSize: "19px" }}
                                >
                                  No Guild
                                </h3>
                              </div>
                            </div>
                          )}
                        </React.Fragment>
                      ))}
                  </div>
                </div>

                <div className="col-md-4 for-lef-border">
                  <div className="CareerDv">
                    {/*<div className="career-kills">*/}
                    {/*    <h4>Career Kills</h4>*/}
                    {/*    <h3>{carrerKills()}</h3>*/}
                    {/*</div>*/}

                    <div className="balance">
                      <h3>My Bank</h3>
                      <div className="coins">
                        <div className="two-span">
                          <div className="bankLft">
                            <h4 className="txt">Dead Dimes coins</h4>
                            {props?.currency
                              ?.filter(
                                (val) => val?.slug === "dead_dimes_coins"
                              )
                              .map((value, index) => (
                                <div
                                  className="for-img"
                                  style={{ width: "60px" }}
                                >
                                  <img
                                    src={value?.icon_url}
                                    width={"60px"}
                                    alt=""
                                  />
                                </div>
                              ))}
                          </div>
                          <h4>
                            {/* {props?.user?.dead_coins} */}
                            {parseInt(
                              props?.user?.dead_coins
                            ).toLocaleString()}
                            {/* {props?.user?.formated_coins?.dead_coins} */}
                          </h4>
                        </div>
                        <div className="two-span">
                          <div className="bankLft">
                            <h4 className="txt">Kilz Token coins</h4>
                            {props?.currency
                              ?.filter(
                                (val) => val?.slug === "kilz_token_coins"
                              )
                              .map((value, index) => (
                                <div
                                  className="for-img"
                                  style={{ width: "60px" }}
                                >
                                  <img
                                    src={value?.icon_url}
                                    width={"60px"}
                                    alt=""
                                  />
                                </div>
                              ))}
                          </div>
                          <h4>
                            {parseInt(
                              props?.user?.kills_coins
                            ).toLocaleString()}
                            {/* {props?.user?.formated_coins?.kills_coins} */}
                          </h4>
                        </div>
                        <div className="two-span">
                          <div className="bankLft">
                            <h4 className="txt">Burn coins</h4>
                            {props?.currency
                              ?.filter((val) => val?.slug === "burn_coins")
                              .map((value, index) => (
                                <div
                                  className="for-img"
                                  style={{ width: "60px" }}
                                >
                                  <img
                                    src={value?.icon_url}
                                    width={"60px"}
                                    alt=""
                                  />
                                </div>
                              ))}
                          </div>
                          <h4>
                            {parseInt(props?.user?.coins).toLocaleString()}
                            {/* {props?.user?.formated_coins?.kills_coins} */}
                          </h4>
                        </div>
                      </div>
                    </div>

                    <div className="Awardss">
                      <h3>Stats & Awards</h3>

                      <ul className="AboutPlayers awardsBox">
                        <div className="two-span">
                          <h4>Player Level</h4>
                          <h4>
                            <span>
                              {
                                props.myCharacters.find(
                                  (val) => val?.id === activeCharacter
                                )?.level
                              }
                            </span>{" "}
                          </h4>
                        </div>
                        <div className="two-span">
                          <h4>Player Rank</h4>
                          <h4>
                            <span>{props.user?.rank || 0}</span>{" "}
                          </h4>
                        </div>
                        <div className="two-span">
                          <h4>Career Kills</h4>
                          <h4>
                            <span>{carrerKills()}</span>{" "}
                          </h4>
                        </div>

                        <div className="two-span">
                          <h4>Career Earnings</h4>
                          <h4>
                            <span>{props.user?.career_earning?.toLocaleString() || 0}</span>{" "}
                          </h4>
                        </div>

                        {/*<div className="two-span">*/}
                        {/*    <h4>Character Kills</h4>*/}
                        {/*    <h4><span>{*/}
                        {/*        parseInt(props.myCharacters.find((val)=>val?.id  === activeCharacter)?.kills)?.toLocaleString()*/}
                        {/*    }</span>  </h4>*/}

                        {/*</div>*/}

                        {/*<<div className="two-span">*/}
                        {/*    <h4>career kills</h4>*/}
                        {/*    <h4><span>125,999</span>  </h4>*/}

                        {/*</div>>*/}

                        {props?.pets
                          ?.filter((val) => val?.is_pet_favourite === 1)
                          ?.map((val, key) => (
                              <li key={key} className="playersCharacter">
                                <div className="player-img-of-first-li">
                                  <figure>
                                    <img
                                      src={val?.shop?.image_url}
                                      alt="" />
                                  </figure>
                                </div>
                              </li>
                          ))}
                        {[
                          ...new Array(
                            3 -
                              props?.pets?.filter(
                                (val) => val?.is_pet_favourite === 1
                              ).length
                          ),
                        ].map((val, key) => (
                          <li key={key}>
                            <div className={"imgBox-award"}>
                              <figure>
                                {/*<img width={'100%'} src={val?.image_url} alt=""/>*/}
                              </figure>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>

                    {activeCharacter !== 0 &&
                      typeof activeCharacter === "number" && (
                        <>
                          {/*<Weapons id={activeCharacter} />*/}
                          {/*<Pets id={activeCharacter} />*/}
                        </>
                      )}
                  </div>
                </div>
                {/*</div>*/}
                {/*</div>*/}
              </div>

              <div className="row">
                <div className="col-sm-12">
                  <div className="actionLinks">
                    <Link to={"/rulebook"}>Rule book</Link>
                    <div className="spacer"></div>
                    <Link to={"/archive-history"}>
                      the archives -history pdl
                    </Link>
                    <div className="spacer"></div>
                    <Link to={"/heroes-villains"}>Heroes And Villains</Link>
                    <div className="spacer"></div>
                    <Link to={"/seasons"}>Max Awards</Link>
                    <div className="spacer"></div>
                    <Link to={"/gallery"}>Gallery</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* // Modal */}
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        className="characterModal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <Classes onHide={handleClose} />
        </Modal.Body>
      </Modal>
      {/* // Modal */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.Auth.user,
    character: state.Site.characters,
    currency: state.Site.currency,
    myCharacters: state.UserCharacter.characters,
    pets: state.Shop.items?.data || [],
    awards: state.UserCharacter.awards,
    selected_character: state.Auth.selected_character,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCharacterDetail: (id, item_for) =>
      dispatch(getCharacterDetailRequest(id, item_for)),
    getMyCharacter: () => dispatch(getMyCharactersRequest()),
    getProfile: () => dispatch(getProfileRequest()),
    setLoader: (bool) => dispatch(setLoader(bool)),
    setCharacter: (id) => dispatch(setCharacter(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
