import {
    ACCEPT_GUILD_REQUEST,
    CANCEL_GUILD_REQUEST, FILTER_GUILD_ITEM_REQUEST, READ_GUILD_NOTIFICATION,
    SET_GUILD,
    SET_GUILD_MEMBER,
    SET_GUILD_NOTIFICATION, SET_GUILD_SETTING,
    SET_LIST_GUILD
} from "../constants";
import data from "bootstrap/js/src/dom/data";

const initState = {
    list: null,
    guild: null,
    guildSetting: null,
    notification: [],
}

const Guild = (state = initState, action) => {
    switch (action.type) {

        case SET_GUILD_NOTIFICATION :
            return {
                ...state,
                notification: action.payload,
            }
        case SET_LIST_GUILD :
            return {
                ...state,
                list: action.payload,
            }
        case SET_GUILD :
            return {
                ...state,
                guild: action.payload,
            }
        case SET_GUILD_SETTING :
            return {
                ...state,
                guildSetting: action.payload,
            }
        case SET_GUILD_MEMBER :
            let findIndex = state.list.data.findIndex((val) => val?.id == action.guild_id);
            let newArray = [...state.list.data];
            newArray[findIndex] = action.payload;
            return {
                ...state,
                list: {
                    ...state.list,
                    data: newArray,
                }
            }
        case ACCEPT_GUILD_REQUEST:
            return {
                ...state,
                guild: {
                    ...state.guild,
                    member: action.payload,
                },
            }
        case CANCEL_GUILD_REQUEST:
            return {
                ...state,
                guild: {
                    ...state.guild,
                    member: action.payload,
                },
            }
        case FILTER_GUILD_ITEM_REQUEST:
            let inventory = state?.guild?.inventory?.find((val) => val?.id === action.payload?.item_id);
            // console.log(action.payload?.qty,inventory?.qty,inventory);
            return {
                ...state,
                guild: {
                    ...state.guild,
                    inventory:
                        (parseInt(action.payload?.qty) >= parseInt(inventory?.qty)) ?
                            [
                                ...state?.guild?.inventory?.filter((val) => val?.id !== action.payload?.item_id)
                            ]
                            :
                            [
                                ...state?.guild?.inventory?.map((val) => {
                                    if(val?.id === action.payload?.item_id){
                                        return {...val , qty: parseInt(val?.qty) - action.payload?.qty }
                                    }else{
                                        return {...val}
                                    }
                                })
                            ],
                },
            }

        case READ_GUILD_NOTIFICATION:
            return {
                ...state,
                notification: [...state.notification.map((key,val)=>{
                    return {...val,is_read : 0}
                })]
            }

        default:
            return state;
    }
}

export default Guild;