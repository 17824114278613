import React from 'react';
import {connect} from "react-redux";
import BorderLeft from '../assets/img/borderleft.png';
import LogoDv from '../assets/img/logodv.png';
import BorderRight from '../assets/img/borderright.png';

const PDLBorder = () => {

    return (
        <>
            <div className="border-left">
                <img src={BorderLeft} className="img-fluid" alt="" />
            </div>
            <div className="centerLogoDv">
                <figure><img src={LogoDv} alt="" /></figure>
            </div>
            <div className="border-right">
                <img src={BorderRight} className="img-fluid" alt="" />
            </div>
        </>
);
}

const mapStateToProps = () => {
        return {

        }
    }

const mapDispatchToProps = () => {

}

export default connect(mapStateToProps)(PDLBorder);