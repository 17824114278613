import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import PDLBorder from "../../components/PDLBorder";
import Logo from "../../components/Logo";
import AuthHeader from "../../components/AuthHeader";
import PlayerBottom from '../../assets/img/playerbottom.png';
import PlayerTop from '../../assets/img/playertop.png';
import {buyItemRequest, getItemRequest} from "../../redux/Shop/action";
import {setLoader} from "../../redux/Site/action";
import Loader from "../NotFound/Loader";
import {Link, useNavigate, useParams} from "react-router-dom";
import NotFound from "../NotFound/NotFound";
import '../../assets/css/style.css';
import '../../assets/css/inventory-detail.css';




const ItemsDetails = (props) => {

    const params = useParams();
    const navigate = useNavigate();
    const [loading , setLoading] = useState(false);

    const get = async () => {
        props.setLoader(true);
        await props.getItem(params?.id);
        props.setLoader(false);
    }

    const buy = () => {
        setLoading(true);
        props.buyItem({item_id : params?.id})
            .then(() => {
                setLoading(false);
                // console.log("/inventory");
                navigate('/shop');
            })
            .catch(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        get();
        return () => {

        }
    }, []);

    useEffect(()=>{
        console.log(props.loader);
    },[props.loader]);

    if (props.loader) return <Loader/>

    if(!props.item) return <NotFound />

    if(props.item && props.item?.publish == 0) return <NotFound content={"Item is not available for purchase."} />

    return (
        <section className="fram-sec">
            <div className="frame-wrapper">

                {/* Border*/}
                <PDLBorder/>
                {/*Logo*/}
                <Logo/>

                {/*Menus*/}
                <div className="row">
                    {/*<div className="col-md-7">*/}

                    {/*</div>*/}
                    <div className="col-md-12">
                        <div className="Menus text-right">
                            <AuthHeader/>
                        </div>
                    </div>
                </div>
                {/*Menus*/}
                <section className="inventory-detailed">
                    <div className="container">
                        <div className="row ">
                            <div className="col-lg-5">
                                <div className="forTop-border py-1">
                                    <figure><img className="w-100" src={PlayerTop} alt=""/></figure>
                                </div>
                                <div className="Player-statusDv">
                                    <div className="head">
                                        <h3 className="title">
                                            Item Status
                                        </h3>
                                    </div>
                                    <ul className="status-list">
                                        <li>
                                            <h4>Level</h4>
                                            <span>{props.item?.level}</span>
                                        </li>
                                        <br/>
                                        <li>
                                            <h4>Health</h4>
                                            <span>{props.item?.health?.toLocaleString()}</span>
                                        </li>
                                        <br/>
                                        <li>
                                            <h4>Base</h4>
                                            <span>{props.item?.base?.toLocaleString()}</span>
                                        </li>
                                        <br/>

                                        <li>
                                            <h4>Damage</h4>
                                            <span>{props.item?.damage?.toLocaleString()}</span>
                                        </li>
                                        <br/>

                                        <li>
                                            <h4>Armor Pen</h4>
                                            <span>{props.item?.armor_pen?.toLocaleString()}</span>
                                        </li>
                                        <br/>

                                        <li>
                                            <h4>Armor Pen Resist</h4>
                                            <span>{props.item?.armor_pen_resist}</span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="forBottom-border py-1">
                                    <figure><img className="w-100" src={PlayerBottom} alt=""/></figure>
                                </div>
                            </div>

                            <div className="col-lg-7">
                                <div className="product-details">
                                    <div className="heading">
                                        <h1 className="title">{ props.item?.title }</h1>
                                    </div>
                                    <div className="image-with-btn d-block">
                                        <div className="img-box">
                                            <figure><img width={'100%'} src={props.item?.image_url} alt=""/></figure>
                                        </div>
                                        <div className="heading">
                                            <h1 className="title">Category : {props.item?.category?.name}</h1>
                                            <h1 className="title">
                                                Price :
                                                <img src={props.item?.currency?.icon_url} width={'10%'} alt="" style={{margin: '0 10px'}}/>
                                                {/* {parseInt(props.item?.price).toLocaleString()} */}
                                                {props.item?.price}
                                            </h1>
                                            <h1 className="title">Quantity : {parseInt(props.item?.stock).toLocaleString()}</h1>

                                        </div>
                                    </div>
                                    <div className="description">
                                        <p>{props?.item?.description}</p>
                                    </div>
                                    <div className="button-group">
                                        <Link to={'/shop'} className="btn customButton">Back</Link>
                                        {
                                            loading ?
                                                <button type={"button"} className="btn">
                                                    <i className={"fa fa-spinner fa-spin"}></i>
                                                </button>
                                                :
                                                <button type={"button"} onClick={buy} className="btn">Buy</button>

                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </section>
);
}

const mapStateToProps = (state) =>
    {
        return {
            item: state.Shop.item,
            loader: state.Site.loader,
        }
    }

const mapDispatchToProps = (dispatch) =>
    {
        return {
            setLoader: (bool) => dispatch(setLoader(bool)),
            getItem: (id) => dispatch(getItemRequest(id)),
            buyItem : (data) => dispatch(buyItemRequest(data)),
        }
    }

export default connect(mapStateToProps, mapDispatchToProps)(ItemsDetails);