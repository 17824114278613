import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import Character1 from '../assets/img/character1.png';
import '../assets/css/warrior2.css';
import PlayLogo from "./PlayLogo";
import {Link} from "react-router-dom";
import PlayBg from "../assets/img/playbg.png";
import warriorBg from "../assets/img/warriorbg.png";
import PDLBorder from "./PDLBorder";
import Logo from "./Logo";
import AuthHeader from "./AuthHeader";
import {filterItemsByCategoryRequest, getItemsRequest} from "../redux/Inventory/action";


const Category = (props) => {

    return (
        <>
            {
                (props.parentComponent === "gift" || props.parentComponent === "trade") &&
                props.category.map((val, key) => {
                    return <li key={key}>
                        <a className="nav-link"
                           onClick={() => props.filterByCategory(val?.id)}>{val?.name}</a>
                    </li>
                })
            }

            {
                (props.parentComponent === "character") &&
                props?.category.map((val, key) => {
                    return (
                            val?.placement === "left" ||
                            val?.placement === "right" ||
                            val?.placement === "bottom" ||
                            (val?.equip == 1 && val?.equip_show == 0)
                        )
                        &&
                        <li key={key}>
                            <a className={`${props.tab == val?.id && 'active'}`} onClick={() => {
                                props.get(val?.id);
                                props.setTab(val?.id)
                            }}>
                                {val?.name}
                            </a>
                        </li>
                })
            }
        </>
    );
}

const mapStateToProps = (state) =>
{
    return {
        category: state.Site.category,
    }
}

const mapDispatchToProps = (dispatch) =>
{
    return {
        getItems: (url = null, search = null) => dispatch(getItemsRequest(url, search)),
        filterItemsByCategory: (id) => dispatch(filterItemsByCategoryRequest(id)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Category);