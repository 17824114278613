import React, {useEffect} from 'react';
import {connect} from "react-redux";
import PDLBorder from "../../components/PDLBorder";
import Logo from "../../components/Logo";
import '../../assets/css/thearchives.css';
import AuthHeader from "../../components/AuthHeader";
import Rulebook from '../../assets/img/Rulebook.png';
import {getArchiveRequest, getCharactersRequest, getPagesRequest, setLoader} from "../../redux/Site/action";
import NotFound from "../NotFound/NotFound";
import Loading from "../NotFound/Loader";
import inventorybg from "../../assets/img/inventorybg.png";


const Archive = (props) => {

    const getPageRequest = async () => {
        await props.setLoader(true);
        await props.getArchive();
        await props.setLoader(false);
    }

    useEffect(()=>{
        getPageRequest();

        return () => {

        }
    },[]);


    if(props.loader) return (<Loading/>);

    // if(!props.archives.length > 0) return (<NotFound />);


    return (
        <section className="fram-sec" style={{
            backgroundImage : `url('${inventorybg}')`,
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat',
            padding: '3rem 2rem',
            margin: '4.5rem 2rem',
            position: 'relative',
        }}>
            <div className="frame-wrapper">

                {/* Border*/}
                <PDLBorder/>
                {/*Logo*/}
                <Logo/>

                {/*Menus*/}
                <div className="row">
                    {/*<div className="col-md-7">*/}

                    {/*</div>*/}
                    <div className="col-md-12">
                        <div className="Menus text-right">
                            <AuthHeader/>
                        </div>
                    </div>
                </div>
                {/*Menus*/}

                <section className="thearchives">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 text-center animate__animated animate__fadeInDown">
                                <h4>The Archives -History Of Play Dead Live</h4>
                            </div>
                        </div>

                            {
                                props.archives.map((val,key)=> (
                                    key % 2 === 0 ?
                                        <div className={"row pt-5"}>
                                            <div className={"col-lg-5 animate__animated animate__fadeInRight " + key}>
                                                <figure>
                                                    <img src={val?.image_url} className="archives img-fluid" alt=""/>
                                                </figure>
                                            </div>
                                            <div
                                                className={"col-lg-6 my-auto animate__animated animate__fadeInLeft " + key}>
                                                <p>{val?.content}</p>
                                            </div>
                                        </div>
                                        :
                                        <div className={"row pt-5"}>
                                            <div
                                                className="col-lg-6 my-auto pt-5 animate__animated animate__fadeInRight">
                                                <p>{val?.content}</p>
                                            </div>
                                            <div className="col-lg-5 pt-5 animate__animated animate__fadeInLeft">
                                                <figure>
                                                    <img src={val?.image_url} className="archives img-fluid" alt=""/>
                                                </figure>
                                            </div>
                                        </div>
                                ))
                            }
                    </div>
                </section>

            </div>
        </section>
    );
}

const mapStateToProps = (state) => {
    return {
        archives : state.Site.archives,
        loader : state.Site.loader,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getArchive : () => dispatch(getArchiveRequest()),
        setLoader : (bool) => dispatch(setLoader(bool)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Archive);