import React, {useEffect, useState} from "react";
import AuthHeader from "./AuthHeader";
import PDLBorder from "./PDLBorder";
import Logo from "./Logo";
import auctionBack from "../assets/img/crastle-back.png";
import '../assets/css/room.css';
import roomborder from "../../src/assets/img/roomborder.png";
import {connect, useDispatch, useSelector} from "react-redux";
import {getGallryRequest} from "../redux/Inventory/action";
import {setLoader} from "../redux/Site/action";
import {showLoader} from "../redux/helpers";

const Room = (props) => {

    const user = useSelector((state) => state?.Auth?.user);
    const [bg, setBg] = useState(null);
    const [room, setRoom] = useState(1);
    const dispatch = useDispatch();
    const gallry = useSelector((state) => state?.Inventory?.gallry);

    const get = async (room_no = null) => {
        showLoader('flex');
        await dispatch(getGallryRequest(room_no || room));
        showLoader('none');
    }

    // useEffect(()=>{
    // },[room])

    useEffect(() => {
        if (user) setBg(user?.room_1 ? user?.room_1_url : null);
        get();
    }, [])

    // console.log("dsd",bg);

    return (
        <>
            <section className="fram-sec" id={'room'}>
                <div
                    className={"room-frame-wrapper frame-sec-for-room"}
                    style={{
                        backgroundImage: `url('${(bg) ? bg : auctionBack}')`,
                        backgroundSize: "100% 100%",
                        backgroundRepeat: "no-repeat",
                        padding: "3rem 2rem",
                        margin: "4.5rem 2rem",
                        position: "relative",
                    }}
                >
                    {/* Border*/}
                    <PDLBorder/>
                    {/*Logo*/}
                    <Logo/>

                    {/*Menus*/}
                    <div className="row">
                        {/*<div className="col-md-7">*/}

                        {/*</div>*/}
                        <div className="col-md-12">
                            <div className="Menus text-right">
                                <AuthHeader/>
                            </div>
                        </div>
                    </div>
                    {/*Menus*/}

                    {/*Main Content Start Here*/}

                    <section className="room-sec">
                        <div className="container">
                            <div className="row">
                                {
                                    gallry?.map((val, key) => (
                                        <div className="col-md-3" key={key}>
                                            <div className="romm-img-box">
                                                <div className="room-border-image">
                                                    <img src={roomborder}/>
                                                </div>
                                                <div className="roominner-image">
                                                    <img src={val?.shop?.image_url}/>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            {
                                gallry.length <= 0 &&
                                <div className={"row mt-5 justify-content-center"}>
                                    <div className="profile-creation text-center animate__animated animate__fadeInDown">
                                        <h3>Items Not Found</h3>
                                    </div>
                                </div>
                            }

                            <div className="row button-row-for-room">
                                <div className="button-dv-for-room">
                                    <div className="button-group room-gap-css">
                                        <a
                                            onClick={() => {
                                                setRoom(1);
                                                get(1);
                                                setBg((user?.room_1) ? user?.room_1_url : null)
                                            }}
                                            className="play-btn">
                                            Room 1
                                        </a>
                                    </div>
                                    <div className="button-group room-gap-css">
                                        <a
                                            onClick={() => {
                                                setRoom(2);
                                                get(2);
                                                setBg((user?.room_2) ? user?.room_2_url : null)
                                            }}
                                            className="play-btn"
                                        >
                                            Room 2
                                        </a>
                                    </div>
                                    <div className="button-group room-gap-css">
                                        <a
                                            onClick={() => {
                                                setRoom(3);
                                                get(3);
                                                setBg((user?.room_3) ? user?.room_3_url : null)
                                            }}
                                            className="play-btn">
                                            Room 3
                                        </a>
                                    </div>
                                    <div className="button-group room-gap-css">
                                        <a
                                            onClick={() => {
                                                setRoom(4);
                                                get(4);
                                                setBg((user?.room_4) ? user?.room_4_url : null);
                                            }}
                                            className="play-btn">
                                            Room 4
                                        </a>
                                    </div>
                                    <div className="button-group room-gap-css">
                                        <a
                                            onClick={() => {
                                                setRoom(5);
                                                get(5);
                                                setBg((user?.room_5) ? user?.room_5_url : null)
                                            }}
                                            className="play-btn">
                                            Room 5
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
        </>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        setLoader: (bool) => dispatch(setLoader(bool)),
    }
}

export default connect(null, mapDispatchToProps)(Room);
