export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const WELCOME = "WELCOME";
export const SET_USER = "SET_USER";
export const SET_RELIC = "SET_RELIC";
export const SET_ALERT = "SET_ALERT";
export const RESET_ALERT = "RESET_ALERT";
export const SET_CHARACTER = "SET_CHARACTER";

export const SET_LOADER = "SET_LOADER";
export const SET_PAGES = "SET_PAGES";
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const DELETE_ALL_NOTIFICATIONS = "DELETE_ALL_NOTIFICATIONS";
export const DELETE_NOTIFICATIONS = "DELETE_NOTIFICATIONS";
export const SET_ALL_READ_NOTIFICATIONS = "SET_ALL_READ_NOTIFICATIONS";
export const SET_CHARACTERS = "SET_CHARACTERS";
export const SET_CATEGORY = "SET_CATEGORY";
export const SET_SITE_SETTING = "SET_SITE_SETTING";
export const SET_GALLERY_SETTING = "SET_GALLERY_SETTING";
export const SET_SITE_SLIDER = "SET_SITE_SLIDER";
export const SET_SITE_BURN_ARCADE = "SET_SITE_BURN_ARCADE";
export const SET_SITE_HEROS = "SET_SITE_HEROS";
export const SET_SITE_FAMES = "SET_SITE_FAMES";
export const SET_SITE_FAMES_CATEGORY = "SET_SITE_FAMES_CATEGORY";
export const SET_SESSION = "SET_SESSION";
export const SET_CURRENCY = "SET_CURRENCY";
export const SET_USER_CHARACTER = "SET_USER_CHARACTER";
export const SET_SITE_BOX_LIST = "SET_SITE_BOX_LIST";
export const SET_SITE_HAPPEN = "SET_SITE_HAPPEN";
export const SET_SITE_ARCHIVE = "SET_SITE_ARCHIVE";


export const SET_MY_CHARACTERS = "SET_MY_CHARACTERS";
export const CREATE_CHARACTER = "CREATE_CHARACTER";
export const CHARACTER_DETAIL = "CHARACTER_DETAIL";
export const CHARACTER_INVENTORY = "CHARACTER_INVENTORY";
export const CHARACTER_AWARDS = "CHARACTER_AWARDS";
export const MISC_NOTES_CHANGES = "MISC_NOTES_CHANGES";

export const SET_ITEMS = "SET_ITEMS";
export const SET_ITEM = "SET_ITEM";
export const ADD_PET_ITEM_TO_FAVOURITE = "ADD_PET_ITEM_TO_FAVOURITE";

export const SET_CRAFT_ITEMS = "SET_CRAFT_ITEMS";
export const SET_CRAFT_ITEM = "SET_CRAFT_ITEM";

export const SET_AUCTION_ITEMS = "SET_AUCTION_ITEMS";
export const SET_AUCTION_LIST_ITEMS = "SET_AUCTION_LIST_ITEMS";
export const SET_MY_AUCTION_ITEMS = "SET_MY_AUCTION_ITEMS";
export const SET_VIEW_AUCTION_ITEMS = "SET_VIEW_AUCTION_ITEMS";
export const SET_VIEW_AUCTION_ITEM_STATUS = "SET_VIEW_AUCTION_ITEM_STATUS";
export const SET_VIEW_AUCTION_ITEM_BIDS = "SET_VIEW_AUCTION_ITEM_BIDS";

export const SET_INVENTORY_ITEMS = "SET_INVENTORY_ITEMS";
export const SET_INVENTORY_ITEM = "SET_INVENTORY_ITEM";
export const SET_CHARACTER_IN_INVENTORY_ITEM = "SET_CHARACTER_IN_INVENTORY_ITEM";
export const SET_GEMS_GLYPHS = "SET_GEMS_GLYPHS";
export const SET_GALLRY_ITEMS = "SET_GALLRY_ITEMS";
export const SET_BIONIC_ITEMS = "SET_BIONIC_ITEMS";
export const SET_NEW_GEMS_GLYPHS = "SET_NEW_GEMS_GLYPHS";
export const RESET_NEW_GEMS_GLYPHS = "RESET_NEW_GEMS_GLYPHS";
export const FILTER_INVENTORY_ITEM = "FILTER_INVENTORY_ITEM";
export const SET_BAGS_ITEMS = "SET_BAGS_ITEMS";
export const SET_NEW_BAGS_ITEMS = "SET_NEW_BAGS_ITEMS";
export const REMOVE_BAGS_ITEMS = "REMOVE_BAGS_ITEMS";
export const RETURN_BAGS_ITEMS = "RETURN_BAGS_ITEMS";

export const SET_SEARCH_USERS = "SET_SEARCH_USERS";
export const SET_TRADE = "SET_TRADE";
export const SET_SINGLE_HISTORY_TRADE = "SET_SINGLE_HISTORY_TRADE";
export const SET_NEW_TRADE_REQUEST = "SET_NEW_TRADE_REQUEST";
export const RESET_NEW_TRADE_REQUEST = "RESET_NEW_TRADE_REQUEST";
export const RESET_REQUEST_TRADE_TIME = "RESET_REQUEST_TRADE_TIME";

export const SET_GUILD_NOTIFICATION = "SET_GUILD_NOTIFICATION";
export const READ_GUILD_NOTIFICATION = "READ_GUILD_NOTIFICATION";
export const SET_LIST_GUILD = "SET_LIST_GUILD";
export const SET_GUILD = "SET_GUILD";
export const SET_GUILD_SETTING = "SET_GUILD_SETTING";
export const SET_GUILD_MEMBER = "SET_GUILD_MEMBER";
export const ACCEPT_GUILD_REQUEST = "ACCEPT_GUILD_REQUEST";
export const CANCEL_GUILD_REQUEST = "CANCEL_GUILD_REQUEST";
export const SET_GUILD_ITEM_REQUEST = "SET_GUILD_ITEM_REQUEST";
export const FILTER_GUILD_ITEM_REQUEST = "FILTER_GUILD_ITEM_REQUEST";
