import {
    CHARACTER_AWARDS,
    CHARACTER_DETAIL, CHARACTER_INVENTORY,
    CREATE_CHARACTER, MISC_NOTES_CHANGES, SET_BIONIC_ITEMS,
    SET_MY_CHARACTERS,
} from "../constants";
import { headersWithImage, headersWithToken} from "../headers";
import {
    applyBionicsAPi, changeMiscNotesAPi,
    createCharacterAPi, editCharacterAPi, equipApi,
    getCharacterDetailApi,
    getCharacterInventoryApi,
    getMyCharactersAPi,
    loginAPi,
    pagesAPi,
    signUpAPi, unEquipApi
} from "../apis";
import axios from "axios";
import {setAlert} from "../Alert/action";
import {responseError} from "../errorHandler";


export const setMyCharacters = (data) => {
    return {
        type: SET_MY_CHARACTERS,
        payload: data
    }
}

export const setAwards = (data) => {
    return {
        type: CHARACTER_AWARDS,
        payload: data
    }
}

export const getMyCharactersRequest = () => {
    return async (dispatch, getState) => {
        try {
            let token = getState().Auth.token;
            let response = await axios.get(getMyCharactersAPi, headersWithToken(token));
            dispatch(setMyCharacters([]));
            dispatch(setMyCharacters(response.data.response.data));
        } catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    }
}

export const setMyCharacter = (data) => {
    return {
        type: CREATE_CHARACTER,
        payload: data
    }
}

export const createMyCharacterRequest = (data) => {
    return async (dispatch, getState) => new Promise(async (resolve, reject) => {
        try {
            let token = getState().Auth.token;
            let response = await axios.post(createCharacterAPi, data, headersWithImage(token));
            dispatch(setMyCharacter(response.data.response.data.character));
            resolve(true);
        } catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
            reject(false);
        }
    });
}

export const editMyCharacterRequest = (id,data) => {
    return async (dispatch, getState) => new Promise(async (resolve, reject) => {
        try {
            let token = getState().Auth.token;
            let response = await axios.post(editCharacterAPi(id), data, headersWithImage(token));
            dispatch(setMyCharacters([]));
            dispatch(setMyCharacter(response.data.response.data.character));
            resolve(true);
        } catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
            reject(false);
        }
    });
}

export const setCharacterDetail = (data) => {
    return {
        type: CHARACTER_DETAIL,
        payload: data
    }
}

export const getCharacterDetailRequest = (id,item_for) => {
    return async (dispatch, getState) => {
        try {
            let token = getState().Auth.token;
            let response = await axios.get(getCharacterDetailApi(id,item_for), headersWithToken(token));
            dispatch(setCharacterDetail(response.data.response.data));
        } catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    }
}

export const setCharacterInventory = (data) => {
    return {
        type: CHARACTER_INVENTORY,
        payload: data
    }
}

export const getCharacterInventoryRequest = (type,category_id = null, url = null,search=null) => {
    return async (dispatch, getState) => {
        try {
            let token = getState().Auth.token;
            let link = getCharacterInventoryApi(type);
            if(category_id){
                link = getCharacterInventoryApi(type)+`?${category_id?'category_id='+category_id : ''}`;
            }else if(url){
                link = url
            }else if(search){
                link = getCharacterInventoryApi(type)+`?${search?'search='+search : ''}`;
            }
            // let link = getCharacterInventoryApi(type)+`?${category_id?'category_id='+category_id : ''}`;
            let response = await axios.get(link, headersWithToken(token));
            dispatch(setCharacterInventory(response.data.response.data));
        } catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    }
}

export const equipRequest = (data) => {
    return async (dispatch, getState) => new Promise(async (resolve, reject)=>{
        try {
            let token = getState().Auth.token;
            let response = await axios.post(equipApi,data, headersWithToken(token));
            return resolve(true);
            // dispatch(setCharacterInventory(response.data.response.data));
        } catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
            return reject(false);
        }
    });
}

export const unequipRequest = (data) => {
    return async (dispatch, getState) => new Promise(async (resolve, reject)=>{
        try {
            let token = getState().Auth.token;
            let response = await axios.post(unEquipApi,data, headersWithToken(token));
            return resolve(true);
            // dispatch(setCharacterInventory(response.data.response.data));
        } catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
            return reject(false);
        }
    });
}

export const applyBionicRequest = (data) => {
    return async (dispatch, getState) => new Promise(async (resolve, reject)=>{
        try {
            let token = getState().Auth.token;
            let response = await axios.post(applyBionicsAPi,data, headersWithToken(token));
            console.log(response.data.response.data);
            dispatch({
                type : SET_BIONIC_ITEMS,
                payload : data?.inventory_id,
            });
            return resolve(true);
            // dispatch(setCharacterInventory(response.data.response.data));
        } catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
            return reject(false);
        }
    });
}

export const miscNotesChanges = (data) => {
    return async (dispatch, getState) => new Promise(async (resolve, reject)=>{
        try {
            let token = getState().Auth.token;
            let response = await axios.post(changeMiscNotesAPi,data, headersWithToken(token));
            dispatch({
                type : MISC_NOTES_CHANGES,
                payload : data?.notes,
            })
            dispatch(setAlert([
                {
                    message : "Misc Notes Changes Successfully!",
                    color : 'success',
                }
            ]))
            return resolve(true);
        } catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
            return reject(false);
        }
    });
}