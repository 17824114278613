import React, {useEffect, useState} from 'react';
import {connect, useSelector} from "react-redux";
import Logo from "../../components/Logo";
import '../../assets/css/castle.css';
import AuthHeader from "../../components/AuthHeader";
import {Link, useNavigate, useParams} from "react-router-dom";
import {
    addItemInTrade,
} from "../../redux/Trade/action";
import {showLoader , getImageFromStorage } from "../../redux/helpers";
import PDLBorder from "../../components/PDLBorder";
import {acceptGuildRequest, cancelGuildRequest, getGuildRequest} from "../../redux/Guild/action";
import {getItemsRequest} from "../../redux/Inventory/action";
import GuildMenu from "./GuildMenu";
import characterBg from '../../assets/img/character-bg.png';
import PortalisTxt from '../../assets/img/portalis-txt.png';
import treasureTxt from '../../assets/img/treasure-text.png';
import PortalisBack from '../../assets/img/portalis-back.png';
import CastleTxt from '../../assets/img/castle-txt.png';
import CrastleBack from '../../assets/img/crastle-back.png';
import GateTxt from '../../assets/img/gate-txt.png';
import GateBack from '../../assets/img/gate-house-back.png';
import CourtTxt from '../../assets/img/court-text.png';
import CourtBack from '../../assets/img/court-yard.png';
import ChapelTxt from '../../assets/img/chapel-txt.png';
import ChapelBack from '../../assets/img/chapel-back.png';
import DungeonTxt from '../../assets/img/dungeon-txt.png';
import DungeonBack from '../../assets/img/dungeon-back.png';
import SolorTxt from '../../assets/img/solar-txt.png';
import SolorBack from '../../assets/img/solar-back.png';
import ThroneTxt from '../../assets/img/throne-txt.png';
import ThroneBack from '../../assets/img/throne-back.png';
import ThroneRoom from "./ThroneRoom";


const Portalis = (props) => {

    const params = useParams();

    const get = async () => {
        showLoader('flex');
        await props.getGuild(params.id);
        await props.getItems();
        showLoader('none');
    }

    useEffect(() => {
        get();
    }, [params.id])

    return (
        <div className={"fram-sec"}>
            <div className="frame-wrapper-p" style={{
                backgroundImage: `url('${
                    ( () => {
                        if(params?.slug === "portalis") return (props?.guild?.portalis_image !== "" && props?.guild?.portalis_image !== null )? getImageFromStorage(props?.guild?.portalis_image) : PortalisBack;
                        if(params?.slug === "castle") return (props?.guild?.castle_image !== "" && props?.guild?.castle_image !== null )? getImageFromStorage(props?.guild?.castle_image) : CrastleBack;
                        if(params?.slug === "gate-house") return  (props?.guild?.gate_image !== "" && props?.guild?.gate_image !== null )? getImageFromStorage(props?.guild?.gate_image) : GateBack;
                        if(params?.slug === "court-yard") return (props?.guild?.court_image !== "" && props?.guild?.court_image !== null )? getImageFromStorage(props?.guild?.court_image) : CourtBack;
                        if(params?.slug === "chapel") return (props?.guild?.chapel_image !== "" && props?.guild?.chapel_image !== null )? getImageFromStorage(props?.guild?.chapel_image) : ChapelBack;
                        if(params?.slug === "dungeon") return (props?.guild?.dungeon_image !== "" && props?.guild?.dungeon_image !== null )? getImageFromStorage(props?.guild?.dungeon_image) : DungeonBack;
                        if(params?.slug === "solar") return (props?.guild?.solar_image !== "" && props?.guild?.solar_image !== null )? getImageFromStorage(props?.guild?.solar_image) : SolorBack;
                        if(params?.slug === "throne-room") return (props?.guild?.throne_image !== "" && props?.guild?.throne_image !== null )? getImageFromStorage(props?.guild?.throne_image) : characterBg;
                        if(params?.slug === "treasure") return (props?.guild?.treasure_image !== "" && props?.guild?.treasure_image !== null )? getImageFromStorage(props?.guild?.treasure_image) : characterBg;
                        if(params?.slug === "guild-hall") return (props?.guild?.guilt_image !== "" && props?.guild?.guilt_image !== null )? getImageFromStorage(props?.guild?.guilt_image) : PortalisBack;
                    }) ()
                }')`
            }}>
                {/* Border*/}
                <PDLBorder/>
                {/*Logo*/}
                <Logo/>

                {/*Menus*/}
                <div className="row">
                    {/*<div className="col-md-7">*/}

                    {/*</div>*/}
                    <div className="col-md-12">
                        <div className="Menus text-right">
                            <AuthHeader/>
                        </div>
                    </div>
                </div>

                <section className="castle_sec">
                    <div className="container">

                        <GuildMenu />

                        <div className="heading_wrapper">
                            <figure><img src={
                                (() => {
                                    if (params?.slug === "portalis") return PortalisTxt;
                                    if (params?.slug === "castle") return CastleTxt;
                                    if (params?.slug === "gate-house") return GateTxt;
                                    if (params?.slug === "court-yard") return CourtTxt;
                                    if (params?.slug === "chapel") return ChapelTxt;
                                    if (params?.slug === "dungeon") return DungeonTxt;
                                    if (params?.slug === "solar") return SolorTxt;
                                    if (params?.slug === "throne-room") return ThroneTxt;
                                    if (params?.slug === "guild-hall") return PortalisTxt;
                                    if (params?.slug === "treasure") return treasureTxt;
                                })()
                                } alt="" className="img-fluid" style={{width:'50%'}} /></figure>
                        </div>

                        {
                            (() => {
                                // if (params?.slug === "portalis") return PortalisTxt;
                                // if (params?.slug === "castle") return CastleTxt;
                                // if (params?.slug === "gate-house") return GateTxt;
                                // if (params?.slug === "court-yard") return CourtTxt;
                                // if (params?.slug === "chapel") return ChapelTxt;
                                // if (params?.slug === "dungeon") return DungeonTxt;
                                // if (params?.slug === "solar") return SolorTxt;
                                if (params?.slug === "throne-room") return <ThroneRoom />;
                                // if (params?.slug === "guild-hall") return PortalisTxt;
                            })()
                        }

                    </div>
                </section>
            </div>
        </div>
    );
}


const mapStateToProps = (state) => {
    return {
        user : state.Auth.user,
        guild : state.Guild.guild,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getGuild : (id) => dispatch(getGuildRequest(id)),
        acceptGuild : (data) => dispatch(acceptGuildRequest(data)),
        cancelGuild : (data) => dispatch(cancelGuildRequest(data)),
        getItems: (url = null,search=null) => dispatch(getItemsRequest(url,search)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Portalis);