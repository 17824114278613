import React, { useEffect, useRef, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { localRoutes, authRoutes } from "./routes";
import ProtectedRoute from "./routes/ProtectedRoute";
import "./assets/css/bootstrap.min.css";
import "./assets/css/image-uploader.min.css";
import "./assets/css/modal.css";
import Wrapper from "./components/Wrapper";
import Alert from "./components/Alert";
import { connect } from "react-redux";
import {
  getArchiveRequest,
  getBoxListRequest,
  getBurnAcradeRequest,
  getCategoryRequest,
  getCharactersRequest,
  getCurrencyRequest,
  getFameSessionRequest,
  getHappenNowRequest,
  getNotificationsRequest,
  getPagesRequest,
  getSettingRequest,
  getSliderRequest,
} from "./redux/Site/action";
import { showLoader } from "./redux/helpers";
import { pusher } from "./redux/pusherConfig";
import { resetTrade, setNewTradeRequest } from "./redux/TradeRequest/action";
import TradeRequest from "./components/Modals/TradeRequest";
import { tradeCancelRequest } from "./redux/Trade/action";
import { getMyCharactersRequest } from "./redux/UserCharacter/action";
import { getProfileRequest, setCharacter } from "./redux/Auth/action";
import { getNotificationGuildRequest } from "./redux/Guild/action";
import Maintenance from "./pages/Maintenance/index";
import welcomeVideo from "../src/assets/loadvideo.mov";

function App(props) {
  const [characterListRef, setCharacterListRef] = useState(0);
  const location = useLocation();
  const navigator = useNavigate();
  const startRequest = async () => {
    showLoader("flex");
    await props.pagesRequest();
    await props.characterRequest();
    await props.getCategoryRequest();
    await props.getMyCharacters();
    await props.getSettingRequest();

    await props.getBoxList();
    await props.getHappenNow();
    await props.getSlider();
    await props.getBurnAcrade();
    await props.getCurrency();
    await props.getArchive();
    setTimeout(() => {
      showLoader("none");
    }, 5000);
  };
  const getNewTradingRequest = () => {
    let config = pusher(props.token);
    let channel = config.subscribe(`newTrade-${props.user?.id}`);
    channel.bind("new-trade-request", function (data) {
      props.setNewTrade(data);
      console.log(data);
    });
  };
  const getNewNotificationRequest = () => {
    let config = pusher(props.token);
    let channel = config.subscribe(`notification_${props.user?.id}`);
    console.log(channel);
    channel.bind("new-notification-request", function (data) {
      props.getNotifications();
      console.log(data);
    });
  };
  const getGuildNotificationRequest = () => {
    let config = pusher(props.token);
    let channel = config.subscribe(`guild_notification_${props.user?.id}`);
    console.log("getGuildNotificationRequest", channel);
    channel.bind("new-guild-notification-request", function (data) {
      props.getGuildNotifications();
      console.log("getGuildNotificationRequest", data);
    });
  };
  const cancelTradingRequest = async () => {
    let config = pusher(props.token);
    console.log(
      "App page",
      `trade-cancel-${props.newTrade?.trade?.sender_id}-${props.newTrade?.trade?.receiver_id}`
    );
    let channel = config.subscribe(
      `trade-cancel-${props.newTrade?.trade?.sender_id}-${props.newTrade?.trade?.receiver_id}`
    );
    channel.bind("trade-cancel", function (data) {
      props.resetTrade();
    });
  };

  useEffect(() => {
    startRequest();
    if (props.islogin) {
      getNewTradingRequest();
      getGuildNotificationRequest();
      getNewNotificationRequest();
      props.getFameSession();
      props.getNotifications();
      props.getGuildNotifications();
      props.getProfile();
    }
  }, []);

  useEffect(() => {
    if (props.islogin && props.newTrade) {
      cancelTradingRequest();
    }
  }, [props.newTrade]);

  useEffect(() => {
    if (props.islogin && props.myCharacters.length > 0) {
      props.setCharacter(props.myCharacters[0]?.id);
    }
  }, [props.myCharacters]);

  // const [videoLoader, setVideoLoader] = useState(false);
  // useEffect(() => {
  //   if (document.referrer && document.referrer !== window.location.href) {
  //   } else {
  //     setVideoLoader(true);
  //   }
  // }, []);

  // useEffect(() => {
  //   if (videoLoader) {
  //     const timeout = setTimeout(() => {
  //       setVideoLoader(false);
  //     }, 10000); // 10 seconds in milliseconds

  //     return () => clearTimeout(timeout);
  //   }
  // }, [videoLoader]);

  return (
    <div className="">
      <div id="loader-full" className="loader-full">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
      <TradeRequest />
      <Routes>
        {props?.maintenance_mode === 0 &&
          localRoutes.map((val, key) => {
            return <Route key={key} path={val.path} element={val.component} />;
          })}
        {props?.maintenance_mode === 0 &&
          authRoutes.map((val, key) => {
            return (
              <Route
                key={key}
                path={val.path}
                element={
                  <ProtectedRoute>
                    <Wrapper>{val.component}</Wrapper>
                  </ProtectedRoute>
                }
              />
            );
            // return <Route key={key} path={val.path} element={val.component} />
          })}
        {props?.maintenance_mode === 1 && (
          <Route path={"/"} element={<Maintenance />} />
        )}
      </Routes>
      <Alert />
      {props?.maintenance_mode === 0 && (
        <div className={"corner-characters"}>
          <div className={"current-state-character"}>
            {props.myCharacters?.filter(
              (val) => val?.id === props?.selected_character
            ).length > 0 &&
              characterListRef === 0 && (
                <>
                  {props.myCharacters
                    ?.filter((val) => val?.id === props?.selected_character)
                    ?.map((val, key) => (
                      <figure key={key} onClick={() => setCharacterListRef(1)}>
                        {
                          <img src={val?.logo_url} alt={val?.name} />
                          // :
                          // <i onClick={()=>setCharacterListRef(1)} className={"fa fa-trash fa-2x text-white"}></i>
                        }
                      </figure>
                    ))}
                </>
              )}
          </div>
          {characterListRef === 1 && (
            <ul className={"my-characters-list"}>
              {props.myCharacters?.map((val, key) => (
                <li key={key}>
                  <figure
                    onClick={() => {
                      props?.setCharacter(val?.id);
                      setCharacterListRef(0);
                      if (location.pathname?.includes("/character/detail/")) {
                        navigator("/character/detail/" + val?.id);
                      } else if (
                        location.pathname?.includes("/character/view/")
                      ) {
                        navigator("/character/view/" + val?.id);
                      }
                    }}
                  >
                    <img
                      src={val?.logo_url}
                      alt={val?.name}
                      style={{
                        border:
                          val?.id === props?.selected_character
                            ? "3px solid #cda55c"
                            : "none",
                      }}
                    />
                    {/*{*/}
                    {/*    val?.id === props?.selected_character &&*/}
                    {/*    <i className={" fa fa-star"}*/}
                    {/*       style={{*/}
                    {/*           color : '#E3BF4B',*/}
                    {/*       */}
                    {/*       }}></i>*/}
                    {/*}*/}
                  </figure>
                </li>
              ))}
              {
                // props.myCharacters?.length > 0 &&
                <li className={"close-ico"}>
                  <figure onClick={() => setCharacterListRef(0)}>
                    <i className={"fa fa-times fa-2x"}></i>
                  </figure>
                </li>
              }
            </ul>
          )}
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    maintenance_mode: state.Site.setting?.maintenance_mode || 0,
    islogin: state.Auth.islogin,
    selected_character: state.Auth.selected_character,
    token: state.Auth.token,
    user: state.Auth.user,
    newTrade: state.TradeRequest.newTrade,
    myCharacters: state.UserCharacter.characters,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFameSession: () => dispatch(getFameSessionRequest()),
    pagesRequest: () => dispatch(getPagesRequest()),
    characterRequest: () => dispatch(getCharactersRequest()),
    getCategoryRequest: () => dispatch(getCategoryRequest()),
    getSettingRequest: () => dispatch(getSettingRequest()),
    setNewTrade: (data) => dispatch(setNewTradeRequest(data)),
    getMyCharacters: () => dispatch(getMyCharactersRequest()),
    getGuildNotifications: () => dispatch(getNotificationGuildRequest()),

    resetTrade: () => dispatch(resetTrade()),

    getBoxList: () => dispatch(getBoxListRequest()),
    getHappenNow: () => dispatch(getHappenNowRequest()),
    getSlider: () => dispatch(getSliderRequest()),
    getBurnAcrade: () => dispatch(getBurnAcradeRequest()),
    getCurrency: () => dispatch(getCurrencyRequest()),
    getNotifications: () => dispatch(getNotificationsRequest()),
    getArchive: () => dispatch(getArchiveRequest()),
    setCharacter: (id) => dispatch(setCharacter(id)),
    getProfile: () => dispatch(getProfileRequest()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
