import React from 'react';
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";

const ThroneRoom = () => {

    const guild = useSelector(state => state?.Guild?.guild);

    return (
        <div className="all-guild">
            <div className="row">
                {
                    guild?.achievement?.map((val, key) => (
                        <div key={key} className="col-lg-3 col-md-6">
                            <div className="box">
                                <div className="img-box">
                                    <figure>
                                        <img src={val?.item?.image_url} alt="" className="img-fluid"/>
                                    </figure>
                                </div>
                                <div className="txt">
                                    <h4>Item Name <span>{val?.item?.title}</span></h4>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}

export default ThroneRoom;