import React, {useEffect, useState} from 'react';
import {Route, Routes, useNavigate} from "react-router-dom";
import {connect} from "react-redux";
import {Button, Modal} from "react-bootstrap";
import Profile from '../../assets/img/profile.jpg';
import {acceptRequest, rejectRequest, resetTime, resetTrade, setNewTradeRequest} from "../../redux/TradeRequest/action";
import {showLoader, startTimer} from "../../redux/helpers";
import {pusher} from "../../redux/pusherConfig";
import {addPlayerRequest, joinGuildRequest} from "../../redux/Guild/action";
import {toast} from "react-toastify";
import axios from "axios";
import {headersWithToken} from "../../redux/headers";
import {getGuildPlayerApi} from "../../redux/apis";
import {removeGemGlyphsItemRequest} from "../../redux/Inventory/action";

function Confirm(props) {

    const navigator = useNavigate();
    const [isloading , setIsloading] = useState(false);

    const confirm = async () => {
        setIsloading(true);
        props.removeGemGlyphsItem(props.id)
            .then(async ()=>{
                await props.getItem(props.paramId);
                setIsloading(false);
                closeModal();
            })
            .catch(()=>{
                setIsloading(false);
                closeModal();
            });
    }


    const closeModal = () => {
        props.setOpen(false);
    }

    return (
        <Modal
            show={props.open}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Confirmation
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className={"text-center text-white"}>Do you want to confirm to delete?</p>
            </Modal.Body>
            <Modal.Footer>
                {
                    isloading ?
                        <Button className={"btn btn-success"} type={"button"}>
                            <i className={"fa fa-spinner fa-spin"}></i>
                        </Button>
                        :
                        <Button type={"button"} onClick={confirm} className={"btn btn-success"}>Yes</Button>
                }
                <Button className={"btn btn-success"} onClick={closeModal}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    );
}

const mapStateToProps = (state) => {
    return {
        token : state.Auth.token,
        characters : state.UserCharacter.characters,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        joinGuild : (data) => dispatch(joinGuildRequest(data)),
        addPlayer : (data) => dispatch(addPlayerRequest(data)),
        removeGemGlyphsItem : (id) => dispatch(removeGemGlyphsItemRequest(id)),

    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Confirm);
