import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import PDLBorder from "../../components/PDLBorder";
import Logo from "../../components/Logo";
import '../../assets/css/character-creation.css';
import AuthHeader from "../../components/AuthHeader";
// import characterCreation from '../../assets/img/character-creation.png';
import characterCreation from '../../assets/img/profiletitle.png';
import {createMyCharacterRequest, setMyCharacter} from "../../redux/UserCharacter/action";
import {useNavigate} from "react-router-dom";
import {setAlert} from "../../redux/Alert/action";
import {updateProfileRequest} from "../../redux/Auth/action";

const Edit = (props) => {

    const navigator = useNavigate();
    const [loading, setLoading] = useState(null);
    const [image, setImage] = useState(null);
    const [form, setForm] = useState({
        name : '',
        password : '',
        confirm_password : '',
        profile : null,
    });

    const onChangeHandler = (event) => {
        let {name, value} = event.target;

        setForm((preVal) => {
            return {
                ...preVal,
                [name]: value,
            }
        });
    }

    const onChangeFile = (event) => {
        let files = event.target.files[0];
        let nameArr = files.name.split('.');
        let fileType = nameArr[nameArr.length-1];
        if(
            fileType === 'jpg' || fileType === 'JPG' || 
            fileType === 'png' || fileType === 'PNG' || 
            fileType === 'jpeg' || fileType === 'JPEG'
        ){
            setForm((preVal) => {
                return {
                    ...preVal,profile: files,
                }
            });
            let output = document.getElementById('output');
            output.src = URL.createObjectURL(files);
            setImage(output.src);
            output.onload = function () {
                URL.revokeObjectURL(output.src) // free memory
            }
        }
        else{
            props.setAlert([{message: `Only jpg,png,jpeg is allowed!`,color :'warning'}]);
            return true;
        }
    }


    const onSubmitHandler = async (event) => {
        event.preventDefault();
        let formData = new FormData();
        formData.append('name', form.name);
        formData.append('profile', form.profile);
        formData.append('password', form.password);
        formData.append('confirm_password', form.confirm_password);
        setLoading(true);
        props.updateProfile(formData)
            .then(() => {
                setLoading(false);
                console.log("true");
                navigator('/profile', { replace: true });
            })
            .catch((e) => {
                setLoading(false);
                console.log(e);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    useEffect(()=>{
        return ()=>{

        }
    },[]);

    return (
        <section className="fram-sec">
            <div className="frame-wrapper">

                {/* Border*/}
                <PDLBorder/>
                {/*Logo*/}
                <Logo/>

                {/*Menus*/}
                <div className="row">
                    {/*<div className="col-md-7">*/}

                    {/*</div>*/}
                    <div className="col-md-12">
                        <div className="Menus text-right">
                            <AuthHeader/>
                        </div>
                    </div>
                </div>
                {/*Menus*/}

                <section className="character">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3"></div>
                            <div className="col-lg-6">
                                <div className="character-creation text-center">
                                    <figure>
                                        {/* <img src={characterCreation} className="img-fluid" alt="" /> */}
                                    </figure>
                                    <div class="profile-creation text-center animate__animated animate__fadeInDown"><h3>Edit Profile</h3></div>
                                </div>
                                <div className="character-form-wrapper">
                                    <form onSubmit={onSubmitHandler}>

                                        <div className="form-group justify-content-center row mt-4">
                                            {/*<div className="col-md-6">*/}
                                            {/*    <div className="input-images">*/}
                                            {/*        <div className="image-uploader" id={"output"} style={*/}
                                            {/*            image && {*/}
                                            {/*                backgroundImage: `url('${image}')`,*/}
                                            {/*            }*/}
                                            {/*        }>*/}
                                            {/*            <label htmlFor="customFile">*/}
                                            {/*                <div className="upload-text">*/}
                                            {/*                    /!*<i className="material-icons">cloud_upload</i>*!/*/}
                                            {/*                    <span>Upload Profile Picture</span>*/}
                                            {/*                </div>*/}
                                            {/*            </label>*/}
                                            {/*            <input type="file"*/}
                                            {/*                   required={true}*/}
                                            {/*                   onChange={onChangeFile}*/}
                                            {/*                   id="customFile"*/}
                                            {/*                   name="profile"/>*/}
                                            {/*            <div className="uploaded"></div>*/}

                                            {/*        </div>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                        </div>

                                        <div className="from-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="name">Name</label>
                                                <input type="text" id={'name'} onChange={onChangeHandler} required={true}
                                                       name={"name"} defaultValue={form.name}
                                                       className="inputCustomHeight form-control"
                                                       placeholder="Type Your Name"/>
                                            </div>
                                        </div>

                                        <div className="from-group my-2 row">
                                            <div className="col-md-12">
                                                <label htmlFor="password">New Password</label>
                                                <input type="password" onChange={onChangeHandler} required={true}
                                                       name={"password"} defaultValue={form.password}
                                                       className="inputCustomHeight form-control"
                                                       placeholder="*********"/>
                                            </div>
                                        </div>

                                        <div className="from-group my-2 row">
                                            <div className="col-md-12">
                                                <label htmlFor="password">Confirm Password</label>
                                                <input type="password" onChange={onChangeHandler} required={true}
                                                       name={"confirm_password"} defaultValue={form.confirm_password}
                                                       className="inputCustomHeight form-control"
                                                       placeholder="*********"/>
                                            </div>
                                        </div>

                                        <div className="form-group mt-4">
                                            {
                                                loading ?
                                                    <button type={"button"} className="btn">
                                                        <i className={"fa fa-spinner fa-spin"}></i>
                                                    </button>
                                                    :
                                                    <button type={"submit"} className="btn">submit</button>
                                            }
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-lg-3"></div>
                        </div>
                    </div>
                </section>

            </div>
        </section>
    );
}

const mapStateToProps = (state) => {
    return {
        islogin: state.Auth.islogin,
        character: state.Site.characters,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateProfile: (data) => dispatch(updateProfileRequest(data)),
        setAlert: (data) => dispatch(setAlert(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Edit);