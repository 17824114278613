import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import Profile from "../../assets/img/profile.jpg";
import {
  acceptRequest,
  rejectRequest,
  resetTime,
  resetTrade,
  setNewTradeRequest,
} from "../../redux/TradeRequest/action";
import {
  onInputChangeHandler,
  showLoader,
  startTimer,
} from "../../redux/helpers";
import { pusher } from "../../redux/pusherConfig";
import { addPlayerRequest, joinGuildRequest } from "../../redux/Guild/action";
import { toast } from "react-toastify";
import axios from "axios";
import { headersWithToken } from "../../redux/headers";
import { getGuildPlayerApi } from "../../redux/apis";
import { removeGemGlyphsItemRequest } from "../../redux/Inventory/action";
import { createAuctionItemRequest } from "../../redux/Auction/action";
import { setAlert } from "../../redux/Alert/action";

function AddAuction(props) {
  const navigator = useNavigate();
  const [isloading, setIsloading] = useState(false);
  const [value, setValue] = useState("");
  const initial = {
    inventory_id: "",
    shop_id: "",
    min_amount: 0,
    auction_end_in: 0,
    start_date: "",
    start_time: "",
    end_date: "",
    end_time: "",
    qty: 1,
  };
  const [form, setForm] = useState({ ...initial });
  const currency = useSelector((state) => state?.Site?.currency);
  const dispatch = useDispatch();

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setIsloading(true);

    // let min_amount_var = parseInt(value.replace(',', ''));
    const min_amount_var = parseFloat(value.replace(/,/g, ''));

    if (min_amount_var < 10) {
      dispatch(setAlert([{
        message: 'Auction start amount must be minimum : 10', color: 'warning'
      }]));
      setIsloading(false);
      return false;
    }

    props
      .createAuctionItem({
        ...form,
        min_amount: min_amount_var, // Convert to integer
        shop_id: props?.item?.shop?.id,
        inventory_id: props?.id,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      })
      .then(async () => {
        setIsloading(false);
        closeModal();
        navigator("/auction/my");
      })
      .catch(() => {
        setIsloading(false);
        closeModal();
      });
  };

  const closeModal = () => {
    props.setOpen(false);
  };

  const onInputChangeHandler1 = (e, setState) => {
    let { name, value } = e.target;
    const inputValue = e.target.value.replace(/\D/g, ""); // Remove non-digit characters


    var formattedValue = inputValue
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    setValue(formattedValue);
    // setState((preVal) => {
    //   return {
    //     ...preVal,
    //     [name]: formattedValue,
    //   };
    // });
  };

  return (
    <Modal
      id={"myAuctionModal"}
      show={props.open}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        {/*<Modal.Title id="contained-modal-title-vcenter">*/}
        {/*    Confirmation*/}
        {/*</Modal.Title>*/}
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={onSubmitHandler}>
          <div className="auction-post-box">
            <div className="auction-item">
              <div className="img-box">
                <figure>
                  <img
                    src={props?.item?.shop?.image_url}
                    width={"17%"}
                    alt=""
                    className="img-fluid"
                  />
                </figure>
              </div>
              <div className="title">
                <h3>{props?.item?.shop?.title}</h3>
              </div>
            </div>
            <div className="set-price-and-duration">
              <div className="form-group">
                <label>Set Minimum Bid Price</label>
                <input
                  type="text"
                  name={"min_amount"}
                  required={true}
                  min={10}
                  onChange={(e) => onInputChangeHandler1(e, setForm)}
                  placeholder="00.00"
                  value={value}
                  className="form-control"
                />
              </div>
              <span className="seprator"></span>
              <div className="form-group">
                <label>Currency</label>
                <select
                  name="currency_slug"
                  required={true}
                  onChange={(e) => onInputChangeHandler(e, setForm)}
                  className="form-control text-white bg-black"
                  style={{ background: "black", color: "#fff", height: 55 }}
                >
                  <option
                    value=""
                    style={{ background: "black", color: "#fff" }}
                    className={"bg-black text-white"}
                  >
                    Select Currency
                  </option>
                  {currency?.map((val, key) => (
                    <option
                      key={key}
                      value={val?.slug}
                      style={{ background: "black", color: "#fff" }}
                    >
                      {val?.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="set-price-and-duration">
              <div className="form-group">
                <label>Auction End In</label>
                <select
                  name="auction_end_in"
                  id=""
                  required={true}
                  className="form-control text-white bg-black"
                  style={{ background: "black", color: "#fff", height: 55 }}
                  onChange={(e) => onInputChangeHandler(e, setForm)}
                >
                  <option
                    value=""
                    style={{ background: "black", color: "#fff" }}
                    className={"bg-black text-white"}
                  >
                    Select Time
                  </option>
                  {/* 0.0333,  for 2 minutes auction time */}
                  {[1, 12, 24, 36, 48].map((val, key) => (
                    <option
                      value={val}
                      key={key}
                      style={{ background: "black", color: "#fff" }}
                    >
                      {val} Hours
                    </option>
                  ))}
                </select>

                {/*<input type="date" name={'start_date'} required={true}*/}
                {/*       onChange={(e)=>onInputChangeHandler(e,setForm)}*/}
                {/*       className="form-control" />*/}
              </div>
              <span className="seprator"></span>
              {/*<div className="form-group">*/}
              {/*    <label>Start Time</label>*/}
              {/*    <input type="time" name={'start_time'} required={true}*/}
              {/*           onChange={(e)=>onInputChangeHandler(e,setForm)}*/}
              {/*           className="form-control" />*/}
              {/*</div>*/}
            </div>

            <div className="set-price-and-duration">
              <div className="form-group">
                <label>Quantity</label>
                <input type="number" name={'qty'} required={true}
                  value={form.qty} max={props?.item?.qty} min={1}
                  onChange={(e) => onInputChangeHandler(e, setForm)}
                  className="form-control" />
              </div>
            </div>

            <div className="button-group">
              <button
                type={"button"}
                onClick={() => props?.setOpen(false)}
                className="btn"
              >
                Cancel
              </button>
              <button type={"submit"} className="btn">
                {isloading ? (
                  <i className={"fa fa-spin fa-spinner"}></i>
                ) : (
                  "Post"
                )}
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
      {/*<Modal.Footer>*/}
      {/*    {*/}
      {/*        isloading ?*/}
      {/*            <Button className={"btn btn-success"} type={"button"}>*/}
      {/*                <i className={"fa fa-spinner fa-spin"}></i>*/}
      {/*            </Button>*/}
      {/*            :*/}
      {/*            <Button type={"button"} onClick={confirm} className={"btn btn-success"}>Yes</Button>*/}
      {/*    }*/}
      {/*    <Button className={"btn btn-success"} onClick={closeModal}>Cancel</Button>*/}
      {/*</Modal.Footer>*/}
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.Auth.token,
    characters: state.UserCharacter.characters,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    joinGuild: (data) => dispatch(joinGuildRequest(data)),
    addPlayer: (data) => dispatch(addPlayerRequest(data)),
    createAuctionItem: (data) => dispatch(createAuctionItemRequest(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAuction);
