import React, {useEffect} from 'react';
import {connect, useDispatch, useSelector} from "react-redux";
// import '../../assets/css/play.css';
import '../../assets/css/home.css';
import '../../assets/css/auction.css';
import Footer from "../../components/Footer";
import Characters from "../../components/Characters";
import WhatHappen from "../../components/WhatHappen";
import Intractive from "../../components/Intractive";
import Slider from "../../components/Slider";
import playGame from '../../assets/img/play-game.png';
import craftingText from '../../assets/img/crafting-txt.png';
import inventorySingle from '../../assets/img/crafting-txt.png';
import auctionText from '../../assets/img/auction-txt.png';
import {Link} from "react-router-dom";
import {getItemsRequest} from "../../redux/Craft/action";
import { getItemsRequest as getAuctionItemRequest, getItemsForAuctionListRequest } from "../../redux/Auction/action";
import {getItemsRequest as getShopItemRequest} from "../../redux/Shop/action";
import BurnArcade from "../../components/BurnArcade";


const Home = (props) => {

    const dispatch = useDispatch();
    const items = useSelector((state) => state?.Craft?.items);
    const shops = useSelector((state) => state?.Shop?.items);
    const auction = useSelector((state) => state?.Auction?.items);
    const token = useSelector((state )=> state?.Auth?.token);
    // console.log("auction",items);

    useEffect(() => {
        // console.log(props?.setting?.fishing_maintenance_mode);
        if (props.islogin) {
            dispatch(getItemsRequest());
            dispatch(getItemsForAuctionListRequest());
            dispatch(getAuctionItemRequest());
            dispatch(getShopItemRequest());
        }
        return () => {

        }
    }, [props.islogin]);

    return (
        <>
            <Characters/>

            {
                props.islogin && 
                <>

                    {props?.setting?.fishing_maintenance_mode == false &&
                        <section section className="play_game">
                            <div className="play-game-box">
                                <div className="img-box">
                                    <figure><img src={playGame} alt="" className="img-fluid" /></figure>
                                </div>
                                <div className="button-group">
                                    <a onClick={() => {
                                        window.location.href = 'https://phantom-fishing.playdeadlive.com/index.html?Token=' + token;
                                    }} className="play-btn">Play Now</a>
                                </div>
                            </div>
                        </section>
                    }

                    {
                        auction &&
                        <section className="auction_sec austion-oo">
                            <div className="container">
                                <div className="heading-wrapper text-center py-4">
                                    <figure><img src={auctionText} className="img-fluid"/></figure>
                                </div>

                                <ul className="auction-items">
                                    {
                                        auction?.data?.filter((val, key) => key < 9)?.map((val, key) => (
                                            <li key={key}>
                                                <Link to={`/auction/view/${val?.id}`}>
                                                    <div className="auction-box">
                                                        <div className="txt">
                                                            {/*<h4>{val?.shop?.title}</h4>*/}
                                                        </div>
                                                        <div className="img-box">
                                                            <figure><img src={val?.shop?.image_url} alt="123"
                                                                         className="img-fluid"/>
                                                            </figure>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </li>
                                        ))
                                    }
                                    {
                                        [...Array(9 - auction?.data?.filter((val, key) => key < 9).length)].map((val, key) => (
                                            <li key={key}>
                                                <div className="auction-box">
                                                    <div className="txt">
                                                    </div>
                                                    <div className="img-box">
                                                        <figure>
                                                            {/*<img src={val?.shop?.image_url} alt="123" className="img-fluid" />*/}
                                                        </figure>
                                                    </div>
                                                </div>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </section>
                    }

                    {/* <section className="pets_sec">
                        <div className="heading">
                            <figure><img src={craftingText} className="img-fluid" /></figure>
                        </div>
                        <div className="all-pets">
                            {
                                items?.filter((val,key)=> key < 5)?.map((val,key)=>(
                                    <div key={key} className="pet-box my-2">
                                        <div className="pet-img overflow-hidden">
                                            <figure><img src={val?.image_url} alt="" className="img-fluid" /></figure>
                                        </div>
                                        <div className="detail">
                                            <div className="name">
                                                <h4 className="pet-name">{val?.shop?.title}</h4>
                                                <h4 className="pet-price">{val?.shop?.amount}</h4>
                                            </div>
                                            <div className="button-group">
                                                <Link to={`craft-item/${val?.id}`} className="buy-now-btn">Buy now</Link>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </section> */}

                    <section className="store_sec">
                        <div className="heading">
                            <h3>Store</h3>
                        </div>
                        <div className="all-items">
                            {
                                shops?.data?.filter((val, key) => key < 10)?.map((val, key) => (
                                    <div key={key} className="item-box">
                                        <div className="item-img overflow-hidden">
                                            <figure><img src={val?.image_url} style={{objectFit: 'cover'}}
                                                         className="img-fluid"/></figure>
                                        </div>
                                        <div className="item-detail">
                                            <div className="name">
                                                <h4 className="item-name">{val?.title}</h4>
                                                <h4 className="item-price">{val?.amount}</h4>
                                            </div>
                                            <div className="button-group">
                                                <Link to={'/shop/' + val?.id} className="buy-btn">Buy now</Link>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </section>

                    <section className="archives_sec">
                        <div className="heading">
                            <h3>The Archives History Of Play Dead Live</h3>
                        </div>
                        <div className="history-scroll">
                            <div className="row align-items-center">
                                {
                                    props.archives.map((val, key) => (
                                        key % 2 === 0 ?
                                            <React.Fragment key={key}>
                                                <div className="col-md-6">
                                                    <div className="img-box">
                                                        <figure><img src={val?.image_url} alt="" className="img-fluid"/>
                                                        </figure>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="content">
                                                        <p>{val?.content}</p>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                            :
                                            <React.Fragment key={key}>
                                                <div className="col-md-6">
                                                    <div className="content right">
                                                        <p>{val?.content}</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="img-box right">
                                                        <figure><img src={val?.image_url} alt="" className="img-fluid"/>
                                                        </figure>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                    ))
                                }
                                {/* <div className="col-md-6">*/}
                                {/*    <div className="img-box">*/}
                                {/*        <figure><img src="img/history1.png" alt="" className="img-fluid" /></figure>*/}
                                {/*    </div> */}
                                {/*</div>*/}
                                {/*<div className="col-md-6">*/}
                                {/*    <div className="content">*/}
                                {/*        <p>*/}
                                {/*            Lorem ipsum dolor sit amet, consectetur adipiscing*/}
                                {/*            elit, sed do eiusmod tempor incididunt ut labore*/}
                                {/*            et dolore magna aliqua. Ut enim ad minim veniam,*/}
                                {/*            quis nostrud exercitation ullamco laboris nisi ut*/}
                                {/*            aliquip ex ea commodo consequat.*/}
                                {/*        </p>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </section>

                    <section className="para-sec d-none">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12">
                                    <p
                                        dangerouslySetInnerHTML={{__html: props.setting?.home_second_text}}
                                    />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="rudy-sec">
                        <div className="container">
                            <div className="row">
                                {
                                    props.boxList.map((val, key) => (
                                        <div className="col-lg-4">
                                            <div className="ruby-wrapper">
                                                <div className="ruby-content-wrapper">
                                                    <h4>{val?.title}</h4>
                                                    <p className="text-white">{val?.content}</p>
                                                    <a href="#" className="btn-theme2">Next</a>
                                                </div>
                                                <div className="ruby-img-wrapper">
                                                    <figure>
                                                        <img src={val?.image_url} className="img-fluid imgBoxStyling" alt="" />
                                                    </figure>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>

                    </section>
                </>
            }

            <section className="d-none character">
                <div className="container">

                    {/*<div className="row text-center pb-5">*/}
                    {/*    <div className="col-sm-12">*/}
                    {/*        <figure>*/}
                    {/*            <img src={Character}*/}
                    {/*                 className="animate__animated  animate__fadeInDown animate__delay-2s img-fluid"*/}
                    {/*                 alt="" />*/}
                    {/*        </figure>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*character heading row ends here*/}
                    {/*<div className="row">*/}
                    {/*    <div className="col-md-4 animate__animated  animate__fadeInLeft animate__delay-2s">*/}
                    {/*        <div className="character-list">*/}
                    {/*            /!*7 character start here*!/*/}
                    {/*            <div className="character-wrapper active-wr">*/}
                    {/*                <a href="warrior.php">*/}
                    {/*                    <figure>*/}
                    {/*                        <img src={Character7} className="char-img img-fluid" alt="" />*/}
                    {/*                            <img src={TheWarrior} className="char-name img-fluid" alt="" />*/}
                    {/*                    </figure>*/}
                    {/*                </a>*/}
                    {/*            </div>*/}
                    {/*        /!*    7 character ends here    *!/*/}
                    {/*        </div>*/}
                    {/*        <p className="char-des">Ferocious combatant that relies on strength and rage. Warriors are*/}
                    {/*            the strongest of the classes. Warriors can equip any type of weapon but feel most at*/}
                    {/*            home with melee weapons.</p>*/}
                    {/*        <a href="#" className="cta">See more</a>*/}
                    {/*    </div>*/}
                    {/*    <div className="col-md-4">*/}
                    {/*        <div className="character-list animate__animated  animate__fadeInDown animate__delay-2s ">*/}
                    {/*            /!* 2 character start here  *!/*/}
                    {/*            <div className="character-wrapper ">*/}
                    {/*                <a href="medic.php">*/}
                    {/*                    <figure>*/}
                    {/*                        <img src={Character2} className="char-img img-fluid" alt="" />*/}
                    {/*                            <img src={Themedic} className="char-name img-fluid" alt="" />*/}
                    {/*                    </figure>*/}
                    {/*                </a>*/}
                    {/*            </div>*/}
                    {/*            /!* 2 character ends here *!/*/}
                    {/*            /!* 3 character start here *!/*/}
                    {/*            <div className="character-wrapper">*/}
                    {/*                <a href="engineer.php">*/}
                    {/*                    <figure>*/}
                    {/*                        <img src={Character3} className="char-img img-fluid" alt="" />*/}
                    {/*                            <img src={TheEngineer} className="char-name img-fluid" alt="" />*/}
                    {/*                    </figure>*/}
                    {/*                </a>*/}
                    {/*            </div>*/}
                    {/*            /!* 3 character ends here *!/*/}
                    {/*            /!* 4 character start here *!/*/}
                    {/*            <div className="character-wrapper">*/}
                    {/*                <a href="priest.php">*/}
                    {/*                    <figure>*/}
                    {/*                        <img src={Character4} className="char-img img-fluid" alt="" />*/}
                    {/*                            <img src={ThePriest} className="char-name img-fluid" alt="" />*/}
                    {/*                    </figure>*/}
                    {/*                </a>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className="col-md-4">*/}
                    {/*        <div className="character-list animate__animated  animate__fadeInDown animate__delay-2s ">*/}
                    {/*            <div className="character-wrapper">*/}
                    {/*                <a href="ninja.php">*/}
                    {/*                    <figure>*/}
                    {/*                        <img src={Character5} className="char-img img-fluid" alt="" />*/}
                    {/*                            <img src={TheNinja} className="char-name img-fluid" alt="" />*/}
                    {/*                    </figure>*/}
                    {/*                </a>*/}
                    {/*            </div>*/}
                    {/*            /!* 5 character ends here *!/*/}
                    {/*            /!* 6 character start here *!/*/}
                    {/*            <div className="character-wrapper">*/}
                    {/*                <a href="tank.php">*/}
                    {/*                    <figure>*/}
                    {/*                        <img src={Character6} className="char-img img-fluid" alt="" />*/}
                    {/*                            <img src={Thetank} className="char-name img-fluid" alt="" />*/}
                    {/*                    </figure>*/}
                    {/*                </a>*/}
                    {/*            </div>*/}
                    {/*            /!* 6 character ends here *!/*/}
                    {/*            /!* 1 character start here *!/*/}
                    {/*            <div className="character-wrapper">*/}
                    {/*                <a href="sniper.php">*/}
                    {/*                    <figure>*/}
                    {/*                        <img src={Character1} className="char-img img-fluid" alt="" />*/}
                    {/*                            <img src={Sniper} className="char-name img-fluid" alt="" />*/}
                    {/*                    </figure>*/}
                    {/*                </a>*/}
                    {/*            </div>*/}
                    {/*            /!* 1 character ends here *!/*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="row cta-row">*/}
                    {/*    {*/}
                    {/*        props.islogin?*/}
                    {/*            <div className="col-sm-12">*/}
                    {/*                <div className="btn-wrapper text-center">*/}
                    {/*                    <Link to="/character-creation" className="btn-theme">Create Character</Link>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            :*/}
                    {/*            <div className="col-sm-12 animate__animated  animate__fadeInleft animate__delay-2s text-center">*/}
                    {/*                <a href="welcome.php" className="btn-theme">Read More News</a>*/}
                    {/*            </div>*/}
                    {/*    }*/}
                    {/*</div>*/}
                </div>
            </section>

            {
                props.slider?.length > 0 &&
                <Slider/>
            }

            {
                props.happenNows?.length > 0 &&
                <WhatHappen/>
            }
            {/* <Intractive /> */}
            {
                props.burnArcade?.length > 0 &&
                <BurnArcade/>
            }

            <Footer/>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        archives: state.Site.archives,
        islogin: state.Auth.islogin,
        setting: state.Site.setting,
        happenNows: state.Site.happenNows,
        boxList: state.Site.boxList,
        slider: state.Site.slider,
        burnArcade: state.Site.burnArcade,

    }
}

export default connect(mapStateToProps)(Home);