import React, {useEffect} from 'react';
import {connect} from "react-redux";
import PDLBorder from "../../components/PDLBorder";
import Logo from "../../components/Logo";
import '../../assets/css/profile.css';
import AuthHeader from "../../components/AuthHeader";
import Rulebook from '../../assets/img/Rulebook.png';
import {getCharactersRequest, getPagesRequest, setLoader} from "../../redux/Site/action";


const Maintenance = (props) => {


    return (
        <section className="fram-sec">
            <div className="frame-wrapper">

                {/* Border*/}
                <PDLBorder/>
                {/*Logo*/}
                <Logo/>

                {/*Menus*/}
                <div className="row">
                </div>
                {/*Menus*/}

                <section className="profile">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-2">

                            </div>
                            <div className="col-lg-8">
                                <div className="profile-creation text-center">
                                    <figure>
                                        <h3 className={"mb-5 pdlStyleHeading"}>Maintenance Mode</h3>
                                    </figure>
                                    <p className={"pdlStyleParagraph"}>
                                        website is an under development mode
                                    </p>
                                </div>

                            </div>
                            <div className="col-lg-2"></div>
                        </div>
                    </div>
                </section>
            </div>
        </section>
    );
}

const mapStateToProps = (state) => {
    return {

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Maintenance);