import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import PDLBorder from "../../../components/PDLBorder";
import Logo from "../../../components/Logo";
import '../../../assets/css/playerdetail.css';
import AuthHeader from "../../../components/AuthHeader";
import {
    equipRequest,
    getCharacterDetailRequest,
    getCharacterInventoryRequest, unequipRequest
} from "../../../redux/UserCharacter/action";
import Loader from "../../NotFound/Loader";
import {setLoader} from "../../../redux/Site/action";
import {useParams , useLocation} from "react-router-dom";
import Stats from "./Stats";
import playertop from "../../../assets/img/playertop.png";
import playerback1 from "../../../assets/img/playerback1.png";
import Legends from "./Legends";
import Weapons from "./Weapons";
import Misc from "./Misc";
import Left from "./Left";
import Right from "./Right";
import Inventory from "./Inventory";
import { showLoader } from "../../../redux/helpers";
import MiscNotes from "../../../components/Modals/MiscNotes";


const Details = (props) => {

    const [type , setType] = useState(1);
    const [open , setOpen] = useState(false);
    const Param = useParams();
    const get = async (id,type) => {
        props.setLoader(true);
        await props.getCharacterDetail(id,type);
        props.setLoader(false);
    }

    const filter = async (id,type) => {
        setType(type);
        showLoader('flex');
        await props.getCharacterDetail(id,type);
        await props.getCharacterInventory(type);
        showLoader('none');
    }

    const onUnEquipItem = async (id) => {
        if(!id) return false;
        showLoader('flex');
        props.unEquip({
            id : id,
            character_id : Param?.id,
            type : type
        })
            .then(async ()=>{
                await props.getCharacterDetail(Param?.id,type);
                await props.getCharacterInventory(type);
                showLoader('none');
            })
            .catch(()=>{
                showLoader('none');
            })
    }

    const findExpInPercentage = () => {
        let totalExpCurrentLevel = (props?.details?.character?.exps?.end || 0) - (props?.details?.character?.exps?.start || 0);
        let myExp = props?.details?.character?.exp;
        let myStartExp = (props?.details?.character?.level === 0) ? props?.details?.character?.exp : Math.abs(myExp - totalExpCurrentLevel) ;
        // console.log(myStartExp,totalExpCurrentLevel,( myStartExp / totalExpCurrentLevel ) * 100);
        return ((myStartExp / totalExpCurrentLevel)*100).toFixed(1);
    }

    useEffect(() => {
        get(Param?.id,type);
        return () => {

        }
    }, [Param?.id]);
    // console.log("type",type);

    if (props.loader) return <Loader/>

    return (
        <section className="frame-wrapper" style={{
            backgroundImage : `url('${playerback1}')`,
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat',
            padding: '3rem 2rem',
            margin: '4.5rem 2rem',
            position: 'relative',
        }}>
            <div className="">

                {/* Border*/}
                <PDLBorder/>
                {/*Logo*/}
                <Logo/>

                {/*Menus*/}
                <div className="row">
                    {/*<div className="col-md-7">*/}

                    {/*</div>*/}
                    <div className="col-md-12">
                        <div className="Menus text-right">
                            <AuthHeader/>
                        </div>
                    </div>
                </div>
                {/*Menus*/}


                <div className="main-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="DetailBox BoxOne">
                                            <div className="level">
                                                <div className="form-group">
                                                    <label htmlFor="forlevel">level</label>
                                                    <input type="text" defaultValue={props?.details?.character?.level} id="forlevel" readOnly={true} />
                                                </div>
                                            </div>

                                            <Left onUnEquipItem={onUnEquipItem} />

                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="characterDetail">
                                            <div className="button-group text-center">
                                                <button onClick={()=>filter(Param?.id,1) } className={`btn ${ type === 1 && 'active'}`}>pvp</button>
                                                <button onClick={()=>filter(Param?.id,0) } className={`btn ${ type === 0 && 'active'}`}>pve</button>
                                            </div>
                                            <div className="character-exp">
                                                <h4>character exp</h4>
                                                <div className="progress">
                                                    <label>{findExpInPercentage()}%</label>
                                                    <div className="progress-bar" role="progressbar" aria-valuenow={findExpInPercentage()}
                                                         aria-valuemin="0" aria-valuemax="100" style={{
                                                        width:`${findExpInPercentage()}%`,
                                                    }}>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="characterImg text-center">
                                                <figure><img width={'100%'} src={props.details?.character?.character?.avatar_image_url} alt="" /></figure>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="DetailBox BoxTwo">
                                            <div className="level for-hidden">
                                                <div className="form-group">
                                                    <label htmlFor="forlevel">level</label>
                                                    <input type="text" value="02" id="forlevel" readOnly={true} />
                                                </div>
                                            </div>
                                            <Right onUnEquipItem={onUnEquipItem} />
                                        </div>
                                    </div>
                                </div>
                                <Weapons onUnEquipItem={onUnEquipItem} />
                            </div>
                            <div className="col-lg-5">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="spacer"></div>

                                        <Stats
                                            id={Param?.id}
                                            type={type}
                                            userId={props.user_id}
                                            setMiscModal={setOpen}
                                            onUnEquipItem={onUnEquipItem} />
                                        <Legends onUnEquipItem={onUnEquipItem} />

                                    </div>

                                    <div className="col-lg-6">

                                        <div className="spacer"></div>
                                        <div className="forTop-border py-1">
                                            <figure><img className="w-100" src={playertop} alt="" /></figure>
                                        </div>

                                        <Inventory character_id={Param?.id} type={type} />

                                        <Misc onUnEquipItem={onUnEquipItem} />


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <MiscNotes id={Param?.id} open={open} setOpen={setOpen} />
        </section>
    );
}

const mapStateToProps = (state) => {
    return {
        islogin: state.Auth.islogin,
        user_id: state.Auth.user?.id,
        loader: state.Site.loader,
        details: state.UserCharacter.details,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setLoader: (bool) => dispatch(setLoader(bool)),
        unEquip : (data) => dispatch(unequipRequest(data)),
        getCharacterDetail: (id,item_for) => dispatch(getCharacterDetailRequest(id,item_for)),
        getCharacterInventory: (type,id = '') => dispatch(getCharacterInventoryRequest(type,id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Details);