import React from 'react';
import {connect} from "react-redux";
import Logo from '../assets/img/logo.png';
import {Link} from "react-router-dom";


const PDLBorder = () => {

    return (
        <div className="TopCenter">
            <div className="logoDv">
                <Link to={'/'}>
                    <figure><img src={Logo} className="animate__animated animate__pulse" alt=""/>
                    </figure>
                </Link>
            </div>
        </div>
);
}

const mapStateToProps = () => {
        return {

        }
    }

const mapDispatchToProps = () => {

    }

export default connect(mapStateToProps)(PDLBorder);