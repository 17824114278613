import {
    ACCEPT_GUILD_REQUEST, CANCEL_GUILD_REQUEST, FILTER_GUILD_ITEM_REQUEST, READ_GUILD_NOTIFICATION,
    SET_GUILD, SET_GUILD_ITEM_REQUEST, SET_GUILD_MEMBER, SET_GUILD_NOTIFICATION, SET_GUILD_SETTING,
    SET_LIST_GUILD
} from "../constants";
import {headers, headersWithToken} from "../headers";
import {
    acceptRequestGuildApi, addItemGuildApi,
    addPlayerGuildApi,
    cancelRequestGuildApi,
    createGuildApi,
    finishedApi,
    joinRequestGuildApi,
    leavePlayerGuildApi,
    listGuildApi,
    notificationGuildApi,
    playersGuildApi, readAllNotificationGuildApi, reverseItemGuildApi,
    sendGiftApi, sendItemGuildApi,
    showGuildApi,
    guildSettingApi,
} from "../apis";
import axios from "axios";
import {setAlert} from "../Alert/action";
import {responseError} from "../errorHandler";


export const setNotification = (data) => {
    return {
        type : SET_GUILD_NOTIFICATION,
        payload : data
    }
}

export const getNotificationGuildRequest = () => {
    return async (dispatch,getState) => {
        try{
            let token = getState().Auth.token;
            let response = await axios.get(notificationGuildApi,headersWithToken(token));
            dispatch(setNotification(response.data.response.data));
        }catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    };
}
export const readAllNotification = () => {
    return {
        type : READ_GUILD_NOTIFICATION,
        payload : null
    }
}
export const readAllNotificationGuildRequest = () => {
    return async (dispatch,getState) => {
        try{
            let token = getState().Auth.token;
            await axios.get(readAllNotificationGuildApi,headersWithToken(token));
            dispatch(readAllNotification());
        }catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    };
}

export const setGuilds = (data) => {
    return {
        type : SET_LIST_GUILD,
        payload : data
    }
}

export const getListGuildRequest = (url = null ,search = null) => {
    return async (dispatch,getState) => {
        try{
            let token = getState().Auth.token;
            let link = url ? url : (search? listGuildApi+'?search='+search : listGuildApi) ;
            let response = await axios.get(link,headersWithToken(token));
            dispatch(setGuilds(response.data.response.data));
        }catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    };
}

export const getPlayersRequest = () => {
    return async (dispatch,getState) => new Promise(async (resolve, reject)=>{
        try{
            let token = getState().Auth.token;
            let response = await axios.get(playersGuildApi,headersWithToken(token));
            return resolve(response.data.response.data);
        }catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
            return reject(false);
        }
    });
}

export const setGuild = (data) => {
    return {
        type : SET_GUILD,
        payload : data
    }
}

export const setGuildSetting = (data) => {
    return {
        type : SET_GUILD_SETTING,
        payload : data
    }
}

export const getGuildRequest = (id) => {
    return async (dispatch,getState) => {
        try{
            let token = getState().Auth.token;
            let response = await axios.get(showGuildApi(id),headersWithToken(token));
            dispatch(setGuild(response.data.response.data.guild));
            dispatch(setGuildSetting(response.data.response.data.setting));
        }catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    };
}
export const getGuildSettingRequest = () => {
    return async (dispatch, getState) => {
        try {
            let token = getState().Auth.token;
            let response = await axios.get(guildSettingApi, headersWithToken(token));
            dispatch(setGuildSetting(response.data.response.data));
        } catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    };
}

export const createGuildRequest = (data) => {
    return async (dispatch,getState) => new Promise(async (resolve, reject)=>{
        try{
            let token = getState().Auth.token;
            let response = await axios.post(createGuildApi,data,headersWithToken(token));
            // dispatch(setGuild(response.data.response.data?.guild));
            return resolve(true);
        }catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
            return reject(false);
        }
    });
}

export const addPlayerRequest = (data) => {
    return async (dispatch,getState) => new Promise(async (resolve, reject)=>{
        try{
            let token = getState().Auth.token;
            let response = await axios.post(addPlayerGuildApi,data,headersWithToken(token));
            dispatch(acceptGuildMember(response.data.response.data));
            resolve(true);
        }catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
            reject(false);
        }
    });
}

export const leavePlayerRequest = (data) => {
    return async (dispatch,getState) => {
        try{
            let token = getState().Auth.token;
            await axios.post(leavePlayerGuildApi,data,headersWithToken(token));
            await dispatch(getGuildRequest(data?.guild_id));
            // console.log(response.data.response.data);
        }catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    };
}

export const setNewJoinRequestMember = (data,id) => {
    return {
        type : SET_GUILD_MEMBER,
        payload : data,
        guild_id : id,
    }
}

export const joinGuildRequest = (data) => {
    return async (dispatch,getState) => new Promise(async (resolve, reject)=>{
        try{
            let token = getState().Auth.token;
            let response = await axios.post(joinRequestGuildApi,data,headersWithToken(token));
            console.log(response.data.response.data.member,data.guild_id);
            await dispatch(getPlayersRequest());
            dispatch(setAlert([{message : 'Join request sent successfully' , color : 'success'}]));
            return resolve(true);
        }catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
            return reject(false);
        }
    });
}

export const acceptGuildMember = (data) => {
    return {
        type : ACCEPT_GUILD_REQUEST,
        payload : data,
    }
}

export const acceptGuildRequest = (data) => {
    return async (dispatch,getState) => {
        try{
            let token = getState().Auth.token;
            let response = await axios.post(acceptRequestGuildApi,data,headersWithToken(token));
            console.log(response.data.response.data);
            dispatch(acceptGuildMember(response.data.response.data));
        }catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    };
}

export const cancelGuildMember = (data) => {
    return {
        type : CANCEL_GUILD_REQUEST,
        payload : data,
    }
}

export const cancelGuildRequest = (data) => {
    return async (dispatch,getState) => {
        try{
            let token = getState().Auth.token;
            let response = await axios.post(cancelRequestGuildApi,data,headersWithToken(token));
            console.log(response.data.response.data);
            dispatch(cancelGuildMember(response.data.response.data));
        }catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    };
}


export const setGuildMemberItem = (data) => {
    return {
        type : SET_GUILD_ITEM_REQUEST,
        payload : data,
    }
}

export const setItemGuildRequest = (data) => {
    return async (dispatch,getState) => {
        try{
            let token = getState().Auth.token;
            let response = await axios.post(addItemGuildApi,data,headersWithToken(token));
            console.log("response",response);
            // dispatch(setGuildMemberItem(response.data.response.data));
        }catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    };
}


export const filterGuildItem = (data) => {
    return {
        type : FILTER_GUILD_ITEM_REQUEST,
        payload : data,
    }
}

export const sendGuildItemToPlayer = (data) => {
    return async (dispatch,getState) => {
        try{
            let token = getState().Auth.token;
            let response = await axios.post(sendItemGuildApi,data,headersWithToken(token));
            // console.log("response",response);
            dispatch(filterGuildItem(data));
        }catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    };
}

export const reverseItemRequest = (data) => {
    return async (dispatch,getState) => {
        try{
            let token = getState().Auth.token;
            let response = await axios.post(reverseItemGuildApi,data,headersWithToken(token));
            // console.log("response",response);
            dispatch(filterGuildItem(data?.item_id));
        }catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    };
}


