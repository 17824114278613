import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import PDLBorder from "../../components/PDLBorder";
import Logo from "../../components/Logo";
import "../../assets/css/inventory.css";

import AuthHeader from "../../components/AuthHeader";
import Inventorytxt from "../../assets/img/inventorytxt.png";
import {
  filterItemsByCategoryRequest, getFishItemsRequest,
  getItemsRequest,
} from "../../redux/Inventory/action";
import PlayerTop from "../../assets/img/playertop.png";
import PlayerBottom from "../../assets/img/playerbottom.png";
import { setLoader } from "../../redux/Site/action";
import Loader from "../NotFound/Loader";
import { Link } from "react-router-dom";
import Loading from "../NotFound/Loader";
import inventorybg from "../../assets/img/gamegridbg.png";
import { showLoader } from "../../redux/helpers";
import { site_web_url } from "../../redux/apis";
import ItemWrapper from "../../components/ItemWrapper";
import AddBagModal from "../../components/Modals/AddBagModal";
import InventoryItemGuild from "../../components/Modals/InventoryItemGuild";

const Inventory = (props) => {
  const [loading, setLoading] = useState(false);
  const [guildModal, setGuildModal] = useState(false);
  const [addItemInfo, setAddItemInfo] = useState(null);
  const [search, setSearch] = useState(null);
  const [dropDown, setDropDown] = useState({
    id: 0,
    show: false,
  });
  const { selected_character } = useSelector((state) => state?.Auth);
  const { characters } = useSelector((state) => state?.UserCharacter);

  const [bagNo, setBagNo] = useState(0);
  const [item, setItem] = useState(null);
  const [addBag, setAddBag] = useState(false);

  const get = async () => {
    showLoader("flex");
    await props.getItems();
    showLoader("none");
  };

  const pagination = async (url) => {
    showLoader("flex");
    await props.getItems(url);
    showLoader("none");
  };

  const filterByCategory = async (category_id) => {
    // props.setLoader(true);
    showLoader("flex");
    await props.filterItemsByCategory(category_id);
    // props.setLoader(false);
    setSearch("");
    showLoader("none");
  };

  const onSearchHandler = async (event) => {
    event.preventDefault();
    showLoader("flex");
    console.log(search);
    await props.getItems(null, search);
    showLoader("none");
  };

  const handleContextMenu = (event, id) => {
    event.preventDefault();
    setDropDown({ id: id, show: true });
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropDown && !event.target.closest(".buttonDropDown")) {
        setDropDown({ id: 0, show: false });
      }
    }

    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, [dropDown]);

  useEffect(() => {
    get();
    return () => {};
  }, []);

  if (props.loader) return <Loader />;

  return (
    <section className="fram-sec inventroyPage">
      {loading && <Loading />}
      <div
        className="frame-wrapper inventoryBgImage"
        style={{
          backgroundImage: `url('${inventorybg}') !important`,
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
          padding: "3rem 2rem",
          margin: "4.5rem 2rem",
          position: "relative",
        }}
      >
        {/* Border*/}
        <PDLBorder />
        {/*Logo*/}
        <Logo />

        {/*Menus*/}
        <div className="row">
          {/*<div className="col-md-7">*/}

          {/*</div>*/}
          <div className="col-md-12">
            <div className="Menus text-right">
              <AuthHeader />
            </div>
          </div>
        </div>
        {/*Menus*/}

        <div className="main-content">
          <section className="invent-sec">
            <div className="container-fluid">
              <div className="inventroy-Box no-img">
                <div className="row justify-content-center">
                  {/*<div className="col-sm-4"></div>*/}
                  <div className="col-sm-4">
                    <div className="title text-center">
                      <h1 className={"pdlStyleHeading"}>Fish Inventory</h1>
                      {/*<figure>*/}
                      {/*  <img src={Inventorytxt} alt="" />*/}
                      {/*</figure>*/}
                    </div>
                    <div className="search-Box">
                      <form onSubmit={onSearchHandler}>
                        <div className="form-group d-flex justify-content-center align-content-center ">
                          <input
                            type="text"
                            required={true}
                            onChange={(e) => setSearch(e.target.value)}
                            className="form-control"
                            value={search}
                            placeholder="Search item here"
                          />
                          {search && (
                            <button
                              type={"button"}
                              onClick={() => {
                                setSearch("");
                                get();
                              }}
                              className="btn m-0 customButton"
                            >
                              <i className={"fa fa-times"}></i>
                            </button>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                  {/*<div className="col-sm-4"></div>*/}
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <div className="inventories">
                      <div className="forTop-border py-1">
                        <figure>
                          <img className="w-100" src={PlayerTop} alt="" />
                        </figure>
                      </div>
                      <div
                        className="nav flex-column nav-pills inventory-list"
                        id="v-pills-tab"
                        role="tablist"
                        aria-orientation="vertical"
                      >
                        <ul className="inventory-links">
                          <li>
                            <a className="nav-link" onClick={get}>
                              All
                            </a>
                          </li>
                          {props.category.map((val, key) => {
                            return (
                              <li key={key}>
                                <a
                                  className="nav-link"
                                  onClick={() => filterByCategory(val?.id)}
                                >
                                  {val?.name}
                                </a>
                              </li>
                            );
                          })}
                        </ul>

                      </div>
                      <div className="forBottom-border py-1">
                        <figure>
                          <img className="w-100" src={PlayerBottom} alt="" />
                        </figure>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-9">
                    <div className="inventory-selection">
                      <div className="row">
                        {props.items?.data.map((val, key) => {
                          return (
                            <ItemWrapper
                              key={key}
                              className="col-2"
                              item={{
                                title: val?.shop?.title,
                                health: val?.shop?.health,
                                base: val?.shop?.base,
                                damage: val?.shop?.damage,
                                armor_pen: val?.shop?.armor_pen,
                                armor_pen_resist: val?.shop?.armor_pen_resist,
                              }}
                            >
                              <Link to={"/fish-inventory/" + val?.id}>
                                <div
                                  className={`inventory-itemBox1`}
                                  style={{
                                    textAlign: "center",
                                    borderStyle: "solid",
                                    borderWidth: "2px",
                                    borderImageSource:
                                      val?.color && val?.color !== ""
                                        ? "none"
                                        : "linear-gradient(to right,#b88425,#c5973f,#d2a957,#e0bc6f,#edcf88,#efd28b,#f1d58f,#f3d892,#eacb80,#e1bf6f,#d9b25d,#d0a64c)",
                                    height: "135px",
                                    width: "80%",
                                    borderImageSlice: "1",
                                    marginBottom: "10px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    position: "relative",
                                    margin: "0px auto 10px",
                                    cursor: "pointer",
                                    ...(val?.color && val?.color !== ""
                                      ? { borderColor: `${val?.color}` }
                                      : {}),
                                  }}
                                  onContextMenu={(e) =>
                                    handleContextMenu(e, val?.id)
                                  }
                                >
                                  <figure className={"mb-0"}>
                                    <img
                                      width={"100%"}
                                      className={"mt-0"}
                                      src={val?.shop?.image_url}
                                      alt=""
                                    />
                                  </figure>
                                  <div className="count">
                                    <span className="large">{val?.qty}</span>
                                  </div>
                                </div>
                              </Link>
                              {dropDown?.show && dropDown?.id === val?.id && (
                                <div
                                  className={"Menus " + dropDown?.id}
                                  style={{ position: "absolute" }}
                                >
                                  <div
                                    style={{
                                      display: "block",
                                      width: "114px",
                                      background: "#000",
                                    }}
                                  >
                                    {[...Array(5)].map((number, key) => (
                                      <a
                                        data-rr-ui-dropdown-item=""
                                        className="dropdown-item"
                                        onClick={() => {
                                          setBagNo(key + 1);
                                          setItem(val);
                                          setAddBag(true);
                                        }}
                                        role="button"
                                        tabIndex="0"
                                      >
                                        Add to bag {key + 1}
                                      </a>
                                    ))}
                                    {characters?.find(
                                      (val) => val?.id === selected_character
                                    ) &&
                                      characters?.find(
                                        (val) => val?.id === selected_character
                                      )?.guild_members &&
                                      (characters?.find(
                                        (val) => val?.id === selected_character
                                      )?.guild_members?.type === 0 ||
                                        characters?.find(
                                          (val) =>
                                            val?.id === selected_character
                                        )?.guild_members?.type === 1) && (
                                        <a
                                          data-rr-ui-dropdown-item=""
                                          className="dropdown-item"
                                          onClick={() => {
                                            setAddItemInfo({
                                              character_id: selected_character,
                                              guild_id: characters?.find(
                                                (val) =>
                                                  val?.id === selected_character
                                              )?.guild_members?.guild_id,
                                              item_id: val?.id,
                                              max_qty: val?.qty,
                                            });
                                            setGuildModal(true);
                                          }}
                                          role="button"
                                          tabIndex="0"
                                        >
                                          Add to guild
                                        </a>
                                      )}
                                  </div>
                                </div>
                              )}
                            </ItemWrapper>
                          );
                        })}
                      </div>

                      <div className="row mt-5 justify-content-center mr-5">
                        {props.items?.data.length > 0 &&
                          props.items?.links?.map((val, key) => {
                            return (
                              <button
                                key={key}
                                type={"button"}
                                disabled={val?.url == null && true}
                                onClick={() => val?.url && pagination(val?.url)}
                                className="btn customButton mx-2"
                                dangerouslySetInnerHTML={{ __html: val?.label }}
                              />
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      <AddBagModal
        open={addBag}
        setOpen={setAddBag}
        bagNo={bagNo}
        character_id={props?.character_id || 0}
        item={item}
        request={() => props.getItems()}
      />
      {guildModal && (
        <InventoryItemGuild
          open={guildModal}
          setOpen={setGuildModal}
          addItemInfo={addItemInfo}
        />
      )}
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    items: state.Inventory.items,
    loader: state.Site.loader,
    category: state.Site.category,
    user_id: state.Auth.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoader: (bool) => dispatch(setLoader(bool)),
    getItems: (url = null, search = null) =>
      dispatch(getFishItemsRequest(url, search)),
    filterItemsByCategory: (id) => dispatch(filterItemsByCategoryRequest(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Inventory);
