import React, {useEffect, useState} from 'react';
import {Route, Routes, useNavigate} from "react-router-dom";
import {connect, useDispatch, useSelector} from "react-redux";
import {Button, Modal} from "react-bootstrap";
import Profile from '../../assets/img/profile.jpg';
import {acceptRequest, rejectRequest, resetTime, resetTrade, setNewTradeRequest} from "../../redux/TradeRequest/action";
import {showLoader, startTimer} from "../../redux/helpers";
import {pusher} from "../../redux/pusherConfig";
import {addPlayerRequest, joinGuildRequest} from "../../redux/Guild/action";
import {toast} from "react-toastify";
import axios from "axios";
import {headersWithToken} from "../../redux/headers";
import {getGuildPlayerApi} from "../../redux/apis";
import {
    applyGemGlyphsRequest,
    getGemGlyphsRequest,
    getItemRequest,
    removeGemGlyphsItemRequest
} from "../../redux/Inventory/action";

function PowerConfirm(props) {

    const navigator = useNavigate();
    const gemsGlyphs = useSelector((state => state.Inventory.gemsGlyphs.find(val => val?.id === props.item_id)));
    const [isloading , setIsloading] = useState(false);
    console.log("gemsGlyphs",props.item_id,gemsGlyphs);

    const confirm = () => {
        if(props.item_id !== 0){
            setIsloading(true);
            props.applyGemGlyphs({inventory_id : props.inventoryItemId , item_id : props.item_id})
                .then(async ()=>{
                    await props.getItem(props.id);
                    setIsloading(false);
                    props.setOpen(false);
                })
                .catch(()=>{
                    setIsloading(false);
                });
        }else{
            toast.warning("Please select any item.");
        }
    }


    const closeModal = () => {
        props.setOpen(false);
    }
    console.log("item",props?.item);

    return (
        <Modal
            show={props.open}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title className={"mt-4"} id="contained-modal-title-vcenter">
                    <h2>
                        Confirmation
                    </h2>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={"row mx-3"}>
                    <div className="col-5 Player-statusDv">
                        <div className="head">
                            <h2 className="title">
                                Item Stats
                            </h2>
                        </div>
                        <ul className={"list-group rounded"} style={{
                            borderBottom: '1px solid #ddd',
                        }}>
                            <li style={{
                                borderBottom : '1px solid #ddd'
                            }} className={"d-flex align-items-center px-2 py-4 flex-row justify-content-between"}>
                                <h6 className={"m-0 title"}>Health</h6>
                                <h6 className={"m-0 text-white"}>
                                    {props.item?.health}
                                    {" + "} <span className={"text-success"}>{gemsGlyphs?.health}</span>
                                </h6>
                            </li>
                            <li style={{
                                borderBottom : '1px solid #ddd'
                            }} className={"d-flex align-items-center px-2 py-4 flex-row justify-content-between"}>
                                <h6 className={"m-0 title"}>Base</h6>
                                <h6 className={"m-0 text-white"}>{props.item?.base}
                                    {" + "} <span className={"text-success"}>{gemsGlyphs?.base}</span>
                                </h6>
                            </li>
                            <li style={{
                                borderBottom : '1px solid #ddd'
                            }} className={"d-flex align-items-center px-2 py-4 flex-row justify-content-between"}>
                                <h6 className={"m-0 title"}>Damage</h6>
                                <h6 className={"m-0 text-white"}>{props.item?.damage}
                                    {" + "} <span className={"text-success"}>{gemsGlyphs?.damage}</span>
                                </h6>
                            </li>
                            <li style={{
                                borderBottom : '1px solid #ddd'
                            }} className={"d-flex align-items-center px-2 py-4 flex-row justify-content-between"}>
                                <h6 className={"m-0 title"}>Armor Pen</h6>
                                <h6 className={"m-0 text-white"}>{props.item?.armor_pen}
                                    {" + "} <span className={"text-success"}>{gemsGlyphs?.armor_pen}</span>
                                </h6>
                            </li>
                            <li style={{
                                borderBottom : '1px solid #ddd'
                            }} className={"d-flex align-items-center px-2 py-4 flex-row justify-content-between"}>
                                <h6 className={"m-0 title"}>Armor Pen Resist</h6>
                                <h6 className={"m-0 text-white"}>{props.item?.armor_pen_resist}
                                    {" + "} <span className={"text-success"}>{gemsGlyphs?.armor_pen_resist}</span>
                                </h6>
                            </li>
                        </ul>
                    </div>
                    <div className="col-7">
                        <figure><img width={'100%'} src={gemsGlyphs?.item?.image_url} alt=""/></figure>
                    </div>
                </div>
                <div className={"mt-5 d-flex flex-row justify-content-end align-items-center mb-5"}>
                    <Button className={"btn btn-success"} onClick={closeModal}>Cancel</Button>
                    {
                        isloading ?
                            <Button className={"btn btn-success"} type={"button"}>
                                <i className={"fa fa-spinner fa-spin"}></i>
                            </Button>
                            :
                            <Button type={"button"} onClick={confirm} className={"btn btn-success"}>Yes</Button>
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>
    );
}

const mapStateToProps = (state) => {
    return {
        gemsGlyphs: state.Inventory.gemsGlyphs,
        item: state.Inventory.item,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getItem: (id) => dispatch(getItemRequest(id)),
        getGemGlyph : () => dispatch(getGemGlyphsRequest()),
        applyGemGlyphs : (data) => dispatch(applyGemGlyphsRequest(data)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(PowerConfirm);
