import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import LegendTop from '../../../assets/img/legendtop.png';
import LegendBottom from '../../../assets/img/legendbottom.png';
import {createMyCharacterRequest, getCharacterDetailRequest, setMyCharacter} from "../../../redux/UserCharacter/action";
import {setLoader} from "../../../redux/Site/action";
import Slider from 'react-slick';
import BoxImage from "../../../components/BoxImage";


const Legends = (props) => {

    const settings = {
        dots: false ,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <>
            <div className="legendDv-withBorder">
                <div className="forTop-border">
                    <figure><img src={LegendTop} alt=""/></figure>
                </div>
                <div className="LegendDv">
                    <div className="title">
                        <h4>Activated Legends</h4>
                    </div>
                    <Slider {...settings} className="Legend-Slider text-white">
                        {
                            props?.legends.map((val,key)=>{
                                return <div onClick={()=>props.onUnEquipItem(val?.id)}
                                            key={key}
                                            className={"legends-items"}>
                                    <div className="imgbox">
                                        <div className="imgbox">
                                            <BoxImage image={val?.shop?.image_url} />
                                        </div>
                                    </div>
                                    <div className="contentDv">
                                        <ul className="lists">
                                            <li>H : {val?.health}</li>
                                            <li>D : {val?.damage}</li>
                                            <li>B : {val?.base}</li>
                                            <li>AP : {val?.armor_pen}</li>
                                            <li>APR : {val?.armor_pen_resist}</li>
                                        </ul>
                                    </div>
                                </div>
                            })
                        }
                        {
                            props.noOfLegends.map((val,key)=>{
                                return <div key={key} className="legends-items">
                                    <div className="imgbox">
                                        <figure><img src="" className="animate__animated animate__bounceIn animate__delay-1s"
                                                     alt="" /></figure>
                                    </div>
                                    <div className="contentDv">
                                        <ul className="lists">
                                            <li>H : 0 </li>
                                            <li>D : 0 </li>
                                            <li>B : 0 </li>
                                            <li>AP : 0 </li>
                                            <li>APR : 0 </li>
                                        </ul>
                                    </div>
                                </div>
                            })
                        }
                    </Slider>
                </div>
            </div>
            <div className="forBottom-border">
                <figure><img src={LegendBottom} alt=""/></figure>
            </div>
        </>
    )
        ;
}

const filterLegends = (weapons) => {
    let noOfWeapons =  weapons.filter((val)=> val?.placement === "bottom" && (val?.slug === "activated_legends"));
    let noOfLegends = [];
    noOfWeapons.map((val)=>{
        noOfLegends = [...noOfLegends,...val?.items];
    });
    let arr = [];
    if(5 - parseInt(noOfLegends.length) <= 5){
        for (let i = 1 ; i<= (5 - parseInt(noOfLegends.length)) ; i++ ){
            arr.push(i);
        }
    }
    console.log("Legendds",noOfLegends,parseInt(noOfLegends.length));
    return arr;
}

const legend = (weapons) => {
    let filter =  weapons.filter((val)=> val?.placement === "bottom" && (val?.slug === "activated_legends"));
    return (filter.length > 0)? filter[0]?.items : [];
}

const mapStateToProps = (state) => {
    return {
        islogin: state.Auth.islogin,
        details: state.UserCharacter.details,
        legends : legend(state.UserCharacter.details?.characteristics || []),
        noOfLegends : filterLegends(state.UserCharacter.details?.characteristics || []),
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setLoader: (bool) => dispatch(setLoader(bool)),
        getCharacterDetail: (id) => dispatch(getCharacterDetailRequest(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Legends);


/*

 */