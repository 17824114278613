import React, {useEffect, useState, useCallback, useMemo} from 'react';
import {forHumans, getDayHourMinSec, getSecondsFromStartEnd} from "../../redux/helpers";
import {useCountdown} from "../../hooks/useCountdown";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import {setViewItemStatus} from "../../redux/Auction/action";
import {CountDownTimer} from "./ItemDetails";

const AuctionItem = ({val}) => {

    const time = getSecondsFromStartEnd(
        `${val?.start_date} ${val?.start_time}`,
        (val?.status === 1)?`${val?.end_date} ${val?.end_time}` : `${val?.start_date} ${val?.start_time}`
    );

    const currency = useSelector((state )=> state?.Site?.currency);
    // const [days, hours, minutes, seconds] = useCountdown(time);
    // console.log("AuctionItem",moment.duration( time).humanize(true));
    // console.log("AuctionItem",time,days, hours, minutes, seconds);
    const navigate = useNavigate();

    return (
        <li style={{cursor:'pointer'}} className={"my-2"} onClick={()=>navigate(`/auction/view/${val?.id}`)}>
            <div className="aucton-box">
                <div className="topbar">
                    {
                        (val?.status === 0 || val?.status === 1) &&
                        <>
                            <h5>Auction {val?.status === 0 ? 'Start' : 'Ends'} In</h5>
                            <h4>
                                <CountDownTimer
                                    item={val}
                                    time={getSecondsFromStartEnd(
                                        `${val?.start_date} ${val?.start_time}`,
                                        (val?.status === 1)?`${val?.end_date} ${val?.end_time}` : `${val?.start_date} ${val?.start_time}`
                                    )}
                                />
                            </h4>
                            {/*<h5>*/}
                            {/*    {*/}
                            {/*        moment(val?.start_date).format("DD MMM YYYY")*/}
                            {/*        +" | "+*/}
                            {/*        val?.start_time*/}
                            {/*    }*/}
                            {/*</h5>*/}
                            {/*<h4>{forHumans(time)}</h4>*/}
                            {/*<h4>{ (days > 0 ? `D : ${days}` : '') +' '+ (hours > 0 ? `H : ${hours}` : '') +' '+ (minutes > 0 ? `M : ${minutes}` : '') +' '+ (seconds > 0 ? `S : ${seconds}` : '')}</h4>*/}
                        </>
                    }
                </div>
                <div className="img-box">
                    <figure>
                        <img src={val?.shop?.image_url} alt=""
                             width={'50%'} style={{objectFit: 'cover'}}
                             className="img-fluid"/>
                    </figure>
                </div>
                <div className="bottombar">
                    {/*<h5>Auction Ends In</h5>*/}
                    <h4 className={"d-flex justify-content-center "}> Bid Starting From
                        {
                            currency?.filter((value)=>value?.slug === val?.currency_slug)?.map((value,key)=>(
                                <figure className="" key={key}>
                                    <img src={value?.icon_url} width={'30px'} height={'30px'} className={"img-fluid"} />
                                </figure>
                            ))
                        }
                        {/* {parseInt(val?.min_amount).toLocaleString()} */}
                        {val?.min_amount}
                    </h4>
                </div>
            </div>
        </li>
    );

}

export default AuctionItem;