import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

import Logo from "../../components/Logo";
import PDLBorder from "../../components/PDLBorder";
import '../../assets/css/login.css';
import loginbg from "../../assets/img/loginbg.png";
import {Link, useNavigate} from "react-router-dom";
import {loginRequest, signUpRequest} from "../../redux/Auth/action";
import {pusher} from "../../redux/pusherConfig";

const Login = (props) => {

    const [user , setUser] = useState({
        email : '',
        password : '',
        remember_me : false
    });
    const [loading , setLoading ] = useState(false);
    const navigate = useNavigate();

    const onChangeHandler = (event) => {
        let {name  , value} = event.target;

        if(name === "remember_me"){
            value = event.target.checked;
        }

        setUser((preVal)=>{
            return {
                ...preVal,
                [name] : value,
            }
        });
    }

    const onSubmitHandler = async (event) => {
        event.preventDefault();
        setLoading(true);
        props.login(user)
            .then(() => {
                // console.log("Welcome",props.welcome,(props.welcome == 'true')? "/" : "/welcome");
                // console.log(props);

                navigate((props.welcome == true)? "/" : "/welcome" , { replace: true });
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    useEffect(()=>{

        return () => {

        }
    },[])

    return (
        <>
            <section className="fram-sec">
                <div className="frame-wrapper" style={{
                    backgroundImage : `url('${loginbg}')`,
                    backgroundSize: '100% 100%',
                    backgroundRepeat: 'no-repeat',
                    padding: '3rem 2rem',
                    margin: '4.5rem 2rem',
                    position: 'relative',
                }}>
                    <PDLBorder />

                    <Logo />

                    <div className="row">
                        <div className="col-md-7">

                        </div>
                        <div className="col-md-5">

                        </div>
                    </div>

                    <div className="login">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-4">
                                </div>
                                <div className="col-lg-4">
                                    <div className="login-wrapper">
                                        <form autoComplete={"new-password"} onSubmit={onSubmitHandler}>
                                            <h4 className="text-center">Log In</h4>
                                            <div className="form-group">
                                                <input type="email" className="login-field form-control" onChange={onChangeHandler}
                                                       placeholder="Email" value={user.email} name={'email'} required={true} autoComplete={"off"} />
                                            </div>
                                            <div className="form-group">
                                                <input type="password" className="login-field form-control" onChange={onChangeHandler}
                                                       placeholder="Password" value={user.password} name={'password'} required={true} autoComplete={"off"} />
                                            </div>
                                            <div className="form-group row marginOneRem">
                                                <div className="col-md-6 form-check">
                                                    <input type="checkbox" className="form-check-input"
                                                           checked={user.remember_me} onChange={onChangeHandler}
                                                           id="exampleCheck1" name={'remember_me'}/>
                                                    <label className="form-check-label" htmlFor="exampleCheck1">
                                                        Remember me
                                                    </label>
                                                </div>
                                                <div className="col-md-6 text-right">
                                                    <Link to={"/forgot"} className="forget-password">Forgot Password?</Link>
                                                </div>
                                            </div>
                                            <div className="form-group marginOneRem">
                                                {
                                                    loading?
                                                        <button type="button" className="form-submit">
                                                            <i className={"fa fa-spinner fa-spin"}></i>
                                                        </button>
                                                        :
                                                        <button type="submit" className="form-submit">
                                                            Login
                                                        </button>
                                                }
                                            </div>
                                            <div className="form-group text-center marginOneRem">
                                                <span className="create-account">Don't have account sign-up &nbsp;
                                                    <Link to={'/signup'}>Sign up</Link>
                                                </span>
                                            </div>
                                            <div className="form-group text-center marginOneRem">
                                                <span className="create-account">Need assistance??  &nbsp;
                                                    <Link to={'/contact-us'}>Contact Us</Link>
                                                </span>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                                <div className="col-lg-4">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        welcome : state.Auth,
        token: state.Auth.token,
        user: state.Auth.user,

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        login : (data) => dispatch(loginRequest(data)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Login);