import {
    DELETE_ALL_NOTIFICATIONS,
    DELETE_NOTIFICATIONS,
    SET_ALL_READ_NOTIFICATIONS,
    SET_CATEGORY,
    SET_CHARACTERS, SET_CURRENCY, SET_GALLERY_SETTING,
    SET_LOADER, SET_NOTIFICATIONS,
    SET_PAGES, SET_SESSION, SET_SITE_ARCHIVE,
    SET_SITE_BOX_LIST, SET_SITE_BURN_ARCADE, SET_SITE_FAMES, SET_SITE_FAMES_CATEGORY, SET_SITE_HAPPEN, SET_SITE_HEROS,
    SET_SITE_SETTING,
    SET_SITE_SLIDER, SET_USER_CHARACTER
} from "../constants";
import {burnArcadeAPi} from "../apis";

const initState = {
    loader : false,
    notifications : [],
    pages : [],
    characters : [],
    category : [],
    setting : null,
    userCharacter : null,
    slider : [],
    burnArcade : [],
    boxList : [],
    happenNows : [],
    heros : [],
    gallery : [],
    archives : [],
    fames : [],
    famesCategory : [],
    sessions : [],
    currency : [],
}


const Site = (state = initState , action) => {
    switch (action.type) {

        case SET_PAGES :
            return {
                ...state,
                pages : action.payload,
            }
        case SET_NOTIFICATIONS :
            return {
                ...state,
                notifications : action.payload,
            }
        case DELETE_NOTIFICATIONS:
            return {
                ...state,
                notifications : state.notifications?.filter((val)=>val?.id !== action.payload),
            }
        case DELETE_ALL_NOTIFICATIONS :
            return {
                ...state,
                notifications : [],
            }
        case SET_ALL_READ_NOTIFICATIONS :
            return {
                ...state,
                notifications : [
                    ...state.notifications.map((val,key)=>{
                        return {...val,is_read : 1}
                    })
                ],
            }
        case SET_CHARACTERS:
            return {
                ...state,
                characters : action.payload,
            }
        case SET_CATEGORY :
            return {
                ...state,
                category : action.payload,
            }
        case SET_LOADER :
            return {
                ...state,
                loader : action.payload,
            }
        case SET_SITE_SETTING :
            return {
                ...state,
                setting : action.payload,
            }
        case SET_GALLERY_SETTING :
            return {
                ...state,
                gallery : action.payload,
            }
        case SET_SITE_SLIDER :
            return {
                ...state,
                slider : action.payload,
            }
        case SET_SITE_BURN_ARCADE :
            return {
                ...state,
                burnArcade : action.payload,
            }
        case SET_SITE_BOX_LIST :
            return {
                ...state,
                boxList : action.payload,
            }
        case SET_SITE_HAPPEN :
            return {
                ...state,
                happenNows : action.payload,
            }
        case SET_SITE_ARCHIVE :
            return {
                ...state,
                archives : action.payload,
            }
        case SET_SITE_HEROS :
            return {
                ...state,
                heros : action.payload,
            }

        case SET_SITE_FAMES :
            return {
                ...state,
                fames : action.payload,
            }
        case SET_SITE_FAMES_CATEGORY :
            return {
                ...state,
                famesCategory : action.payload,
            }
        case SET_SESSION :
            return {
                ...state,
                sessions : action.payload,
            }
        case SET_CURRENCY :
            return {
                ...state,
                currency : action.payload,
            }
        case SET_USER_CHARACTER :
            return {
                ...state,
                userCharacter : action.payload,
            }

        default: return state;
    }
}

export default Site;