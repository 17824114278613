import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import '../../assets/css/signup.css';
import PDLBorder from "../../components/PDLBorder";
import Logo from "../../components/Logo";
import {Link, useNavigate} from "react-router-dom";
import {signUpRequest} from "../../redux/Auth/action";
import loginbg from "../../assets/img/loginbg.png";


const Signup = (props) => {

    const [user , setUser] = useState({
        name : '',
        email : '',
        password : '',
    });
    const [loading , setLoading ] = useState(false);
    const navigate = useNavigate();

    const onChangeHandler = (event) => {
        let {name  , value} = event.target;

        setUser((preVal)=>{
            return {
                ...preVal,
                [name] : value,
            }
        });
    }

    const onSubmitHandler = async (event) => {
        event.preventDefault();
        setLoading(true);
        props.signUp(user)
            .then(() => {
                navigate("/login", { replace: true });
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    useEffect(()=>{
        return () => {
        }
    },[])

    return (
        <>
            <section className="fram-sec">
                <div className="frame-wrapper" style={{
                    backgroundImage : `url('${loginbg}')`,
                    backgroundSize: '100% 100%',
                    backgroundRepeat: 'no-repeat',
                    padding: '3rem 2rem',
                    margin: '4.5rem 2rem',
                    position: 'relative',
                }}>
                    <PDLBorder />

                    <Logo />

                    <div className="row">
                        <div className="col-md-7">

                        </div>
                        <div className="col-md-5">

                        </div>
                    </div>

                    <div className="signup">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-4">

                                </div>
                                <div className="col-lg-4">
                                    <div className="signup-wrapper">
                                        <form onSubmit={onSubmitHandler} autoComplete={"new-password"}>
                                            <h4 className="text-center">Sign Up</h4>
                                            <div className="form-group">
                                                <input type="text" className="login-field form-control" onChange={onChangeHandler}
                                                placeholder="Name" value={user.name} name={'name'} required={true} autoComplete={"off"} />
                                            </div>
                                            <div className="form-group">
                                                <input type="email" className="login-field form-control" onChange={onChangeHandler}
                                                placeholder="Email" value={user.email} name={'email'} required={true} autoComplete={"off"} />
                                            </div>
                                            <div className="form-group">
                                                <input type="password" className="login-field form-control" onChange={onChangeHandler}
                                                placeholder="Password" value={user.password} name={'password'} required={true} autoComplete={"off"} />
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-md-6">
                                                    <Link  className="form-submit" to={'/login'}>Login</Link>
                                                    {/*<a type="submit" href="login.php" className="form-submit">Log in</a>*/}

                                                </div>
                                                <div className="col-md-6">
                                                    {
                                                        loading?
                                                            <button type="button" className="form-submit">
                                                                <i className={"fas fa-spinner fa-spin"}></i>
                                                            </button>
                                                            :
                                                            <button type="submit" className="form-submit">
                                                                Sign Up
                                                            </button>
                                                    }
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="col-lg-4">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

const mapStateToProps = () => {
    return {

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signUp : (data) => dispatch(signUpRequest(data)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Signup);