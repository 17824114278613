import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import PDLBorder from "../../components/PDLBorder";
import Logo from "../../components/Logo";
import AuthHeader from "../../components/AuthHeader";
import PlayerBottom from '../../assets/img/playerbottom.png';
import PlayerTop from '../../assets/img/playertop.png';
import {
    filterItemsByCategoryRequest,
    getItemRequest,
    removeGemGlyphsItemRequest,
    updateItemRequest
} from "../../redux/Inventory/action";
import {setLoader} from "../../redux/Site/action";
import Loader from "../NotFound/Loader";
import {Link, useNavigate, useParams} from "react-router-dom";
import NotFound from "../NotFound/NotFound";
import '../../assets/css/bounty.css';
import {showLoader} from "../../redux/helpers";
import bountyBg from "../../assets/img/bounty-bg.png";
import Confirm from "../../components/Modals/Confirm";
import PowerConfirm from "../../components/Modals/PowerConfirm";
import {getCharacterDetailRequest} from "../../redux/UserCharacter/action";
import CharacterEnhance from "./CharacterEnhance";
import MiscNotes from "../../components/Modals/MiscNotes";
import {site_web_url} from "../../redux/apis";
import domtoimage from 'dom-to-image';
const View = (props) => {

    const params = useParams();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [miscModal, setMiscModal] = useState(false);
    const [type , setType] = useState(1);

    const get = async () => {
        props.setLoader(true);
        await props.getCharacterDetail(params?.id,type);
        await props.filterItemsByCategory(27);
        props.setLoader(false);
    }

    const convertToJpg = () => {
        const boxImage = document.getElementById('printNode');
        domtoimage.toJpeg(boxImage, { quality: 0.95 })
            .then(function (dataUrl) {
                console.log(dataUrl);
                let link = document.createElement('a');
                link.download = 'download.jpeg';
                link.href = dataUrl;
                link.click();
                // $('#saveasImage').html(`Save as JPG`);
            });
    }

    useEffect(() => {
        get();
        return () => {
        }
    }, []);

    useEffect(() => {
        get();
        console.log("Details",props.details);
    }, [params?.id]);

    if (props.loader) return <Loader/>

    if (!props.details) return <NotFound/>

    return (
        <section className="fram-sec" id={"printNode"}>
            <div className="frame-wrapper">

                {/* Border*/}
                <PDLBorder/>
                {/*Logo*/}
                <Logo/>

                {/*Menus*/}
                <div className="row">
                    {/*<div className="col-md-7">*/}

                    {/*</div>*/}
                    <div className="col-md-12">
                        <div className="Menus text-right">
                            <AuthHeader/>
                        </div>
                    </div>
                </div>
                {/*Menus*/}

                <section className="mycharacter-sec">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="bounty-img-wrapper" style={{
                                    backgroundImage : `url(${bountyBg})`,
                                }}>
                                    {/* <div className="bount-heading-wrapper">
                                        <h5>{props?.details?.character?.character?.title}</h5>
                                    </div> */}
                                    <div className="bount-img">
                                        <figure>
                                            <img src={props?.details?.character?.logo_url} className="img-fluid" alt="" />
                                        </figure>
                                    </div>
                                </div>
                                <div className="bount-heading-wrapper">
                                        <h5>{props?.details?.character?.name}</h5>
                                    </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="bounty-hunter-wrapper" style={{
                                    backgroundImage : `url(${bountyBg})`,
                                }}>
                                    <div className="bounty-heading-wrapper">
                                        <h5>{props?.details?.character?.character?.title}</h5>
                                    </div>
                                    <div className='hunter-listing'>
                                    <div className="hunter-list-wrappper">
                                        <ul>
                                            <li>
                                                <span className="property">Class</span>
                                                <span className="value">{props?.details?.character?.character?.title}</span>
                                            </li>
                                            <li>
                                                <span className="property">Level</span>
                                                <span className="value">{props?.details?.character?.level}</span>
                                            </li>
                                            <li>
                                                <span className="property">Att level</span>
                                                <span className="value">{props?.details?.character?.character_base_values?.att?.toLocaleString()}</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="hunter-stats-wrapper">
                                        <h5>unequipped stats</h5>
                                    </div>
                                    <div className="hunter-list-wrappper">
                                        <ul>
                                            <li>
                                                <span className="property">Health</span>
                                                <span className="value">{props?.details?.character?.character_base_values?.health?.toLocaleString()}</span>
                                            </li>
                                            <li>
                                                <span className="property">Damage</span>
                                                <span className="value">{props?.details?.character?.character_base_values?.damage?.toLocaleString()}</span>
                                            </li>
                                            <li>
                                                <span className="property">Base</span>
                                                <span className="value">{props?.details?.character?.character_base_values?.base?.toLocaleString()}</span>
                                            </li>
                                            <li>
                                                <span className="property">Armor pen</span>
                                                <span className="value">{props?.details?.character?.character_base_values?.armor_pen?.toLocaleString()}</span>
                                            </li>
                                            <li>
                                                <span className="property">Armor pen resist</span>
                                                <span className="value">{props?.details?.character?.character_base_values?.armor_pen_resist?.toLocaleString()}</span>
                                            </li>

                                        </ul>
                                    </div>
                                    <div className="hunter-stats-wrapper">
                                        <h5>total ar stats</h5>
                                    </div>
                                    <div className="hunter-list-wrappper">
                                        <ul>
                                            <li>
                                                <span className="property">Health</span>
                                                <span className="value">{props?.details?.character?.health?.toLocaleString()}</span>
                                            </li>
                                            <li>
                                                <span className="property">Damage</span>
                                                <span className="value">{props?.details?.character?.damage?.toLocaleString()}</span>
                                            </li>
                                            <li>
                                                <span className="property">Base</span>
                                                <span className="value">{props?.details?.character?.base?.toLocaleString()}</span>
                                            </li>
                                            <li>
                                                <span className="property">Armor pen</span>
                                                <span className="value">{props?.details?.character?.armor_pen?.toLocaleString()}</span>
                                            </li>
                                            <li>
                                                <span className="property">Armor pen resist</span>
                                                <span className="value">{props?.details?.character?.armor_pen_resist?.toLocaleString()}</span>
                                            </li>

                                        </ul>
                                    </div>
                                    </div>
                                    <div className="hunter-btn-wrapper">
                                        <button type="button" className="btn mx-0" onClick={()=>setMiscModal(true)}>
                                            Misc Stats note
                                        </button>
                                        {/*<Link to={''} className="btn mx-1">Stats</Link>*/}
                                        {/*<button type={'button'} onClick={convertToJpg}>convertToJpg</button>*/}
                                        <a target={"_blank"}
                                           href={`${site_web_url}pdf/${params.id}/1/${props?.details?.character?.user_id}`}
                                           className="btn mx-0">
                                            Export
                                        </a>
                                        <Link to={`/character/edit/${params.id}`} className="btn m-1">Edit</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="weapons-wrapper" style={{
                                    backgroundImage : `url(${bountyBg})`,
                                }}>
                                    <ul>
                                        <li>
                                            <div className="single-weapon-wrapper">
                                                <div className="weapon-heading-wrapper">
                                                    <h6>Health</h6>
                                                </div>
                                                {
                                                    props?.details?.character?.bionics
                                                        ?.filter((val)=>val?.bionic_type === "health").length > 0?
                                                    <>
                                                        {
                                                            props?.details?.character?.bionics
                                                                ?.filter((val)=>val?.bionic_type === "health")
                                                                ?.map((val,key)=>(
                                                                    <div className="weapon-img-wrapper" key={key} style={{
                                                                    }}>
                                                                        <figure>
                                                                            <img src={val?.shop?.image_url} style={{
                                                                                width : '100px',
                                                                                height : '80px',
                                                                            }}
                                                                                 className="img-fluid" alt=""
                                                                            />
                                                                        </figure>
                                                                    </div>
                                                                ))
                                                        }
                                                    </>
                                                    :
                                                    <div style={{
                                                    width : '100px',
                                                    height : '80px',
                                                }}>
                                                    </div>
                                                }


                                            </div>
                                        </li>
                                        <li>
                                            <div className="single-weapon-wrapper">
                                                <div className="weapon-heading-wrapper">
                                                    <h6>Damage</h6>
                                                </div>
                                                {
                                                    props?.details?.character?.bionics
                                                        ?.filter((val)=>val?.bionic_type === "damage").length > 0?
                                                        <>
                                                            {
                                                                props?.details?.character?.bionics
                                                                    ?.filter((val)=>val?.bionic_type === "damage")
                                                                    ?.map((val,key)=>(
                                                                        <div className="weapon-img-wrapper" key={key} style={{
                                                                        }}>
                                                                            <figure>
                                                                                <img src={val?.shop?.image_url} style={{
                                                                                    width : '100px',
                                                                                    height : '80px',
                                                                                }}
                                                                                     className="img-fluid" alt=""
                                                                                />
                                                                            </figure>
                                                                        </div>
                                                                    ))
                                                            }
                                                        </>
                                                        :
                                                        <div style={{
                                                            width : '100px',
                                                            height : '80px',
                                                        }}>
                                                        </div>
                                                }

                                            </div>
                                        </li>
                                        <li>
                                            <div className="single-weapon-wrapper">
                                                <div className="weapon-heading-wrapper">
                                                    <h6>Base</h6>
                                                </div>
                                                {
                                                    props?.details?.character?.bionics
                                                        ?.filter((val)=>val?.bionic_type === "base").length > 0?
                                                    <>
                                                        {
                                                            props?.details?.character?.bionics
                                                                ?.filter((val)=>val?.bionic_type === "base")
                                                                ?.map((val,key)=>(
                                                                    <div className="weapon-img-wrapper" key={key} style={{
                                                                    }}>
                                                                        <figure>
                                                                            <img src={val?.shop?.image_url} style={{
                                                                                width : '100px',
                                                                                height : '80px',
                                                                            }}
                                                                                 className="img-fluid" alt=""
                                                                            />
                                                                        </figure>
                                                                    </div>
                                                                ))
                                                        }
                                                    </>
                                                    :
                                                    <div style={{
                                                        width : '100px',
                                                        height : '80px',
                                                    }}>
                                                    </div>
                                                }
                                            </div>
                                        </li>
                                        <li>
                                            <div className="single-weapon-wrapper">
                                                <div className="weapon-heading-wrapper">
                                                    <h6>Armor pen</h6>
                                                </div>
                                                {
                                                    props?.details?.character?.bionics
                                                        ?.filter((val)=>val?.bionic_type === "armor_pen").length > 0 ?
                                                    <>
                                                        {
                                                            props?.details?.character?.bionics
                                                                ?.filter((val)=>val?.bionic_type === "armor_pen")
                                                                ?.map((val,key)=>(
                                                                    <div className="weapon-img-wrapper" key={key} style={{
                                                                    }}>
                                                                        <figure>
                                                                            <img src={val?.shop?.image_url} style={{
                                                                                width : '100px',
                                                                                height : '80px',
                                                                            }}
                                                                                 className="img-fluid" alt=""
                                                                            />
                                                                        </figure>
                                                                    </div>
                                                                ))
                                                        }
                                                    </>
                                                    :
                                                    <div style={{
                                                        width : '100px',
                                                        height : '80px',
                                                    }}>
                                                    </div>
                                                }
                                            </div>
                                        </li>
                                        <li>
                                            <div className="single-weapon-wrapper">
                                                <div className="weapon-heading-wrapper">
                                                    <h6>Apr</h6>
                                                </div>
                                                {
                                                    props?.details?.character?.bionics
                                                        ?.filter((val)=>val?.bionic_type === "armor_pen_resist").length > 0 ?
                                                        <>
                                                            {
                                                                props?.details?.character?.bionics
                                                                    ?.filter((val)=>val?.bionic_type === "armor_pen_resist")
                                                                    ?.map((val,key)=>(
                                                                        <div className="weapon-img-wrapper" key={key} style={{
                                                                        }}>
                                                                            <figure>
                                                                                <img src={val?.shop?.image_url} style={{
                                                                                    width : '100px',
                                                                                    height : '80px',
                                                                                }}
                                                                                     className="img-fluid" alt=""
                                                                                />
                                                                            </figure>
                                                                        </div>
                                                                    ))
                                                            }
                                                        </>
                                                        :
                                                        <div style={{
                                                                width : '100px',
                                                                height : '80px',
                                                            }}>
                                                        </div>
                                                }
                                            </div>
                                        </li>
                                        <li>
                                            <div className="single-weapon-wrapper">
                                                <div className="weapon-heading-wrapper">
                                                    <h6>ATT</h6>
                                                </div>
                                                {
                                                    props?.details?.character?.bionics
                                                        ?.filter((val)=>val?.bionic_type === "att").length > 0?
                                                        <>
                                                            {
                                                                props?.details?.character?.bionics
                                                                    ?.filter((val)=>val?.bionic_type === "att")
                                                                    ?.map((val,key)=>(
                                                                        <div className="weapon-img-wrapper" key={key} style={{
                                                                        }}>
                                                                            <figure>
                                                                                <img src={val?.shop?.image_url} style={{
                                                                                    width : '100px',
                                                                                    height : '80px',
                                                                                }}
                                                                                     className="img-fluid" alt=""
                                                                                />
                                                                            </figure>
                                                                        </div>
                                                                    ))
                                                            }
                                                        </>
                                                        :
                                                        <div style={{
                                                                width : '100px',
                                                                height : '80px',
                                                            }}>
                                                        </div>
                                                }
                                            </div>
                                        </li>
                                    </ul>
                                    <div className="hunter-btn-wrapper">
                                        <button typeof="button" onClick={()=>setOpen(true)} className="btn">Enhance</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
            <CharacterEnhance open={open} type={type} setOpen={setOpen} />
            <MiscNotes id={params?.id} open={miscModal} setOpen={setMiscModal} />

        </section>
    );
}

const mapStateToProps = (state) => {
    return {
        details: state.UserCharacter.details,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setLoader: (bool) => dispatch(setLoader(bool)),
        filterItemsByCategory : (id) => dispatch(filterItemsByCategoryRequest(id)),
        getCharacterDetail: (id,item_for) => dispatch(getCharacterDetailRequest(id,item_for)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(View);