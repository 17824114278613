import React from 'react';
import {connect} from "react-redux";
import Border from '../assets/img/footer.png';
import {Link} from "react-router-dom";
import Site from "../redux/Site/reducer";


const Footer = (props) => {
    return (
        <footer>
            <div className="footer-bg" style={{
                backgroundImage: `url('${Border}')`,
            }}>
                <div className="container">
                    <div className="row py-4 align-items-center">
                        <div className="col-lg-12">
                            <div className="footer-menu text-center">
                                <ul id="footer-menu1">
                                    <li className="menu-item">
                                        <Link className="menu-links" to={'/privacy-policy'}>Privacy Policy </Link>
                                    </li>
                                    <li className="menu-item">
                                        <Link className="menu-links" to={'/terms-of-condition'}> Terms of Service</Link>
                                    </li>
                                    {/* <li className="menu-item">
                                        <Link className="menu-links" to={'/support'}> Support</Link>
                                    </li> */}
                                    <li className="menu-item">
                                        {/* <a className="menu-links" href={'/contact-us'}>Contact Us </a> */}
                                        <Link className="menu-links" to={'/contact-us'}> Contact Us</Link>
                                    </li>
                                    <li className="menu-item">
                                        <Link className="menu-links" to={"/rulebook"}>
                                            Rule Book
                                        </Link>
                                    </li>
                                </ul>
                                <p className="footer-des"
                                   dangerouslySetInnerHTML={{ __html: props.setting?.footer_text}}
                                />
                                <div className="spacer">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

const mapStateToProps = (state) => {
    return {
        setting: state.Site.setting,
    }
}


export default connect(mapStateToProps)(Footer);