import { createStore, applyMiddleware , compose } from 'redux';
import {composeWithDevTools} from "redux-devtools-extension";
import rootReducer from "./rootReducer";
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['Auth','TradeRequest']
}

const middleware = [thunk];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const composeEnhancers = composeWithDevTools({

// });

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = createStore(
    persistedReducer,
    composeWithDevTools(
        applyMiddleware(...middleware),
    )
);

export const persistor = persistStore(store)

