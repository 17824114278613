import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import PDLBorder from "../../components/PDLBorder";
import Logo from "../../components/Logo";
import '../../assets/css/auction-detail.css';
import AuthHeader from "../../components/AuthHeader";
import Loader from "../NotFound/Loader";
import {useNavigate, useParams} from "react-router-dom";
import {getSecondsFromStartEnd, showLoader} from "../../redux/helpers";
import { viewAuctionRequest} from "../../redux/Auction/action";
import {useCountdown} from "../../hooks/useCountdown";
import NotFound from "../NotFound/NotFound";
import moment from "moment";

export const CountDownTimer = ({time}) => {

    const [days, hours, minutes, seconds] = useCountdown(time);

    return (
        <span className="time">{
            (days > 0 ? `D : ${days}` : '')
            +' '+
            (hours > 0 ? `${hours.toString().padStart(2,'0')}` : '')
            +' : '+
            (minutes > 0 ? `${minutes.toString().padStart(2,'0')}` : '')
            +' : '+
            (seconds > 0 ? `${seconds.toString().padStart(2,'0')}` : '')
        }</span>
    );
}


const MyAuctionView = (props) => {

    const {id} = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loader = useSelector((state) => state.Site.loader);

    const item = useSelector((state )=> state?.Auction?.item);

    const get = async () => {
        showLoader('flex');
        await dispatch(viewAuctionRequest(id));
        showLoader('none');
    }

    useEffect(() => {
        get();
        return () => {
        }
    }, []);


    if (loader) return <Loader/>

    if(!item) return <NotFound />

    return (
        <>
            <div className="frame-wrapper">

                {/* Border*/}
                <PDLBorder/>
                {/*Logo*/}
                <Logo/>

                {/*Menus*/}
                <div className="row">
                    {/*<div className="col-md-7">*/}

                    {/*</div>*/}
                    <div className="col-md-12">
                        <div className="Menus text-right">
                            <AuthHeader/>
                        </div>
                    </div>
                </div>
                {/*Menus*/}

                <section className="auction_detail">
                    <div className="container">
                        <div className="about-auction">
                            <div className="img-box">
                                <figure>
                                    <img src={item?.shop?.image_url} alt="" className="img-fluid" />
                                </figure>
                            </div>
                            <div className="detail">
                                {
                                    item?.status === 2?
                                        <>
                                            <h4 className={"end-date"}>
                                                Auction Ended
                                            </h4>
                                        </>
                                        :
                                        <>
                                            {
                                                (item?.status === 0 || item?.status === 1) &&
                                                <h4 className={"end-date d-flex"}>
                                                    Auction {item?.status === 0 ? 'Start' : 'Ends'} In
                                                    {
                                                        item?.status === 0?
                                                            <CountDownTimer time={getSecondsFromStartEnd(`${item?.end_date} ${item?.end_time}`, `${item?.start_date} ${item?.start_time}`)} />
                                                            :
                                                            <CountDownTimer time={getSecondsFromStartEnd(`${moment().format('DD.MM.YYYY HH:mm:ss')}`, `${item?.end_date} ${item?.end_time}`)} />
                                                    }
                                                </h4>
                                            }
                                        </>
                                }

                                <h3>{item?.shop?.title}</h3>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table">
                                <tbody>
                                {
                                    item?.bids?.map((val,key)=>(
                                        <tr key={key}>
                                            <td className="name">{val?.user?.name}</td>
                                            <td className="offer">Offer {val?.amount}</td>
                                            <td className="buttons">
                                                <div className="button-group">
                                                    {/*<button>Accept offer</button>*/}
                                                    {/*<button>Ignore</button>*/}
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>

            </div>
        </>
    );
}

export default MyAuctionView;