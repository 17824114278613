import React, {useEffect, useState} from 'react';
import {Link, Route, Routes, useNavigate} from "react-router-dom";
import {connect, useDispatch, useSelector} from "react-redux";
import {Button, Modal} from "react-bootstrap";
import {toast} from "react-toastify";
import '../../assets/css/characterEnhance.css';
import {applyBionicRequest, getCharacterDetailRequest} from "../../redux/UserCharacter/action";

function CharacterEnhance(props) {

    const navigator = useNavigate();
    const [type , setType ] = useState('health');
    const [itemId , setItemId] = useState(0);
    const [itemData , setItemData] = useState(null);
    const [isloading , setIsloading] = useState(false);

    const confirm = () => {
        if(itemId !== 0){
            setIsloading(true);
            props.applyBionic({
                inventory_id : itemId,
                character_id : props?.details?.character?.id
            })
                .then(async ()=>{
                    await props.getCharacterDetail(props?.details?.character?.id,props?.type);
                    // await props.getItem(props?.details?.character?.id);
                    setIsloading(false);
                    props.setOpen(false);
                })
                .catch(()=>{
                    setIsloading(false);
                });
        }else{
            toast.warning("Please select any bionic item.");
        }
    }

    const onSelectBionicItem = (id) => {
        setItemId(id);
        setItemData(props?.bionics?.data?.find(val=>val?.id == id));
        // console.log(props?.bionics?.data?.find(val=>val?.id == id));
    }


    const closeModal = () => {
        props.setOpen(false);
    }

    return (
        <Modal
            show={props.open}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="characterEnhance-modal"
        >
            <Modal.Header>
                <Modal.Title className={"mt-4"} id="contained-modal-title-vcenter">
                    <h2>
                        Enhance Character
                    </h2>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={"row mx-3"}>
                    <div className="col-5 Player-statusDv">
                        <div className="head">
                            <h2 className="title">
                                Character Stats
                            </h2>
                        </div>
                        <ul className={"list-group rounded"} style={{
                            borderBottom: '1px solid #ddd',
                        }}>
                            <li style={{
                                borderBottom : '1px solid #ddd'
                            }} className={"d-flex align-items-center px-2 py-4 flex-row justify-content-between"}>
                                <h6 className={"m-0 title"}>Health</h6>
                                <h6 className={"m-0 text-white"}>
                                    {props?.details?.character?.health}
                                    {" + "} <span className={"text-success"}>
                                    {type === "health" ? itemData?.shop?.bionic_value : 0}
                                    </span>
                                </h6>
                            </li>
                            <li style={{
                                borderBottom : '1px solid #ddd'
                            }} className={"d-flex align-items-center px-2 py-4 flex-row justify-content-between"}>
                                <h6 className={"m-0 title"}>Base</h6>
                                <h6 className={"m-0 text-white"}>{props?.details?.character?.base}
                                    {" + "} <span className={"text-success"}>
                                       {type === "base" ? itemData?.shop?.bionic_value : 0}
                                    </span>
                                </h6>
                            </li>
                            <li style={{
                                borderBottom : '1px solid #ddd'
                            }} className={"d-flex align-items-center px-2 py-4 flex-row justify-content-between"}>
                                <h6 className={"m-0 title"}>Damage</h6>
                                <h6 className={"m-0 text-white"}>{props?.details?.character?.damage}
                                    {" + "} <span className={"text-success"}>
                                       {type === "damage" ? itemData?.shop?.bionic_value : 0}
                                    </span>
                                </h6>
                            </li>
                            <li style={{
                                borderBottom : '1px solid #ddd'
                            }} className={"d-flex align-items-center px-2 py-4 flex-row justify-content-between"}>
                                <h6 className={"m-0 title"}>Armor Pen</h6>
                                <h6 className={"m-0 text-white"}>{props?.details?.character?.armor_pen}
                                    {" + "} <span className={"text-success"}>
                                       {type === "armor_pen" ? itemData?.shop?.bionic_value : 0}
                                    </span>
                                </h6>
                            </li>
                            <li style={{
                                borderBottom : '1px solid #ddd'
                            }} className={"d-flex align-items-center px-2 py-4 flex-row justify-content-between"}>
                                <h6 className={"m-0 title"}>Armor Pen Resist</h6>
                                <h6 className={"m-0 text-white"}>{props?.details?.character?.armor_pen_resist}
                                    {" + "} <span className={"text-success"}>
                                       {type === "armor_pen_resist" ? itemData?.shop?.bionic_value : 0}
                                    </span>
                                </h6>
                            </li>
                            <li style={{
                                borderBottom : '1px solid #ddd'
                            }} className={"d-flex align-items-center px-2 py-4 flex-row justify-content-between"}>
                                <h6 className={"m-0 title"}>ATT</h6>
                                <h6 className={"m-0 text-white"}>{props?.details?.character?.att}
                                    {" + "} <span className={"text-success"}>
                                       {type === "att" ? itemData?.shop?.bionic_value : 0}
                                    </span>
                                </h6>
                            </li>
                        </ul>
                    </div>
                    <div className="col-7">
                        <div>
                            <select name=""
                                    onChange={(e)=>setType(e.target.value)}
                                    id="" className="form-control">
                                <option value="health">Health</option>
                                <option value="base">Base</option>
                                <option value="damage">Damage</option>
                                <option value="armor_pen">Armor Pen</option>
                                <option value="armor_pen_resist">Armor Pen Resist</option>
                                <option value="att">Att</option>
                            </select>
                        </div>
                        <div>
                            {
                                props?.bionics?.data?.filter((val,key)=>{
                                    return val?.shop?.bionic_type == type
                                })?.map((val,key)=>(
                                    <div key={key} className="col-1">
                                        <>
                                            <div className={`inventory-itemBox1 box`}
                                                 onClick={()=>onSelectBionicItem(val?.id)}
                                                 style={{
                                                    textAlign: 'center',
                                                    borderStyle: 'solid',
                                                    borderWidth: '2px',
                                                    borderImageSource: (val?.id != itemId) ?'none' : "var(--ItemBorder)",
                                                    height: '70px',
                                                    width: '100%',
                                                    borderImageSlice: '1',
                                                    marginBottom: '10px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    position: 'relative',
                                                    cursor: 'pointer',
                                                }}>
                                                <figure><img width={'100%'}  src={val?.item?.image_url} alt="" /></figure>
                                                <div className="count">
                                                    <span className="large">{val?.qty}</span>
                                                </div>
                                            </div>
                                        </>
                                    </div>
                                ))
                            }
                        </div>

                    </div>
                </div>
                <div className={"mt-5 d-flex flex-row justify-content-end align-items-center mb-5"}>
                    <Button className={"btn btn-success"} onClick={closeModal}>Cancel</Button>
                    {
                        isloading ?
                            <Button className={"btn btn-success"} type={"button"}>
                                <i className={"fa fa-spinner fa-spin"}></i> Applying
                            </Button>
                            :
                            <Button type={"button"} onClick={confirm} className={"btn btn-success"}>Apply</Button>
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>
    );
}

const mapStateToProps = (state) => {
    return {
        bionics : state.Inventory?.items,
        details: state.UserCharacter.details,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        applyBionic : (data) => dispatch(applyBionicRequest(data)),
        getCharacterDetail: (id,item_for) => dispatch(getCharacterDetailRequest(id,item_for)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(CharacterEnhance);
