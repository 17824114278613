import React, {useEffect, useState} from 'react';
import {Route, Routes, useNavigate} from "react-router-dom";
import {connect} from "react-redux";
import {Button, Modal} from "react-bootstrap";
import Profile from '../../assets/img/profile.jpg';
import {acceptRequest, rejectRequest, resetTime, resetTrade, setNewTradeRequest} from "../../redux/TradeRequest/action";
import {startTimer} from "../../redux/helpers";
import {pusher} from "../../redux/pusherConfig";

function TradeRequest(props) {

    const [open , setOpen] = useState(false);
    const [rejectLoading , setRejectLoading] = useState(false);
    const [acceptLoading , setAcceptLoading] = useState(false);
    const [timeInterval , setTimeInterval] = useState(null);
    const [timeShow , setTimeShow] = useState('03:00');
    const navigator = useNavigate();

    const closeModal = () => {
        props.reject(props.newTrade?.trade?.id);
        clearInterval(timeInterval);
        setOpen(false);
    }
    const accept = () => {
        props.accept(props.newTrade?.trade?.id)
            .then(() => {
                clearInterval(timeInterval);
                navigator('/trade/' + props.newTrade?.trade?.id);
                setOpen(false);
            })
            .catch((e) => {
                console.log(e);
            })
    }

    const startTimer = (duration, display) => {
        let timer = duration, minutes, seconds;
        timer = props.time;
        let x = setInterval(function () {
            minutes = parseInt(timer / 60, 10);
            seconds = parseInt(timer % 60, 10);

            minutes = minutes < 10 ? "0" + minutes : minutes;
            seconds = seconds < 10 ? "0" + seconds : seconds;
            setTimeShow(minutes + ":" + seconds);

            --timer;
            props.resetTime(timer);

            if (timer < 0) {
                closeModal();
                clearInterval(x);
            }
        }, 1000);
        setTimeInterval(x);
    }


    useEffect(()=>{

        return () => {

        }
    },[]);

    useEffect(()=>{
        if(props.newTrade){
            setOpen(true);
            let display = document.querySelector('#time');
            // startTimer(props.time, display);
        }else{
            setOpen(false);
        }
    },[props.newTrade]);

    return (
        <Modal
            show={open}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    New Trading Request
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row justify-content-center">
                    <div className="col-3">
                        <div className={"d-flex flex-column justify-content-center"}>
                            <figure>
                                <img src={props.newTrade?.trade?.sender?.profile_url || Profile} width={'100%'} alt=""/>
                            </figure>
                            <h3>{props.newTrade?.trade?.sender?.name}</h3>
                        </div>
                    </div>
                    <div className="col-5">
                        <div>

                        </div>
                    </div>
                    <div className="col-3">
                        <div className={"d-flex flex-column justify-content-center"}>
                            <figure>
                                <img src={props.newTrade?.trade?.receiver?.profile_url || Profile} width={'100%'} alt=""/>
                            </figure>
                            <h3>{props.newTrade?.trade?.receiver?.name}</h3>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div>Trade close in <span id="time">{timeShow}</span> minutes!</div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                {
                    acceptLoading ?
                        <Button className={"btn btn-success"}>
                            <i className={"fa fa-spinner fa-spin"}></i>
                        </Button>
                        :
                        <Button className={"btn btn-success"} onClick={accept}>Accept</Button>
                }
                {
                    rejectLoading ?
                        <Button className={"btn btn-success"}>
                            <i className={"fa fa-spinner fa-spin"}></i>
                        </Button>
                        :
                        <Button className={"btn btn-success"} onClick={closeModal}>Reject</Button>
                }
            </Modal.Footer>
        </Modal>
    );
}

const mapStateToProps = (state) => {
    return {
        newTrade : state.TradeRequest.newTrade,
        time : state.TradeRequest.time,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setNewTrade : (data) => dispatch(setNewTradeRequest(data)),
        accept : (id) => dispatch(acceptRequest(id)),
        reject : (id) => dispatch(rejectRequest(id)),
        resetTime : (time) => dispatch(resetTime(time)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(TradeRequest);
