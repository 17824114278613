import { combineReducers } from 'redux';
import Auth from "./Auth/reducer";
import Alert from "./Alert/reducer";
import Site from "./Site/reducer";
import UserCharacter from "./UserCharacter/reducer";
import Shop from "./Shop/reducer";
import Inventory from "./Inventory/reducer";
import Trade from "./Trade/reducer";
import TradeRequest from "./TradeRequest/reducer";
import Gift from "./Gift/reducer";
import Guild from "./Guild/reducer";
import Craft from "./Craft/reducer";
import Auction from "./Auction/reducer";

const rootReducer = combineReducers({
    Auth : Auth,
    Alert : Alert,
    Site : Site,
    UserCharacter : UserCharacter,
    Shop : Shop,
    Inventory : Inventory,
    Trade : Trade,
    TradeRequest : TradeRequest,
    Gift : Gift,
    Guild : Guild,
    Craft : Craft,
    Auction : Auction,
});

export default rootReducer;