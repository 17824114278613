import React, {useEffect, useState} from 'react';
import {Link, Route, Routes, useNavigate} from "react-router-dom";
import {connect} from "react-redux";
import {Button, Modal} from "react-bootstrap";
import Profile from '../../assets/img/profile.jpg';
import {acceptRequest, rejectRequest, resetTime, resetTrade, setNewTradeRequest} from "../../redux/TradeRequest/action";
import {onInputChangeHandler, showLoader, startTimer} from "../../redux/helpers";
import {pusher} from "../../redux/pusherConfig";
import {addPlayerRequest, getGuildRequest, joinGuildRequest, setItemGuildRequest} from "../../redux/Guild/action";
import {toast} from "react-toastify";
import axios from "axios";
import {headersWithToken} from "../../redux/headers";
import {getGuildPlayerApi} from "../../redux/apis";
import { addBagsRequest, getItemsRequest } from "../../redux/Inventory/action";
import { setAlert } from "../../redux/Alert/action";

function AddBagModal(props) {

    const [isloading, setIsloading] = useState(false);
    const [qty,setQty] = useState(props?.item?.qty || 0);
    const navigator = useNavigate();


    const closeModal = () => {
        props.setOpen(false);
    }

    const submitHandler = async () => {
        setIsloading(true);
        if (qty > props.item.qty) {
            toast.error(`Quantity must not be greater than ${props?.item?.qty}`)
            setIsloading(false);
            return false;
        }
        props.addBags({
            bag_no : props?.bagNo,
            character_id : props?.character_id,
            inventory_id: props?.item?.id,
            shop_id: props?.item?.item_id,
            qty : qty,
        })
            .then(async () => {
                setIsloading(false);
                closeModal();
                await props?.request();
            })
            .catch(() => {
                setIsloading(false);
            });
    }

    return (
        <Modal
            show={props.open}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={closeModal}
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Add to bag {props?.bagNo}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div className={"row d-flex justify-content-center"} >
                    <div className="auction-post-box row justify-content-center">
                        <div className="auction-item col-10">
                            {/*<div className="img-box">*/}
                                <figure className={"d-flex justify-content-center"}>
                                    <img
                                        src={props?.item?.shop?.image_url}
                                        width={'25%'} alt=""
                                        className="img-fluid" />
                                </figure>
                            {/*</div>*/}
                            {/*<div className="title">*/}
                                <h3 className={"text-center"}>{props?.item?.shop?.title}</h3>
                            <div className={"d-flex flex-row justify-content-center"}>
                                <label className="text-center">Qty : </label>
                                <input type="number" value={qty} placeholder={"quantity"}
                                       className={"inputCustomHeight form-control"}
                                       min={0} name={"qty"}
                                       onChange={(e)=>setQty(parseInt(e.target.value)) }
                                       max={props?.item?.qty}/>
                            </div>
                            {/*</div>*/}
                        </div>
                    </div>
                </div>

            </Modal.Body>
            <Modal.Footer className={"m-5 d-flex justify-content-center"}>
                <Button className={"btn btn-success"} onClick={closeModal}>Cancel</Button>
                {
                    isloading ?
                        <Button className={"btn btn-success"} type={"button"}>
                            <i className={"fas fa-spinner fa-spin"}></i>
                        </Button>
                        :
                        <Button type={"button"} onClick={submitHandler} className={"btn btn-success"}>Add</Button>
                }
            </Modal.Footer>
        </Modal>
    );
}

const mapStateToProps = (state) => {
    return {
        bags: state.Inventory.bags,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addBags : (data) => dispatch(addBagsRequest(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddBagModal);
