export const headers = () => {
    return {
        headers : {
            'Accept' : 'application/json',
            'Content-Type' : 'application/json',
            // 'timezone' : Intl.DateTimeFormat().resolvedOptions().timeZone,
            'Access-Control-Allow-Origin': '*'
        }
    }
}

export const headersWithToken = (token) => {
    return {
        headers : {
            'Accept' : 'application/json',
            'Authorization' : `Bearer ${token}`,
            'Content-Type' : 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    }
}

export const headersWithImage = (token) => {
    return {
        headers : {
            'Accept' : 'application/json',
            'Authorization' : `Bearer ${token}`,
            'Content-Type' : 'multipart/form-data',
            'Access-Control-Allow-Origin': '*'
        }
    }
}
