import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import '../../assets/css/signup.css';
import PDLBorder from "../../components/PDLBorder";
import Logo from "../../components/Logo";
import { Link, useNavigate } from "react-router-dom";
import { signUpRequest, submitInquiryRequest } from "../../redux/Auth/action";
import loginbg from "../../assets/img/loginbg.png";


const ContactUs = (props) => {

    const [inquiry, setInquiry] = useState({
        name: '',
        email: '',
        message: '',
    });
    const [loading, setLoading] = useState(false);

    const onChangeHandler = (event) => {
        let { name, value } = event.target;

        setInquiry((preVal) => {
            return {
                ...preVal,
                [name]: value,
            }
        });
    }

    const onSubmitHandler = async (event) => {
        event.preventDefault();
        setLoading(true);
        props.submitInquiry(inquiry)
            .then(() => {
                // navigate("/login", { replace: true });
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                setLoading(false);
                setInquiry({
                    name: '',
                    email: '',
                    message: '',
                });
            });
    }

    useEffect(() => {
        return () => {
        }
    }, [])

    return (
        <>
            <section className="fram-sec">
                <div className="frame-wrapper" style={{
                    backgroundImage: `url('${loginbg}')`,
                    backgroundSize: '100% 100%',
                    backgroundRepeat: 'no-repeat',
                    padding: '3rem 2rem',
                    margin: '4.5rem 2rem',
                    position: 'relative',
                }}>
                    <PDLBorder />

                    <Logo />

                    <div className="row">
                        <div className="col-md-7">

                        </div>
                        <div className="col-md-5">

                        </div>
                    </div>

                    <div className="signup">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-4">

                                </div>
                                <div className="col-lg-4">
                                    <div className="signup-wrapper">
                                        <form onSubmit={onSubmitHandler} autoComplete={"new-password"}>
                                            <h4 className="text-center">Contact Us</h4>
                                            <div className="form-group">
                                                <input type="text" className="login-field form-control" onChange={onChangeHandler}
                                                    placeholder="Name" value={inquiry.name} name={'name'} required={true} autoComplete={"off"} />
                                            </div>
                                            <div className="form-group">
                                                <input type="email" className="login-field form-control" onChange={onChangeHandler}
                                                    placeholder="Email" value={inquiry.email} name={'email'} required={true} autoComplete={"off"} />
                                            </div>
                                            <div className="form-group">
                                                <textarea placeholder="Write what is on your mind." className="login-field form-control" rows={7} onChange={onChangeHandler} value={inquiry.message} name={'message'} required={true} autoComplete={"off"} />
                                            </div>
                                            <div className="form-group row">

                                                <div className="col-md-12">
                                                    {
                                                        loading ?
                                                            <button type="button" className="form-submit">
                                                                <i className={"fas fa-spinner fa-spin"}></i>
                                                            </button>
                                                            :
                                                            <button type="submit" className="form-submit">
                                                                Submit Inquiry
                                                            </button>
                                                    }
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="col-lg-4">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

const mapStateToProps = () => {
    return {

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        submitInquiry: (data) => dispatch(submitInquiryRequest(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);