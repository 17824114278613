import React from 'react';
import {connect} from "react-redux";
import Logo from '../assets/img/logo.png';
import TradeRequest from "./Modals/TradeRequest";


const Wrapper = ({children}) => {

    return (
        <>
            {children}
        </>
    );
}

const mapStateToProps = () => {
    return {}
}

const mapDispatchToProps = () => {

}

export default connect(mapStateToProps)(Wrapper);