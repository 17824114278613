import React, {useEffect, useState} from 'react';
import {Route, Routes, useNavigate} from "react-router-dom";
import {connect} from "react-redux";
import {Button, Modal} from "react-bootstrap";
import Profile from '../../assets/img/profile.jpg';
import {acceptRequest, rejectRequest, resetTime, resetTrade, setNewTradeRequest} from "../../redux/TradeRequest/action";
import {showLoader, startTimer} from "../../redux/helpers";
import {pusher} from "../../redux/pusherConfig";
import {joinGuildRequest} from "../../redux/Guild/action";
import {toast} from "react-toastify";

function GiftQuantity(props) {

    const navigator = useNavigate();
    const [qty , setQty] = useState(props?.modalItem?.sendQty || 1);


    const closeModal = () => {
        props.setOpen(false);
    }

    const qtyAction = (type) =>{
        if(type === "-"){
            if(qty > 1){
                setQty(qty - 1);
            }
        }else{
            if(qty < props?.modalItem?.qty){
                console.log(type,qty);
                setQty(qty + 1);
            }
        }
    }


    useEffect(() => {
        console.log(props.modalItem);
    }, [props.modalItem]);

    return (
        <Modal
            show={props.open}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter" className={"h3"}>
                    Add Quantity
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row justify-content-center">
                    <div className="col-3">
                        <figure>
                            <img width={'100%'} src={props.modalItem?.shop?.image_url} alt=""/>
                        </figure>

                    </div>
                    <div className="col-8">
                        <h3>{props.modalItem?.shop?.title}</h3>
                        <p>{props.modalItem?.shop?.description}</p>
                        <div className={"d-flex flex-row justify-content-start"}>
                            <button onClick={()=>qtyAction('-')} type={"button"}  className={" btn "}>
                                <i className={"text-danger fa fa-minus"}></i>
                            </button>
                            <button type={"button"}  className={" text-white form-control btn "}>
                                {qty} / {props?.modalItem?.qty}
                            </button>
                            <button onClick={()=>qtyAction('+')} type={"button"} className={" btn "}>
                                <i className={"text-success fa fa-plus"}></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-end">
                    <div className="col-4 mr-3 mb-3">
                        <Button className={"btn btn-success"} onClick={closeModal}>Cancel</Button>
                        <Button className={"btn btn-success"}
                        onClick={()=>{props.setQuantity(qty,props?.modalItem?.id);props.setOpen(false)}}>
                            Set
                        </Button>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>

            </Modal.Footer>
        </Modal>
    );
}

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(GiftQuantity);
