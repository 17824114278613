import React, {useEffect, useState} from 'react';
import {Route, Routes, useNavigate} from "react-router-dom";
import {connect} from "react-redux";
import {Button, Modal} from "react-bootstrap";
import Profile from '../../assets/img/profile.jpg';
import {acceptRequest, rejectRequest, resetTime, resetTrade, setNewTradeRequest} from "../../redux/TradeRequest/action";
import {showLoader, startTimer} from "../../redux/helpers";
import {pusher} from "../../redux/pusherConfig";
import {joinGuildRequest} from "../../redux/Guild/action";
import {toast} from "react-toastify";
import {updateTradeQtyRequest} from "../../redux/Trade/action";

function TradeQuantity(props) {

    const navigator = useNavigate();

    const [ loading , setLoading ] = useState(false);
    const [qty , setQty] = useState(props.modalItem?.qty || 1);


    const closeModal = () => {
        props.setOpen(false);
    }

    const qtyAction = (type) =>{
        if(type === "-"){
            if(qty > 1){
                setQty(qty - 1);
            }
        }else{
            if(qty < props?.modalItem?.inventory?.qty){
                console.log(type,qty);
                setQty(qty + 1);
            }
        }
    }

    const setQuantityRequest = () => {
        setLoading(true);
        props.updateTradeQty({id : props?.modalItem?.id ,qty : qty})
            .then((data)=>{
                console.log(data);
                props.updateQuantity(data?.id,data?.qty)
                setLoading(false);
                props.setOpen(false)
            })
            .catch((e)=>{
            })
    }


    useEffect(() => {
        console.log(props.modalItem);
    }, [props.modalItem]);

    return (
        <Modal
            show={props.open}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter" className={"h3"}>
                    Add Quantity
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row justify-content-center">
                    <div className="col-3">
                        <figure>
                            <img width={'100%'} src={props.modalItem?.inventory?.shop?.image_url} alt=""/>
                        </figure>

                    </div>
                    <div className="col-8">
                        <h3>{props.modalItem?.inventory?.shop?.title}</h3>
                        <p>{props.modalItem?.inventory?.shop?.description}</p>
                        <div className={"d-flex flex-row justify-content-start"}>
                            <button onClick={()=>qtyAction('-')} type={"button"}  className={" btn "}>
                                <i className={"text-danger fa fa-minus"}></i>
                            </button>
                            <button type={"button"}  className={" text-white form-control btn "}>
                                {qty} / {props?.modalItem?.inventory?.qty}
                            </button>
                            <button onClick={()=>qtyAction('+')} type={"button"} className={" btn "}>
                                <i className={"text-success fa fa-plus"}></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-end">
                    <div className="col-4 mr-3 mb-3">
                        <Button className={"btn btn-success"} onClick={closeModal}>Cancel</Button>
                        {
                            loading ?
                                <Button className={"btn btn-success"}>
                                    <i className={"fa fa-spinner fa-spin"}></i>
                                </Button>
                                :
                                <Button className={"btn btn-success"}
                                        onClick={setQuantityRequest}>
                                    Set
                                </Button>
                        }
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>

            </Modal.Footer>
        </Modal>
    );
}

const mapStateToProps = (state) => {
    return {

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateTradeQty : (data) => dispatch(updateTradeQtyRequest(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TradeQuantity);
