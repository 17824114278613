import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import PDLBorder from "../../components/PDLBorder";
import Logo from "../../components/Logo";
import '../../assets/css/thearchives.css';
import '../../assets/css/wallFame.css';
import AuthHeader from "../../components/AuthHeader";
import {
    getFamesRequest,
    setLoader
} from "../../redux/Site/action";
import Loading from "../NotFound/Loader";
import inventorybg from "../../assets/img/inventorybg.png";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'


const WallOfFames = (props) => {

    const date = new Date();
    const [year, setYear] = useState('');
    const [name, setName] = useState('');

    const getPageRequest = async () => {
        await props.setLoader(true);
        await props.getFames(year);
        await props.setLoader(false);
    }

    const onChangeYearHandler = (type) => {
        if(type === "+"){
            setYear(year+1);
        }else{
            setYear(year-1);
        }
    }

    useEffect(() => {
        getPageRequest(year);

        return () => {

        }
    }, [year]);

    useEffect(() => {
        if(props.sessions.length > 0){
            console.log(props.sessions[0]?.slug);
            setYear(props.sessions[0]?.slug);
        }
    }, [props.sessions]);


    if (props.loader) return (<Loading/>);

    // if(!props.archives.length > 0) return (<NotFound />);


    return (
        <section className="fram-sec" style={{
            backgroundImage: `url('${inventorybg}')`,
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat',
            padding: '3rem 2rem',
            margin: '4.5rem 2rem',
            position: 'relative',
        }}>
            <div className="frame-wrapper">

                {/* Border*/}
                <PDLBorder/>
                {/*Logo*/}
                <Logo/>

                {/*Menus*/}
                <div className="row">
                    {/*<div className="col-md-7">*/}

                    {/*</div>*/}
                    <div className="col-md-12">
                        <div className="Menus text-right">
                            <AuthHeader/>
                        </div>
                    </div>
                </div>
                {/*Menus*/}

                <section className="wall-of-fame">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="content-wrapper ">
                                    <h3 className="text-center">Max Awards {name}</h3>
                                </div>
                            </div>
                        </div>
                        <div className="row card-img">
                            {
                                props.fames.map((val, key) => (
                                    <div key={key} className="col-lg-3">
                                        <div className="img-card-wrapper">
                                            <div className="card-name-wrapper">
                                                <h4>{val?.category_title}</h4>
                                            </div>
                                            <div className={"card-wrapper"}>
                                                    <figure key={key}>
                                                        {/*<OverlayTrigger placement="right"*/}
                                                        {/*                delay={{ show: 250, hide: 400 }}*/}
                                                        {/*                overlay={<div className={"bg-dark"} style={{*/}
                                                        {/*                    backgroundColor : 'rgba(0,0,0,1)',*/}
                                                        {/*                    borderRadius : 5,*/}
                                                        {/*                    boxShadow : '-4px 6px 21px 0px rgba(255,255,255,0.75)',*/}
                                                        {/*                }}>*/}
                                                        {/*                    <h5 className={"text-white px-3 py-1 m-0"}>{val?.character?.name}</h5>*/}
                                                        {/*                </div>}*/}
                                                        {/*>*/}
                                                            <img src={val?.image_url}
                                                                 className={"img-fluid"} alt=""/>
                                                        {/*</OverlayTrigger>*/}
                                                    </figure>
                                            </div>
                                            <div className="card-name-wrapper">
                                                <h4>{val?.character?.name}</h4>
                                            </div>
                                        </div>

                                    </div>
                                ))
                            }
                        </div>

                        <div className="row mt-5 justify-content-center d-flex justify-content-center character-form-wrapper">
                            <select
                                name="sessions"
                                onChange={(e)=>{
                                    props?.sessions?.filter((val) => val?.slug === e.target.value ).map((val)=> {
                                        setName(val?.name);
                                    });
                                    setYear(e.target.value);
                                }}
                                className="col-3 form-control inputCustomHeight">
                                {
                                    props?.sessions?.map((val,key)=>(
                                        <option
                                            selected={ (val?.slug === year)?true : false }
                                            value={val?.slug}
                                            key={key}
                                        >
                                            Max Awards {val?.name}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                </section>

            </div>
        </section>
    );
}

const mapStateToProps = (state) => {
    return {
        famesCategory: state.Site.famesCategory,
        fames: state.Site.fames,
        loader: state.Site.loader,
        sessions: state.Site.sessions,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getFames: (year = '') => dispatch(getFamesRequest(year)),
        setLoader: (bool) => dispatch(setLoader(bool)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WallOfFames);