import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const BoxImage = ({image}) => {

    return (
        <figure>
            <img width={'100%'} className={"imgBoxStyling animate__animated animate__bounceIn animate__delay-1s"} src={image} alt=""/>
        </figure>
    );
}

export default (BoxImage);