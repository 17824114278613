import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import '../assets/css/warrior2.css';
import whatHappenImg from "../assets/img/what-happen.png";



const WhatHappen = (props) => {

    const [index , setIndex] = useState(0);

    const onClickUpComming = () => {
        setIndex((preval)=>{
            return (preval >= (props?.happenNows?.length-1))? 0 : preval +1;
        });
    }

    const onClickPrevious = () => {
        setIndex((preval)=>{
            return (preval === 0)? props?.happenNows?.length - 1 : preval - 1;
        });
    }

    return (
        <>
            {
                props.islogin ?
                    <section className="what-happens mt-5">
                        <div className="happens-bg">
                            <div className="container">
                                <div className="row py-4 text-center">
                                    <div className="col-sm-12">
                                        <h4>whats happening now</h4>
                                    </div>
                                </div>
                                {
                                    props.happenNows.filter((val,key)=> key === index).map((val,key)=>(
                                        <div className="row" key={key}>
                                            <div className="col-lg-6">
                                                <figure>
                                                    <img src={val?.image_url} className="img-fluid" alt="" />
                                                </figure>
                                            </div>
                                            <div className="col-lg-6">
                                                <div style={{ color: '#ffff' }} dangerouslySetInnerHTML={{
                                                    __html: val?.content
                                                }}></div>
                                                <div className="btn-wrappers">
                                                    <a onClick={onClickPrevious} className="btn-theme">Previous</a>
                                                    <a onClick={onClickUpComming} className="btn-theme">Upcoming</a>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </section>
                    :
                    <></>

            }
        </>
);
}

const mapStateToProps = (state) => {
    return {
        islogin: state.Auth.islogin,
        happenNows: state.Site.happenNows,
    }
}

const mapDispatchToProps = () => {

}

export default connect(mapStateToProps)(WhatHappen);