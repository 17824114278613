import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import PDLBorder from "../../components/PDLBorder";
import Logo from "../../components/Logo";
import '../../assets/css/blackmarket.css';
import AuthHeader from "../../components/AuthHeader";
import inventorybg from '../../assets/img/inventorybg.png';
import { getItemsRequest } from "../../redux/Shop/action";
import { setLoader } from "../../redux/Site/action";
import Loader from "../NotFound/Loader";
import { Link } from "react-router-dom";
import { showLoader } from "../../redux/helpers";
import ItemWrapper from "../../components/ItemWrapper";


const Shop = (props) => {

    const [loading, setLoading] = useState(null);
    const [search, setSearch] = useState(null);
    const [page, setPage] = useState(1);
    const [category, setCategory] = useState('all');

    const setPageParam = (page) => {
        const url = new URL(window.location.href);
        url.searchParams.set('page', page);
        window.history.pushState({}, '', url);
    };

    const get = async () => {
        props.setLoader(true);
        // await props.getItems();
        await props.getItems(null, `?page=${page}`);
        props.setLoader(false);
        setPageParam(page);
    }

    const pagination = async (data, page_number, type) => {
        console.log(data, page_number, type);
        // showLoader('flex');
        if (type == 'page_number') {
            setPage(page_number);
        }
        if (type == 'next') {
            if (data.last_page > page) {
                setPage(page + 1);
            }
        }
        if (type == 'previous') {
            if (page > 1) {
                setPage(page - 1);

            } else {
                setPage(1);
            }
        }
        setPageParam(page);
        // showLoader('none');
    }

    const onSearchHandler = async (event) => {
        event.preventDefault();
        showLoader('flex');
        await props.getItems(null, `?search=${search}`);
        showLoader('none');
    }

    const onCategoryHandler = async (val) => {
        showLoader('flex');
        await props.getItems(null, `?category=${val}`);
        showLoader('none');
    }



    useEffect(() => {
        get();
        return () => {

        }

    }, [page]);


    if (props.loader) return <Loader />

    return (
        <div id={'shopPage'}>
            <section className="fram-sec" style={{
                backgroundImage: `url('${inventorybg}')`,
                backgroundSize: '100% 100%',
                backgroundRepeat: 'no-repeat',
                padding: '3rem 2rem',
                margin: '4.5rem 2rem',
                position: 'relative',
            }}>
                <div className="frame-wrapper">

                    {/* Border*/}
                    <PDLBorder />
                    {/*Logo*/}
                    <Logo />

                    {/*Menus*/}
                    <div className="row">
                        {/*<div className="col-md-7">*/}

                        {/*</div>*/}
                        <div className="col-md-12">
                            <div className="Menus text-right">
                                <AuthHeader />
                            </div>
                        </div>
                    </div>
                    {/*Menus*/}

                    <section className="mycharacter-sec">
                        <div className="container pb-5">
                            <div className="row align-items-center">
                                <div className="col-lg-2"></div>
                                <div className="col-lg-8">
                                    <div className="profile-creation text-center animate__animated animate__fadeInDown">
                                        <h3>Black Market Store</h3>
                                    </div>

                                </div>
                                <div className="col-lg-2"></div>
                            </div>

                            <div className="row justify-content-center ">
                                <form className={"col-lg-8"}
                                    onSubmit={onSearchHandler}
                                >
                                    <div className="form-group d-flex justify-content-center align-content-center ">
                                        <select style={{
                                            width: '30%'
                                        }}
                                            name="category_id"
                                            id=""
                                            className="form-control pdlCustomInput"
                                            onChange={(e) => {
                                                setCategory(e.target.value);
                                                onCategoryHandler(e.target.value);
                                            }}
                                        >
                                            <option style={{
                                                background: 'rgba(0, 0, 0, 0.3)',
                                                color: '#fff',
                                            }} value={"all"}>All</option>
                                            {
                                                props.category.map((val, key) => {
                                                    return <option
                                                        key={key}
                                                        style={{
                                                            background: 'rgba(0, 0, 0, 0.3)',
                                                            color: '#fff',
                                                        }}
                                                        value={val?.id}>{val?.name}</option>
                                                })
                                            }
                                        </select>
                                        <input type="text" required={true}
                                            onChange={(e) => setSearch(e.target.value)}
                                            className="form-control pdlCustomInput"
                                            value={search}
                                            placeholder="Search item here" />
                                        {
                                            search &&
                                            <a
                                                    // type={"button"}
                                                    onClick={() => { setSearch(''); get(); }}
                                                // className="btn m-0 customButton"
                                                className="p-1"
                                            >
                                                <i className={"text-danger fa fa-times"}></i>
                                            </a>
                                        }
                                    </div>
                                </form>
                            </div>

                            {
                                props.items?.data.length > 0 ?
                                    <>
                                        <div className="row container">
                                            {
                                                props.items?.data.map((val, key) => {
                                                    return <div
                                                        key={key}
                                                        className="col-lg-3"

                                                    >
                                                        <div className="mychar-wrapper text-center">
                                                            <div className="black-img-wrapper text-right">
                                                                <figure className={"mr-1"}>
                                                                    <img src={val?.image_url}
                                                                        className="animate__animated animate__fadeInDown img-fluid"
                                                                        alt="" />
                                                                </figure>
                                                            </div>
                                                            <div className="my-char-content">
                                                                <div className="player-name row">
                                                                    <p className="col-12 m-0 justify-content-center Pname-title" style={{ fontSize: 15 }}>
                                                                        {val?.title}
                                                                    </p>
                                                                    <p className="col-12 m-0 d-flex justify-content-center align-items-center"
                                                                        style={{ textAlign: 'end', fontSize: 15 }}>
                                                                        <img src={val?.currency?.icon_url}
                                                                            className="rounded-circle"
                                                                            style={{ width: '50px', height: '36px', objectFit: 'contain', margin: '9px 13px' }}
                                                                            alt="" />
                                                                        {/* {parseInt(val?.price).toLocaleString()} */}
                                                                        {val?.price}
                                                                    </p>
                                                                </div>
                                                                <Link to={'/shop/' + val?.id} className="btn customButton">Buy now({parseInt(val?.stock).toLocaleString()})</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                })
                                            }

                                        </div>


                                        <div className="row mt-5 justify-content-center">
                                            <button type="button" disabled={props.items?.current_page === 1} onClick={() => pagination(props.items, 0, 'previous')} className="btn customButton mx-2">Previous</button>
                                            {props.items?.last_page > 1 && (
                                                <>
                                                    {props.items.current_page > 3 &&
                                                        <>
                                                            <button type="button" className=" btn customButton mx-2">...</button>
                                                        </>
                                                    }
                                                    {Array.from({ length: Math.min(props.items.last_page - Math.max(4, props.items.current_page), 5) }, (_, index) => props.items.current_page + index).map(page_number => (
                                                        <button key={page_number} onClick={() => pagination(props.items, page_number, 'page_number')} type="button" className={`${props.items.current_page === page_number ? 'active_btn_class' : 'btn customButton'} mx-2`}>{page_number}</button>
                                                    ))}
                                                    {props.items.current_page < props.items.last_page - 3 && <button type="button" className=" btn customButton mx-2">...</button>}
                                                    <button onClick={() => pagination(props.items, props.items.last_page, 'page_number')} type="button" className={`${props.items.current_page === props.items.last_page ? 'active_btn_class' : 'btn customButton'} mx-2`}>{props.items.last_page}</button>
                                                </>
                                            )}
                                            <button type="button" disabled={props.items?.last_page === props.items?.current_page} onClick={() => pagination(props.items, 0, 'next')} className="btn customButton mx-2">Next</button>
                                        </div>
                                    </>
                                    :
                                    <div className={"row mt-5 justify-content-center"}>
                                        <div className="profile-creation text-center animate__animated animate__fadeInDown">
                                            <h3>Items Not Found</h3>
                                        </div>
                                    </div>
                            }

                        </div>
                    </section>

                </div>
            </section>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        items: state.Shop.items,
        loader: state.Site.loader,
        category: state.Site.category,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setLoader: (bool) => dispatch(setLoader(bool)),
        getItems: (url = null, search = null) => dispatch(getItemsRequest(url, search)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Shop);