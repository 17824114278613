import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import Logo from "../../components/Logo";
import '../../assets/css/guild.css';
import AuthHeader from "../../components/AuthHeader";
import {Link, useNavigate, useParams} from "react-router-dom";
import {
    addItemInTrade,
} from "../../redux/Trade/action";
import {showLoader} from "../../redux/helpers";
import PDLBorder from "../../components/PDLBorder";
import GuildImage from '../../assets/img/Guild.png';
import Macrus from '../../assets/img/macrus.png';
import Member from '../../assets/img/member.png';
import {
    acceptGuildRequest,
    cancelGuildRequest,
    getListGuildRequest,
    getNotificationGuildRequest
} from "../../redux/Guild/action";
import JoinRequest from '../../components/Modals/JoinRequest';
import Navigation from "../../components/navigation";
import {getMyCharactersRequest} from "../../redux/UserCharacter/action";

const Notification = (props) => {

    const params = useParams();

    const get = async () => {
        showLoader('flex');
        await props.getNotifications();
        showLoader('none');
    }

    const acceptGuildRequest = async (id) => {
        showLoader('flex');
        await props.acceptGuild({notification_id : id});
        await props.getNotifications();
        showLoader('none');
    }
    const rejectGuildRequest = async (id) => {
        showLoader('flex');
        await props.cancelGuild({notification_id : id});
        await props.getNotifications();
        showLoader('none');
    }

    useEffect(() => {
        get();
        return () => {
        }
    }, [])

    return (
        <section className="fram-sec">
            <div className="frame-wrapper">
                {/* Border*/}
                <PDLBorder/>
                {/*Logo*/}
                <Logo/>

                {/*Menus*/}
                <div className="row">
                    {/*<div className="col-md-7">*/}

                    {/*</div>*/}
                    <div className="col-md-12">
                        <div className="Menus text-right">
                            <AuthHeader/>
                        </div>
                    </div>
                </div>


                <section className="guild">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="guild-title text-center">
                                    <figure>
                                        <img src={GuildImage} alt="" />
                                    </figure>
                                </div>
                            </div>
                        </div>
                        <div className="row pt-5">
                            <div className="col-sm-12">
                                <ul className="nav nav-tabs justify-content-center" id="myTab" role="tablist">
                                    <li className="nav-item">
                                        <Link className="nav-link" to={'/'} id="contact-tab">Guild Notification</Link>
                                    </li>
                                </ul>

                                <div className="row pt-5">
                                    <div className="col-sm-12">
                                        <div className="tab-content" id="nav-tabContent">
                                            <div className="tab-pane fade show active" id="nav-home" role="tabpanel"
                                                 aria-labelledby="nav-home-tab">

                                                <div className="guild-wrapper">
                                                        {
                                                            (props.notification && props.notification?.length > 0)?
                                                                <div className="row justify-content-center pt-5">
                                                                    {
                                                                        props.notification?.map((val,key)=>{
                                                                            if( val?.guild && (val?.type === "request_send" || val?.type === "request_receive") ){
                                                                                return <div key={key} className="col-lg-5">
                                                                                    {/*<a href="discord.php">*/}
                                                                                    <div className="guild-card-wrapper guild-main-wrapper mt-0 px-2 py-3">
                                                                                        <div className="row">
                                                                                            <div className="col-md-4 my-auto">
                                                                                                <div className="guild-img-wrapper d-flex justify-content-center">
                                                                                                    <img src={val?.character?.logo_url} width={'100%'} alt=""/>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-md-8">
                                                                                                <div className="guild-content-wrapper">
                                                                                                    <div className="guild-description mt-3">
                                                                                                        <h6 className={"pdlStyleHeading pdlColor"}
                                                                                                            style={{color:'#E3BF4B'}}>
                                                                                                            {val?.message}
                                                                                                        </h6>
                                                                                                    </div>

                                                                                                    <div className={"d-flex flex-row justify-content-start my-1"}>
                                                                                                        <p className={"text-white mr-1 mb-0"}>Guild name :</p>
                                                                                                        <Link className={"guild-title ml-1"} to={'/guild/'+val?.guild_id}>
                                                                                                            <h5 className="guild-title">
                                                                                                                {val?.guild?.name}
                                                                                                            </h5>
                                                                                                        </Link>
                                                                                                    </div>
                                                                                                    <div className={"d-flex flex-row justify-content-start"}>
                                                                                                        <p className={"text-white mr-1"} style={{ flex:'0 0 0 0 55%' }}>Character name :</p>
                                                                                                        <div className={"guild-title ml-1"} style={{ flex:'0 0 0 0 45%' }} >
                                                                                                            <h5 className="guild-title">
                                                                                                                {val?.character?.name}
                                                                                                            </h5>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div className="guild-name-and-join">
                                                                                                        <button type={"button"} onClick={()=>acceptGuildRequest(val?.id)} className="btn">
                                                                                                            Accept
                                                                                                        </button>
                                                                                                        <button type={"button"} onClick={()=>rejectGuildRequest(val?.id)} className="btn">
                                                                                                            Reject
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {/*</a>*/}
                                                                                </div>
                                                                            }
                                                                        })
                                                                    }
                                                                </div>
                                                                :
                                                                <div className="row pt-5 justify-content-center">
                                                                    <div className="col-lg-4">
                                                                        <p className={"pdlStyleParagraph text-center"}>No Guilds Notification Found!!</p>
                                                                    </div>
                                                                </div>
                                                        }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>

            </div>
        </section>
    );
}


const mapStateToProps = (state) => {
    return {
        notification : state.Guild.notification,
        user : state.Auth.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getNotifications : () => dispatch(getNotificationGuildRequest()),
        acceptGuild : (data) => dispatch(acceptGuildRequest(data)),
        cancelGuild : (data) => dispatch(cancelGuildRequest(data)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Notification);