import React, {useEffect, useState} from 'react';
import WeaponStop from '../../../assets/img/weaponstop.png';
import WeaponsBottom from '../../../assets/img/weaponsbottom.png';
import { getCharacterDetailRequest} from "../../../redux/UserCharacter/action";
import {setLoader} from "../../../redux/Site/action";
import {connect} from "react-redux";
import btop from "../../../assets/img/btop.png";
import bbottom from "../../../assets/img/bbottom.png";
import BoxImage from "../../../components/BoxImage";


const Left = (props) => {

    return (
        <>
            <div className="Detail-inner">
                <div className="forTop-border">
                    <figure><img src={btop} alt="" /></figure>
                </div>
                <div className="itemsBox text-white">
                    {
                        props?.details?.characteristics.map((val,key)=>{
                            return (val?.placement === "left") &&
                            <div key={key} onClick={()=>props.onUnEquipItem(
                                (val?.items.length > 0 && val?.items[0]?.id) || 0
                            )} className="clothings">
                                <div className="imgbox">
                                        <BoxImage image={
                                            (val?.items.length > 0 && val?.items[0]?.shop?.image_url) || ''
                                        } />
                                </div>
                                <div className="contentDv">
                                    <h5 className="title">{val?.name}</h5>
                                    <ul className="lists">
                                        <li>H : {(val?.items.length > 0 && val?.items[0]?.health) || 0}</li>
                                        <li>D : {(val?.items.length > 0 && val?.items[0]?.damage) || 0}</li>
                                        <li>B : {(val?.items.length > 0 && val?.items[0]?.base) || 0}</li>
                                        <li>AP : {(val?.items.length > 0 && val?.items[0]?.armor_pen) || 0}</li>
                                        <li>APR : {(val?.items.length > 0 && val?.items[0]?.armor_pen_resist) || 0}</li>
                                    </ul>
                                </div>
                            </div>
                        })
                    }
                </div>
                <div className="forBottom-border">
                    <figure><img src={bbottom} alt="" /></figure>
                </div>
            </div>
        </>
    )
        ;
}

const mapStateToProps = (state) => {
    return {
        islogin: state.Auth.islogin,
        details: state.UserCharacter.details,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setLoader: (bool) => dispatch(setLoader(bool)),
        getCharacterDetail: (id) => dispatch(getCharacterDetailRequest(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Left);


/*

 */