import React, {useEffect} from 'react';
import {connect} from "react-redux";
import PDLBorder from "../../components/PDLBorder";
import Logo from "../../components/Logo";
import '../../assets/css/profile.css';
import AuthHeader from "../../components/AuthHeader";
import Rulebook from '../../assets/img/Rulebook.png';
import {getCharactersRequest, getPagesRequest, setLoader} from "../../redux/Site/action";
import NotFound from "../NotFound/NotFound";
import Loading from "../NotFound/Loader";
import inventorybg from "../../assets/img/inventorybg.png";


const RuleBook = (props) => {

    const getPageRequest = async () => {
        await props.setLoader(true);
        await props.pagesRequest();
        await props.setLoader(false);
    }

    useEffect(()=>{

        if(props.ruleBook.length > 0) getPageRequest();
        return () => {

        }
    },[]);


    if(props.loader) return (<Loading/>);

    if(!props.ruleBook.length > 0) return (<NotFound />);


    return (
        <section className="fram-sec" style={{
            backgroundImage : `url('${inventorybg}')`,
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat',
            padding: '3rem 2rem',
            margin: '4.5rem 2rem',
            position: 'relative',
        }}>
            <div className="frame-wrapper">

                {/* Border*/}
                <PDLBorder/>
                {/*Logo*/}
                <Logo/>

                {/*Menus*/}
                <div className="row">
                    {/*<div className="col-md-7">*/}

                    {/*</div>*/}
                    <div className="col-md-12">
                        <div className="Menus text-right">
                            <AuthHeader/>
                        </div>
                    </div>
                </div>
                {/*Menus*/}

                <section className="profile">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-2">

                            </div>
                            <div className="col-lg-8">
                                <div className="profile-creation text-center">
                                    <figure>
                                        <h3 className={"mb-5 pdlStyleHeading"}>{props.ruleBook[0].title}</h3>
                                        {/*<img src={Rulebook} className="img-fluid mb-5" alt=""/>*/}
                                    </figure>
                                    {/*<p className={"pdlStyleParagraph text-center"}>*/}
                                        <p className={"pdlStyleParagraph text-center"} dangerouslySetInnerHTML={{ __html: props.ruleBook[0].content}} />
                                    {/**/}
                                    {/*</p>*/}
                                </div>

                            </div>
                            <div className="col-lg-2"></div>
                        </div>
                    </div>
                </section>
            </div>
        </section>
    );
}

const mapStateToProps = (state) => {
    return {
        ruleBook : state.Site.pages.filter((val)=> val.slug === "privacy-policy"),
        loader : state.Site.loader,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        pagesRequest : () => dispatch(getPagesRequest()),
        setLoader : (bool) => dispatch(setLoader(bool)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(RuleBook);