import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import warrior from "../../assets/img/warrior.png";
import '../../assets/css/myprofile.css';
import {createMyCharacterRequest, getMyCharactersRequest} from "../../redux/UserCharacter/action";

const Profile = (props) => {


    useEffect(()=>{
        return () =>{

        }
    },[]);

    useEffect(()=>{
        if(props.myCharacters.length > 0){
            // setActiveCharacter(props.myCharacters[0]?.id);
        }
    },[props.myCharacters])


    return (
        <div className="Warrior-Dv">
            <div className="head">
                <h4 className={"pdlStyleHeading"}>{props?.data?.name}</h4>
            </div>
            <div className="characterKills">
                <div className="property">
                    <h4 className={"pdlStyleHeading"}>Character Kills</h4>
                </div>
                <div className="value">
                    <h3>{parseInt(props?.data?.kills).toLocaleString()}</h3>
                </div>
            </div>
            <p>{props?.data?.character?.description}</p>
            {/*<ul className="awardsList">*/}
            {/*    {*/}
            {/*        props?.relic?.map((val,key)=>(*/}
            {/*            <li key={key}>*/}
            {/*                <div className="imgBox-award">*/}
            {/*                    <figure><img width={'100%'} src={val?.shop?.image_url} alt=""/></figure>*/}
            {/*                </div>*/}
            {/*            </li>*/}
            {/*        ))*/}
            {/*    }*/}
            {/*</ul>*/}
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        character: state.Site.characters,
        relic : state.Auth.relic,
        myCharacters : state.UserCharacter.characters,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getMyCharacter : () => dispatch(getMyCharactersRequest()),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Profile);