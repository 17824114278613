import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import '../assets/css/warrior2.css';
import {useNavigate} from "react-router-dom";

const BurnArcade = (props) => {
    const navigate = useNavigate();

    return (<>
            <section className="what-happens mt-5 mb-5">
                <div className="happens-bg">
                    <div className="container">
                        <div className="row py-4 text-center">
                            <div className="col-sm-12">
                                <h4>The Burn Arcade</h4>
                            </div>
                        </div>
                        <div className="row">
                            {
                                props?.burnArcade?.map((val,key)=>(
                                    <div className="col-md-4" key={key}>
                                        <div className="item-box" style={{
                                            borderStyle: 'solid',
                                            borderWidth: '2px',
                                            borderImageSource: 'linear-gradient( to right, #b88425, #c5973f, #d2a957, #e0bc6f, #edcf88, #efd28b, #f1d58f, #f3d892, #eacb80, #e1bf6f, #d9b25d, #d0a64c )',
                                            borderImageSlice: 1,
                                            backgroundSize: 'cover',
                                            // display : 'flex',
                                            // alignItems: 'center',
                                            // justifyContent : 'center',
                                            padding: '7px 0'
                                        }}>
                                            <div className="item-detail">
                                                <div className="name">
                                                    {
                                                        parseInt(val?.coming_soon) === 0  &&
                                                        <h4 className="item-name h6 text-center"
                                                            style={{fontSize:'15px'}}>
                                                            {val?.title}
                                                        </h4>
                                                    }
                                                </div>
                                            </div>
                                            <div className="item-img overflow-hidden">
                                                <figure className={'d-flex justify-content-center'}>
                                                    <img
                                                    src={val?.image_url}
                                                    className="img-fluid" style={{
                                                        objectFit : 'cover',
                                                        height : '50%',
                                                        width : '50%'
                                                    }} />
                                                </figure>
                                            </div>
                                            <div className="item-detail">
                                                <div className="name">
                                                    {
                                                        parseInt(val?.coming_soon) === 1 ?
                                                            <h4 className="item-name h6 text-center" style={{fontSize:'15px'}}>
                                                                Coming Soon
                                                            </h4>
                                                            :
                                                            <h4 onClick={()=> {
                                                                window.location.href = val?.url
                                                            }} className={"item-name h6 text-center"}
                                                                style={{fontSize:'15px',cursor:'pointer'}}>
                                                                Click To Play
                                                            </h4>
                                                    }
                                                </div>
                                        </div>
                                    </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>);
}

const mapStateToProps = (state) => {
    return {
        burnArcade: state.Site.burnArcade,
    }
}


export default connect(mapStateToProps)(BurnArcade);