import {LOGIN, LOGOUT, SET_CHARACTER, SET_RELIC, SET_USER, WELCOME} from "../constants";

const initState = {
    islogin : false,
    token : null,
    user : null,
    isChatModal :false,
    welcome : localStorage.getItem('welcome'),
    relic : [],
    selected_character : 0,
}


const Auth = (state = initState , action) => {

    switch (action.type) {

        case LOGIN :
            return {
                ...state,
                islogin: true,
                token : action.token,
                user : action.user,
            }
        case LOGOUT:
            return {
                ...state,
                islogin: false,
                token : null,
                user : null,
                selected_character : 0
            }
        case SET_USER :
            return {
                ...state,
                user : action.payload,
            }
        case SET_RELIC :
            return {
                ...state,
                relic : action.payload,
            }
        case WELCOME :
            return {
                ...state,
                welcome: localStorage.getItem('welcome'),
            }
        case SET_CHARACTER :
            return {
                ...state,
                selected_character : action.payload,
            }



        default: return state;
    }
}

export default Auth;