import React, {useEffect, useState} from 'react';
import {
    equipRequest,
    getCharacterDetailRequest,
    getCharacterInventoryRequest
} from "../../../redux/UserCharacter/action";
import {setLoader} from "../../../redux/Site/action";
import {connect} from "react-redux";
import beforeimg from "../../../assets/img/beforeimg.png";
import arrowRight from "../../../assets/img/arrow-right.png";
import playerBottom from "../../../assets/img/playerbottom.png";
import inventorytxt from "../../../assets/img/inventorytxt.png";
import BoxImage from "../../../components/BoxImage";
import {showLoader} from "../../../redux/helpers";
import PuffLoader from "react-spinners/PuffLoader";
import Category from "../../../components/Category";
import SearchInventory from "../../../components/SearchInventory";
import AddBagModal from "../../../components/Modals/AddBagModal";
import BagsItems from "../../../components/Modals/BagsItems";
import {getBagsRequest} from "../../../redux/Inventory/action";


const Inventory = (props) => {

    const [tab, setTab] = useState('all');
    const [bagNo, setBagNo] = useState(0);
    const [item, setItem] = useState(null);
    const [addBag, setAddBag] = useState(false);
    const [bagsModal, setBagsModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dropDown, setDropDown] = useState({
        id: 0,
        show: false,
    });

    const get = async (id = '', url = null, search = null) => {
        setLoading(true);
        await props.getInventory(props.type, id, url, search);
        await props?.getBags(props?.character_id)
        setLoading(false);
    }

    const onEquipItem = async (id, category_id) => {
        showLoader('flex');
        props.equip({
            id: id,
            character_id: props?.character_id,
            category_id: category_id,
            type : props.type
        })
            .then(async () => {
                await props.getCharacterDetail(props?.character_id, props.type);
                await props.getInventory(props.type, (tab === 'all') ? '' : tab);
                showLoader('none');
            })
            .catch(() => {
                showLoader('none');
            })
    }

    const handleContextMenu = (event, id) => {
        event.preventDefault();
        setDropDown({id: id, show: true})
    }

    useEffect(() => {
        get();
        return () => {

        }
    }, []);

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropDown && !event.target.closest('.buttonDropDown')) {
                setDropDown({id: 0, show: false});
            }
        }

        document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', handleClickOutside);
    }, [dropDown]);

    return (
        <>
            <div className="inventroy-Box custom-box">
                <div className="beforeImg">
                    <figure><img src={beforeimg} alt=""/></figure>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <div className="inventories">
                            <ul className="inventory-links">
                                <li>
                                    <a className={`${tab == 'all' && 'active'}`} onClick={() => {
                                        get();
                                        setTab('all')
                                    }}>
                                        All
                                    </a>
                                </li>

                                <Category parentComponent={"character"} get={get} setTab={setTab} tab={tab}/>

                            </ul>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="title">
                            <figure><img src={inventorytxt} alt=""/></figure>
                        </div>
                        <div className="search-Box">
                            <SearchInventory component={'character'} itemRequest={get}/>
                        </div>
                        <div className="inventory-selection">
                            <div className="row justify-content-center">
                                {
                                    loading ?
                                        <div className={"col-10"}>
                                            <PuffLoader color={"#E3BF4B"} size={120}/>
                                        </div>
                                        :
                                        <>
                                            {
                                                props?.inventory?.data.length > 0 ?
                                                    <>
                                                        {
                                                            props.inventory?.data.map((val, key) => (
                                                                (val?.qty > 0) ?
                                                                    (
                                                                        <div key={key} className="col-6">
                                                                            <div
                                                                                className="inventory-itemBox buttonDropDown"
                                                                                onContextMenu={(e) => handleContextMenu(e, val?.id)}>
                                                                                <a className={"yy"}
                                                                                   onClick={() => onEquipItem(val?.id, val?.category?.id)}>
                                                                                    <BoxImage
                                                                                        image={val?.shop?.image_url}/>
                                                                                    <div className="count">
                                                                                        <span
                                                                                            className="large">{val?.qty}</span>
                                                                                    </div>
                                                                                </a>
                                                                            </div>

                                                                            {
                                                                                (dropDown?.show && dropDown?.id === val?.id) &&
                                                                                <div className="Menus">
                                                                                    <div style={{
                                                                                        display: 'block',
                                                                                        width : '114px',
                                                                                        background : '#000'
                                                                                    }}>
                                                                                        {
                                                                                            [...Array(5)].map((number, key) => (
                                                                                                <a data-rr-ui-dropdown-item=""
                                                                                                   className="dropdown-item" onClick={()=>{
                                                                                                       setBagNo(key+1);
                                                                                                       setItem(val);
                                                                                                       setAddBag(true);
                                                                                                       // console.log(item);
                                                                                                    }}
                                                                                                    role="button" tabIndex="0">
                                                                                                    Add to bag {key+1}
                                                                                                </a>
                                                                                            ))
                                                                                        }

                                                                                    </div>
                                                                                </div>
                                                                            }

                                                                        </div>
                                                                    )
                                                                    :
                                                                    null
                                                            ))
                                                        }
                                                    </>
                                                    :
                                                    <span style={{
                                                        color: '#E3BF4B', fontFamily: 'aniron', fontSize: '10px',
                                                    }}>no inventory found</span>
                                            }
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                {
                    props.inventory &&
                    <div className="nextBtn d-flex flex-row justify-content-between align-items-center my-2">
                        <a href={"javascript:void(0);"}
                           onClick={() => get('', (props?.inventory?.prev_page_url || null))}>
                            {
                                props?.inventory?.prev_page_url &&
                                <img src={arrowRight} style={{
                                    transform: 'rotate(-181deg)',
                                }} alt=""/>
                            }
                        </a>
                        <a href={"javascript:void(0);"}
                           onClick={() => get('', (props?.inventory?.next_page_url || null))}>
                            {
                                props?.inventory?.next_page_url &&
                                <img src={arrowRight} alt=""/>
                            }
                        </a>
                    </div>
                }
            </div>
            <div className="d-flex custom-btns-here flex-wrap gap-2">
                {
                    [...Array(5)].map((val,key)=>(
                        <button type={"button"}
                                onClick={()=>{
                                    setBagNo(key+1);
                                    setBagsModal(true);
                                }}
                                className="btn">Bag {key+1}</button>
                    ))
                }
            </div>
            <div className="forBottom-border py-1">
                <figure><img className="w-100" src={playerBottom} alt=""/></figure>
            </div>

            <AddBagModal
                open={addBag}
                setOpen={setAddBag}
                bagNo={bagNo}
                character_id={props?.character_id}
                item={item} />

            <BagsItems
                open={bagsModal}
                setOpen={setBagsModal}
                bagNo={bagNo}
                character_id={props?.character_id}
                request={ ()=>console.log("") }
            />

        </>
    );
}

const mapStateToProps = (state) => {
    return {
        islogin: state.Auth.islogin,
        category: state.Site.category,
        inventory: state.UserCharacter.inventory,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setLoader: (bool) => dispatch(setLoader(bool)),
        getInventory: (type, id = '', url = null, search = null) => dispatch(getCharacterInventoryRequest(type, id, url, search)),
        getBags : (character_id) => dispatch(getBagsRequest(character_id)),
        equip: (data) => dispatch(equipRequest(data)),
        getCharacterDetail: (id, item_for) => dispatch(getCharacterDetailRequest(id, item_for)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Inventory);

