import React, {useEffect, useState} from 'react';
import {Route, Routes, useNavigate} from "react-router-dom";
import {connect} from "react-redux";
import {Button, Modal} from "react-bootstrap";
import Profile from '../../assets/img/profile.jpg';
import {acceptRequest, rejectRequest, resetTime, resetTrade, setNewTradeRequest} from "../../redux/TradeRequest/action";
import {showLoader, startTimer} from "../../redux/helpers";
import {pusher} from "../../redux/pusherConfig";
import {joinGuildRequest} from "../../redux/Guild/action";
import {toast} from "react-toastify";

function JoinRequest(props) {

    const [character, setCharacter] = useState(null);
    const [isloading, setIsloading] = useState(false);
    const navigator = useNavigate();


    const closeModal = () => {
        props.setOpen(false);
    }

    const join = async () => {
        if (character) {
            setIsloading(true);
            props.joinGuild({
                guild_id: props.guildId,
                character_id: character,
            })
            .then(() => {
                setIsloading(false);
                closeModal();
                // navigator('/guild/' + props.guildId)
                navigator('/guild')
            })
            .catch(() => setIsloading(false));
            // setIsloading(false)
        } else {
            toast.warning('please select character!');
        }
    }

    useEffect(() => {

        return () => {

        }
    }, []);

    return (
        <Modal
            show={props.open}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            className='guild-character-modal'
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Join Request
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="justify-content-center guilds-character">
                    {
                        props.characters.map((val, key) => {
                            return <div key={key}  className='characterBox'>
                                <label htmlFor={`character${key}`}>
                                    <div onClick={() => setCharacter(val?.id)} className={"p-2"} style={
                                        (character === val?.id) ?
                                            {
                                                borderStyle: 'solid',
                                                borderWidth: '3px',
                                                borderImageSource: 'linear-gradient( to right, #b88425, #c5973f, #d2a957, #e0bc6f, #edcf88, #efd28b, #f1d58f, #f3d892, #eacb80, #e1bf6f, #d9b25d, #d0a64c )',
                                                borderImageSlice: 1,
                                            }
                                            :
                                            {
                                                borderStyle: 'solid',
                                                borderWidth: '3px',
                                                borderImageSource: 'linear-gradient( to right, #ddd, #ddd, #ddd, #ddd, #ddd, #ddd, #ddd, #ddd, #ddd, #ddd, #ddd, #ddd )',
                                                borderImageSlice: 1,
                                            }
                                    }>
                                        <img src={val?.logo_url} alt=""/>
                                    </div>
                                </label>
                                <div className={"text-center text-white"}>
                                    {val?.name}
                                </div>
                                <input type="radio" name={`character${key}`} onClick={() => setCharacter(val?.id)}
                                       value={val?.id} style={{visibility: "hidden"}} id=""/>

                            </div>
                        })
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className={"btn btn-success"} onClick={closeModal}>Cancel</Button>
                {
                    isloading ?
                        <Button className={"btn btn-success"} type={"button"}>
                            <i className={"fa fa-spinner fa-spin"}></i>
                        </Button>
                        :
                        <Button className={"btn btn-success"} onClick={join}>Send Request</Button>
                }
            </Modal.Footer>
        </Modal>
    );
}

const mapStateToProps = (state) => {
    return {
        characters: state.UserCharacter.characters,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        joinGuild: (data) => dispatch(joinGuildRequest(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(JoinRequest);
