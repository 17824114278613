import {RESET_ALERT, SET_ALERT} from "../constants";

const initState = {
    config: {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar : false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    },
    alerts : [],
    color : null,
    message : null
}


const Alert = (state = initState , action) => {

    switch (action.type) {
        case SET_ALERT :
            return {
                ...state,
                alerts: action.payload,
                // co/lor : action.payload.color,
                // message : action.payload.message,
            }
        case RESET_ALERT:
            return  {
                ...state,
                alerts : [],
            }

        default: return state;
    }
}

export default Alert;