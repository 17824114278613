import {
    CHARACTER_AWARDS,
    CHARACTER_DETAIL, CHARACTER_INVENTORY,
    CREATE_CHARACTER, MISC_NOTES_CHANGES,
    SET_MY_CHARACTERS,
} from "../constants";

const initState = {
    characters : [],
    details : null,
    inventory : null,
    awards : [],
}


const UserCharacter = (state = initState , action) => {
    switch (action.type) {

        case SET_MY_CHARACTERS :
            return {
                ...state,
                characters : action.payload,
            }
        case CREATE_CHARACTER:
            return {
                ...state,
                characters : [
                    ...state.characters,
                    action.payload,
                ],
            }
        case CHARACTER_DETAIL :
            return {
                ...state,
                details : action.payload,
            }
        case CHARACTER_INVENTORY:
            return {
                ...state,
                inventory : action.payload,
            }
        case CHARACTER_AWARDS:
            return {
                ...state,
                awards : action.payload,
            }
        case MISC_NOTES_CHANGES :
            return {
                ...state,
                details: {
                    ...state?.details,
                    character : {
                        ...state?.details?.character,
                        notes : action.payload,
                    }
                }
            }

        default: return state;
    }
}

export default UserCharacter;