import React, {useRef, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {connect, useSelector} from "react-redux";
import {Button, Modal} from "react-bootstrap";
import {getGuildRequest, joinGuildRequest, setItemGuildRequest} from "../../redux/Guild/action";
import {getItemsRequest} from "../../redux/Inventory/action";
import {toast} from "react-toastify";

function InventoryItemGuild(props) {

    const alertError = useRef(null);
    const [items, setItems] = useState({
        id: 0,
        qty: 1,
    });
    const inventory = useSelector(
        (state) => state?.Inventory?.data?.find((val) => val?.id === props?.addItemInfo?.item_id)
    );
    const character = useSelector(
        (state) => state?.UserCharacter?.characters?.find((val) => val?.id === props?.addItemInfo?.character_id)
    );
    // const member = character?.guild_members?.find((val) => val?.guild_id === props?.addItemInfo?.guild_id);
    // console.log("member",member);

    const [characterId, setCharacterId] = useState(null);
    const [isloading, setIsloading] = useState(false);
    const navigator = useNavigate();


    const closeModal = () => {
        props.setOpen(false);
    }

    // const getPlayers = async () => {
    //     try{
    //         let x = await  axios.get(getGuildPlayerApi(props.guildId),headersWithToken(props.token));
    //         setItems(x.data.response.data);
    //     }catch (e) {
    //         toast.error(e.message);
    //     }
    // }

    const submitHandler = async () => {
        if(items?.qty > props?.addItemInfo?.max_qty){
            toast.warning("maximum quantity is "+props?.addItemInfo?.max_qty);
            return false;
        }
        setIsloading(true);
        props.setItemGuild({
            guild_id: props?.addItemInfo.guild_id,
            items: [{
                id : props?.addItemInfo?.item_id,
                qty : items?.qty
            }],
            character_id: props?.addItemInfo?.character_id,
        })
            .then(async () => {
                // await props.getGuild(props.guildId);
                await props.getItems();
                setIsloading(false);
                props.setOpen(false);
            })
            .catch(() => {
                setIsloading(false);
            });
    }

    const addItem = (id) => {
        setItems((preVal) => {
            return {
                ...preVal,
                id: id,
            }
        });
        // if(characterId){
        //     setIsloading(true);
        //     props.addPlayer({guild_id : props.guildId,character_id : characterId})
        //         .then(()=>{
        //             setIsloading(false);
        //             props.setOpen(false);
        //         })
        //         .catch((e)=>{
        //             setIsloading(false);
        //         })
        // }else{
        //     toast.warning("please select character");
        // }
    }

    return (
        <Modal
            show={props.open}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Items
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row justify-content-center">
                    <div className="col-3">
                        <h3>Guild</h3>
                        <div className="guild-img-wrapper">
                            <figure>
                                <img
                                    src={character?.guild_members?.guild?.logo_url}
                                    className="guild-img img-fluid"
                                    alt=""/>
                            </figure>
                        </div>
                        <h4 className="guild-title">{character?.guild_members?.guild?.name}</h4>
                        <div className="guild-description mt-3">
                            <p>{character?.guild_members?.guild?.description}</p>
                        </div>
                    </div>
                    <div className={"col-1"}>
                        <div className="line-seprator-vertical"></div>
                    </div>
                    {
                        props.items?.data?.filter((val) => val?.id === props?.addItemInfo?.item_id)
                            ?.map((val, key) => {
                                return <div key={key} className="col-3">
                                    <div className={`inventory-itemBox1`}
                                         style={{
                                             textAlign: 'center',
                                             width: '100%',
                                             borderImageSlice: '1',
                                             marginBottom: '10px',
                                             display: 'flex',
                                             alignItems: 'center',
                                             justifyContent: 'center',
                                             position: 'relative',
                                             cursor: 'pointer',
                                         }}>
                                        <figure className={"m-0"}><img width={'100%'} src={val?.shop?.image_url}
                                                                       alt=""/></figure>
                                    </div>
                                </div>
                            })
                    }
                    <div className={"col-1"}>
                        <div className="line-seprator-vertical"></div>
                    </div>
                    <div className="col-3">
                        <h3>Character</h3>
                        <div className="guild-img-wrapper">
                            <figure>
                                <img
                                    src={character?.logo_url}
                                    className="guild-img img-fluid"
                                    alt=""/>
                            </figure>
                        </div>
                        <h4 className="guild-title">{character?.name}</h4>
                        <div className="guild-description mt-3">
                            <p>{character?.description}</p>
                        </div>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <div className="col-4">
                        <input
                            type="number"
                            onChange={(e)=>{
                                setItems((prevState)=>{
                                    let error = document.querySelector('#error');
                                    console.log(alertError);
                                    if( parseInt(e.target.value) > props?.addItemInfo?.max_qty){
                                        error.classList.remove('hide');
                                        error.classList.add('show');
                                    }else{
                                        error.classList.add('show');
                                        error.classList.remove('hide');
                                    }
                                    return {
                                    ...prevState,
                                        qty : ( parseInt(e.target.value) > props?.addItemInfo?.max_qty) ? props?.addItemInfo?.max_qty : e.target.value,
                                    }
                                })
                            }}
                            className={"form-control"}
                            value={items?.qty}
                            min={0}
                            name="qty"
                            max={props?.addItemInfo?.max_qty}
                            id=""
                        />
                        <p
                            id={'error'}
                            className={"text-danger hide"}>
                            {'maximum quantity is '+props?.addItemInfo?.max_qty}
                        </p>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className={"btn btn-success"} onClick={closeModal}>Cancel</Button>
                {
                    isloading ?
                        <Button className={"btn btn-success"} type={"button"}>
                            <i className={"fas fa-spinner fa-spin"}></i>
                        </Button>
                        :
                        <Button type={"button"} onClick={submitHandler} className={"btn btn-success"}>Add Item</Button>
                }
            </Modal.Footer>
        </Modal>
    );
}

const mapStateToProps = (state) => {
    return {
        token: state.Auth.token,
        items: state.Inventory.items,
        characters: state.UserCharacter.characters,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getGuild: (id) => dispatch(getGuildRequest(id)),
        getItems: (url = null, search = null) => dispatch(getItemsRequest(url, search)),
        joinGuild: (data) => dispatch(joinGuildRequest(data)),
        setItemGuild: (data) => dispatch(setItemGuildRequest(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InventoryItemGuild);
