import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

const Navigation = (props) => {

    return (
        <div className="row mt-5 justify-content-center mr-5">
            {
                (props?.links.length > 0) && props?.links?.map((val,key)=>{
                    return <button key={key} type={"button"} disabled={(val?.url == null) && true }
                                   onClick={()=>(val?.url && props.get(val?.url))}
                                   className="btn customButton mx-2" dangerouslySetInnerHTML={{__html: val?.label}} />
                })
            }
        </div>
    );
}


const mapStateToProps = (state) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Navigation);