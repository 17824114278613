import React from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import Logo from "../assets/img/logo.png";
import {Link, useNavigate} from "react-router-dom";
import {logout} from "../redux/Auth/action";
import {Nav, NavDropdown, Navbar} from "react-bootstrap";
import {readAllNotificationsRequest} from "../redux/Site/action";
import {readAllNotificationGuildRequest} from "../redux/Guild/action";
import {getItemsRequest} from "../redux/Craft/action";

const CustomNavTitle = ({notifications}) => {
    const dispatch = useDispatch();
    // Your custom component JSX goes here
    return (
        <div>
            <p className={"pdlFont"}>
                <i className={"text-bold fa fa-bell fa-2x"}>
                    {notifications?.filter((val, key) => val?.is_read === 0).length >
                        0 && <span className={"text-danger"}>*</span>}
                </i>
            </p>
        </div>
    );
};
const AuthHeader = (props) => {
    const navigator = useNavigate();
    const dispatch = useDispatch();
    const notifications = useSelector((state) => state?.Site?.notifications);
    const items = useSelector((state) => state?.AuthHeader?.items);

    return (
        <div className="nav-menu">
            <ul className="menus">
                {props.islogin && (
                    <>
                        <li className="menu-items">
                            <Link className="menu-links" to="/">
                                Home
                            </Link>
                        </li>
                        <li className="menu-items dropdown">
                            <NavDropdown id="nav-dropdown-dark-example" title="Profile">
                                <NavDropdown.Item onClick={() => navigator("/my-pets")}>
                                    My Pets
                                </NavDropdown.Item>
                                <NavDropdown.Item onClick={() => navigator("/room")}>
                                    My Rooms
                                </NavDropdown.Item>
                                <NavDropdown.Item onClick={() => navigator("/profile")}>
                                    My Profile
                                </NavDropdown.Item>
                                <NavDropdown.Item onClick={() => navigator("/edit-profile")}>
                                    Edit Profile
                                </NavDropdown.Item>
                                <NavDropdown.Item onClick={() => navigator("/crafting")}>
                                    My Crafts
                                </NavDropdown.Item>
                                <NavDropdown.Item onClick={() => navigator("/fish-inventory")}>
                                    Fish Inventory
                                </NavDropdown.Item>
                            </NavDropdown>
                        </li>
                        {/*<li className="menu-items">*/}
                        {/*    <Link className="menu-links" to="/profile">Profile</Link>*/}
                        {/*</li>*/}
                        <li className="menu-items dropdown">
                            <NavDropdown id="nav-dropdown-dark-example" title="Guild">
                                <NavDropdown.Item onClick={() => navigator("/guild")}>
                                    Guild List
                                </NavDropdown.Item>
                                <NavDropdown.Item onClick={() => navigator("/guild/make")}>
                                    Create Guild
                                </NavDropdown.Item>
                                <NavDropdown.Item
                                    onClick={() => {
                                        dispatch(readAllNotificationGuildRequest());
                                        navigator("/guild/notification");
                                    }}
                                >
                                    Notification
                                    {props?.guildNotification?.filter((val) => val?.is_read === 1)
                                        .length > 0 && (
                                        <span className={"text-danger h6 ml-1"}>*</span>
                                    )}
                                </NavDropdown.Item>
                            </NavDropdown>
                        </li>

                        <li className="menu-items">
                            <Link className="menu-links" to="/characters">
                                Characters
                            </Link>
                        </li>
                    </>
                )}
            </ul>

            <ul className="menus">
                {props.islogin ? (
                    <>
                        {/*<li className="menu-items">*/}
                        {/*    <Link className="menu-links" to="/profile">Auction</Link>*/}
                        {/*</li>*/}
                        <li className="menu-items dropdown notificationDropdown">
                            <NavDropdown
                                id="nav-dropdown-dark-example"
                                onClick={() => dispatch(readAllNotificationsRequest())}
                                title={<CustomNavTitle notifications={notifications}/>}
                            >
                                {notifications
                                    ?.filter((val, key) => key >= 0 && key <= 7)
                                    ?.map((val, key) => (
                                        <NavDropdown.Item
                                            key={key}
                                            className="display d-flex flex-row justify-content-between align-items-center border border-1 border-white"
                                        >
                                            <div>
                                                <i
                                                    className={`fa fa-2x ${
                                                        val?.is_read === 0 ? "fa-bell" : "fa-bell-o"
                                                    }`}
                                                ></i>
                                            </div>
                                            <div>
                                                <p className={"text-white m-0 ml-2"}>{val?.message}</p>
                                            </div>
                                        </NavDropdown.Item>
                                    ))}
                                {notifications.length > 0 && (
                                    <NavDropdown.Item
                                        onClick={() => navigator("/notification")}
                                        className="display d-flex flex-row justify-content-center align-items-center border border-1 border-white"
                                    >
                                        View All
                                    </NavDropdown.Item>
                                )}

                                {notifications.length <= 0 && (
                                    <NavDropdown.Item
                                        className="display d-flex flex-row justify-content-center align-items-center border border-1 border-white">
                                        No Notification Found
                                    </NavDropdown.Item>
                                )}
                            </NavDropdown>
                        </li>
                        <li className="d-none menu-items dropdown">
                            <div className="icons all-notification-stuff">
                                <div className="notification">
                                    {/*<a href="#">*/}
                                    {/*  <div className="notBtn" href="#">*/}
                                    {/*    /!* <!--Number supports double digets and automaticly hides itself when there is nothing between divs --> *!/*/}
                                    {/*    /!* <div className="number">2</div> *!/*/}
                                    {/*    <i className="fa fa-bell" aria-hidden="true"></i>*/}
                                    {/*    <div className="box pt-5">*/}
                                    {/*      <div >*/}
                                    {/*          */}
                                    {/*      </div>*/}
                                    {/*    </div>*/}
                                    {/*  </div>*/}
                                    {/*</a>*/}
                                </div>
                            </div>
                        </li>

                        <li className="menu-items dropdown">
                            <NavDropdown id="nav-dropdown-dark-example" title="Auction">
                                <NavDropdown.Item onClick={() => navigator("/auction")}>
                                    Auction List
                                </NavDropdown.Item>
                                <NavDropdown.Item onClick={() => navigator("/auction-inventory")}>
                                    Auction Inventory
                                </NavDropdown.Item>
                                <NavDropdown.Item onClick={() => navigator("/my-bids")}>
                                    My Bids
                                </NavDropdown.Item>
                                <NavDropdown.Item onClick={() => navigator("/auction/my")}>
                                    My Auctions
                                </NavDropdown.Item>
                            </NavDropdown>
                        </li>

                        <li className="menu-items">
                            <Link className="menu-links" to={"/inventory"}>
                                Inventory
                            </Link>
                        </li>
                        <li className="menu-items">
                            <Link className="menu-links" to="/shop">
                                Shop
                            </Link>
                        </li>

                        <li className="menu-items">
                            <a onClick={props.logout} className="menu-links">
                                Logout
                            </a>
                        </li>
                    </>
                ) : (
                    <>
                        <li className="menu-items">
                            <a className="menu-links" href="">
                                help
                            </a>
                        </li>
                        <li className="menu-items">
                            <Link className="menu-links" to={"/signup"}>
                                sign up
                            </Link>
                        </li>
                        <li className="menu-items">
                            <Link className="menu-links" to={"/login"}>
                                log in
                            </Link>
                        </li>
                    </>
                )}
            </ul>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        guildNotification: state.Guild.notification,
        islogin: state.Auth.islogin,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => dispatch(logout()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthHeader);
