import {
    SET_CRAFT_ITEM,
    SET_CRAFT_ITEMS,
} from "../constants";
import { headersWithToken} from "../headers";
import {buyItemAPi, getCraftItemAPi, getCraftItemsAPi, getItemsAPi, makeCraftItemApi} from "../apis";
import axios from "axios";
import {setAlert} from "../Alert/action";
import {responseError} from "../errorHandler";


export const setItems = (data) => {
    return {
        type: SET_CRAFT_ITEMS,
        payload: data
    }
}

export const getItemsRequest = (url  = null,searchQuery = '') => {
    return async (dispatch, getState) => {
        try {
            let token = getState().Auth.token;
            let link = url? url :  getCraftItemsAPi+(searchQuery? searchQuery : '');
            let response = await axios.get(link, headersWithToken(token));
            dispatch(setItems(response.data.response.data));
        } catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    }
}

export const setItem = (data) => {
    return {
        type: SET_CRAFT_ITEM,
        payload: data
    }
}

export const getItemRequest = (id) => {
    return async (dispatch, getState) => {
        try {
            let token = getState().Auth.token;
            let response = await axios.get(getCraftItemAPi(id), headersWithToken(token));
            dispatch(setItem({
                ...response.data.response.data?.shop,
                myItems : response.data.response.data?.myItems
            }));
        } catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    }
}


export const createCraftItemRequest = (data) => {
    return async (dispatch, getState) => new Promise(async (resolve, reject)=>{
        try {
            let token = getState().Auth.token;
            let response = await axios.post(makeCraftItemApi,data, headersWithToken(token));
            dispatch(setAlert([{message : 'crafting successfully done' ,color : 'success'}]));
            return resolve(true);
        } catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
            return reject(false);
        }
    });
}