import React, {useEffect, useState} from 'react';
import {Route, Routes, useNavigate} from "react-router-dom";
import {connect, useDispatch, useSelector} from "react-redux";
import {Button, Modal} from "react-bootstrap";
import Profile from '../../assets/img/profile.jpg';
import {acceptRequest, rejectRequest, resetTime, resetTrade, setNewTradeRequest} from "../../redux/TradeRequest/action";
import {showLoader, startTimer} from "../../redux/helpers";
import {pusher} from "../../redux/pusherConfig";
import {addPlayerRequest, joinGuildRequest} from "../../redux/Guild/action";
import {toast} from "react-toastify";
import axios from "axios";
import {headersWithToken} from "../../redux/headers";
import {getGuildPlayerApi} from "../../redux/apis";
import {
    applyGemGlyphsRequest, assignItemRequest,
    getGemGlyphsRequest,
    getItemRequest,
    removeGemGlyphsItemRequest
} from "../../redux/Inventory/action";
import {getMyCharactersRequest} from "../../redux/UserCharacter/action";
import PlayerTop from "../../assets/img/playertop.png";
import PlayerBottom from "../../assets/img/playerbottom.png";

function AssignCharacters(props) {

    const navigator = useNavigate();
    const dispatch = useDispatch();
    const [character , setCharacter] = useState(0);
    const [isloading , setIsloading] = useState(false);

    const get = async () => {
        await props.getCharacter();
    }

    const select = async () => {
        setIsloading(true);
        await dispatch(assignItemRequest({
            character_id : character,
            id : props?.id,
        }))
            .then(()=>{
                setIsloading(false);
                closeModal();
            })
            .catch(()=>{
                setIsloading(false);
            })
    }


    const closeModal = () => {
        props.setOpen(false);
    }

    useEffect(()=>{
        get();
    },[])

    return (
        <Modal
            show={props.open}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title className={"mt-4"} id="contained-modal-title-vcenter">
                    <h2>
                        Assign Character
                    </h2>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={"row mx-3"}>
                    {
                        props.characters?.map((val,key)=>(
                            <div className="col-lg-3 my-1" key={key} style={{
                                cursor :'pointer',
                            }} onClick={()=>setCharacter(val?.id)}>
                                {/*<div className="forTop-border py-1">*/}
                                {/*    <figure><img className="w-100" src={PlayerTop} alt=""/></figure>*/}
                                {/*</div>*/}
                                <div className="Player-statusDv" style={{
                                    border : `2px solid ${(character == val?.id)? '#E3BF4B' : '#2f2f2f' }`
                                }}>
                                    <div>
                                        <figure>
                                            <img src={val?.logo_url} height={200} style={{objectFit:"contain"}} width={'100%'} alt=""/>
                                        </figure>
                                    </div>
                                    <div className="head">
                                        <h3 className="title text-center">
                                            {val?.name}
                                        </h3>
                                    </div>
                                </div>
                                {/*<div className="forBottom-border py-1">*/}
                                {/*    <figure><img className="w-100" src={PlayerBottom} alt=""/></figure>*/}
                                {/*</div>*/}
                            </div>
                        ))
                    }
                </div>
                <div className={"mt-5 d-flex flex-row justify-content-end align-items-center mb-5"}>
                    <Button className={"btn btn-success"} onClick={closeModal}>Cancel</Button>
                    {
                        isloading ?
                            <Button className={"btn btn-success"} type={"button"}>
                                <i className={"fa fa-spinner fa-spin"}></i>
                            </Button>
                            :
                            <Button type={"button"} onClick={select} className={"btn btn-success"}>Assign</Button>
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>
    );
}

const mapStateToProps = (state) => {
    return {
        characters : state.UserCharacter.characters,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getCharacter : () => dispatch(getMyCharactersRequest()),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(AssignCharacters);
