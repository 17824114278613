import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import Logo from "../../components/Logo";
import '../../assets/css/guildcreation.css';
import AuthHeader from "../../components/AuthHeader";
import {useNavigate, useParams} from "react-router-dom";
import {
    addItemInTrade,
} from "../../redux/Trade/action";
import {showLoader} from "../../redux/helpers";
import PDLBorder from "../../components/PDLBorder";
import GuildCreation from '../../assets/img/guildcreation.png';
import {createGuildRequest, getPlayersRequest} from "../../redux/Guild/action";

const GuildMake = (props) => {

    const params = useParams();
    const navigator = useNavigate();
    const [ leaders , setLeaders ] = useState([]);
    const [ coLeaders , setCoLeaders ] = useState([]);
    const [ players , setPlayers ] = useState([]);
    const [image, setImage] = useState(null);

    const [form , setForm] = useState({
        name : '',
        description : '',
        co_leader_id : '',
        leader_id : '',
        logo : null,
        players : [],
    });

    const onChangeFile = (event) => {
        let files = event.target.files[0];
        setForm((preVal) => {
            return {
                ...preVal,
                logo: files,
            }
        });

        let output = document.getElementById('output');
        output.src = URL.createObjectURL(files);
        setImage(output.src);
        output.onload = function () {
            URL.revokeObjectURL(output.src) // free memory
        }
    }

    const get = async () => {
        showLoader('flex');
        props.getPlayers()
            .then((data)=>{
                setLeaders(data?.leaders || []);
                setCoLeaders(data?.coLeaders || []);
                // setPlayers(data?.coLeaders || []);
                showLoader('none');
            })
            .catch((e)=>{
                showLoader('none');
            });
    }

    const onChangeHandler = (event) => {
        let {name , value} = event.target;
        if(name === "co_leader_id"){
            setPlayers(coLeaders.filter((val)=>val?.id != value));
        }

        setForm((preVal) => {
            return {
                ...preVal,
                [name]: value,
            }
        });
    }

    const onSubmitHandler = (event) =>{
        event.preventDefault();
        let formData = new FormData();
        formData.append('logo',form.logo);
        formData.append('name',form.name);
        formData.append('description',form.description);
        formData.append('co_leader_id',form.co_leader_id);
        formData.append('leader_id',form.leader_id);
        console.log(form);
        showLoader('flex');
        props.createGuild(formData)
            .then(()=>{
                showLoader('none');
                navigator('/guild');
            })
            .catch(()=>{
                showLoader('none');
            });
    }

    useEffect(() => {
        get();
        return () => {
        }
    }, [])

    return (
        <section className="fram-sec">
            <div className="frame-wrapper">
                {/* Border*/}
                <PDLBorder/>
                {/*Logo*/}
                <Logo/>

                {/*Menus*/}
                <div className="row">
                    {/*<div className="col-md-7">*/}

                    {/*</div>*/}
                    <div className="col-md-12">
                        <div className="Menus text-right">
                            <AuthHeader/>
                        </div>
                    </div>
                </div>


                <section className="character">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3"></div>
                            <div className="col-lg-6">
                                <div className="character-creation text-center">
                                    <figure>
                                        <img src={GuildCreation} className="img-fluid" alt="" />
                                    </figure>
                                </div>
                                <div className="character-form-wrapper">
                                    <form onSubmit={onSubmitHandler}>
                                        <div className="from-group row">
                                            <div className="col-md-6">
                                                <label htmlFor="GuildName">Guild Name</label>
                                                <input type="text" onChange={onChangeHandler} required={true} name={'name'}
                                                className="form-control" value={form.name}
                                                placeholder="Type here" />
                                            </div>
                                        </div>
                                        <div className="form-group my-3">
                                            <textarea name="description" defaultValue={form.description} required={true} placeholder="Description ..."
                                            className="form-control" onChange={onChangeHandler}
                                            cols="30" rows="8"></textarea>
                                        </div>

                                        {/*<div className="form-group row mt-4">*/}
                                        {/*    <div className="col-md-4">*/}
                                        {/*        <div className="input-images">*/}
                                        {/*            <div className="image-uploader" id={"output"} style={*/}
                                        {/*                image && {*/}
                                        {/*                    backgroundImage: `url('${image}')`,*/}
                                        {/*                }*/}
                                        {/*            }>*/}
                                        {/*                <label htmlFor="customFile">*/}
                                        {/*                    <div className="upload-text">*/}
                                        {/*                        /!*<i className="material-icons">cloud_upload</i>*!/*/}
                                        {/*                        <span>Upload logo</span>*/}
                                        {/*                    </div>*/}
                                        {/*                </label>*/}
                                        {/*                <input type="file"*/}
                                        {/*                       required={true}*/}
                                        {/*                       onChange={onChangeFile}*/}
                                        {/*                       id="customFile"*/}
                                        {/*                       name="logo"/>*/}
                                        {/*                <div className="uploaded"></div>*/}

                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}

                                        <div className="form-group row">
                                            <div className="col-lg-6">
                                                <select name="leader_id" onChange={onChangeHandler} defaultValue={form.leader_id} required={true}
                                                className={"form-control inputCustomHeight character-name"}>
                                                    <option value="" hidden>Select Guild Leader</option>
                                                    {
                                                        leaders.map((val,key)=>{
                                                            return <option value={val?.id} key={key}>{val?.name}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div className="col-lg-6">
                                                <select name="co_leader_id" onChange={onChangeHandler} defaultValue={form.co_leader_id} required={true}
                                                        className={"form-control inputCustomHeight character-name"}>
                                                    <option value="" hidden>Select Co-Leader</option>
                                                    {
                                                        coLeaders.map((val,key)=>{
                                                            return <option value={val?.id} key={key}>{val?.name}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            {
                                                (players.length > 0) &&
                                                <div className="col-lg-6">
                                                    <select name="players" onChange={onChangeHandler} required={true}
                                                            className={"form-control inputCustomHeight character-name"}>
                                                        <option value="" hidden>Search Player</option>
                                                        {
                                                            players.map((val,key)=>{
                                                                return <option value={val?.id} key={key}>{val?.name}</option>
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            }
                                            <div className="col-lg-6">
                                                <button type={"submit"} className="btn">create guild</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-lg-3"></div>
                        </div>
                    </div>
                </section>

            </div>
        </section>
    );
}


const mapStateToProps = (state) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getPlayers : () => dispatch(getPlayersRequest()),
        createGuild : (data) => dispatch(createGuildRequest(data)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(GuildMake);