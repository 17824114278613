import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import '../assets/css/warrior2.css';
// import "";
// import "slick-carousel/slick/slick-theme.css";
import SlikSlider from "react-slick";

const Slider = (props) => {

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay : true,
        arrows : false,
    };

    return (
        <>
            <section className="what-happens mt-5 mb-5">
                <div className="happens-bg">
                    <div className="container">
                        <SlikSlider {...settings}>
                            {
                                props.slider.map((val,key)=>(
                                    <div key={key}>
                                        <div style={{
                                            backgroundImage : `url('${val?.image_url}')`,
                                            backgroundRepeat: 'no-repeat',
                                            height: '25em',
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            padding : '50px',
                                        }}>
                                            <h4 className={""}>{val?.title}</h4>
                                            <p className={"mt-4"}>{val?.content}</p>
                                            <div className={"mt-5"}>
                                                <a className={"customButton mt-4"} href={val?.button_link}>{val?.button_name}</a>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }

                        </SlikSlider>
                    </div>
                </div>
            </section>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        slider: state.Site.slider,
    }
}


export default connect(mapStateToProps)(Slider);