import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import PDLBorder from "../../components/PDLBorder";
import Logo from "../../components/Logo";
import '../../assets/css/my-pets.css';
import AuthHeader from "../../components/AuthHeader";
import inventorybg from '../../assets/img/inventorybg.png';
import {getItemsRequest, getPetsItemsRequest} from "../../redux/Shop/action";
import {setLoader} from "../../redux/Site/action";
import Loader from "../NotFound/Loader";
import {Link} from "react-router-dom";
import {showLoader} from "../../redux/helpers";
import ItemWrapper from "../../components/ItemWrapper";
import {addFavoritePetRequest} from "../../redux/Shop/action";

const Pets = (props) => {

    const [ loading , setLoading ] = useState(null);
    const [search , setSearch] = useState(null);
    const [ category , setCategory ] = useState('all');

    const get = async () => {
        props.setLoader(true);
        await props.getItems();
        props.setLoader(false);
    }

    const pagination = async(url) => {
        showLoader('flex');
        await props.getItems(url);
        showLoader('none');
    }

    const onSearchHandler = async (event) => {
        event.preventDefault();
        showLoader('flex');
        await props.getItems(null,`?search=${search}`);
        showLoader('none');
    }

    const onFavouriteSelectHandler = async (data) =>{
        await props?.addFavoritePet(data);
    }

    useEffect(() => {
        get();
        return () => {
        }
    }, []);


    if (props.loader) return <Loader/>

    return (
        <div id={'mypets'}>
        <section className="fram-sec" style={{
            // backgroundImage : `url('${ (props?.setting?.pet_background)? props?.setting?.pet_background_url : inventorybg }')`,
            // backgroundSize: '100% 100%',
            // backgroundRepeat: 'no-repeat',
            // padding: '3rem 2rem',
            // margin: '4.5rem 2rem',
            // position: 'relative',
        }}>
            <div className="frame-wrapper-pets" style={{
                backgroundImage : `url('${ (props?.setting?.pet_background)? props?.setting?.pet_background_url : inventorybg }')`,
                backgroundSize: '100% 100%',
                backgroundRepeat: 'no-repeat',
                height: 'auto',
                padding: '2rem 2rem 3rem',
                margin: '4.5rem 2rem',
                position: 'relative'
            }}>

                {/* Border*/}
                <PDLBorder/>
                {/*Logo*/}
                <Logo/>

                {/*Menus*/}
                <div className="row">
                    {/*<div className="col-md-7">*/}

                    {/*</div>*/}
                    <div className="col-md-12">
                        <div className="Menus text-right">
                            <AuthHeader/>
                        </div>
                    </div>
                </div>
                {/*Menus*/}

                <section className="mycharacter-sec">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-2"></div>
                            <div className="col-lg-8">
                                <div className="profile-creation text-center animate__animated animate__fadeInDown">
                                    <h3>Pet Stable</h3>
                                </div>

                            </div>
                            <div className="col-lg-2"></div>
                        </div>

                        <div className="row justify-content-center mt-2">
                            <form className={"col-lg-8"}
                                onSubmit={onSearchHandler}
                            >
                                <div className="form-group d-flex justify-content-center align-content-center ">
                                    <input type="text" required={true}
                                           onChange={(e)=>setSearch(e.target.value)}
                                           className="form-control pdlCustomInput w-75"
                                           value={search}
                                           placeholder="Search item here" />
                                    {
                                        search &&
                                        <a
                                            // type={"button"}
                                                onClick={()=>{setSearch('');get();}}
                                                // className="btn m-0 customButton"
                                                className="p-1"
                                        >
                                            <i className={"text-danger fa fa-times"}></i>
                                        </a>
                                    }
                                </div>
                            </form>
                        </div>

                        {
                            props.items?.data.length > 0 ?
                                <>
                                    <div className="row container mt-3">
                                        {
                                            props.items?.data.map((val, key) => {
                                                return <div key={key} className="col-lg-3">
                                                    <div className="">
                                                        {/*mychar-wrapper*/}
                                                        <div className="black-img-wrapper text-right">
                                                            <figure className={"mr-1"}>
                                                                <img src={val?.shop?.image_url}
                                                                     className="animate__animated animate__fadeInDown img-fluid"
                                                                     alt=""/>
                                                                {
                                                                    <i className={"fa fa-star"}
                                                                       onClick={()=>onFavouriteSelectHandler({
                                                                           is_pet_favourite : (val?.is_pet_favourite === 1)? 0 : 1,
                                                                           id : val?.id
                                                                       })}
                                                                       style={{
                                                                           color : `${ (val?.is_pet_favourite === 1)? '#E3BF4B' : '#fff' }`,
                                                                           position : "absolute",
                                                                           top : 11,
                                                                           right : 32,
                                                                           fontSize : 30
                                                                       }}></i>
                                                                }
                                                            </figure>

                                                        </div>
                                                        <div className="my-char-content">
                                                            <div className="player-name row">
                                                                <p className="col-8 Pname-title">{val?.shop?.title}</p>
                                                            </div>
                                                            <Link  to={'/inventory/'+val?.id} className="btn customButton">View</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            })
                                        }

                                    </div>

                                    <div className="row mt-5 justify-content-center">
                                        {
                                            (props.items?.data.length > 0) && props.items?.links?.map((val,key)=>{
                                                return <button key={key} type={"button"} disabled={(val?.url == null) && false }
                                                        onClick={()=>(val?.url && pagination(val?.url))}
                                                        className="btn customButton mx-2" dangerouslySetInnerHTML={{__html: val?.label}} />
                                            })
                                        }
                                    </div>
                                </>
                                :
                                <div className={"row mt-5 justify-content-center"}>
                                    <div className="profile-creation text-center animate__animated animate__fadeInDown">
                                        <h3>Items Not Found</h3>
                                    </div>
                                </div>
                        }

                    </div>
                </section>

            </div>
        </section>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        setting : state.Site.setting,
        items: state.Shop.items,
        loader: state.Site.loader,
        category : state.Site.category,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setLoader: (bool) => dispatch(setLoader(bool)),
        // addFavoritePet : (data) => dispatch(addFavoritePetRequest(data)),
        addFavoritePet : (data) => dispatch(addFavoritePetRequest(data)),
        getItems: (url = null,search=null) => dispatch(getPetsItemsRequest(url,search)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Pets);