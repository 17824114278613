import { SET_CRAFT_ITEM, SET_CRAFT_ITEMS } from "../constants";

const initState = {
    items : [],
    item : null,
}


const Craft = (state = initState , action) => {
    switch (action.type) {

        case SET_CRAFT_ITEMS :
            return {
                ...state,
                items : action.payload,
            }
        case SET_CRAFT_ITEM :
            return {
                ...state,
                item : action.payload,
            }

        default: return state;
    }
}

export default Craft;