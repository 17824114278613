import React, {useEffect, useState} from 'react';
import {connect, useDispatch} from "react-redux";
import PDLBorder from "../../components/PDLBorder";
import Logo from "../../components/Logo";
import AuthHeader from "../../components/AuthHeader";
import PlayerBottom from '../../assets/img/playerbottom.png';
import PlayerTop from '../../assets/img/playertop.png';
import {
    getFishItemRequest,
    getItemRequest,
    removeGemGlyphsItemRequest,
    updateItemRequest
} from "../../redux/Inventory/action";
import {setLoader} from "../../redux/Site/action";
import Loader from "../NotFound/Loader";
import {Link, useNavigate, useParams} from "react-router-dom";
import NotFound from "../NotFound/NotFound";
import '../../assets/css/inventory-detail.css';
import {showLoader} from "../../redux/helpers";
import GemsGlyphs from "../../components/Modals/gems_glyphs";
import Confirm from "../../components/Modals/Confirm";
import PowerConfirm from "../../components/Modals/PowerConfirm";
import AssignCharacters from "../../components/Modals/AssignCharacters";
import AddAuction from "../../components/Modals/AddAuction";
import axios from "axios";
import {getFishMoveTOInventoryAPi} from "../../redux/apis";
import {headersWithToken} from "../../redux/headers";
import {responseError} from "../../redux/errorHandler";
import {setAlert} from "../../redux/Alert/action";


const ItemsDetails = (props) => {

    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading , setLoading] = useState(false);

    const get = async () => {
        props.setLoader(true);
        await props.getItem(params?.id);
        props.setLoader(false);
    }

    const moveToInventory = (id) => {
        setLoading(true);
        axios.get(getFishMoveTOInventoryAPi+'/'+id,headersWithToken(props?.token))
            .then((resp)=>{
                setLoading(false);
                get();
                navigate('/fish-inventory');
            })
            .catch((e)=>{
                let error = responseError(e);
                setLoading(false);
                dispatch(setAlert(error));
            })
    }

    useEffect(() => {
        return () => {
        }
    }, []);

    useEffect(() => {
        get();
    }, [params?.id]);

    if (props.loader) return <Loader/>

    if (!props.item) return <NotFound/>

    return (
        <section className="fram-sec">
            <div className="frame-wrapper">

                {/* Border*/}
                <PDLBorder/>
                {/*Logo*/}
                <Logo/>

                {/*Menus*/}
                <div className="row">
                    {/*<div className="col-md-7">*/}

                    {/*</div>*/}
                    <div className="col-md-12">
                        <div className="Menus text-right">
                            <AuthHeader/>
                        </div>
                    </div>
                </div>
                {/*Menus*/}
                <section className="inventory-detailed">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-5">
                                <div className="forTop-border py-1">
                                    <figure><img className="w-100" src={PlayerTop} alt=""/></figure>
                                </div>
                                <div className="Player-statusDv">
                                    <div className="head">
                                        <h3 className="title">
                                            Item Stats
                                        </h3>
                                    </div>
                                    <ul className="status-list">
                                        <li>
                                            <h4>Level</h4>
                                            <span>{props.item?.shop?.level}</span>
                                        </li>
                                        <br/>
                                        <li>
                                            <h4>Health</h4>
                                            {/*<span>{props.item?.health}*/}
                                            <span className={"text-success"}>({props.item?.shop?.health})</span>
                                            {/*</span>*/}
                                        </li>
                                        <br/>
                                        <li>
                                            <h4>Base</h4>
                                            {/*<span>{props.item?.base}*/}
                                            <span className={"text-success"}>({props.item?.shop?.base})</span>
                                            {/*</span>*/}
                                        </li>
                                        <br/>

                                        <li>
                                            <h4>Damage</h4>
                                            {/*<span>{props.item?.damage}*/}
                                            <span className={"text-success"}>({props.item?.shop?.damage})</span>
                                            {/*</span>*/}
                                        </li>
                                        <br/>

                                        <li>
                                            <h4>Armor Pen</h4>
                                            {/*<span>{props.item?.armor_pen}*/}
                                            <span className={"text-success"}>({props.item?.shop?.armor_pen})</span>
                                            {/*</span>*/}
                                        </li>
                                        <br/>

                                        <li>
                                            <h4>Armor Pen Resist</h4>
                                            {/*<span>{props.item?.armor_pen_resist}*/}
                                            <span className={"text-success"}>({props.item?.shop?.armor_pen_resist})</span>
                                            {/*</span>*/}
                                        </li>

                                        <br/>
                                        <li>
                                            <h4>Quantity</h4>
                                            <span>{props.item?.qty}</span>
                                        </li>

                                    </ul>
                                </div>
                                <div className="forBottom-border py-1">
                                    <figure><img className="w-100" src={PlayerBottom} alt=""/></figure>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="product-details">
                                    <div className="heading">
                                        <h1 className="title">{props.item?.shop?.title}</h1>
                                    </div>
                                    <div className="image-with-btn">
                                        <div className="img-box">
                                            <figure><img width={'100%'} src={props.item?.shop?.image_url} alt=""/></figure>
                                        </div>
                                    </div>
                                    {/*<div className="description">*/}
                                    {/*    <p>{props.item.}</p>*/}
                                    {/*</div>*/}
                                    <div className="description">
                                        <p>{props?.item?.shop?.description}</p>
                                    </div>


                                        <div className="button-group my-2">
                                            <Link to={'/fish-inventory'} className="btn customButton">Back</Link>
                                            <button type={"button"}
                                                    onClick={()=>moveToInventory(props?.item?.id)}
                                                    className="btn customButton">
                                                { (loading) ? <i className={"fa fa-spin fa-spinner"}></i> : 'Move to Inventory' }
                                            </button>
                                        </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </section>
    );
}

const mapStateToProps = (state) => {
    return {
        item: state.Inventory.item,
        loader: state.Site.loader,
        token: state.Auth.token,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setLoader: (bool) => dispatch(setLoader(bool)),
        getItem: (id) => dispatch(getFishItemRequest(id)),
        updateItem : (data) => dispatch(updateItemRequest(data)),
        removeGemGlyphsItem : (id) => dispatch(removeGemGlyphsItemRequest(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemsDetails);