import React, {useEffect, useState} from 'react';
import {Link, Route, Routes, useNavigate} from "react-router-dom";
import {connect, useDispatch, useSelector} from "react-redux";
import {Button, Modal} from "react-bootstrap";
import Profile from '../../assets/img/profile.jpg';
import {acceptRequest, rejectRequest, resetTime, resetTrade, setNewTradeRequest} from "../../redux/TradeRequest/action";
import {onInputChangeHandler, showLoader, startTimer} from "../../redux/helpers";
import {pusher} from "../../redux/pusherConfig";
import {
    addPlayerRequest,
    getGuildRequest,
    joinGuildRequest, reverseItemRequest,
    sendGuildItemToPlayer,
    setItemGuildRequest
} from "../../redux/Guild/action";
import {toast} from "react-toastify";
import axios from "axios";
import {headersWithToken} from "../../redux/headers";
import {getGuildPlayerApi} from "../../redux/apis";
import {addBagsRequest, getItemsRequest} from "../../redux/Inventory/action";

function SendInventoryModal(props) {

    const [reverseLoading, setReverseLoading] = useState(false);
    const [isloading, setIsloading] = useState(false);
    const [characterId, setCharacterId] = useState(0);
    const [qty, setQty] = useState(0);
    const guild = useSelector(state => state?.Guild?.guild);
    const { selected_character } = useSelector((state) => state?.Auth);
    const navigator = useNavigate();
    const dispatch = useDispatch();

    const closeModal = () => {
        props.setOpen(false);
    }

    const submitHandler = async () => {
        if(qty <= 0){
            toast('quantity must be greater than 1');
            return true;
        }
        setIsloading(true);
        await dispatch(sendGuildItemToPlayer({
            character_id: characterId,
            guild_id: guild?.id,
            item_id: props?.inventoryId,
            selected_character : selected_character,
            qty : qty,
        }))
            .then((res) => {
                setIsloading(false);
                closeModal();
            })
            .catch((e) => {
                console.log(e);
                setIsloading(false);
            });
    }

    const reverseItemHandler = async () => {
        setReverseLoading(true);
        await dispatch(reverseItemRequest({
            character_id: characterId,
            guild_id: guild?.id,
            item_id: props?.inventoryId,
            selected_character : selected_character,
        }))
            .then((res) => {
                setReverseLoading(false);
                closeModal();
            })
            .catch((e) => {
                // console.log(e);
                setReverseLoading(false);
            });
    }

    return (
        <Modal
            show={props.open}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={closeModal}
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Send Inventory Item
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div className={"row d-flex justify-content-center"}>
                    <div className="auction-post-box row justify-content-center">
                        {
                            guild?.inventory?.filter((val,key)=> val?.id === props?.inventoryId )
                                ?.map((val, key) => (
                                    <div key={key} className="auction-item col-5">
                                    <figure className={"d-flex justify-content-center"}>
                                        <img
                                            src={val?.shop?.image_url}
                                            width={'25%'} alt=""
                                            className="img-fluid"/>
                                        </figure>
                                    <h3 className={"text-center"}>{val?.shop?.title}</h3>
                                </div>
                            ))
                        }
                        <div className="col-5">
                            <select name="character_id"
                                    onChange={(e) => setCharacterId(e.target.value)}
                                    className={"form-control inputCustomHeight pdlSelect character-name"}>
                                <option value="" hidden>Select Character</option>
                                {
                                    guild?.member.map((val, key) => {
                                        return <option key={key} value={val.character_id}>{val?.character?.name}</option>
                                    })
                                }
                            </select>
                            <div className={"row d-flex flex-row justify-content-center"}>
                                <label className="text-center">Qty : </label>
                                <input type="number" value={qty} placeholder={"quantity"}
                                       className={"inputCustomHeight form-control"}
                                       min={0} name={"qty"}
                                       onChange={(e)=>{
                                           console.log(guild?.inventory?.find((val)=> val?.id === props?.inventoryId));
                                           if(parseInt(e.target.value) >= guild?.inventory?.find((val)=> val?.id === props?.inventoryId)?.qty) {
                                               return setQty(
                                                   guild?.inventory?.find((val)=> val?.id === props?.inventoryId)?.qty || 0
                                               );
                                           }else {
                                               return setQty(parseInt(e.target.value));
                                           }
                                       }}
                                       max={props?.item?.qty}/>
                                <p className={"text-danger"}>
                                    {
                                        'maximum quantity is '
                                        +
                                        guild?.inventory?.find((val)=> val?.id === props?.inventoryId)?.qty || 0
                                    }
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </Modal.Body>
            <Modal.Footer className={"m-5 d-flex justify-content-center"}>
                <Button className={"btn btn-success"} onClick={closeModal}>Cancel</Button>
                {
                    reverseLoading ?
                        <button className={"btn btn-success"} type={"button"}>
                            <i className={"fas fa-spinner fa-spin"}></i>
                        </button>
                        :
                        <Button type={"button"} onClick={reverseItemHandler} className={"btn btn-success"}>Reverse Item</Button>
                }
                {
                    isloading ?
                        <button className={"btn btn-success"} type={"button"}>
                            <i className={"fas fa-spinner fa-spin"}></i>
                        </button>
                        :
                        <Button type={"button"} onClick={submitHandler} className={"btn btn-success"}>Send</Button>
                }
            </Modal.Footer>
        </Modal>
    );
}

export default SendInventoryModal;
