import React, {useEffect, useState} from 'react';
import {Route, Routes, useNavigate} from "react-router-dom";
import {connect, useDispatch} from "react-redux";
import {Button, Modal} from "react-bootstrap";
import Profile from '../../assets/img/profile.jpg';
import {acceptRequest, rejectRequest, resetTime, resetTrade, setNewTradeRequest} from "../../redux/TradeRequest/action";
import {showLoader, startTimer} from "../../redux/helpers";
import {pusher} from "../../redux/pusherConfig";
import {joinGuildRequest} from "../../redux/Guild/action";
import {toast} from "react-toastify";
import Loader from "../Loader";
import {miscNotesChanges} from "../../redux/UserCharacter/action";

function MiscNotes(props) {

    const dispatch = useDispatch();
    const [notes , setNotes] = useState("");
    const [loading , setLoading] = useState(false);

    const onSubmitHandler = () => {
        setLoading(true);
        dispatch(miscNotesChanges({
            id : props?.id,
            notes : notes,
        }))
            .then(()=>{
                setLoading(false);
                closeModal();
            })
            .catch(()=>{
                setLoading(false);
            })
    }


    const closeModal = () => {
        props.setOpen(false);
    }

    useEffect(() => {
        setNotes(props?.details?.character?.notes);
    }, [props.id]);

    return (
        <Modal
            show={props.open}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter" className={"h3"}>
                    Misc Stats Notes
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row justify-content-center">
                    <div className="col-10">
                        <textarea name=""
                        onChange={(e)=>setNotes(e.target.value)}
                        className={"inputCustomHeight form-control"}
                        defaultValue={notes}></textarea>
                    </div>
                </div>
                <div className="row justify-content-end">
                    <div className="col-6 mt-3 mr-3 mb-3">
                        <Button className={"btn btn-success"} onClick={closeModal}>Cancel</Button>
                        {
                            loading?
                                <Button className={"btn btn-success"} type={"button"}>
                                    <i className={"fas fa-spinner fa-spin"}></i> {" "}Saving
                                </Button>
                                :
                                <Button
                                    className={"btn btn-success"}
                                    onClick={onSubmitHandler}
                                    type={"button"}>Save Changes</Button>
                        }
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>

            </Modal.Footer>
        </Modal>
    );
}

const mapStateToProps = (state) => {
    return {
        details: state.UserCharacter.details,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MiscNotes);
