import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import PDLBorder from "../../components/PDLBorder";
import Logo from "../../components/Logo";
import '../../assets/css/auction.css';
import AuthHeader from "../../components/AuthHeader";
import auctionText from '../../assets/img/auction-txt.png';
import {getItemsRequest} from "../../redux/Auction/action";
import Loader from "../NotFound/Loader";
import {Link, useNavigate} from "react-router-dom";
import {showLoader} from "../../redux/helpers";
import Video from '../../assets/welcome.mp4';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const Auction = (props) => {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const items = useSelector((state) => state?.Auction?.items).sort( ( a , b ) => a?.time_left - b?.time_left );
    const loader = useSelector((state) => state.Site.loader);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        draggable: true
    };

    const get = async () => {
        // showLoader('flex');
        // dispatch(setLoader(true));
        // setLoading(true);
        await dispatch(getItemsRequest());
        // props.setLoader(false);
        // setLoading(false);
        // showLoader('none');
        // dispatch(setLoader(false));
    }

    const SliderCom = (props) => (
        <>
            {
                props?.length > 9 ?
                    <Slider {...settings}>{props?.children}</Slider>
                    :
                    <>{props?.children}</>
            }
        </>
    )


    useEffect(() => {
        get();
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, [3000])
        return () => {
        }
    }, []);

    // useEffect(()=>{
    //     if(items && items?.data && items?.data?.length >= 1){
    //         console.log("Start",items?.data);
    //         let middleIndex = ((typeof (items?.data?.length / 2) === 'float')? Math.round(items?.data?.length / 2) : (items?.data?.length / 2) ) -1 ;
    //         console.log("Middle Index",middleIndex);
    //         setMiddle(items?.data?.find((val,key)=> key === (middleIndex) ));
    //         console.log("Left",items?.data?.filter((val,key)=> key >= 0 && key < (middleIndex)));
    //         if( items?.data?.length >= 1 ) setLeft( items?.data?.filter((val,key)=> key >= 0 && key < (middleIndex)) );
    //         console.log("Right",items?.data?.filter((val,key)=> key > (middleIndex) && key < ( items?.data?.length - 1 )));
    //         if( items?.data?.length >= 1 ) setRight( items?.data?.filter((val,key)=> key > (middleIndex) && key < ( items?.data?.length - 1 )) );
    //     }
    // },[items])
    // console.log("List",[...Array(9 - items?.filter((val, key) => key >= (0*9) && key < ( (0+1) * 9)  ).length)]);

    if (loader) return <Loader/>

    return (
        <>
            {
                loading ?
                    <div className="welcome_video" id="WelcomeVdo"
                        // style={{display: (loading)?'block':'none'}}
                    >
                        <video id="AuctionVdo" width="100%" muted={true} height="100vh" autoPlay={true}>
                            <source src={Video} type="video/mp4"/>
                        </video>
                    </div>
                    :
                    <div className="auction-frame-wrapper auction_sec">

                        {/* Border*/}
                        <PDLBorder/>
                        {/*Logo*/}
                        <Logo/>

                        {/*Menus*/}
                        <div className="row">
                            {/*<div className="col-md-7">*/}

                            {/*</div>*/}
                            <div className="col-md-12">
                                <div className="Menus text-right">
                                    <AuthHeader/>
                                </div>
                            </div>
                        </div>
                        {/*Menus*/}

                        <div className="heading-wrapper text-center py-4">
                            <figure><img src={auctionText} alt="" className="img-fluid"/></figure>
                        </div>
                        <SliderCom length={items?.length}>
                            {
                                items?.length === 0 &&
                                [...Array(9)]?.map((val, key) => (
                                    <li key={key}>
                                        <div className="auction-box">
                                            <div className="txt">
                                                <h4>{''}</h4>
                                            </div>
                                            <div className="img-box">
                                                <figure>
                                                    {/*<img src="" alt="123" className="img-fluid" />*/}
                                                </figure>
                                            </div>
                                        </div>
                                    </li>
                                ))
                            }
                            {
                                [ ...Array( Math.ceil(items.length/9 ) ) ]?.map((num,index)=>(
                                    <section key={index} className="">
                                        <div className="container">
                                            <div id="single-card" className={`${(items?.length <= 9)?'for-single':''}`} >
                                                <ul className="auction-items">
                                                    {
                                                        items?.filter((val, key) => key >= (index*9) && key < ( (index+1) * 9) )?.map((val, key) => (
                                                            <li key={key}>
                                                                <Link to={`/auction/view/${val?.id}`}>
                                                                    <div className={"auction-box "+key}>
                                                                        <div className="txt">
                                                                            {/*<h4>{val?.shop?.title}</h4>*/}
                                                                        </div>
                                                                        <div className="img-box">
                                                                            <figure><img src={val?.shop?.image_url}
                                                                                         alt="123"
                                                                                         className="img-fluid"/></figure>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </li>
                                                        ))
                                                    }
                                                    {
                                                        [...Array(9 - items?.filter((val, key) => key >= (index*9) && key < ( (index+1) * 9)  ).length)]?.map((val, key) => (
                                                            <li key={key}>
                                                                <div className="auction-box">
                                                                    <div className="txt">
                                                                        <h4>{''}</h4>
                                                                    </div>
                                                                    <div className="img-box">
                                                                        <figure>
                                                                            {/*<img src="" alt="123" className="img-fluid" />*/}
                                                                        </figure>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </section>
                                ))
                            }
                        </SliderCom>

                    </div>
            }
                </>
                );
            }

            export default Auction;