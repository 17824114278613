import {
    ADD_PET_ITEM_TO_FAVOURITE,
    REMOVE_BAGS_ITEMS,
    RESET_NEW_GEMS_GLYPHS, RETURN_BAGS_ITEMS, SET_BAGS_ITEMS, SET_CHARACTER_IN_INVENTORY_ITEM, SET_GALLRY_ITEMS,
    SET_GEMS_GLYPHS,
    SET_INVENTORY_ITEM,
    SET_INVENTORY_ITEMS,
    SET_ITEM,
    SET_ITEMS, SET_NEW_BAGS_ITEMS, SET_NEW_GEMS_GLYPHS,
} from "../constants";
import {headers, headersWithToken} from "../headers";
import {
    addBagsApi, addFavoritePetApi,
    assignInventoryItemApi,
    buyItemAPi,
    doEnhanceApi, getAuctionInventoryAPi, getBagsApi, getFishInventoryAPi, getGallryInventoryApi,
    getInventoryAPi,
    getItemAPi,
    getItemsAPi, getPowerItems, getSingleAuctionInventoryAPi, getSingleFishInventoryAPi,
    getSingleInventoryAPi, powerItemApply, removeBagsApi, removePowerItem, returnToInventoryApi, sendToMainInventoryAPi,
    updateInventoryApi
} from "../apis";
import axios from "axios";
import {setAlert} from "../Alert/action";
import {responseError} from "../errorHandler";


export const setItems = (data) => {
    return {
        type: SET_INVENTORY_ITEMS,
        payload: data
    }
}

export const getItemsRequest = (url = null,search=null) => {
    return async (dispatch, getState) => {
        try {
            let token = getState().Auth.token;
            let link = url || getInventoryAPi + (search? '?search=' + search : '');
            let response = await axios.get(link, headersWithToken(token));
            dispatch(setItems(response.data.response.data));
        } catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    }
}
export const getAuctionItemsRequest = (url = null,search=null) => {
    return async (dispatch, getState) => {
        try {
            let token = getState().Auth.token;
            let link = url || getAuctionInventoryAPi + (search? '?search=' + search : '');
            let response = await axios.get(link, headersWithToken(token));
            dispatch(setItems(response.data.response.data));
        } catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    }
}
export const filterAuctionItemsByCategoryRequest = (category_id) => {
    return async (dispatch, getState) => {
        try {
            let link = getAuctionInventoryAPi+'?category_id='+category_id;
            let token = getState().Auth.token;
            let response = await axios.get(link, headersWithToken(token));
            dispatch(setItems(response.data.response.data));
        } catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    }
}
export const sendToInventoryRequest = (data) => {
    return async (dispatch, getState) => {
        try {
            let token = getState().Auth.token;
            let response = await axios.post(sendToMainInventoryAPi,data, headersWithToken(token));
        } catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    }
}
export const getAuctionItemRequest = (id) => {
    return async (dispatch, getState) => {
        try {
            let token = getState().Auth.token;
            let response = await axios.get(getSingleAuctionInventoryAPi(id), headersWithToken(token));
            dispatch(setItem(response.data.response.data,null));
        } catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    }
}

export const getFishItemsRequest = (url = null,search=null) => {
    return async (dispatch, getState) => {
        try {
            let token = getState().Auth.token;
            let link = url || getFishInventoryAPi + (search? '?search=' + search : '');
            let response = await axios.get(link, headersWithToken(token));
            dispatch(setItems(response.data.response.data));
        } catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    }
}

export const setGemGlyphs = (data) => {
    return {
        type: SET_GEMS_GLYPHS,
        payload: data
    }
}
export const getGemGlyphsRequest = () => {
    return async (dispatch, getState) => {
        try {
            let token = getState().Auth.token;
            let response = await axios.get(getPowerItems,headersWithToken(token));
            dispatch(setGemGlyphs(response.data.response.data));
        } catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    }
}

export const setGallryItem = (data) => {
    return {
        type: SET_GALLRY_ITEMS,
        payload: data
    }
}
export const getGallryRequest = (id) => {
    return async (dispatch, getState) => {
        try {
            let token = getState().Auth.token;
            let response = await axios.get(getGallryInventoryApi(id),headersWithToken(token));
            dispatch(setGallryItem(response.data.response.data));
        } catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    }
}

export const setNewGemGlyphs = (data) => {
    return {
        type: SET_NEW_GEMS_GLYPHS,
        payload: data
    }
}

export const applyGemGlyphsRequest = (data) => {
    return async (dispatch, getState) => new Promise(async (resolve, reject)=>{
        try {
            let token = getState().Auth.token;
            let response = await axios.post(powerItemApply,data,headersWithToken(token));
            dispatch(setNewGemGlyphs(response.data.response.data));
            return resolve(true);
        } catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
            return reject(false);
        }
    })
}

export const resetNewGemGlyphs = (id,stats) => {
    return {
        type: RESET_NEW_GEMS_GLYPHS,
        payload: {
            stats : stats,
            id : id
        }
    }
}

export const removeGemGlyphsItemRequest = (id) => {
    return async (dispatch, getState) => new Promise(async (resolve, reject)=>{
        try {
            let token = getState().Auth.token;
            let response = await axios.get(removePowerItem(id),headersWithToken(token));
            // dispatch(getItemRequest(id));
            dispatch(resetNewGemGlyphs(id,response.data.response.data));
            return resolve(true);
        } catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
            return reject(false);
        }
    })
}

export const filterItemsByCategoryRequest = (category_id) => {
    return async (dispatch, getState) => {
        try {
            let link = getInventoryAPi+'?category_id='+category_id;
            let token = getState().Auth.token;
            let response = await axios.get(link, headersWithToken(token));
            dispatch(setItems(response.data.response.data));
        } catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    }
}

export const setItem = (data,match_inventory) => {
    return {
        type: SET_INVENTORY_ITEM,
        payload: data,
        match_inventory : match_inventory,
    }
}

export const getItemRequest = (id) => {
    return async (dispatch, getState) => {
        try {
            let token = getState().Auth.token;
            let response = await axios.get(getSingleInventoryAPi(id), headersWithToken(token));
            dispatch(setItem(response.data.response.data?.inventory,response.data.response.data?.match_inventory));
        } catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    }
}
export const getFishItemRequest = (id) => {
    return async (dispatch, getState) => {
        try {
            let token = getState().Auth.token;
            let response = await axios.get(getSingleFishInventoryAPi(id), headersWithToken(token));
            dispatch(setItem(response.data.response.data,null));
        } catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    }
}

export const updateItemRequest = (data)=>{
    return async (dispatch, getState) => {
        try {
            let token = getState().Auth.token;
            let message ='';
            if(data?.item_for === 1) { message = 'item converted to PVP';}
            else if(data?.item_for === 0) { message = 'item converted to PVE';}
            else if(data?.item_for === 2) { message = 'Deselect mode sucessfully';}
            
            let response = await axios.post(updateInventoryApi,data, headersWithToken(token));
            dispatch(setItem(response.data.response.data?.inventory));
            dispatch(setAlert([
                {
                    message : message,
                    color : 'success'
                }
            ]));
        } catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    }
}

export const doEnhanceRequest = (data)=>{
    return async (dispatch, getState) => new Promise(async (resolve, reject)=>{
        try {
            let token = getState().Auth.token;
            let response = await axios.post(doEnhanceApi,data, headersWithToken(token));
            dispatch(setAlert([
                {
                    message : 'item upgrade successfully',
                    color : 'success'
                }
            ]));
            // console.log();
            return resolve(response.data.response.data?.inventory?.id);
        } catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
            return reject(false);
        }
    });
}

export const assignItemRequest = (data)=>{
    return async (dispatch, getState) => new Promise(async (resolve, reject)=>{
        try {
            let token = getState().Auth.token;
            let response = await axios.post(assignInventoryItemApi(),data, headersWithToken(token));
            dispatch({
                type : SET_CHARACTER_IN_INVENTORY_ITEM,
                payload : data?.character_id,
            })
            dispatch(setAlert([
                {
                    message : 'Assign character Successfully',
                    color : 'success'
                }
            ]));
            // console.log();
            return resolve(response.data.response.data?.inventory?.id);
        } catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
            return reject(false);
        }
    });
}

export const setBags = (bags) => {
    return {
        type: SET_BAGS_ITEMS,
        payload: bags,
    }
}
export const setNewBagItem = (item) => {
    return {
        type: SET_NEW_BAGS_ITEMS,
        payload: item,
    }
}
export const returnItem = (id) => {
    return {
        type: RETURN_BAGS_ITEMS,
        payload: id,
    }
}
export const removeBagItem = (id) => {
    return {
        type: REMOVE_BAGS_ITEMS,
        payload: id,
    }
}

export const getBagsRequest = ( character_id  ) => {
    return async (dispatch, getState) => {
        try {
            let token = getState().Auth.token;
            let response = await axios.get(getBagsApi(character_id), headersWithToken(token));
            dispatch(setBags(response.data.response.data));
        } catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    }
}

export const addBagsRequest = ( data  ) => {
    return async (dispatch, getState) => {
        try {
            let token = getState().Auth.token;
            let response = await axios.post(addBagsApi,data, headersWithToken(token));
            dispatch(setNewBagItem(response.data.response.data));
            dispatch(setAlert([{ message : 'Item successfully added in bag ' , color : 'success'}]));
        } catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    }
}

export const returnToInventoryRequest = ( id,bagNo ) => {
    return async (dispatch, getState) => {
        try {
            let token = getState().Auth.token;
            await axios.get(returnToInventoryApi(id,bagNo), headersWithToken(token));
            dispatch(returnItem(id));
            dispatch(setAlert([{ message : 'Item successfully returned to inventory ' , color : 'success'}]));
        } catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    }
}

export const removeBagsRequest = ( id  ) => {
    return async (dispatch, getState) => {
        try {
            let token = getState().Auth.token;
            dispatch(removeBagItem(id));
            let response = await axios.get(removeBagsApi(id), headersWithToken(token));
        } catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    }
}

export const addFavoritePetRequest = ( data  ) => {
    return async (dispatch, getState) => {
        try {
            let token = getState().Auth.token;
            let response = await axios.post(addFavoritePetApi,data, headersWithToken(token));
            dispatch(()=>({
                type : ADD_PET_ITEM_TO_FAVOURITE,
                payload : data
            }));

        } catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    }
}
