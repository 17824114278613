import React, {useEffect, useState} from 'react';
import {Route, Routes, useNavigate} from "react-router-dom";
import {connect} from "react-redux";
import {Button, Modal} from "react-bootstrap";
import Profile from '../../assets/img/profile.jpg';
import {acceptRequest, rejectRequest, resetTime, resetTrade, setNewTradeRequest} from "../../redux/TradeRequest/action";
import {showLoader, startTimer} from "../../redux/helpers";
import {pusher} from "../../redux/pusherConfig";
import {addPlayerRequest, joinGuildRequest} from "../../redux/Guild/action";
import {toast} from "react-toastify";
import axios from "axios";
import {headersWithToken} from "../../redux/headers";
import {getGuildPlayerApi} from "../../redux/apis";
import {applyGemGlyphsRequest, getGemGlyphsRequest, getItemRequest} from "../../redux/Inventory/action";
import ItemWrapper from "../ItemWrapper";

function GemsGlyphs(props) {

    const [isLoading , setIsloading] = useState(false);
    const [itemId , setItemId] = useState(0);
    const navigator = useNavigate();


    const closeModal = () => {
        props.setOpen(false);
    }

    const add = () => {
        if(itemId !== 0){
            closeModal(false);
            props.setPowerConfirm(true);
            // setIsloading(true);
            // props.applyGemGlyphs({inventory_id : props.inventoryItemId , item_id : itemId})
            //     .then(async ()=>{
            //         await props.getItem(props.id);
            //         setIsloading(false);
            //         props.setOpen(false);
            //     })
            //     .catch(()=>{
            //         setIsloading(false);
            //     });

        }else{
            toast.warning("Please select any item.");
        }
    }



    useEffect(()=>{
        props.getGemGlyph();
        return () => {}
    },[props.open]);

    console.log("props.powerItemId",props.powerItemId);

    return (
        <Modal
            show={props.open}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Add Power Item
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={"mx-2"}>

                <div className="row justify-content-center">
                    {
                        props.gemsGlyphs.map((val, key) => {
                            return <React.Fragment key={key}>
                            {
                                val?.qty > 0 ?
                                    <ItemWrapper
                                        className={props.powerItemId+" col-2 "+(props.powerItemId == val?.id )}
                                        item={{
                                            title: val?.title,
                                            health: val?.health,
                                            base: val?.base,
                                            damage: val?.damage,
                                            armor_pen: val?.armor_pen,
                                            armor_pen_resist: val?.armor_pen_resist,
                                        }}
                                    >
                                        <div onClick={()=>{
                                            console.log("",itemId);
                                            setItemId(val?.id);
                                            props.setPowerItemId(val?.id);
                                        }} className={` inventory-itemBox rounded`} style={{
                                            position : 'relative',
                                            height: '129px',
                                            objectFit: 'contain',
                                            border : `2px solid ${ (props.powerItemId === val?.id )? '#E3BF4B' : '#2f2f2f' }`
                                        }}>
                                            <figure className={"m-0"}><img width={'100%'} src={val?.item?.image_url} alt="" /></figure>
                                            <div className="count position-absolute">
                                                <span className="large px-1">{val?.qty}</span>
                                            </div>
                                        </div>
                                    </ItemWrapper>
                                    :
                                    <></>
                            }
                            </React.Fragment>
                        })
                    }
                </div>

                <div className="row justify-content-end mt-5">
                    <Button className={"btn btn-success"} onClick={closeModal}>Cancel</Button>
                    {
                        isLoading?
                        <Button className={"btn btn-success"} type={"button"}>
                            <i className={"fa fa-spinner fa-spin"}></i>
                        </Button>
                            :
                        <Button type={"button"} onClick={add} className={"btn btn-success"}>Add</Button>
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>

            </Modal.Footer>
        </Modal>
    );
}

const mapStateToProps = (state) => {
    return {
        gemsGlyphs: state.Inventory.gemsGlyphs,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getItem: (id) => dispatch(getItemRequest(id)),
        getGemGlyph : () => dispatch(getGemGlyphsRequest()),
        applyGemGlyphs : (data) => dispatch(applyGemGlyphsRequest(data)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(GemsGlyphs);
