import React, { useEffect, useState } from 'react';
import {connect} from "react-redux";

import Logo from "../../components/Logo";
import PDLBorder from "../../components/PDLBorder";
import '../../assets/css/login.css';
import { Link, useLocation, useNavigate } from "react-router-dom";
import loginbg from "../../assets/img/loginbg.png";
import { resetPasswordRequest } from '../../redux/Auth/action';

const Forgot = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState({
        token: '',
        password: '',
        confirm_password: '',
    });

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const token = params.get('token');

        if (token == null) {
            navigate("/", { replace: true });
        } else {
            setUser(prevUser => ({
                ...prevUser,
                token: token,
            }));
        }

    }, [location.search]);




    const onChangeHandler = (event) => {
        let { name, value } = event.target;

        setUser((preVal) => {
            return {
                ...preVal,
                [name]: value,
            }
        });


    }

    const onSubmitHandler = async (event) => {
        event.preventDefault();
        setLoading(true);
        props.resetPassword(user)
            .then(() => {
                navigate("/login", { replace: true });
            })
            .catch((e) => {
                console.log(e);
                setLoading(false);
            })
            .finally(() => {
                setLoading(false);
            });
    }



    return (
        <>
            <section className="fram-sec">
                <div className="frame-wrapper" style={{
                    backgroundImage : `url('${loginbg}')`,
                    backgroundSize: '100% 100%',
                    backgroundRepeat: 'no-repeat',
                    padding: '3rem 2rem',
                    margin: '4.5rem 2rem',
                    position: 'relative',
                }}>
                    <PDLBorder />

                    <Logo />

                    <div className="row">
                        <div className="col-md-7">

                        </div>
                        <div className="col-md-5">

                        </div>
                    </div>

                    <div className="login">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-4">
                                </div>
                                <div className="col-lg-4">
                                    <div className="login-wrapper">
                                        <form action="" onSubmit={onSubmitHandler}>
                                            <h4 className="text-center">Recovery Password</h4>

                                            <div className="form-group">
                                                <input type="password" name='password' onChange={onChangeHandler} className="login-field form-control"
                                                       placeholder="New Password" />
                                            </div>
                                            <div className="form-group">
                                                <input type="password" name='confirm_password' onChange={onChangeHandler} className="login-field form-control"
                                                       placeholder="Confirm Password" />
                                            </div>
                                            <div className="form-group marginOneRem">
                                                {
                                                    loading ?
                                                        <button type="button" className="form-submit">
                                                            <i className={"fa fa-spinner fa-spin"}></i>
                                                        </button>
                                                        :
                                                        <button type="submit" className="form-submit">
                                                            Reset Password
                                                        </button>
                                                }
                                            </div>
                                            <div className="form-group text-center marginOneRem">
                                                <span className="create-account">Don't have account sign-up &nbsp;
                                                    <Link to={'/signup'}>Sign up</Link>
                                                </span>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                                <div className="col-lg-4">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

const mapStateToProps = () => {
    return {

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        resetPassword: (data) => dispatch(resetPasswordRequest(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Forgot);
// export default Forgot