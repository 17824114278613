import React, {useEffect, useState} from 'react';
import WeaponStop from '../../../assets/img/weaponstop.png';
import WeaponsBottom from '../../../assets/img/weaponsbottom.png';
import { getCharacterDetailRequest} from "../../../redux/UserCharacter/action";
import {setLoader} from "../../../redux/Site/action";
import Slider from 'react-slick';
import {connect} from "react-redux";
import LegendTop from "../../../assets/img/legendtop.png";
import LegendBottom from "../../../assets/img/legendbottom.png";
import BoxImage from "../../../components/BoxImage";


const Misc = (props) => {

    const settings = {
        dots: false ,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    console.log("props.noOfMisc",props.noOfMisc);

    return (
        <>
            <div className="legendDv-withBorder">
                <div className="forTop-border">
                    <figure><img src={LegendTop} alt=""/></figure>
                </div>
                <div className="LegendDv">
                    <div className="title">
                        <h4>Misc</h4>
                    </div>
                    <Slider {...settings} className="Legend-Slider text-white">
                        {
                            props?.misc.map((val,key)=>{
                                return <div onClick={()=>props.onUnEquipItem(val?.id)} key={key} className="legends-items">
                                        <div className="imgbox">
                                            <BoxImage image={val?.shop?.image_url} />
                                        </div>
                                    <div className="contentDv">
                                        <ul className="lists">
                                            <li>H : {val?.health}</li>
                                            <li>D : {val?.damage}</li>
                                            <li>B : {val?.base}</li>
                                            <li>AP : {val?.armor_pen}</li>
                                            <li>APR : {val?.armor_pen_resist}</li>
                                        </ul>
                                    </div>
                                </div>
                            })
                        }
                        {
                            props.noOfMisc.map((val,key)=>{
                                return <div key={key} className={"legends-items"}>
                                    <div className="imgbox">
                                        <figure><img src="" className="animate__animated animate__bounceIn animate__delay-1s"
                                                     alt="" /></figure>
                                    </div>
                                    <div className="contentDv">
                                        <ul className="lists">
                                            <li>H : 0 </li>
                                            <li>D : 0 </li>
                                            <li>B : 0 </li>
                                            <li>AP : 0 </li>
                                            <li>APR : 0 </li>
                                        </ul>
                                    </div>
                                </div>
                            })
                        }
                    </Slider>
                </div>
            </div>
            <div className="forBottom-border">
                <figure><img src={LegendBottom} alt=""/></figure>
            </div>
        </>
    )
        ;
}


const filterMisc = (weapons) => {
    // || val?.slug === "pets"
    let noOfWeapons =  weapons.filter((val)=> val?.placement === "bottom" && (val?.slug === "misc"));
    let noOfMiscs = [];
    noOfWeapons.map((val)=>{
        noOfMiscs = [...noOfMiscs,...val?.items];
    });
    let arr = [];
    if((4 - parseInt(noOfMiscs.length)) <= 5){
        for (let i = 1 ; i <= (5 - parseInt(noOfMiscs.length));i++ ){
            arr.push(i);
        }
    }
    // console.log("No of Misc",arr);
    return arr;
}

const miscs = (weapons) => {
    //val.slug === "pets" ||
    let filter =  weapons.filter(val=>( (val.placement === "bottom") && (val.slug === "misc") ));
    let x = [];
    filter.map((val)=>{
        val.items.map((inner)=>{
            x.push(inner);
        });
    });
    return x;
}

const mapStateToProps = (state) => {
    return {
        islogin: state.Auth.islogin,
        details: state.UserCharacter.details,
        misc : miscs(state.UserCharacter.details?.characteristics || []),
        noOfMisc : filterMisc(state.UserCharacter.details?.characteristics || []),
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setLoader: (bool) => dispatch(setLoader(bool)),
        getCharacterDetail: (id) => dispatch(getCharacterDetailRequest(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Misc);
