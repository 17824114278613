import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import Logo from "../../components/Logo";
import '../../assets/css/trade.css';
import  '../../assets/css/char.css';
import AuthHeader from "../../components/AuthHeader";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import { addItemInTrade } from "../../redux/Trade/action";
import PDLBorder from "../../components/PDLBorder";
import PlayerTop from "../../assets/img/playertop.png";
import PlayerBottom from "../../assets/img/playerbottom.png";
import {setLoader} from "../../redux/Site/action";

const Characters = (props) => {

    const params = useParams();
    const location = useLocation();
    const [open , setOpen] = useState(false);
    const navigator = useNavigate();

    const get = async () => {
        props.setLoader(true);
        await props.getMyCharacter();
        props.setLoader(false);
    }



    useEffect(() => {
        get();
        return () => {
        }
    })


    return (
        <section className="fram-sec">
            <div className="frame-wrapper">

                {/* Border*/}
                <PDLBorder/>
                {/*Logo*/}
                <Logo/>

                {/*Menus*/}
                <div className="row">
                    {/*<div className="col-md-7">*/}

                    {/*</div>*/}
                    <div className="col-md-12">
                        <div className="Menus text-right">
                            <AuthHeader/>
                        </div>
                    </div>
                </div>
                {/*Menus*/}
                <section className="inventory-detailed char-details">
                    <div className="container">
                        <div className="row place-bid-charatcter">
                            {
                                props.characters?.map((val,key)=>(
                                    <div className="placeBidBox" style={{
                                        cursor :'pointer',
                                    }} onClick={()=>navigator('/character/view/'+val?.id)}>
                                        <div className="forTop-border py-1">
                                            <figure><img className="w-100" src={PlayerTop} alt=""/></figure>
                                        </div>
                                        <div className="Player-statusDv">
                                            <div>
                                                <figure>
                                                    <img src={val?.logo_url} width={'100%'} alt=""/>
                                                </figure>
                                            </div>
                                        </div>
                                        <div className="forBottom-border py-1">
                                            <figure><img className="w-100" src={PlayerBottom} alt=""/></figure>
                                        </div>
                                        <div className="head">
                                                <h3 className="title">
                                                    {val?.name}
                                                </h3>
                                            </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </section>

            </div>
        </section>
    );
}


const mapStateToProps = (state) => {
    return {
        characters : state.UserCharacter.characters,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setLoader : (bool) => dispatch(setLoader(bool)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Characters);