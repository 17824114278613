import {
    RESET_NEW_TRADE_REQUEST, RESET_REQUEST_TRADE_TIME,
    SET_NEW_TRADE_REQUEST,
    SET_SEARCH_USERS
} from "../constants";
import {
    getTradeApi, newAcceptApi, newRejectApi

} from "../apis";
import {responseError} from "../errorHandler";
import {setAlert} from "../Alert/action";
import axios from "axios";
import {headersWithToken} from "../headers";
import {setTrade} from "../Trade/action";

export const setNewTradeRequest = (data) => {
    return {
        type : SET_NEW_TRADE_REQUEST,
        payload : data
    }
}

export const resetTrade = () => {
    return {
        type : RESET_NEW_TRADE_REQUEST,
    }
}

export const resetTime = (time) => {
    return {
        type : RESET_REQUEST_TRADE_TIME,
        payload : time,
    }
}

export const rejectRequest = (id) => {
    return async (dispatch,getState) => {
        try{
            let token = getState().Auth.token;
            let response = await axios.get(newRejectApi(id),headersWithToken(token));
            dispatch(resetTrade());
        }catch (e) {
            let error = responseError(e);
            dispatch(resetTrade());
            dispatch(setAlert(error));
        }
    }
}

export const acceptRequest = (id) => {
    return async (dispatch,getState) => new Promise(async (resolve, reject)=>{
        try{
            let token = getState().Auth.token;
            let response = await axios.get(newAcceptApi(id),headersWithToken(token));
            dispatch(resetTrade());
            return resolve(true);
        }catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
            return reject(false);
        }
    });
}
