import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import PDLBorder from "../../components/PDLBorder";
import Logo from "../../components/Logo";
import AuthHeader from "../../components/AuthHeader";
import PlayerBottom from '../../assets/img/playerbottom.png';
import PlayerTop from '../../assets/img/playertop.png';
import {
    getAuctionItemRequest,
    getItemRequest,
    removeGemGlyphsItemRequest,
    sendToInventoryRequest,
    updateItemRequest
} from "../../redux/Inventory/action";
import {setLoader} from "../../redux/Site/action";
import Loader from "../NotFound/Loader";
import {Link, useNavigate, useParams} from "react-router-dom";
import NotFound from "../NotFound/NotFound";
import '../../assets/css/inventory-detail.css';
import {showLoader} from "../../redux/helpers";
import GemsGlyphs from "../../components/Modals/gems_glyphs";
import Confirm from "../../components/Modals/Confirm";
import PowerConfirm from "../../components/Modals/PowerConfirm";
import AssignCharacters from "../../components/Modals/AssignCharacters";
import AddAuction from "../../components/Modals/AddAuction";
import {buyItemRequest} from "../../redux/Shop/action";


const ItemsDetails = (props) => {

    const params = useParams();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [assignCharacter, setAssignCharacter] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [powerConfirm, setPowerConfirm] = useState(false);
    const [auctionModal, setAuctionModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [powerItemId, setPowerItemId] = useState(0);
    const [id, setId] = useState(0);

    const get = async () => {
        props.setLoader(true);
        await props.getItem(params?.id);
        props.setLoader(false);
    }

    const buy = () => {
        setLoading(true);
        props.buyItem({item_id: params?.id})
            .then(() => {
                setLoading(false);
                navigate('/inventory');
            })
            .catch(() => {
                setLoading(false);
            });
    }

    const updateItemFor = async (id, item_for) => {
        showLoader('flex');
        await props.updateItem({id: id, item_for: item_for});
        showLoader('none');
    }

    const removeItem = async (id) => {
        // showLoader('flex');
        props.removeGemGlyphsItem(id)
            .then(async () => {
                await props.getItem(params?.id);
                console.log("getItem", params?.id);
                // showLoader('none');
            })
            .catch(() => {
                // showLoader('none');
            });
        // showLoader('none');
    }

    const openConfirmModal = (id) => {
        setId(id);
        setOpenConfirm(true);
    }

    useEffect(() => {

        return () => {

        }
    }, []);

    useEffect(() => {
        get();
    }, [params?.id]);

    if (props.loader) return <Loader/>

    if (!props.item) return <NotFound/>

    return (
        <section className="fram-sec">
            <div className="frame-wrapper">

                {/* Border*/}
                <PDLBorder/>
                {/*Logo*/}
                <Logo/>

                {/*Menus*/}
                <div className="row">
                    {/*<div className="col-md-7">*/}

                    {/*</div>*/}
                    <div className="col-md-12">
                        <div className="Menus text-right">
                            <AuthHeader/>
                        </div>
                    </div>
                </div>
                {/*Menus*/}
                <section className="inventory-detailed">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-5">
                                <div className="forTop-border py-1">
                                    <figure><img className="w-100" src={PlayerTop} alt=""/></figure>
                                </div>
                                <div className="Player-statusDv">
                                    <div className="head">
                                        <h3 className="title">
                                            Item Stats
                                        </h3>
                                    </div>
                                    <ul className="status-list">
                                        <li>
                                            <h4>Level</h4>
                                            <span>{props.item?.shop?.level}</span>
                                        </li>
                                        <br/>
                                        <li>
                                            <h4>Health</h4>
                                            <span>{props.item?.health}
                                                <span
                                                    className={"text-success"}>({props.item?.shop?.health?.toLocaleString()})</span>
                                            </span>
                                        </li>
                                        <br/>
                                        <li>
                                            <h4>Base</h4>
                                            <span>{props.item?.base}
                                                <span
                                                    className={"text-success"}>({props.item?.shop?.base?.toLocaleString()})</span>
                                            </span>
                                        </li>
                                        <br/>

                                        <li>
                                            <h4>Damage</h4>
                                            <span>{props.item?.damage}
                                                <span
                                                    className={"text-success"}>({props.item?.shop?.damage?.toLocaleString()})</span>
                                            </span>
                                        </li>
                                        <br/>

                                        <li>
                                            <h4>Armor Pen</h4>
                                            <span>{props.item?.armor_pen}
                                                <span
                                                    className={"text-success"}>({props.item?.shop?.armor_pen?.toLocaleString()})</span>
                                            </span>
                                        </li>
                                        <br/>

                                        <li>
                                            <h4>Armor Pen Resist</h4>
                                            <span>{props.item?.armor_pen_resist}
                                                <span
                                                    className={"text-success"}>({props.item?.shop?.armor_pen_resist?.toLocaleString()})</span>
                                            </span>
                                        </li>

                                        <br/>
                                        <li>
                                            <h4>Quantity</h4>
                                            <span>{props.item?.qty}</span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="forBottom-border py-1">
                                    <figure><img className="w-100" src={PlayerBottom} alt=""/></figure>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="product-details">
                                    <div className="heading">
                                        <h1 className="title">{props.item?.shop?.title}</h1>
                                    </div>
                                    <div className="image-with-btn">
                                        <div className="img-box">
                                            <figure><img width={'100%'} src={props.item?.shop?.image_url} alt=""/>
                                            </figure>
                                        </div>
                                    </div>
                                    {/*<div className="description">*/}
                                    {/*    <p>{props.item.}</p>*/}
                                    {/*</div>*/}
                                    <div className="description">
                                        <p>{props?.item?.shop?.description}</p>
                                    </div>

                                    <div className="button-group my-2">
                                        <Link to={'/auction-inventory'} className="btn customButton">Back</Link>
                                        {
                                            loading ?
                                                <button type={"button"} className="btn">
                                                    <i className={"fa fa-spinner fa-spin"}></i>
                                                </button>
                                                :
                                                <button type={"button"} onClick={buy} className="btn">Send to Inventory</button>

                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </section>
    );
}

const mapStateToProps = (state) => {
    return {
        item: state.Inventory.item,
        loader: state.Site.loader,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setLoader: (bool) => dispatch(setLoader(bool)),
        getItem: (id) => dispatch(getAuctionItemRequest(id)),
        updateItem: (data) => dispatch(updateItemRequest(data)),
        buyItem : (data) => dispatch(sendToInventoryRequest(data)),
        removeGemGlyphsItem: (id) => dispatch(removeGemGlyphsItemRequest(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemsDetails);