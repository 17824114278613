import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import Logo from "../../components/Logo";
import '../../assets/css/trade.css';
import AuthHeader from "../../components/AuthHeader";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {
    addItemInTrade,
    addItemInTradeRequest, finishedRequest, getSearchUserRequest,
    getTradeMakeRequest,
    markAsCompleteRequest,
    setTrade, tradeCancelRequest
} from "../../redux/Trade/action";
import {showLoader} from "../../redux/helpers";
import PDLBorder from "../../components/PDLBorder";
import {setAlert} from "../../redux/Alert/action";
import {filterItemsByCategoryRequest, getItemsRequest, setItems} from "../../redux/Inventory/action";
import {resetTrade} from "../../redux/TradeRequest/action";
import Profile from '../../assets/img/profile.jpg';
import {sendGiftRequest} from "../../redux/Gift/action";
import GiftQuantity from "../../components/Modals/GiftQuantity";
import Category from "../../components/Category";
import SearchInventory from "../../components/SearchInventory";

const Gift = (props) => {

    const params = useParams();
    const location = useLocation();
    const [open , setOpen] = useState(false);
    const [modalItem,setModalItem] = useState(null);
    const [search , setSearch] = useState(true);
    const [user , setUser] = useState(null);
    const [items , setItems] = useState([]);
    const [name,setName] = useState('');
    const navigator = useNavigate();

    const onSetUserHander = (val) => {
        setSearch(false);
        setUser(val);
    }

    const onSearchHandler = async (event) => {
        event.preventDefault();
        showLoader('flex');
        await props.getSearchUser(name);
        showLoader('none');
    }

    const setNewItems = (val) => {
        let find = items.find((item) => item?.id === val?.id);
        if (!find) {
            // if (items.length <= 7) {
                setItems((preVal) => {
                    return [
                        ...preVal,
                        {
                            ...val,
                            sendQty : 1
                        }
                    ]
                });
            // } else {
            //     props.setAlert([
            //         {
            //             message: 'Limit is reached.',
            //             color: 'info',
            //         }
            //     ]);
            // }
        } else {
            props.setAlert([
                {
                    message: 'Already added in list.',
                    color: 'info',
                }
            ]);
        }
    }

    const removeItems = (id) => {
        // props.setInventoryItems(props.items.filter((item)=>item?.id !== id));
        setItems((preVal)=>{
            return [
                ...preVal.filter((val)=>val?.id !== id),
            ]
        });
    }


    const filterByCategory = async (category_id) => {
        showLoader('flex');
        await props.filterItemsByCategory(category_id);
        showLoader('none');
    }

    const onSendGiftHandler = () => {
        if(user && items.length > 0){
            showLoader('flex');
            props.sendGift({
                items: items,
                receiver_id: user?.id,
            })
                .then(() => {
                    navigator('/inventory');
                    showLoader('none');
                })
                .catch(() => {
                    showLoader('none');
                })
        }else{
            if (items.length <= 0) props.setAlert([{message: 'At least one item is required.',color : 'warning'}]);
            if (!user) props.setAlert([{message: 'User is required.',color :'warning'}]);
        }
    }

    const setQuantity = (qty,id) => {
        let x = items.findIndex((val)=>val?.id === id);
        let itemData = items.filter((val)=>val)
        if(x >= 0){
            itemData[x]['sendQty'] = qty;
        }
        setItems(itemData);

    }
    const get = async () => {
        showLoader('flex');
        await props.getTradeMake(params?.id);
        await props.getItems();
        showLoader('none');
    }



    useEffect(() => {
        get();

        return () => {
        }
    }, [])

    useEffect(() => {
        if(location?.state && location?.state?.id && props.items?.data){
            let x = props.items?.data.filter((val)=>val?.id === location?.state?.id)
            if(x.length > 0) setNewItems(x[0]);
        }
    }, [props.items])

    // console.log("location?.state",props.items);

    return (
        <section className="fram-sec">
            <div className="frame-wrapper">
                {/* Border*/}
                <PDLBorder/>
                {/*Logo*/}
                <Logo/>

                {/*Menus*/}
                <div className="row">
                    {/*<div className="col-md-7">*/}

                    {/*</div>*/}
                    <div className="col-md-12">
                        <div className="Menus text-right">
                            <AuthHeader/>
                        </div>
                    </div>
                </div>


                <section className="trade-wrapper">
                    <div className="container">
                        <div className="row pb-5">
                            <div className="col-sm-12">
                                <h4 className="text-center">Gift</h4>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="inventroy-Box bef">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="inventories">
                                                <div className="nav flex-column nav-pills" id="v-pills-tab"
                                                     role="tablist" aria-orientation="vertical">
                                                    <ul className="inventory-links">
                                                        <li>
                                                            <a className="nav-link" onClick={get}>All</a>
                                                        </li>
                                                        <Category parentComponent={"gift"} filterByCategory={filterByCategory} />
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="title">
                                                <figure><img src="img/inventorytxt.png" alt=""/></figure>
                                            </div>
                                            <div className="search-Box">
                                                <SearchInventory itemRequest={props.getItems} />
                                            </div>
                                            <div>
                                                <div className="">
                                                    <div className="inventory-selection">
                                                        <div className="row">
                                                            {
                                                                props.items?.data.map((val, key) => {
                                                                    return <div key={key} className="col-3" onClick={()=>setNewItems(val)}>
                                                                        <div className="inventory-itemBox">
                                                                            <figure className='m-0'>
                                                                                <img width={'100%'}
                                                                                     src={val?.shop?.image_url} alt=""/>
                                                                            </figure>
                                                                            <div className="count">
                                                                                <span className="large">{val?.qty}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="nextBtn">
                                        <a href="#"><img src="img/arrow-right.png" alt=""/></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">

                                <div className="your-offering">
                                    <h5>Send To </h5>
                                    <div className="container">
                                        {
                                            search ?
                                                <div className="row justify-content-center">
                                                    <div className="col-lg-12">

                                                        <form onSubmit={onSearchHandler}>
                                                            <div className="row justify-content-center align-content-center">
                                                                <div className=" col-12 d-flex">

                                                                    <input type="text" required={true} name="name" onChange={(e)=>setName(e.target.value)}
                                                                           className={"customInput form-control inputCustomHeight "}
                                                                           id=""/>

                                                                    <button type={'submit'} className="btn customButton">
                                                                        <i className={"fa fa-search"}></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </form>

                                                        <div className="row my-5 justify-content-center align-items-center">
                                                            <div className="col-lg-12">
                                                                <div className="Player-statusDv">
                                                                    {
                                                                        props.users.length > 0 ?
                                                                            <ul className="status-list p-0">
                                                                                {
                                                                                    props.users.map((val, key) => {
                                                                                        return <span key={key}>
                                                                                                <li key={key} className={"py-2 border d-flex flex-row justify-content-between align-items-center"}>
                                                                                                    <h4>{val?.name}</h4>
                                                                                                    <span>
                                                                                                        <button onClick={()=>onSetUserHander(val)} className="btn customButton" type={"button"}>
                                                                                                            Send Gift
                                                                                                        </button>
                                                                                                    </span>
                                                                                                </li>
                                                                                                <br/>
                                                                                            </span>
                                                                                    })
                                                                                }
                                                                            </ul>
                                                                            :
                                                                            <ul className="status-list">
                                                                                <li className={"justify-content-center"}>
                                                                                    <h4>No user found</h4>
                                                                                </li>
                                                                                <br/>
                                                                            </ul>

                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                :
                                                <div className="row justify-content-center">
                                                    <div className="col-lg-7">
                                                        <div className="">
                                                            <figure className={""}>
                                                                <img width={'100%'} src={user?.profile_url} alt=""/>
                                                            </figure>
                                                        </div>
                                                        <div>
                                                            <h3 className={"text-center pdlStyleHeading"}>{user?.name}</h3>
                                                            <div className="text-center trade-btn-wrapper mt-4">
                                                                <button className="btn" onClick={()=>setSearch(true)} type={"button"}>Edit</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                        }

                                    </div>
                                </div>

                                <div className="your-offering up">
                                    <h5>Items : </h5>
                                    <div className="container">
                                        <div className="row">
                                            {
                                                items.map((val,key)=>{
                                                    return <div key={key} className="col-lg-3">
                                                        <div className="inventory-itemBox">
                                                            <figure onClick={()=>removeItems(val?.id)}>
                                                                <img width={'100%'}
                                                                     src={val?.shop?.image_url} alt=""/>
                                                            </figure>
                                                            <div className="count" onClick={()=>{setModalItem(val);setOpen(true)}}>
                                                                <span className="large">{val?.sendQty || 1}/{val?.qty}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                })
                                            }
                                            {
                                                ((8 - items.length)  > 0)
                                                &&
                                                    <>
                                                        {
                                                            [...Array((8 - items.length))].map((val,key)=>{
                                                                return <div key={key} className="col-lg-3">
                                                                    <div className="inventory-itemBox">
                                                                    </div>
                                                                </div>
                                                            })
                                                        }
                                                    </>
                                            }

                                        </div>
                                    </div>
                                </div>

                                <div className="trade-btn-wrapper mt-4">
                                    <button className="btn" onClick={onSendGiftHandler} type={"button"}>Send Gift</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <GiftQuantity setQuantity={setQuantity} modalItem={modalItem} open={open} setOpen={setOpen} />
        </section>
    );
}


const mapStateToProps = (state) => {
    return {
        islogin: state.Auth.islogin,
        user: state.Auth.user,
        items: state.Inventory.items,
        category: state.Site.category,
        trade: state.Trade.trade,
        time: state.TradeRequest.time,
        users : state.Trade.users,

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getSearchUser : (name) => dispatch(getSearchUserRequest(name)),
        getTradeMake: (id) => dispatch(getTradeMakeRequest(id)),
        setAlert: (data) => dispatch(setAlert(data)),
        getItems: (url = null,search=null) => dispatch(getItemsRequest(url,search)),
        filterItemsByCategory: (id) => dispatch(filterItemsByCategoryRequest(id)),
        addItem: (data) => dispatch(addItemInTradeRequest(data)),
        setTrade: (data) => dispatch(setTrade(data)),
        complete: (data) => dispatch(markAsCompleteRequest(data)),
        finished: (data) => dispatch(finishedRequest(data)),
        tradeCancel: (id) => dispatch(tradeCancelRequest(id)),
        resetTrade: () => dispatch(resetTrade()),
        setInventoryItems : (data) => dispatch(setItems(data)),
        sendGift : (data) => dispatch(sendGiftRequest(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Gift);