import React, { useEffect, useState } from 'react';


const ItemWrapper = (props) => {

    const [showDetails, setShowDetails] = useState(false);

    const handleMouseEnter = () => {
        setShowDetails(true);
    };

    const handleMouseLeave = () => {
        setShowDetails(false);
    };

    return (
        <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className={props?.className}
            style={{ position: 'relative' }}
        >
            {props?.children}
            {
                showDetails &&
                <div className="detail-wrap" style={{ position: 'absolute' }}>
                    <h5>{props?.item?.title}</h5>
                    <ul>
                        <li><span className="head">health :</span><span className="property">{props?.item?.health?.toLocaleString()}</span></li>
                        <li><span className="head">Base : </span><span className="property">{props?.item?.base?.toLocaleString()}</span></li>
                        <li><span className="head">Damage : </span><span className="property">{props?.item?.damage?.toLocaleString()}</span></li>
                        <li><span className="head">Armor Pen : </span><span className="property">{props?.item?.armor_pen?.toLocaleString()}</span></li>
                        <li><span className="head">Armor Pen Resist : </span><span className="property">{props?.item?.armor_pen_resist?.toLocaleString()}</span></li>
                    </ul>
                </div>
            }

        </div>
    )
}

export default ItemWrapper;