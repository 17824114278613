import React, {useEffect, useState} from 'react';
import {connect, useSelector} from "react-redux";
import Logo from "../../components/Logo";
// import '../../assets/css/discord.css';
import '../../assets/css/result.css';
import AuthHeader from "../../components/AuthHeader";
import {Link, useNavigate, useParams} from "react-router-dom";
import {
    addItemInTrade,
} from "../../redux/Trade/action";
import {showLoader} from "../../redux/helpers";
import PDLBorder from "../../components/PDLBorder";
import {acceptGuildRequest, cancelGuildRequest, getGuildRequest} from "../../redux/Guild/action";
import AddPlayerRequest from "../../components/Modals/AddPlayerRequest";
import {getItemsRequest} from "../../redux/Inventory/action";
import InventoryItemGuild from "../../components/Modals/InventoryItemGuild";

const GuildMenu = (props) => {

    const navigate = useNavigate();
    const setting = useSelector(state => state?.Guild?.guildSetting);
    const guild = useSelector(state => state?.Guild?.guild);

    return (
        <>
            {
                setting &&
                <ul className="castle-menu-links">
                    {
                        (guild?.level >= setting?.portalis_level ) &&
                        <li>
                            <a onClick={()=>navigate(`/guild/portalis/${guild?.id}`)}>portalis</a>
                        </li>
                    }
                    {
                        (guild?.level >= setting?.castle_level ) &&
                        <li><a onClick={()=>navigate(`/guild/castle/${guild?.id}`)}>castle</a></li>
                    }
                    {
                        (guild?.level >= setting?.gate_level ) &&
                        <li><a onClick={()=>navigate(`/guild/gate-house/${guild?.id}`)}>gate house</a></li>
                    }
                    {
                        (guild?.level >= setting?.court_level ) &&
                        <li><a onClick={()=>navigate(`/guild/court-yard/${guild?.id}`)}>court yard</a></li>
                    }
                    {
                        (guild?.level >= setting?.armory_level ) &&
                        <li><a onClick={()=>navigate(`/guild/${guild?.id}/armor`)}>armory</a></li>
                    }
                    {
                        (guild?.level >= setting?.treasure_level ) &&
                        <li><a onClick={()=>navigate(`/guild/treasure/${guild?.id}`)}>treasure room</a></li>
                    }
                    {
                        (guild?.level >= setting?.chapel_level ) &&
                        <li><a onClick={()=>navigate(`/guild/chapel/${guild?.id}`)}>chapel</a></li>
                    }
                    {
                        (guild?.level >= setting?.dungeon_level ) &&
                        <li><a onClick={()=>navigate(`/guild/dungeon/${guild?.id}`)}>dungeon</a></li>
                    }
                    {
                        (guild?.level >= setting?.solar_level ) &&
                        <li><a onClick={()=>navigate(`/guild/solar/${guild?.id}`)}>solar</a></li>
                    }
                    {
                        (guild?.level >= setting?.throne_level ) &&
                        <li><a onClick={()=>navigate(`/guild/throne-room/${guild?.id}`)} >throne room</a></li>
                    }
                    {
                        (guild?.level >= setting?.guilt_level ) &&
                        <li><a onClick={()=>navigate(`/guild/${guild?.id}/hall`)}>guild hall</a></li>
                    }
                </ul>
            }
        </>
    );
}

export default GuildMenu;