import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import PDLBorder from "./PDLBorder";
import Logo from "./Logo";

const Classes = (props) => {

    const [singleClass , setSingleClass] = useState(props.singleClass || null);
    const onSelectClass = (val) => {
        setSingleClass(val);
    }
    useEffect(()=>{
        setSingleClass(props.singleClass);
    },[props.singleClass]);

    return (
        <section className="fram-sec">
            <div className={`character-modal-frame-wrapper`} style={
                ((singleClass)? {
                    backgroundImage : `url('${singleClass?.back_image_url}')`,
                    backgroundSize: '100% 100%',
                    backgroundRepeat: 'no-repeat',
                    padding: '3rem 2rem',
                    margin: '4.2rem -0.5rem',
                    position: 'relative',
                } : {})
            }>
                <PDLBorder />
                {/* <!-- LOGO --> */}
                <Logo />
                <div className="main-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-3">
                                <h4 style={{ fontSize:'18px'}}
                                    className={"char-name pdlColor pdlFont my-1"}>
                                    CHOOSE YOUR CLASS
                                </h4>

                                <div className="character-list">

                                    {
                                        props.character.map((val,key)=>{
                                            return <div key={key} className={`character-wrapper ${ singleClass && (val.id === singleClass.id)? 'active-wr' : ''}`}>
                                                {/*<a to={`/character/${val?.slug}`}>*/}
                                                <a onClick={()=>onSelectClass(val)}>
                                                    <figure>
                                                        <img src={val?.front_image_url} className="char-img img-fluid" alt=""/>
                                                        <h3 style={{ fontSize:'21px',}} className={"char-name pdlColor pdlFont"}>{val?.title}</h3>
                                                        {/*<img src={Sniper} className=" img-fluid" alt=""/>*/}
                                                    </figure>
                                                </a>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                            <div className="col-lg-6 text-center">
                                {
                                    // ((singleClass) && (singleClass?.slug === "sniper"))
                                    // &&
                                    <div className="main-charater-img">
                                        <figure>
                                            <img src={singleClass?.avatar_image_url}
                                                 className="animate__animated animate__fadeInDown animate__delay-1s img-fluid"
                                                 alt="" />
                                        </figure>
                                    </div>
                                }
                            </div>
                            {
                                singleClass
                                &&
                                <div className="col-lg-3">
                                    <div className="character-specification">
                                        <figure>
                                            <h3 style={{fontSize:'21px',}} className={"char-name pdlColor pdlFont"}>
                                                {singleClass?.title}
                                            </h3>
                                            {/*<img src={TheWarrior} className="char-name img-fluid" alt=""/>*/}
                                        </figure>
                                        <p className="golden-color">{singleClass?.description}</p>
                                        <div className="character-progress-wrapper">
                                            <div className="character-progress">
                                                <div className="spec-name-and-percent">
                                                    <h6>Health</h6>
                                                    <h5>{singleClass?.health}/10</h5>
                                                </div>
                                                <div className="progress">
                                                    <div className="progress-bar golden-bar" role="progressbar"
                                                         style={{width: `${parseInt(singleClass?.health) * 10 }%`}} aria-valuenow={`${parseInt(singleClass?.health) * 10 }`} aria-valuemin="0"
                                                         aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                            <div className="character-progress">
                                                <div className="spec-name-and-percent">
                                                    <h6>Damage</h6>
                                                    <h5>{singleClass?.damage}/10</h5>
                                                </div>
                                                <div className="progress">
                                                    <div className="progress-bar golden-bar" role="progressbar"
                                                         style={{width: `${parseInt(singleClass?.health) * 10 }%`}} aria-valuenow={`${parseInt(singleClass?.health) * 10 }`} aria-valuemin="0"
                                                         aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                            <div className="character-progress">
                                                <div className="spec-name-and-percent">
                                                    <h6>Base</h6>
                                                    <h5>{singleClass?.base}/10</h5>
                                                </div>
                                                <div className="progress">
                                                    <div className="progress-bar golden-bar" role="progressbar"
                                                         style={{width: `${parseInt(singleClass?.health) * 10 }%`}} aria-valuenow={`${parseInt(singleClass?.health) * 10 }`} aria-valuemin="0"
                                                         aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                            <div className="character-progress">
                                                <div className="spec-name-and-percent">
                                                    <h6>Armor Pen</h6>
                                                    <h5>{singleClass?.armor_pen}/10</h5>
                                                </div>
                                                <div className="progress">
                                                    <div className="progress-bar golden-bar" role="progressbar"
                                                         style={{width: `${parseInt(singleClass?.health) * 10 }%`}} aria-valuenow={`${parseInt(singleClass?.health) * 10 }`} aria-valuemin="0"
                                                         aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                            <div className="character-progress">
                                                <div className="spec-name-and-percent">
                                                    <h6>Armor Pen Resist</h6>
                                                    <h5>{singleClass?.armor_pen_resist}/10</h5>
                                                </div>
                                                <div className="progress">
                                                    <div className="progress-bar golden-bar" role="progressbar"
                                                         style={{width: `${parseInt(singleClass?.health) * 10 }%`}} aria-valuenow={`${parseInt(singleClass?.health) * 10 }`} aria-valuemin="0"
                                                         aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                            <div className="character-progress">
                                                <div className="spec-name-and-percent">
                                                    <h6>ATT</h6>
                                                    <h5>{singleClass?.att}/10</h5>
                                                </div>
                                                <div className="progress">
                                                    <div className="progress-bar golden-bar" role="progressbar"
                                                         style={{width: `${parseInt(singleClass?.health) * 10 }%`}} aria-valuenow={`${parseInt(singleClass?.health) * 10 }`} aria-valuemin="0"
                                                         aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="theme-btn-wrapper mt-5 ">
                                            <Link to={'/character-creation'} className="btn btn-theme form-control">Create Character</Link>
                                            <a onClick={()=>props?.onHide()} className="btn btn-theme form-control mt-1">Cancel</a>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>                {/* <!-- LOGO --> */}
            </div>
        </section>

    );
}

const Filter = (characters) => {
    let x = characters.filter((val,key)=> key === 0);
    return x.length>0?x[0] : null
}

const mapStateToProps = (state) => {
    return {
        islogin: state.Auth.islogin,
        character : state.Site.characters,
        singleClass : Filter(state.Site.characters || []),
    }
}

const mapDispatchToProps = () => {

}

export default connect(mapStateToProps)(Classes);