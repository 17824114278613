
const initState = {
}

const Gift = (state = initState , action) => {
    switch (action.type) {


        default: return state;
    }
}

export default Gift;