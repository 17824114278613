import {ADD_PET_ITEM_TO_FAVOURITE, SET_ITEM, SET_ITEMS} from "../constants";

const pagination = {
    current_page : 0,
    data : [],
    first_page_url : null,
    from : 0,
    last_page : 0,
    last_page_url : null,
    links : [],
    next_page_url : null,
    path : null,
    per_page : 0,
    prev_page_url : null,
    to : 0,
    total : 0,
};

const initState = {
    items : pagination,
    item : null,
}


const Shop = (state = initState , action) => {
    switch (action.type) {

        case SET_ITEMS :
            return {
                ...state,
                items : action.payload,
            }
        case SET_ITEM :
            return {
                ...state,
                item : action.payload,
            }
        case ADD_PET_ITEM_TO_FAVOURITE :
            return {
                ...state,
                items : {
                    ...state,
                    data: [
                        ...state?.items?.data?.map((val,key)=>{
                            if(val?.id == action.payload?.id){
                                return {...val,is_pet_favourite:action?.payload?.is_pet_favourite}
                            }else{
                                return val;
                            }
                        })
                    ]
                }
            }

        default: return state;
    }
}

export default Shop;