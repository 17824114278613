import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import PDLBorder from "../../components/PDLBorder";
import Logo from "../../components/Logo";
import "../../assets/css/auction.css";
import AuthHeader from "../../components/AuthHeader";
import auctionText from "../../assets/img/auction-txt.png";
import { getItemsForAuctionListRequest, getItemsRequest } from "../../redux/Auction/action";
import Loader from "../NotFound/Loader";
import { getSecondsFromStartEnd, showLoader } from "../../redux/helpers";
import Video from "../../assets/welcome.mp4";
import PlayerBottom from "../../assets/img/playerbottom.png";
import PlayerTop from "../../assets/img/playertop.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CountDownTimer } from "./ItemDetails";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { getCharacterDetailRequest, getMyCharactersRequest } from "../../redux/UserCharacter/action";
import { getProfileRequest, setCharacter } from "../../redux/Auth/action";
import { setLoader } from "../../redux/Site/action";


const NewAuction = (props) => {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [Loader, setLoader] = useState(false);
    const [select, setSelect] = useState(0);
    const items = useSelector((state) => state?.Auction?.items)

    var list_items = useSelector((state) => state?.Auction?.list_items);

    // if (list_items != null && list_items.length > 0) {
    //     list_items = list_items.sort((a, b) => a?.time_left - b?.time_left);
    // }

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 4;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = list_items.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = pageNumber => setCurrentPage(pageNumber);

    const category = useSelector((state) => state?.Site.category);
    const user = useSelector((state) => state?.Auth.user);
    const currency = useSelector((state) => state?.Site.currency);
    const loader = useSelector((state) => state.Site.loader);
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    // console.log("searchParams",);


    const get = async (link = null) => {
        setLoader(true);
        if (link) showLoader('flex');
        // await props.getProfile();
        await dispatch(getItemsForAuctionListRequest(link));
        await props.getProfile();
        if (link) showLoader('none');
        setLoader(false);
    }
    const filterByCategory = async (id, val) => {
        showLoader('flex');
        await dispatch(getItemsForAuctionListRequest(null, `&category=${id}`));
        showLoader('none');
    }

    useEffect(() => {
        // const myParam = searchParams.get('myParam');
        if (searchParams.get('type')) {
            filterByCategory(searchParams.get('type'), '');
        } else {
            get();
        }
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, [3000])
        return () => {
        }
    }, []);


    if (loader) return <Loader />;

    return (
        <>
            {loading ? (
                <div
                    className="welcome_video"
                    id="WelcomeVdo"
                    // style={{display: (loading)?'block':'none'}}
                >
                    <video
                        id="AuctionVdo"
                        width="100%"
                        muted={true}
                        height="100vh"
                        autoPlay={true}
                    >
                        <source src={Video} type="video/mp4" />
                    </video>
                </div>
            ) : (
                <section id={'auction'}>
                        <div className="auction-frame-wrapper auction_sec">
                            {/* Border*/}
                            <PDLBorder />
                            {/*Logo*/}
                            <Logo />

                            {/*Menus*/}
                            <div className="row">
                                {/*<div className="col-md-7">*/}

                                {/*</div>*/}
                                <div className="col-md-12">
                                    <div className="Menus text-right">
                                        <AuthHeader />
                                    </div>
                                </div>
                            </div>
                            {/*Menus*/}

                            <div className="heading-wrapper text-center py-4">
                                <figure>
                                    <img src={auctionText} alt="" className="img-fluid" />
                                </figure>
                            </div>
                            {/* work here.... */}

                            {/* My Auction Section */}
                            <section className="inventory-detailed player-auction-sec">
                                <div className="container-fluid">
                                    <div className="player-auction-head">
                                        <div className="player-head-and-button">
                                            <div className="head">
                                                <h3>{user?.name}</h3>
                                            </div>

                                            <div className="player-auc-button">
                                                {/* {
                                                    currentPage > 1 &&
                                                    <a
                                                        onClick={() => paginate(currentPage - 1)}
                                                        className="btn custom-button">
                                                        prev
                                                    </a>
                                                }
                                                {
                                                    <a
                                                        onClick={() => paginate(currentPage + 1)}
                                                        className="btn custom-button">
                                                        next
                                                    </a>
                                                } */}

                                                {
                                                    list_items.length > 1 ?
                                                        <div>
                                                            {Array.from({ length: Math.ceil(list_items.length / itemsPerPage) }, (_, i) => (
                                                                <button className={currentPage == (i + 1) ? 'btn_bg_custome_golden btn custom-button' : 'btn custom-button'} key={i} onClick={() => paginate(i + 1)}>{i + 1}</button>
                                                            ))}
                                                        </div>
                                                        : ''
                                                }


                                            </div>
                                        </div>
                                    </div>
                                    <div className="row ">
                                        <div className="col-lg-3">
                                            <div className="forTop-border py-1">
                                                <figure>
                                                    <img className="w-100" src={PlayerTop} alt="" />
                                                </figure>
                                            </div>
                                            <div className="Player-statusDv player-for-auction">
                                                <div className="head">
                                                    <h3 className="title"></h3>
                                                </div>
                                                <ul className="status-list">

                                                    <li onClick={() => {
                                                        get();
                                                        navigate('/auction');
                                                    }}>
                                                        <h4>All</h4>
                                                    </li>
                                                    <br />
                                                    {
                                                        category?.map((val, key) => (
                                                            <React.Fragment key={key}>
                                                                <li onClick={() => {
                                                            // navigate(`${'auction'}?type=${val?.slug}`)
                                                                    filterByCategory(val?.id, '');
                                                                    navigate({
                                                                        pathname: '',
                                                                        search: `?type=${val?.id}`,
                                                                    });
                                                                }}>
                                                                    <h4>{val?.name}</h4>
                                                                </li>
                                                                <br />
                                                            </React.Fragment>
                                                        ))
                                                    }
                                                </ul>
                                            </div>

                                            <div className="forBottom-border py-1">
                                                <figure>
                                                    <img className="w-100" src={PlayerBottom} alt="" />
                                                </figure>
                                            </div>
                                        </div>

                                        <div className="col-lg-9">
                                            <div className="forTop-border py-1">
                                                <figure>
                                                    <img className="w-100" src={PlayerTop} alt="" />
                                                </figure>
                                            </div>
                                            <div className="Player-statusDv player-for-table-auction">
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="head player-table-auction">
                                                            <h3>Item Name</h3>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <div className="head player-table-auction">
                                                            <h3>Hours Remaining</h3>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <div className="head player-table-auction">
                                                            <h3>Seller</h3>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="head player-table-auction">
                                                            <h3>Current Bid</h3>
                                                        </div>
                                                    </div>
                                                </div>

                                                {
                                                    currentItems ? currentItems?.map((val, key) => (
                                                        <div
                                                            key={key}
                                                            className={`row for-allign-table-center ${select === val?.id && 'active-auction-item'}`}
                                                            onClick={() => setSelect(val?.id)}
                                                        >
                                                            <div className="col-md-4">
                                                                <div className="status-list table-player-bolt-amber">
                                                                    <div className="bolt-img-text">
                                                                        <img src={val?.shop?.image_url} width={"20%"} />
                                                                        <h4>{val?.shop?.title}</h4>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-2">
                                                                <div className="status-list table-player-bolt-amber">
                                                                    <h5>
                                                                        <CountDownTimer
                                                                            item={val}
                                                                            time={getSecondsFromStartEnd(
                                                                                `${val?.start_date} ${val?.start_time}`,
                                                                                (val?.status === 1) ? `${val?.end_date} ${val?.end_time}` : `${val?.start_date} ${val?.start_time}`
                                                                            )}
                                                                        />
                                                                    </h5>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-2">
                                                                <div className="status-list table-player-bolt-amber">
                                                                    <h5>{val?.owner?.name}</h5>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-4">
                                                                <div
                                                                    className="status-list table-player-bolt-amber  for-table-flex ">
                                                                    {
                                                                        currency?.filter((value) => value?.slug === val?.currency_slug)
                                                                            ?.map((value, key1) => {
                                                                                console.log("Key", val?.id, val?.latest_bid?.amount, val?.currency_slug, value?.slug)
                                                                                return <React.Fragment key={key1}>
                                                                                    {
                                                                                        (() => {
                                                                                            if (value?.slug === "dead_dimes_coins") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <div
                                                                                                            className="bolt-only-img">
                                                                                                            <img
                                                                                                                src={value?.icon_url}
                                                                                                                width={'25%'} />
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="for-numbers">
                                                                                                            <h5>{parseInt(val?.latest_bid?.amount).toLocaleString() || 0}</h5>
                                                                                                        </div>
                                                                                                    </>
                                                                                                )
                                                                                            } else if (value?.slug === "kilz_token_coins") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <div
                                                                                                            className="bolt-only-img">
                                                                                                            <img
                                                                                                                src={value?.icon_url}
                                                                                                                width={'25%'} />
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="for-numbers">
                                                                                                            <h5>{parseInt(val?.latest_bid?.amount).toLocaleString()}</h5>
                                                                                                        </div>
                                                                                                    </>
                                                                                                )
                                                                                            } else {
                                                                                                return (
                                                                                                    <>
                                                                                                        <div
                                                                                                            className="bolt-only-img">
                                                                                                            <img
                                                                                                                src={value?.icon_url}
                                                                                                                width={'25%'} />
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="for-numbers">
                                                                                                            <h5>{parseInt(val?.latest_bid?.amount).toLocaleString()}</h5>
                                                                                                        </div>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        })()
                                                                                    }
                                                                                </React.Fragment>
                                                                            })
                                                                    }


                                                                    {/*<div className="for-numbers">*/}
                                                                    {/*    <h5>10</h5>*/}
                                                                    {/*</div>*/}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )) : ''
                                                }
                                            </div>
                                            <div className="forBottom-border py-1">
                                                <figure>
                                                    <img className="w-100" src={PlayerBottom} alt="" />
                                                </figure>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {
                                            currency?.map((val, key) => {
                                                return <React.Fragment>
                                                    {
                                                        (() => {
                                                            if (val?.slug === "dead_dimes_coins") {
                                                                return (
                                                                    <div key={key} className={"col-3"}>
                                                                        <div className="Player-statusDv-bottom-all player-bottom-auction m-0">
                                                                            <div className="all-dv-of-player-bottom-auction">
                                                                                <div className=" all-dv-bottom-auc">
                                                                                    <figure className={"rounded-circle"}>
                                                                                        <img src={val?.icon_url} width={"100%"} />
                                                                                    </figure>
                                                                                    <h3 className={"text-white ml-1"}>{parseInt(user?.dead_coins)?.toLocaleString()}</h3>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                )
                                                            } else if (val?.slug === "kilz_token_coins") {
                                                                return (
                                                                    <div key={key} className={"col-3"}>
                                                                        <div className="Player-statusDv-bottom-all player-bottom-auction m-0">
                                                                            <div className="all-dv-of-player-bottom-auction">
                                                                                <div className=" all-dv-bottom-auc">
                                                                                    <figure className={"rounded-circle"}>
                                                                                        <img src={val?.icon_url} width={"100%"} />
                                                                                    </figure>
                                                                                    <h3 className={"text-white ml-1"}>{parseInt(user?.kills_coins)?.toLocaleString()}</h3>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            } else {
                                                                return (
                                                                    <div key={key} className={"col-3"}>
                                                                        <div className="Player-statusDv-bottom-all player-bottom-auction m-0">
                                                                            <div className="all-dv-of-player-bottom-auction">
                                                                                <div className=" all-dv-bottom-auc">
                                                                                    <figure className={"rounded-circle"}>
                                                                                        <img src={val?.icon_url} width={"100%"} />
                                                                                    </figure>
                                                                                    <h3 className={"text-white ml-1"}>{parseInt(user?.coins)?.toLocaleString()}</h3>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        })()
                                                    }
                                                </React.Fragment>
                                            })
                                        }
                                        {/*<div className="col-9">*/}
                                        {/*    <div className="Player-statusDv-bottom-all player-bottom-auction">*/}
                                        {/*        <div className="all-dv-of-player-bottom-auction">*/}

                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        <div className="col-3">
                                            {
                                                select !== 0 &&
                                                <div className="player-auc-button auction-bottom_btn">
                                                    <Link to={`/auction/view/${select}`}
                                                        className="btn custom-button form-control">Bid</Link>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                </section>
            )}
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getProfile: () => dispatch(getProfileRequest()),
    }
}

export default connect(null, mapDispatchToProps)(NewAuction);