import React, { useState } from 'react';
import {connect} from "react-redux";

import Logo from "../../components/Logo";
import PDLBorder from "../../components/PDLBorder";
import '../../assets/css/login.css';
import { Link, useNavigate } from "react-router-dom";
import loginbg from "../../assets/img/loginbg.png";
import { forgetPasswordRequest } from '../../redux/Auth/action';

const Forgot = (props) => {

    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const navigate = useNavigate();


    const onChangeHandler = (event) => {
        let { name, value } = event.target;
        setEmail(value);
    }


    const onSubmitHandler = async (event) => {
        event.preventDefault();
        setLoading(true);
        props.forgetPassword(email)
            .then(() => {
                navigate("/login", { replace: true });
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                setLoading(false);
            });
    }


    return (
        <>
            <section className="fram-sec">
                <div className="frame-wrapper" style={{
                    backgroundImage : `url('${loginbg}')`,
                    backgroundSize: '100% 100%',
                    backgroundRepeat: 'no-repeat',
                    padding: '3rem 2rem',
                    margin: '4.5rem 2rem',
                    position: 'relative',
                }}>
                    <PDLBorder />

                    <Logo />

                    <div className="row">
                        <div className="col-md-7">

                        </div>
                        <div className="col-md-5">

                        </div>
                    </div>

                    <div className="login">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-4">
                                </div>
                                <div className="col-lg-4">
                                    <div className="login-wrapper">
                                        <form action="" onSubmit={onSubmitHandler}>
                                            <h4 className="text-center">Recovery</h4>

                                            <div className="form-group">
                                                <input type="email" name='email' onChange={onChangeHandler} className="login-field form-control"
                                                       placeholder="Email" />
                                            </div>
                                            {/*<div className="form-group row marginOneRem">*/}
                                            {/*    <div className="col-md-6 form-check">*/}
                                            {/*        <input type="checkbox" className="form-check-input"*/}
                                            {/*               id="exampleCheck1" />*/}
                                            {/*            <label className="form-check-label" htmlFor="exampleCheck1">Remember*/}
                                            {/*                me</label>*/}
                                            {/*    </div>*/}
                                            {/*    <div className="col-md-6 text-right">*/}
                                            {/*        <a href="" className="forget-password">Forgot Password?</a>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            <div className="form-group marginOneRem">
                                                {
                                                    loading ?
                                                        <button type="button" className="form-submit">
                                                            <i className={"fa fa-spinner fa-spin"}></i>
                                                        </button>
                                                        :
                                                        <button type="submit" className="form-submit">
                                                            Submit
                                                        </button>
                                                }
                                            </div>
                                            <div className="form-group text-center marginOneRem">
                                                <span className="create-account">Don't have account sign-up &nbsp;
                                                    <Link to={'/signup'}>Sign up</Link>
                                                </span>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                                <div className="col-lg-4">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        welcome: state.Auth,
        token: state.Auth.token,
        user: state.Auth.user,

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        forgetPassword: (data) => dispatch(forgetPasswordRequest(data)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Forgot);