import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
// import '../assets/css/warrior2.css';
import PlayLogo from "./PlayLogo";
import {Link} from "react-router-dom";
import PlayBg from "../assets/img/playbg.png";
import banner1 from "../assets/img/banner1.png";
import banner2 from "../assets/img/banner2.png";
import banner3 from "../assets/img/banner3.png";
import banner4 from "../assets/img/banner4.png";
import banner5 from "../assets/img/banner5.png";
import banner6 from "../assets/img/banner6.png";
import bannerHeading1 from "../assets/img/banner-heading1.png";
import bannerHeading2 from "../assets/img/banner-heading22.png";
import bannerHeading3 from "../assets/img/banner-heading3.png";
import bannerHeading4 from "../assets/img/banner-heading4.png";
import bannerHeading5 from "../assets/img/banner-heading55.png";
import bannerHeading6 from "../assets/img/banner-heading6.png";
import auctionBack from "../assets/img/crastle-back.png";
import PDLBorder from "./PDLBorder";
import Logo from "./Logo";
import AuthHeader from "./AuthHeader";



const Characters = (props) => {

    const [singleClass , setSingleClass] = useState(props.singleClass || null);
    const onSelectClass = (val) => {
        setSingleClass(val);
    }
    useEffect(()=>{
        setSingleClass(props.singleClass);
        console.log(props.singleClass);
    },[props.singleClass]);

    return (
        <>
            {
                props.islogin ?
                    <section className="fram-sec">
                        <div className={"frame-wrapper"} style={{
                            backgroundImage : `url('${auctionBack}')`,
                            backgroundSize: '100% 100%',
                            backgroundRepeat: 'no-repeat',
                            padding: '3rem 2rem',
                            margin: '4.5rem 2rem',
                            position: 'relative',
                        }}>
                            {/* Border*/}
                            <PDLBorder />
                            {/*Logo*/}
                            <Logo />

                            {/*Menus*/}
                            <div className="row">
                                {/*<div className="col-md-7">*/}

                                {/*</div>*/}
                                <div className="col-md-12">
                                    <div className="Menus text-right">
                                        <AuthHeader/>
                                    </div>
                                </div>
                            </div>
                            {/*Menus*/}

                            {/*Main Content Start Here*/}
                            <section className="home_sec">
                                <div className="all-images">
                                    <div className="img-box">
                                        <div className="picture-box">
                                            <figure><img src={banner1} className="img-fluid" alt="" /></figure>
                                        </div>
                                        <div className="txt-box">
                                            <figure><img src={bannerHeading1} alt="" className="img-fluid" />
                                            </figure>
                                            <div className="button-group">
                                                <Link to={"/profile"} className="btn">Play</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="img-box">
                                        <div className="picture-box">
                                            <figure><img src={banner2} className="img-fluid" alt="" /></figure>
                                        </div>
                                        <div className="txt-box">
                                            <figure><img src={bannerHeading2} alt="" className="img-fluid" />
                                            </figure>
                                            <div className="button-group">
                                            
                                            <Link to={"/heroes-villains"} className="btn">Play</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="img-box">
                                        <div className="picture-box">
                                            <figure><img src={banner3} className="img-fluid" alt="" /></figure>
                                        </div>
                                        <div className="txt-box">
                                            <figure><img src={bannerHeading3} alt="" className="img-fluid" />
                                            </figure>
                                            <div className="button-group">
                                                <Link to={"/guild"} className="btn">Play</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="img-box">
                                        <div className="picture-box">
                                            <figure><img src={banner4} className="img-fluid" alt="" /></figure>
                                        </div>
                                        <div className="txt-box">
                                            <figure><img src={bannerHeading4} alt="" className="img-fluid" />
                                            </figure>
                                            <div className="button-group">
                                                <Link to={'/auction'} className="btn">Play</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="img-box">
                                        <div className="picture-box">
                                            <figure><img src={banner5} className="img-fluid" alt="" /></figure>
                                        </div>
                                        <div className="txt-box">
                                            <figure><img src={bannerHeading5} alt="" className="img-fluid" />
                                            </figure>
                                            <div className="button-group">
                                            <Link to={"/gallery"} className="btn">Play</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="img-box">
                                        <div className="picture-box">
                                            <figure><img src={banner6} className="img-fluid" alt="" /></figure>
                                        </div>
                                        <div className="txt-box">
                                            <figure><img src={bannerHeading6} alt="" className="img-fluid" />
                                            </figure>
                                            <div className="button-group">
                                                <Link to={'/crafting'} className="btn">Play</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <div className="d-none main-content">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-lg-3">
                                            <h4 style={{ fontSize:'18px'}}
                                                className={"char-name pdlColor pdlFont my-1"}>
                                                CHOOSE YOUR CLASS
                                            </h4>

                                            <div className="character-list">

                                                {
                                                    props.character.map((val,key)=>{
                                                        return <div key={key} className={`character-wrapper ${ singleClass && (val.id === singleClass.id)? 'active-wr' : ''}`}>
                                                            {/*<a to={`/character/${val?.slug}`}>*/}
                                                            <a onClick={()=>onSelectClass(val)}>
                                                                <figure>
                                                                    <img src={val?.front_image_url} className="char-img img-fluid" alt=""/>
                                                                    <h3 style={{ fontSize:'21px',}} className={"char-name pdlColor pdlFont"}>{val?.title}</h3>
                                                                    {/*<img src={Sniper} className=" img-fluid" alt=""/>*/}
                                                                </figure>
                                                            </a>
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        </div>
                                        <div className="col-lg-6 text-center">
                                            {
                                                // ((singleClass) && (singleClass?.slug === "sniper"))
                                                // &&
                                                <div className="main-charater-img">
                                                    <figure>
                                                        <img src={singleClass?.avatar_image_url}
                                                             className="animate__animated animate__fadeInDown animate__delay-1s img-fluid"
                                                             alt="" />
                                                    </figure>
                                                </div>
                                            }
                                        </div>
                                        {
                                            singleClass
                                            &&
                                            <div className="col-lg-3">
                                                <div className="character-specification">
                                                    <figure>
                                                        <h3 style={{fontSize:'21px',}} className={"char-name pdlColor pdlFont"}>
                                                            {singleClass?.title}
                                                        </h3>
                                                        {/*<img src={TheWarrior} className="char-name img-fluid" alt=""/>*/}
                                                    </figure>
                                                    <p className="golden-color">{singleClass?.description}</p>
                                                    <div className="character-progress-wrapper">
                                                        <div className="character-progress">
                                                            <div className="spec-name-and-percent">
                                                                <h6>Health</h6>
                                                                <h5>{singleClass?.health}/10</h5>
                                                            </div>
                                                            <div className="progress">
                                                                <div className="progress-bar golden-bar" role="progressbar"
                                                                     style={{width: `${parseInt(singleClass?.health) * 10 }%`}} aria-valuenow={`${parseInt(singleClass?.health) * 10 }`} aria-valuemin="0"
                                                                     aria-valuemax="100"></div>
                                                            </div>
                                                        </div>
                                                        <div className="character-progress">
                                                            <div className="spec-name-and-percent">
                                                                <h6>Damage</h6>
                                                                <h5>{singleClass?.damage}/10</h5>
                                                            </div>
                                                            <div className="progress">
                                                                <div className="progress-bar golden-bar" role="progressbar"
                                                                     style={{width: `${parseInt(singleClass?.health) * 10 }%`}} aria-valuenow={`${parseInt(singleClass?.health) * 10 }`} aria-valuemin="0"
                                                                     aria-valuemax="100"></div>
                                                            </div>
                                                        </div>
                                                        <div className="character-progress">
                                                            <div className="spec-name-and-percent">
                                                                <h6>Base</h6>
                                                                <h5>{singleClass?.base}/10</h5>
                                                            </div>
                                                            <div className="progress">
                                                                <div className="progress-bar golden-bar" role="progressbar"
                                                                     style={{width: `${parseInt(singleClass?.health) * 10 }%`}} aria-valuenow={`${parseInt(singleClass?.health) * 10 }`} aria-valuemin="0"
                                                                     aria-valuemax="100"></div>
                                                            </div>
                                                        </div>
                                                        <div className="character-progress">
                                                            <div className="spec-name-and-percent">
                                                                <h6>Armor Pen</h6>
                                                                <h5>{singleClass?.armor_pen}/10</h5>
                                                            </div>
                                                            <div className="progress">
                                                                <div className="progress-bar golden-bar" role="progressbar"
                                                                     style={{width: `${parseInt(singleClass?.health) * 10 }%`}} aria-valuenow={`${parseInt(singleClass?.health) * 10 }`} aria-valuemin="0"
                                                                     aria-valuemax="100"></div>
                                                            </div>
                                                        </div>
                                                        <div className="character-progress">
                                                            <div className="spec-name-and-percent">
                                                                <h6>Armor Pen Resist</h6>
                                                                <h5>{singleClass?.armor_pen_resist}/10</h5>
                                                            </div>
                                                            <div className="progress">
                                                                <div className="progress-bar golden-bar" role="progressbar"
                                                                     style={{width: `${parseInt(singleClass?.health) * 10 }%`}} aria-valuenow={`${parseInt(singleClass?.health) * 10 }`} aria-valuemin="0"
                                                                     aria-valuemax="100"></div>
                                                            </div>
                                                        </div>
                                                        <div className="character-progress">
                                                            <div className="spec-name-and-percent">
                                                                <h6>ATT</h6>
                                                                <h5>{singleClass?.att}/10</h5>
                                                            </div>
                                                            <div className="progress">
                                                                <div className="progress-bar golden-bar" role="progressbar"
                                                                     style={{width: `${parseInt(singleClass?.health) * 10 }%`}} aria-valuenow={`${parseInt(singleClass?.health) * 10 }`} aria-valuemin="0"
                                                                     aria-valuemax="100"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="theme-btn-wrapper mt-5">
                                                        <Link to={'/character-creation'} className="btn btn-theme">Create Character</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    :
                    <section className="fram-sec">
                        <div className="frame-wrapper playBg" style={{
                            backgroundImage : `url('${PlayBg}')`,
                            backgroundSize: '100% 100%',
                            backgroundRepeat: 'no-repeat',
                            padding: '3rem 2rem',
                            margin: '4.5rem 2rem',
                            position: 'relative',
                        }}>
                            {/* Border*/}
                            <PDLBorder />
                            {/*Logo*/}
                            <Logo />

                            {/*Menus*/}
                            <div className="row">
                                <div className="col-md-7">

                                </div>
                                <div className="col-md-5">
                                    <div className="Menus text-right">
                                        <AuthHeader/>
                                    </div>
                                </div>
                            </div>
                            {/*Menus*/}

                            {/*Main Content Start Here*/}
                            <div className="main-content">
                                <div className="container-fluid">
                                    <div className="row playrow">
                                        <div className="col-lg-3">

                                        </div>
                                        <div className="col-lg-6 text-center">
                                            <div className="main-charater-img">
                                                <figure>
                                                    <PlayLogo/>
                                                </figure>
                                                <div className="btn-wrapper animate__animated animate__fadeInUpBig mt-5">
                                                    <Link to={props.islogin ? '/' : '/login'} className=" btn-theme">Next</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
            }
        </>
);
}

const Filter = (characters) => {
    let x = characters.filter((val,key)=> key === 0);
    return x.length>0?x[0] : null
}

const mapStateToProps = (state) => {
    return {
        islogin: state.Auth.islogin,
        character : state.Site.characters,
        singleClass : Filter(state.Site.characters || []),
    }
}

const mapDispatchToProps = () => {

}

export default connect(mapStateToProps)(Characters);