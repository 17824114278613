import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import PDLBorder from "../../components/PDLBorder";
import Logo from "../../components/Logo";
import AuthHeader from "../../components/AuthHeader";
import PlayerBottom from '../../assets/img/playerbottom.png';
import PlayerTop from '../../assets/img/playertop.png';
import { getItemRequest, removeGemGlyphsItemRequest, updateItemRequest } from "../../redux/Inventory/action";
import { setLoader } from "../../redux/Site/action";
import Loader from "../NotFound/Loader";
import { Link, useNavigate, useParams } from "react-router-dom";
import NotFound from "../NotFound/NotFound";
import '../../assets/css/inventory-detail.css';
import { showLoader } from "../../redux/helpers";
import GemsGlyphs from "../../components/Modals/gems_glyphs";
import Confirm from "../../components/Modals/Confirm";
import PowerConfirm from "../../components/Modals/PowerConfirm";
import AssignCharacters from "../../components/Modals/AssignCharacters";
import AddAuction from "../../components/Modals/AddAuction";


const ItemsDetails = (props) => {

    const excludedSlugs = ['pets', 'Potions'];

    const params = useParams();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [assignCharacter, setAssignCharacter] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [powerConfirm, setPowerConfirm] = useState(false);
    const [auctionModal, setAuctionModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [powerItemId, setPowerItemId] = useState(0);
    const [id, setId] = useState(0);

    const get = async () => {
        props.setLoader(true);
        await props.getItem(params?.id);
        props.setLoader(false);

    }

    const buy = () => {
        setLoading(true);
        props.buyItem({ item_id: params?.id })
            .then(() => {
                setLoading(false);
                console.log("/inventory");
                navigate('/inventory');
            })
            .catch(() => {
                setLoading(false);
            });
    }

    const updateItemFor = async (id, item_for) => {
        showLoader('flex');
        await props.updateItem({ id: id, item_for: item_for });
        showLoader('none');
    }

    const removeItem = async (id) => {
        // showLoader('flex');
        props.removeGemGlyphsItem(id)
            .then(async () => {
                await props.getItem(params?.id);
                console.log("getItem", params?.id);
                // showLoader('none');
            })
            .catch(() => {
                // showLoader('none');
            });
        // showLoader('none');
    }

    const openConfirmModal = (id) => {
        setId(id);
        setOpenConfirm(true);
    }

    useEffect(() => {

        return () => {

        }
    }, []);

    useEffect(() => {
        get();
    }, [params?.id]);

    if (props.loader) return <Loader />

    if (!props.item) return <NotFound />

    return (
        <section className="fram-sec">
            <div className="frame-wrapper">

                {/* Border*/}
                <PDLBorder />
                {/*Logo*/}
                <Logo />

                {/*Menus*/}
                <div className="row">
                    {/*<div className="col-md-7">*/}

                    {/*</div>*/}
                    <div className="col-md-12">
                        <div className="Menus text-right">
                            <AuthHeader />
                        </div>
                    </div>
                </div>
                {/*Menus*/}
                <section className="inventory-detailed">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-5">
                                <div className="forTop-border py-1">
                                    <figure><img className="w-100" src={PlayerTop} alt="" /></figure>
                                </div>
                                <div className="Player-statusDv">
                                    <div className="head">
                                        <h3 className="title">
                                            Item Stats
                                        </h3>
                                    </div>
                                    <ul className="status-list">
                                        <li>
                                            <h4>Level</h4>
                                            <span>{props.item?.shop?.level}</span>
                                        </li>
                                        <br />
                                        <li>
                                            <h4>Health</h4>
                                            <span>{props.item?.health}
                                                <span className={"text-success"}>({props.item?.shop?.health?.toLocaleString()})</span>
                                            </span>
                                        </li>
                                        <br />
                                        <li>
                                            <h4>Base</h4>
                                            <span>{props.item?.base}
                                                <span className={"text-success"}>({props.item?.shop?.base?.toLocaleString()})</span>
                                            </span>
                                        </li>
                                        <br />

                                        <li>
                                            <h4>Damage</h4>
                                            <span>{props.item?.damage}
                                                <span className={"text-success"}>({props.item?.shop?.damage?.toLocaleString()})</span>
                                            </span>
                                        </li>
                                        <br />

                                        <li>
                                            <h4>Armor Pen</h4>
                                            <span>{props.item?.armor_pen}
                                                <span className={"text-success"}>({props.item?.shop?.armor_pen?.toLocaleString()})</span>
                                            </span>
                                        </li>
                                        <br />

                                        <li>
                                            <h4>Armor Pen Resist</h4>
                                            <span>{props.item?.armor_pen_resist}
                                                <span className={"text-success"}>({props.item?.shop?.armor_pen_resist?.toLocaleString()})</span>
                                            </span>
                                        </li>

                                        <br />
                                        <li>
                                            <h4>Quantity</h4>
                                            <span>{props.item?.qty}</span>
                                        </li>

                                        <br />
                                        {
                                            (props.item?.shop && props.item?.shop?.enhance_items.length > 0) &&
                                            <li>
                                                <h4>No of Times Enhance</h4>
                                                <span>{props.item?.enhance_history_count_count}</span>
                                            </li>
                                        }

                                    </ul>
                                </div>
                                <div className="forBottom-border py-1">
                                    <figure><img className="w-100" src={PlayerBottom} alt="" /></figure>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="product-details">
                                    <div className="heading">
                                        <h1 className="title">{props.item?.shop?.title}</h1>
                                        {
                                            props?.item?.equip === 0 &&
                                            <div className={"d-flex flex-row align-items-center"}>
                                                    <a onClick={() => setOpen(true)}
                                                        className={" text-decoration-underline"}
                                                        style={{
                                                            color: '#FFE698',
                                                            fontSize: 18,
                                                        }}>
                                                    Add Power Item
                                                </a>

                                                <span style={{
                                                    color: '#e0b96b',
                                                    textAlign: 'center',
                                                    margin: '0 0 0 10px',
                                                    fontSize: '18px'
                                                }}>|</span>

                                                    <a onClick={() => setAssignCharacter(true)}
                                                        className={"ml-2 text-decoration-underline"}
                                                        style={{
                                                            color: '#FFE698',
                                                            fontSize: 18,
                                                        }}>
                                                    Assign Character
                                                </a>

                                            </div>
                                        }
                                    </div>
                                    <div className="image-with-btn">
                                        <div className="img-box">
                                            <figure><img width={'100%'} src={props.item?.shop?.image_url} alt="" /></figure>
                                        </div>
                                    </div>
                                    <div className={"row my-5"}>
                                        {
                                            props.item?.power_items.map((val, key) => {
                                                return <div key={key} className={"col-2 mx-1 p-0 rounded"} style={{
                                                    border: `2px solid #E3BF4B`, position: 'relative',

                                                }}>
                                                    {
                                                        props?.item?.equip === 0 &&
                                                        <span onClick={() => openConfirmModal(val?.id)} style={{
                                                            position: 'absolute',
                                                            background: 'red',
                                                            right: 0,
                                                            padding: '0px 6px',
                                                        }}>
                                                            <i className={"fa fa-times text-white"}></i>
                                                        </span>
                                                    }


                                                    <figure className={"m-0"}><img width={'100%'} src={val?.item?.shop?.image_url} alt="" /></figure>
                                                </div>
                                            })
                                        }
                                    </div>
                                    {/*<div className="description">*/}
                                    {/*    <p>{props.item.}</p>*/}
                                    {/*</div>*/}
                                    <div className="description">
                                        <p>{props?.item?.shop?.description}</p>
                                    </div>

                                    <div className="button-group my-2">
                                        {
                                            (props.item?.shop && props.item?.shop?.is_trade === 1) &&
                                            <Link to={'/user/search/trade'} state={{
                                                    id: props.item?.id,
                                            }} className="btn customButton">Trade</Link>
                                        }


                                        {
                                            (props.item?.shop && props.item?.shop?.enhance_items.length > 0) &&
                                            <>
                                                {/*{*/}
                                                {/*    (!props.item.power_items.length > 0) &&*/}
                                                <Link to={'/enhance/' + props.item?.id} className="btn">Enhance</Link>
                                                {/*}*/}
                                            </>
                                        }
                                    </div>

                                    <div className="button-group my-2">
                                        {
                                            (props.item?.shop && props.item?.shop?.is_auction === 1) &&
                                            <button type={'button'} onClick={() => setAuctionModal(true)} className="btn customButton">Sell on Auction</button>
                                        }
                                    </div>

                                    {

                                        (
                                            props.item?.category?.placement === "left" ||
                                            props.item?.category?.placement === "right" ||
                                            props.item?.category?.placement === "bottom" ||
                                            (props.item?.category?.equip == 1 && props.item?.category?.equip_show == 0)
                                        )
                                        &&
                                        <div className="button-group my-2">
                                                {

                                                    !excludedSlugs.includes(props.item?.category?.slug) ?
                                                        <>
                                                            <button type={"button"}
                                                                onClick={() => updateItemFor(props.item?.id,
                                                                    (props.item?.item_for === 1 ? 2 : 1)
                                                                )}
                                                                className={`btn ${props.item?.item_for === 1 && 'active'}`}>
                                                                PVP
                                                            </button>
                                                            <button type={"button"}
                                                                onClick={() => updateItemFor(props.item?.id,
                                                                    (props.item?.item_for === 0 ? 2 : 0)
                                                                )}
                                                                className={`btn ${props.item?.item_for === 0 && 'active'}`}>
                                                                PVE
                                                            </button>
                                                        </> : ''
                                                }
                                        </div>
                                    }


                                    <div className="button-group my-2">
                                        <Link to={'/inventory'} className="btn customButton">Back</Link>
                                        {
                                            (props.item?.shop && props.item?.shop?.is_gift === 1) &&
                                            <Link to='/gift' state={{
                                                id: props.item?.id,
                                            }} className="btn">Gift</Link>
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>

            <GemsGlyphs id={params?.id}
                inventoryItemId={props.item?.id}
                setOpen={setOpen}
                setPowerConfirm={setPowerConfirm}
                setPowerItemId={setPowerItemId}
                powerItemId={powerItemId}
                open={open} />

            <Confirm open={openConfirm} setOpen={setOpenConfirm} paramId={params?.id} id={id} removeItem={removeItem} />

            <PowerConfirm
                item_id={powerItemId}
                open={powerConfirm}
                setOpen={setPowerConfirm}
                id={params?.id}
                inventoryItemId={props.item?.id}
            />

            <AddAuction
                item={props?.item}
                open={auctionModal} setOpen={setAuctionModal}
                id={params?.id} inventoryItemId={props.item?.id}
            />

            <AssignCharacters
                open={assignCharacter} setOpen={setAssignCharacter}
                id={params?.id}
            />
        </section>
    );
}

const mapStateToProps = (state) => {
    return {
        item: state.Inventory.item,
        loader: state.Site.loader,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setLoader: (bool) => dispatch(setLoader(bool)),
        getItem: (id) => dispatch(getItemRequest(id)),
        updateItem: (data) => dispatch(updateItemRequest(data)),
        removeGemGlyphsItem: (id) => dispatch(removeGemGlyphsItemRequest(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemsDetails);