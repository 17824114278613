import React, {useEffect, useState} from 'react';
import {Link, Route, Routes, useNavigate} from "react-router-dom";
import {connect, useDispatch} from "react-redux";
import {Button, Modal} from "react-bootstrap";
import Profile from '../../assets/img/profile.jpg';
import {acceptRequest, rejectRequest, resetTime, resetTrade, setNewTradeRequest} from "../../redux/TradeRequest/action";
import {showLoader, startTimer} from "../../redux/helpers";
import {pusher} from "../../redux/pusherConfig";
import {addPlayerRequest, getGuildRequest, joinGuildRequest, setItemGuildRequest} from "../../redux/Guild/action";
import {toast} from "react-toastify";
import axios from "axios";
import {headersWithToken} from "../../redux/headers";
import {getGuildPlayerApi, site_web_url} from "../../redux/apis";
import {getItemsRequest, returnToInventoryRequest} from "../../redux/Inventory/action";
import ItemWrapper from "../ItemWrapper";
import {setLoader} from "../../redux/Site/action";

function BagsItems(props) {

    const [isloading, setIsloading] = useState(false);
    const dispatch = useDispatch();

    const closeModal = () => {
        props.setOpen(false);
    }

    const submitHandler = async () => {
        window.location.href = `${site_web_url}export-bags/${props?.character_id}/${props?.user?.id}/${props?.bagNo}`
    }

    const returnToBag = async (id,bagNo) => {
        props.setLoader(true);
        await dispatch(returnToInventoryRequest(id,bagNo));
        props.setLoader(false);
    }

    // console.log("items",props?.items,props?.bagNo);


    return (
        <Modal
            show={props.open}
            size={"lg"}
            aria-labelledby={"contained-modal-title-vcenter"}
            centered
            onHide={closeModal}
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter my-3">
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4 className={"text-center my-3"}>
                    Bag {props?.bagNo}
                </h4>

                <div className="row justify-content-center">
                    {
                        props.items
                            ?.filter((val) => parseInt(val?.bag_no) === parseInt(props?.bagNo))
                            ?.map((val, key) => {
                                return <ItemWrapper
                                    key={key}
                                    item={{
                                        title : val?.shop?.title,
                                        health : val?.health,
                                        base : val?.base,
                                        damage : val?.damage,
                                        armor_pen : val?.armor_pen,
                                        armor_pen_resist : val?.armor_pen_resist,
                                    }}
                                    className="col-2">
                                    {/*<Link to={'/inventory/'+val?.id}>*/}
                                    <div className={`inventory-itemBox1`}
                                         style={{
                                             textAlign: 'center',
                                             borderStyle: 'solid',
                                             borderWidth: '2px',
                                             borderImageSource: 'linear-gradient(to right,#b88425,#c5973f,#d2a957,#e0bc6f,#edcf88,#efd28b,#f1d58f,#f3d892,#eacb80,#e1bf6f,#d9b25d,#d0a64c)',
                                             // height: '70px',
                                             width: '100%',
                                             borderImageSlice: '1',
                                             marginBottom: '10px',
                                             display: 'flex',
                                             alignItems: 'center',
                                             justifyContent: 'center',
                                             position: 'relative',
                                             cursor: 'pointer',
                                             // ...(items.id == val?.id)? {
                                             //     borderStyle: 'solid',
                                             //     borderWidth: '2px',
                                             //     borderImageSource: 'linear-gradient(to right,#b88425,#c5973f,#d2a957,#e0bc6f,#edcf88,#efd28b,#f1d58f,#f3d892,#eacb80,#e1bf6f,#d9b25d,#d0a64c)',
                                             // } : {}
                                         }}>

                                        <figure className={"m-0"}>
                                            <img
                                                width={'100%'}
                                                src={val?.shop?.image_url}
                                                alt=""
                                            />
                                        </figure>

                                        <div className="qualityCounter">
                                            <span className="large">
                                                {val?.qty}
                                            </span>
                                        </div>

                                        <div className="returnToBag"
                                             onClick={()=>returnToBag(val?.id,props?.bagNo)}>
                                            <span className="large">
                                                <i className={"fa fa-arrow-left"}></i>
                                            </span>
                                        </div>

                                    </div>
                                    {/*</Link>*/}
                                </ItemWrapper>
                            })
                    }
                    {
                        [...Array(
                            25 - props.items?.filter((val) => parseInt(val?.bag_no) === parseInt(props?.bagNo)).length
                        )].map((val, key) => (
                            <div key={key} className="col-2">
                                <div className={`inventory-itemBox1`}
                                     style={{
                                         textAlign: 'center',
                                         borderStyle: 'solid',
                                         borderWidth: '2px',
                                         borderImageSource: 'linear-gradient(to right,#b88425,#c5973f,#d2a957,#e0bc6f,#edcf88,#efd28b,#f1d58f,#f3d892,#eacb80,#e1bf6f,#d9b25d,#d0a64c)',
                                         width: '100%',
                                         borderImageSlice: '1',
                                         marginBottom: '10px',
                                         display: 'flex',
                                         alignItems: 'center',
                                         justifyContent: 'center',
                                         position: 'relative',
                                         cursor: 'pointer',
                                         height: '130px',
                                     }}>

                                    <figure className={"m-0"}>
                                        {/*<img width={'100%'}  src={val?.inventory?.item?.image_url} alt="" />*/}
                                    </figure>
                                </div>
                            </div>
                        ))
                    }
                </div>

                <div className="row mt-5 d-flex justify-content-center">
                    <Button className={"btn btn-success"} onClick={closeModal}>Cancel</Button>
                    {
                        isloading ?
                            <Button className={"btn btn-success"} type={"button"}>
                                <i className={"fas fa-spinner fa-spin"}></i>
                            </Button>
                            :
                            <Button type={"button"} onClick={submitHandler}
                                    className={"btn btn-success"}>Export</Button>
                    }
                </div>

            </Modal.Body>
        </Modal>
    );
}

const mapStateToProps = (state) => {
    return {
        items: state.Inventory.bags,
        user: state.Auth.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setLoader: (bool) => dispatch(setLoader(bool)),
        getGuild: (id) => dispatch(getGuildRequest(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BagsItems);
