import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import Logo from "../../components/Logo";
import '../../assets/css/trade.css';
import AuthHeader from "../../components/AuthHeader";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {
    addItemInTrade,
    addItemInTradeRequest, finishedRequest, getTradeHistory,
    getTradeMakeRequest,
    markAsCompleteRequest,
    setTrade, tradeCancelRequest
} from "../../redux/Trade/action";
import {showLoader} from "../../redux/helpers";
import PDLBorder from "../../components/PDLBorder";
import {pusher} from "../../redux/pusherConfig";
import {setAlert} from "../../redux/Alert/action";
import {filterItemsByCategoryRequest, getItemsRequest} from "../../redux/Inventory/action";
import {resetTime, resetTrade} from "../../redux/TradeRequest/action";
import TradeQuantity from "../../components/Modals/TradeQuantity";
import Category from "../../components/Category";
import SearchInventory from "../../components/SearchInventory";

const Trade = (props) => {

    const params = useParams();
    const location = useLocation();
    const navigator = useNavigate();
    const [open , setOpen] = useState(false);
    const [modalItem,setModalItem] = useState(null);
    const [yourOffer , setYourOffer] = useState(null);
    const [otherOffer , setOtherOffer] = useState(null);
    const [type , setType] = useState(null);
    const [timeInterval , setTimeInterval] = useState(null);
    const [history,setHistory] = useState(props?.trade?.history || []);

    const filterByCategory = async (category_id) => {
        showLoader('flex');
        await props.filterItemsByCategory(category_id);
        showLoader('none');
    }

    const get = async () => {
        showLoader('flex');
        await props.getTradeMake(params?.id);
        await props.getItems();
        showLoader('none');
    }

    const rejectRequestPusher = () => {
        let config = pusher(props.token);
        let channel = config.subscribe(`trade-reject-${props.user?.id}`);
        channel.bind('trade-reject', function (data) {
            props.resetTime(0);
            props.setAlert([{
                message : 'Your Trading request has been rejected.',color : 'info',
            }]);
            clearInterval(timeInterval);
            navigator('/user/search/trade');
        });
    }

    const acceptRequestPusher = () => {
        let config = pusher(props.token);
        let channel = config.subscribe(`trade-accept-${props.user?.id}`);
        channel.bind('trade-accept', function (data) {
            console.log("Accept",timeInterval);
            props.resetTime(0);
            clearInterval(timeInterval);
            props.setAlert([{
                message : 'Congratulation your request has been accepted.',color : 'info',
            }]);
        });
    }

    const cancelTradingRequest = async () => {
        let config = pusher(props.token);
        let channel = config.subscribe(`trade-cancel-${props?.trade?.sender_id}-${props?.trade?.receiver_id}`);
        channel.bind('trade-cancel', function (data) {
            props.resetTime(0);
            clearInterval(timeInterval);
            navigator('/inventory');
        });
    }

    const addItemPusher = () => {
        let config = pusher(props.token);
        let channel = config.subscribe(`add-trade-item-${params?.id}`);
        channel.bind('add-trade-item', function (data) {
            console.log(data);
            props.getSingleTradeHistory(data?.trade);
            // props.setTrade(JSON.parse(data?.trade));
        });
    }

    const readyToCheck = () => {
        let config = pusher(props.token);
        let channel = config.subscribe(`trade-complete-${params?.id}`);
        channel.bind('trade-complete', function (data) {
            console.log(data);
            props.getTradeMake(params?.id);
        });
    }

    const finishedPusher = () => {
        let config = pusher(props.token);
        let channel = config.subscribe(`finished-trade-${params?.id}`);
        channel.bind('finished-trade', function (data) {
            console.log(data);
            props.setAlert([{
                message : 'Trade Complete Successfully.',color : 'success',
            }]);
            navigator('/inventory');
        });
    }

    const onLoadItem = async (inventory_id) => {
        showLoader('flex');
        await props.addItem({
            id : params?.id,
            type : type,
            inventory_id : inventory_id,
        });
        showLoader('none');
    }

    const onCheckReady = async () => {
        showLoader('flex');
        await props.complete({
            id : params?.id,
            type : type,
        });
        showLoader('none');
    }

    const tradeNow = async () => {
        showLoader('flex');
        await props.finished({
            id : params?.id,
        })
            .then(()=>{
                navigator('/inventory');
                showLoader('none');
            })
            .catch((e)=>{
                console.log(e);
                showLoader('none');
            });
    }

    const updateQuantity = (id,qty) => {
        let x = history.findIndex((val)=>val?.id === id);
        let itemData = history.filter((val)=>val)
        if(x >= 0){
            itemData[x]['qty'] = qty;
        }
        setHistory(itemData);
    }



    const startTimer = (duration, display) => {
        let timer = duration, minutes, seconds;
        timer = props.time;
        let x = setInterval(function () {
            minutes = parseInt(timer / 60, 10);
            seconds = parseInt(timer % 60, 10);

            minutes = minutes < 10 ? "0" + minutes : minutes;
            seconds = seconds < 10 ? "0" + seconds : seconds;

            display.textContent = minutes + ":" + seconds;
            --timer;
            props.resetTime(timer);

            if (timer < 0) {
                props.tradeCancel(params?.id)
                    .then(()=>{
                        navigator('/inventory');
                    })
                    .catch((e)=>{
                        console.log(e);
                    });
                clearInterval(x);
            }
        }, 1000);
        console.log("Interval Save in State");
        setTimeInterval(x);
    }

    useEffect(() => {
        get();
        rejectRequestPusher();
        acceptRequestPusher();
        addItemPusher();
        readyToCheck();
        finishedPusher();

        // if(location?.state){
        //     onLoadItem(location?.state);
        // }
        return async () => {
        //     let x = await props.tradeCancel(params?.id);
        }
    },[]);


    useEffect(()=>{
        if(props.trade){
            if(props.trade?.sender_id === props.user?.id){
                setType('sender');
                setYourOffer(props?.trade?.sender);
                setOtherOffer(props?.trade?.receiver);
            }
            if(props.trade?.receiver_id === props.user?.id){
                setType('receiver');
                setOtherOffer(props?.trade?.sender);
                setYourOffer(props?.trade?.receiver);
            }
            if(!props.trade?.status){
                let display = document.querySelector('#countTime');
                // startTimer(props.time, display);
            }

            if(props.trade?.status == "1"){
                // cancelTradingRequest();
            }
            console.log("props.trade.history",props.trade.history);
            setHistory(props.trade.history);
        }
    },[props.trade])

    return (
        <section className="fram-sec">
            <div className="frame-wrapper">
                {/* Border*/}
                <PDLBorder/>
                {/*Logo*/}
                <Logo/>

                {/*Menus*/}
                <div className="row">
                    {/*<div className="col-md-7">*/}
                    {/*</div>*/}
                    <div className="col-md-12">
                        <div className="Menus text-right">
                            <AuthHeader/>
                        </div>
                    </div>
                </div>


                <section className="trade-wrapper">
                    <div className="container">
                        <div className="row pb-5">
                            <div className="col-sm-12">
                                <h4 className="text-center">Trade</h4>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="inventroy-Box bef">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="inventories">
                                                <div className="nav flex-column nav-pills" id="v-pills-tab"
                                                     role="tablist" aria-orientation="vertical">
                                                    <ul className="inventory-links">
                                                        <li>
                                                            <a className="nav-link" onClick={get}>All</a>
                                                        </li>
                                                        <Category parentComponent={"trade"} filterByCategory={filterByCategory} />
                                                        {/*{*/}
                                                        {/*    props.category.map((val, key) => {*/}
                                                        {/*        return <li key={key}>*/}
                                                        {/*            <a className="nav-link" onClick={()=>filterByCategory(val?.id)}>{val?.name}</a>*/}
                                                        {/*        </li>*/}
                                                        {/*    })*/}
                                                        {/*}*/}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="title">
                                                <figure><img src="img/inventorytxt.png" alt=""/></figure>
                                            </div>
                                            <div className="search-Box">
                                                <SearchInventory itemRequest={props.getItems} />
                                            </div>
                                            <div>
                                                <div className="">
                                                    <div className="inventory-selection">
                                                        <div className="row">
                                                            {
                                                                props.items?.data.map((val,key)=>{
                                                                    return <div key={key} className="col-3" onClick={()=>onLoadItem(val?.id)}>
                                                                        <div className="inventory-itemBox">
                                                                            <figure className="mb-0">
                                                                                <img width={'100%'} src={val?.shop?.image_url} alt=""/>
                                                                            </figure>
                                                                            <div className="count">
                                                                                <span className="large">{val?.qty}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="nextBtn">
                                        <a href="#"><img src="img/arrow-right.png" alt=""/></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">

                                <div className="your-offering">
                                    <h5>your offering</h5>
                                    <div className="container">
                                        <div className="row">
                                            {
                                                history.map((val,key)=>{
                                                    if(val?.type === type){
                                                        return <div key={key} className="col-lg-3">
                                                            <div style={{width:"74px",padding:"0px",height:"99px"}} className="inventory-itemBox">
                                                                <figure className="mb-0"><img style={{objectFit:"cover"}} width={'100%'} src={val?.inventory?.shop?.image_url} alt=""/></figure>
                                                                <div className="count" onClick={()=>{setOpen(true);setModalItem(val)}}>
                                                                    <span className="large">{val?.qty}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }else{
                                                        return <span key={key}></span>
                                                    }
                                                })
                                            }
                                            <div className="col-lg-3">
                                                <div className="inventory-itemBox">

                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="inventory-itemBox">

                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="inventory-itemBox">

                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="inventory-itemBox">

                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="inventory-itemBox">

                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="inventory-itemBox">

                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="inventory-itemBox">

                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            {
                                                type === "sender"?
                                                    <>
                                                        {
                                                            props.trade?.is_sender_ready == "0" &&
                                                            <div className="col-sm-12">
                                                                <div className="form-check">
                                                                    <input className="form-check-input" onClick={onCheckReady} type="checkbox" value=""
                                                                           id="defaultCheck1"/>
                                                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                                                        Check this box when ready trade.
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            props?.trade?.is_receiver_ready == "0" &&
                                                            <div className="col-sm-12">
                                                                <div className="form-check">
                                                                    <input className="form-check-input" onClick={onCheckReady} type="checkbox" value=""
                                                                           id="defaultCheck1"/>
                                                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                                                        Check this box when ready trade.
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        }
                                                    </>
                                            }

                                        </div>
                                    </div>
                                </div>

                                <div className="your-offering up">
                                    <h5>{otherOffer?.name} offerings:</h5>
                                    <div className="container">
                                        <div className="row">
                                            {
                                                props?.trade?.history?.map((val,key)=>{
                                                    if(val?.type !== type){
                                                        return <div key={key} className="col-lg-3">
                                                            <div style={{width:"74px",padding:"0px",height:"99px"}} className="inventory-itemBox">
                                                                <figure className="mb-0"><img style={{objectFit:"cover"}} width={'100%'} src={val?.inventory?.shop?.image_url} alt=""/></figure>
                                                                <div className="count">
                                                                    <span className="large">{val?.qty}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }else{
                                                        return <span key={key}></span>
                                                    }
                                                })
                                            }

                                            <div className="col-lg-3">
                                                <div className="inventory-itemBox">

                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="inventory-itemBox">

                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="inventory-itemBox">

                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="inventory-itemBox">

                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="inventory-itemBox">

                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="inventory-itemBox">

                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="inventory-itemBox">

                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                {
                                                    !props.trade?.status &&
                                                    <p className="text-white">Trade close in <span id="countTime">03:00</span> minutes!</p>

                                                }
                                                {
                                                    props.trade?.status &&
                                                    <>
                                                        {
                                                            type === "sender"?
                                                                <>
                                                                    {
                                                                        props.trade?.is_receiver_ready == "1"?
                                                                            <p className="text-success">Ready to trade.</p>
                                                                            :
                                                                            <p className="text-white">Not ready to trade.</p>
                                                                    }
                                                                </>
                                                                :
                                                                <>
                                                                    {
                                                                        props?.trade?.is_sender_ready == "1" ?
                                                                            <p className="text-success">Ready to trade.</p>
                                                                            :
                                                                            <p className="text-white">Not ready to trade.</p>
                                                                    }
                                                                </>
                                                        }
                                                        </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {
                                    (type === "sender" && props.trade?.is_complete == 0) &&
                                    <div className="trade-btn-wrapper mt-4">
                                        {
                                            (props.trade?.is_sender_ready == "1" && props?.trade?.is_receiver_ready == "1")?
                                                <button onClick={tradeNow} className="btn" type={"button"}>Trade</button>
                                                :
                                                <button className="btn" type={"button"} disabled={false}>Trade</button>
                                        }
                                    </div>
                                }
                                {
                                    (props.trade?.is_complete == 1) &&
                                    <p className={"mt-5 text-success"}>Trade Complete Successfully</p>
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <TradeQuantity updateQuantity={updateQuantity} open={open} modalItem={modalItem} setOpen={setOpen} />
        </section>
    );
}


const mapStateToProps = (state) => {
        return {
            islogin: state.Auth.islogin,
            user: state.Auth.user,
            items: state.Inventory.items,
            category : state.Site.category,
            trade : state.Trade.trade,
            time : state.TradeRequest.time,
        }
    }

const mapDispatchToProps = (dispatch) => {
        return {
            getTradeMake : (id) => dispatch(getTradeMakeRequest(id)),
            setAlert : (data) => dispatch(setAlert(data)),
            getItems: (url = null,search=null) => dispatch(getItemsRequest(url,search)),
            filterItemsByCategory : (id) => dispatch(filterItemsByCategoryRequest(id)),
            addItem : (data) => dispatch(addItemInTradeRequest(data)),
            setTrade : (data) => dispatch(setTrade(data)),
            complete : (data) => dispatch(markAsCompleteRequest(data)),
            finished : (data) => dispatch(finishedRequest(data)),
            tradeCancel : (id) => dispatch(tradeCancelRequest(id)),
            resetTrade : () => dispatch(resetTrade()),
            resetTime : (time) => dispatch(resetTime(time)),
            getSingleTradeHistory : (id) => dispatch(getTradeHistory(id)),
        }
    }

export default connect(mapStateToProps,mapDispatchToProps)(Trade);