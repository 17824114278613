import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import Logo from "../../components/Logo";
// import '../../assets/css/discord.css';
import '../../assets/css/result.css';
import BackgroundImage from '../../assets/img/result-back.png';
import AuthHeader from "../../components/AuthHeader";
import {Link, useNavigate, useParams} from "react-router-dom";
import {
    addItemInTrade,
} from "../../redux/Trade/action";
import {showLoader} from "../../redux/helpers";
import PDLBorder from "../../components/PDLBorder";
import {acceptGuildRequest, cancelGuildRequest, getGuildRequest} from "../../redux/Guild/action";
import AddPlayerRequest from "../../components/Modals/AddPlayerRequest";
import {getItemsRequest} from "../../redux/Inventory/action";
import InventoryItemGuild from "../../components/Modals/InventoryItemGuild";
import GuildMenu from "./GuildMenu";

const GuildView = (props) => {

    const params = useParams();
    const [open, setOpen] = useState(false);
    const [inventoryModal, setInventoryModal] = useState(false);
    const [requestTab, setRequestTab] = useState(false);

    const get = async () => {
        showLoader('flex');
        await props.getGuild(params.id);
        await props.getItems();
        showLoader('none');
    }

    const accept = async (id) => {
        showLoader('flex');
        await props.acceptGuild({guild_id: params.id, character_id: id});
        showLoader('none');
    }

    const cancel = async (id) => {
        showLoader('flex');
        await props.cancelGuild({guild_id: params.id, character_id: id});
        showLoader('none');
    }

    useEffect(() => {
        get();
    }, [params.id])

    return (
        <>
            {/*// <section className="fram-sec">*/}
            <div className="frame-wrapper-guild-view" style={{
                backgroundImage: `url('${BackgroundImage}')`,
            }}>
                {/* Border*/}
                <PDLBorder/>
                {/*Logo*/}
                <Logo/>

                {/*Menus*/}
                <div className="row">
                    {/*<div className="col-md-7">*/}

                    {/*</div>*/}
                    <div className="col-md-12">
                        <div className="Menus text-right">
                            <AuthHeader/>
                        </div>
                    </div>
                </div>

                <section className="results_sec">
                    <div className="container">

                        <GuildMenu/>

                        <div className="discord-wrapper">
                            <div className="img-box">
                                <figure><img src={props.guild?.logo_url} alt="" className="img-fluid"/></figure>
                            </div>
                            <div className="heading">
                                <h3>{props.guild?.name}</h3>
                                <h4>Guild level <span>{props.guild?.level || 0}</span></h4>
                            </div>
                            <p>
                                {props.guild?.description}
                            </p>
                            <div className="levels">
                                <h4>leader <span>{props.guild?.leader?.name}</span></h4>
                                <h4>Co-leader <span>{props.guild?.co_leader?.name}</span></h4>
                                {/*<h4>Leader <span>Marcus Fenix</span></h4>*/}
                                {/*<h4>Co-leader <span>John</span></h4>*/}
                            </div>
                            <div className="achivements">
                                {
                                    props.guild?.achievement?.length > 0 &&
                                    <>
                                        <h2 className="title">Achievements</h2>
                                        <ul className="all-achivements">
                                            {
                                                props.guild?.achievement?.filter((val, key) => key < 3)
                                                    ?.map((val, key) => {
                                                        return <li key={key}>
                                                            <div className="trophy">
                                                                <figure>
                                                                    <img src={val?.item?.image_url} width={'100%'}
                                                                         className="img-fluid"/>
                                                                </figure>
                                                                <h5>{val?.item?.title}</h5>
                                                            </div>
                                                        </li>
                                                    })
                                            }
                                        </ul>
                                        <div className="button-group">
                                            <Link to={`/guild/throne-room/${props.guild?.id}`}>view more
                                                <svg xmlns="http://www.w3.org/2000/svg" width="4.848"
                                                     height="4.725"
                                                     viewBox="0 0 4.848 4.725">
                                                    <path id="Icon_awesome-arrow-right"
                                                          data-name="Icon awesome-arrow-right"
                                                          d="M2.061,2.964l.24-.24a.259.259,0,0,1,.367,0l2.1,2.1a.259.259,0,0,1,0,.367l-2.1,2.1a.259.259,0,0,1-.367,0l-.24-.24a.26.26,0,0,1,0-.371l1.3-1.242H.26A.259.259,0,0,1,0,5.183V4.837a.259.259,0,0,1,.26-.26h3.11l-1.3-1.242A.258.258,0,0,1,2.061,2.964Z"
                                                          transform="translate(0 -2.647)" fill="#fff"/>
                                                </svg>
                                            </Link>
                                        </div>
                                    </>
                                }

                                <div className="members">
                                    <div className="title">
                                        <h2>members</h2>
                                    </div>
                                    <ul className="all-members">
                                        {
                                            props.guild?.member?.filter((val) => val?.is_requested === 1).map((val, key) => {
                                                return <li key={key}>
                                                    <figure>
                                                        <img src={val?.character?.logo_url} alt=""
                                                             className="img-fluid"/>
                                                    </figure>
                                                </li>
                                            })
                                        }

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/*Previous Design */}
                <section className="d-none discord-sec">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="members-wrapper">
                                    <div className="members-and-request-wrapper">
                                        <p onClick={() => setRequestTab(false)}>members</p>
                                        {
                                            (props.guild?.leader_id === props.user?.id ||
                                                props.guild?.co_leader_id === props.user?.id)
                                            &&
                                            <button type={"button"} onClick={() => setRequestTab(true)}
                                                    className="btn">
                                                requests
                                                <span className="total-request">
                                                ({props.guild?.total_request_members})
                                            </span>
                                            </button>
                                        }
                                    </div>
                                    {
                                        requestTab ?
                                            <table className="mt-5">
                                                <tbody>
                                                <tr className="row1">
                                                    <th className="playername">Player Name</th>
                                                    <th className="playerlevel">Player level</th>
                                                    <th className="playerlevel">Action</th>
                                                </tr>
                                                {
                                                    props.guild?.member.filter((val) => val?.is_requested === 0).map((val, key) => {
                                                        return <tr key={key} className="row2">
                                                            <td className="playername">{val?.character?.name}</td>
                                                            <td className="playerlevel">{val?.character?.level}</td>
                                                            {
                                                                (props.guild?.leader_id === props.user?.id
                                                                    || props.guild?.co_leader_id === props.user?.id) ?
                                                                    <td className="playerlevel">
                                                                        <button
                                                                            onClick={() => accept(val?.character_id)}
                                                                            className="small btn-sm my-2 btn">
                                                                            <i className={"text-success fa-2x fa fa-check"}></i>
                                                                        </button>
                                                                        <button
                                                                            onClick={() => cancel(val?.character_id)}
                                                                            className="small btn-sm my-2 btn">
                                                                            <i className={"text-dark fa-2x fa fa-times"}></i>
                                                                        </button>
                                                                    </td>
                                                                    :
                                                                    <td className="playerlevel">
                                                                        <button
                                                                            className="small btn-sm my-2 btn">Pending
                                                                        </button>
                                                                    </td>
                                                            }

                                                        </tr>
                                                    })
                                                }
                                                </tbody>
                                            </table>
                                            :
                                            <>
                                                <table className="mt-5">
                                                    <tbody>
                                                    <tr className="row1">
                                                        <th className="no">Sr#</th>
                                                        <th className="playername text-left">Player Name</th>
                                                        <th className="playerlevel">Player level</th>
                                                    </tr>
                                                    {
                                                        props.guild?.member.filter((val) => val?.is_requested === 1).map((val, key) => {
                                                            return <tr key={key} className="row2">
                                                                <td className="no">{key + 1}</td>
                                                                <td className="playername text-left">
                                                                    <img src={val?.character?.logo_url}
                                                                         className={"rounded"} width={'25%'}/>
                                                                    <span
                                                                        className={"ml-2"}>{val?.character?.name}</span>
                                                                </td>
                                                                <td className="playerlevel">{val?.character?.level}</td>
                                                            </tr>
                                                        })
                                                    }
                                                    </tbody>
                                                </table>
                                                <div
                                                    className={"d-flex flex-row justify-content-between align-item-center"}>
                                                    {
                                                        (props.guild?.leader?.user_id === props.user?.id || props.guild?.co_leader?.user_id === props.user?.id) &&
                                                        <div className="add-player-btn-wrapper mt-4">
                                                            <button onClick={() => setOpen(true)} type={"button"}
                                                                    className="btn">add player
                                                            </button>
                                                        </div>
                                                    }
                                                    {
                                                        (props.guild?.leader?.user_id === props.user?.id || props.guild?.co_leader?.user_id === props.user?.id) &&
                                                        <div className="add-player-btn-wrapper mt-4">
                                                            <button onClick={() => setInventoryModal(true)}
                                                                    type={"button"}
                                                                    className="btn">Add Item
                                                            </button>
                                                        </div>
                                                    }
                                                </div>

                                            </>
                                    }

                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="discord-wrapper">
                                    <div className="discord-img-wrapper text-center">
                                        <figure>
                                            <img src={props.guild?.logo_url} width={'100%'} className="img-fluid"
                                                 alt=""/>
                                        </figure>
                                    </div>
                                    <div className="discord-content-wrapper text-center mt-4">
                                        <h4>{props.guild?.name}</h4>
                                        <p>{props.guild?.description}</p>
                                        <div className="leader-and-co-leader">
                                            <h5>leader <span className="leader-name">{props.guild?.leader?.name}</span>
                                            </h5>
                                            <h5>Co-leader <span
                                                className="co-leader-name">{props.guild?.co_leader?.name}</span></h5>
                                        </div>
                                        <div className="achievemnets-wrapper">
                                            <h4 className="text-center">Achievements</h4>
                                            <ul id="achievements-list">
                                                {
                                                    props.guild?.achievement?.map((val, key) => {
                                                        return <li key={key}>
                                                            <div className="achi-img">
                                                                <img src={val?.item?.image_url} width={'100%'} alt=""/>
                                                            </div>
                                                            <h6>{val?.item?.title}</h6>
                                                        </li>
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">

                                <div className="inventroy-Box">

                                    <div className="row justify-content-center">
                                        <div className="col-md-4 d-none">
                                            <div className="inventories">
                                                <ul className="inventory-links">
                                                    <li><a className="active" href="#">ammo</a></li>
                                                    <li><a href="#">armor pve</a></li>
                                                    <li><a href="#">armor pvp</a></li>
                                                    <li><a href="#">awards</a></li>
                                                    <li><a href="#">bionics</a></li>
                                                    <li><a href="#">gems & glyphs</a></li>
                                                    <li><a href="#">grenades</a></li>
                                                    <li><a href="#">legends</a></li>
                                                    <li><a href="#">misc</a></li>
                                                    <li><a href="#">player buffs</a></li>
                                                    <li><a href="#">potions</a></li>
                                                    <li><a href="#">pets</a></li>
                                                    <li><a href="#">skin</a></li>
                                                    <li><a href="#">smokes & sparks</a></li>
                                                    <li><a href="#">stronghold</a></li>
                                                    <li><a href="#">weapons</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-md-8">

                                            <div className="search-Box d-none">
                                                <form>
                                                    <div className="form-group">
                                                        <input type="text" className="form-control"
                                                               placeholder="Search item here"/>
                                                    </div>
                                                </form>
                                            </div>
                                            <div className="inventory-selection">
                                                <div className="row">
                                                    {
                                                        props?.guild?.inventory?.map((val, key) => (
                                                            <div className="col-6" key={key}>
                                                                <div className="inventory-itemBox">
                                                                    <figure>
                                                                        <img src={val?.shop?.image_url} width={"100%"}
                                                                             alt={val?.shop?.title}/>
                                                                    </figure>
                                                                    {/*<div className="count">*/}
                                                                    {/*    <span className="large">01</span>*/}
                                                                    {/*</div>*/}
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="nextBtn">
                                        <a href="#"><img src="img/arrow-right.png" alt=""/></a>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
                {/* Previous Design*/}

            </div>
            <AddPlayerRequest setOpen={setOpen} open={open} guildId={params.id}/>
            <InventoryItemGuild setOpen={setInventoryModal} open={inventoryModal} guildId={params.id}/>
            {/*// </section>*/}
        </>
    );
}


const mapStateToProps = (state) => {
    return {
        user: state.Auth.user,
        guild: state.Guild.guild,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getGuild: (id) => dispatch(getGuildRequest(id)),
        acceptGuild: (data) => dispatch(acceptGuildRequest(data)),
        cancelGuild: (data) => dispatch(cancelGuildRequest(data)),
        getItems: (url = null, search = null) => dispatch(getItemsRequest(url, search)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GuildView);