import {RESET_NEW_TRADE_REQUEST, RESET_REQUEST_TRADE_TIME, SET_NEW_TRADE_REQUEST} from "../constants";

const initState = {
    newTrade : null,
    time : 0,
}

const TradeRequest = (state = initState , action) => {
    switch (action.type) {

        case SET_NEW_TRADE_REQUEST :
            return {
                ...state,
                newTrade: action.payload,
                time : 180,
            }
        case RESET_NEW_TRADE_REQUEST :
            console.log("Cancel trade");
            return {
                ...state,
                newTrade : null,
                time : 0,
            }
        case RESET_REQUEST_TRADE_TIME :
            return {
                ...state,
                time: action.payload,
            }


        default: return state;
    }
}

export default TradeRequest;