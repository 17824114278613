import {SET_SEARCH_USERS, SET_SINGLE_HISTORY_TRADE, SET_TRADE} from "../constants";

const initState = {
    users : [],
    trade : null,
}

const Trade = (state = initState , action) => {
    switch (action.type) {

        case SET_SEARCH_USERS :
            return {
                ...state,
                users : action.payload,
            }
        case SET_TRADE :
            return {
                ...state,
                trade : action.payload,
            }
        case SET_SINGLE_HISTORY_TRADE :
            return {
                ...state,
                trade : {
                    ...state.trade,
                    history : action.payload,
                }
            }

        default: return state;
    }
}

export default Trade;