import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {Link} from 'react-router-dom';
import PlayerTop from '../../../assets/img/playertop.png';
import playerBottom from '../../../assets/img/playerbottom.png';
import {createMyCharacterRequest, getCharacterDetailRequest, setMyCharacter} from "../../../redux/UserCharacter/action";
import {setLoader} from "../../../redux/Site/action";
import {site_web_url} from "../../../redux/apis";


const Stats = (props) => {

    return (
        <>
            <div className="forTop-border py-1">
                <figure><img className="w-100" src={PlayerTop} alt=""/></figure>
            </div>
            <div className="all-about-players text-white">
                <div className="players-Name">
                    <div className="title">
                        <h3 className="animate__animated animate__fadeInLeft">{props.details?.character?.name}</h3>
                    </div>
                    <div className="infoBox">
                        <div className="about-player info">
                            <div className="property animate__animated animate__fadeInLeft">
                                <h4>Class</h4>
                            </div>
                            <div className="value">
                                <h4>{props.details?.character?.character_id}</h4>
                            </div>
                        </div>
                        <div className="about-player info">
                            <div className="property animate__animated animate__fadeInLeft">
                                <h4>Level</h4>
                            </div>
                            <div className="value">
                                <h4>{props.details?.character?.level}</h4>
                            </div>
                        </div>
                        <div className="about-player info">
                            <div className="property animate__animated animate__fadeInLeft">
                                <h4>Att level</h4>
                            </div>
                            <div className="value">
                                <h4>{parseInt(props.details?.character?.character_base_values?.att).toLocaleString()}</h4>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="equipments">
                    <div className="title animate__animated animate__fadeInLeft">
                        <h4>unequipped stats</h4>
                    </div>
                    <div className="equiped-stats info">
                        <div className="property animate__animated animate__fadeInLeft">
                            <h4>Health</h4>
                        </div>
                        <div className="value">
                            <h4>{parseInt(props.details?.character?.character_base_values?.health).toLocaleString()}</h4>
                        </div>
                    </div>
                    <div className="equiped-stats info">
                        <div className="property animate__animated animate__fadeInLeft">
                            <h4>Damage</h4>
                        </div>
                        <div className="value">
                            <h4>{parseInt(props.details?.character?.character_base_values?.damage).toLocaleString()}</h4>
                        </div>
                    </div>
                    <div className="equiped-stats info">
                        <div className="property animate__animated animate__fadeInLeft">
                            <h4>Base</h4>
                        </div>
                        <div className="value">
                            <h4>{parseInt(props.details?.character?.character_base_values?.base).toLocaleString()}</h4>
                        </div>
                    </div>
                    <div className="equiped-stats info">
                        <div className="property animate__animated animate__fadeInLeft">
                            <h4>Armor pen</h4>
                        </div>
                        <div className="value">
                            <h4>{parseInt(props.details?.character?.character_base_values?.armor_pen).toLocaleString()}</h4>
                        </div>
                    </div>
                    <div className="equiped-stats info">
                        <div className="property animate__animated animate__fadeInLeft">
                            <h4>Armor pen resist</h4>
                        </div>
                        <div className="value">
                            <h4>{parseInt(props.details?.character?.character_base_values?.armor_pen_resist).toLocaleString()}</h4>
                        </div>
                    </div>
                </div>

                <div className="armored-stats">
                    <div className="title animate__animated animate__fadeInLeft">
                        <h4>total armored stats</h4>
                    </div>
                    {
                        props?.type == "0" ?
                            <>
                                <div className="armoured-info info">
                                    <div className="property animate__animated animate__fadeInLeft">
                                        <h4>Health</h4>
                                    </div>
                                    <div className="value">
                                        <h4>{parseInt(props.details?.character?.pve_health).toLocaleString()}</h4>
                                    </div>
                                </div>
                                <div className="armoured-info info">
                                    <div className="property animate__animated animate__fadeInLeft">
                                        <h4>Damage</h4>
                                    </div>
                                    <div className="value">
                                        <h4>{parseInt(props.details?.character?.pve_damage).toLocaleString()}</h4>
                                    </div>
                                </div>
                                <div className="armoured-info info">
                                    <div className="property animate__animated animate__fadeInLeft">
                                        <h4>Base</h4>
                                    </div>
                                    <div className="value">
                                        <h4>{parseInt(props.details?.character?.pve_base).toLocaleString()}</h4>
                                    </div>
                                </div>
                                <div className="armoured-info info">
                                    <div className="property animate__animated animate__fadeInLeft">
                                        <h4>Armor pen</h4>
                                    </div>
                                    <div className="value">
                                        <h4>{parseInt(props.details?.character?.pve_armor_pen).toLocaleString()}</h4>
                                    </div>
                                </div>
                                <div className="armoured-info info">
                                    <div className="property animate__animated animate__fadeInLeft">
                                        <h4>Armor pen resist</h4>
                                    </div>
                                    <div className="value">
                                        <h4>{parseInt(props.details?.character?.pve_armor_pen_resist).toLocaleString()}</h4>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className="armoured-info info">
                                    <div className="property animate__animated animate__fadeInLeft">
                                        <h4>Health</h4>
                                    </div>
                                    <div className="value">
                                        <h4>{parseInt(props.details?.character?.health).toLocaleString()}</h4>
                                    </div>
                                </div>
                                <div className="armoured-info info">
                                    <div className="property animate__animated animate__fadeInLeft">
                                        <h4>Damage</h4>
                                    </div>
                                    <div className="value">
                                        <h4>{parseInt(props.details?.character?.damage).toLocaleString()}</h4>
                                    </div>
                                </div>
                                <div className="armoured-info info">
                                    <div className="property animate__animated animate__fadeInLeft">
                                        <h4>Base</h4>
                                    </div>
                                    <div className="value">
                                        <h4>{parseInt(props.details?.character?.base).toLocaleString()}</h4>
                                    </div>
                                </div>
                                <div className="armoured-info info">
                                    <div className="property animate__animated animate__fadeInLeft">
                                        <h4>Armor pen</h4>
                                    </div>
                                    <div className="value">
                                        <h4>{parseInt(props.details?.character?.armor_pen).toLocaleString()}</h4>
                                    </div>
                                </div>
                                <div className="armoured-info info">
                                    <div className="property animate__animated animate__fadeInLeft">
                                        <h4>Armor pen resist</h4>
                                    </div>
                                    <div className="value">
                                        <h4>{parseInt(props.details?.character?.armor_pen_resist).toLocaleString()}</h4>
                                    </div>
                                </div>
                            </>
                    }

                </div>
                <div className="actionsBtn">
                    <div className="button-group d-flex flex-row justify-content-center">
                        <button className="btn mr-1" onClick={() => props?.setMiscModal(true)}>Misc Stats Note</button>
                        {/*<button type={"button"} className="btn ml-1">Stats</button>*/}
                    </div>
                    {/*<div className="exportBtn d-flex flex-row justify-content-center">*/}
                    {/*<a href={`${site_web_url}pdf/${props.id}/${props.type}/${props.userId}`}*/}
                    {/*   target={"_blank"} className="btn">Export</a>*/}
                    {/*<Link to={`/character/edit/${props.id}`} className="btn">Edit</Link>*/}
                    {/*</div>*/}

                </div>
            </div>
            <div className="forBottom-border py-1">
                <figure><img className="w-100" src={playerBottom} alt=""/></figure>
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        islogin: state.Auth.islogin,
        details: state.UserCharacter.details,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setLoader: (bool) => dispatch(setLoader(bool)),
        getCharacterDetail: (id) => dispatch(getCharacterDetailRequest(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Stats);