import React, {useEffect} from 'react';
import {connect} from "react-redux";
import PDLBorder from "../../components/PDLBorder";
import Logo from "../../components/Logo";
import '../../assets/css/profile.css';
import AuthHeader from "../../components/AuthHeader";
import {css} from "@emotion/react";
import PuffLoader from "react-spinners/PuffLoader";
import RingLoader from "react-spinners/PuffLoader";
import {PulseLoader} from "react-spinners";


const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const Loading = () => {


    return (
        <section className="fram-sec">
            <div className="frame-wrapper">

                {/* Border*/}
                <PDLBorder/>
                {/*Logo*/}
                <Logo/>

                {/*Menus*/}
                <div className="row">
                    {/*<div className="col-md-7">*/}

                    {/*</div>*/}
                    <div className="col-md-12">
                        <div className="Menus text-right">
                            <AuthHeader/>
                        </div>
                    </div>
                </div>
                {/*Menus*/}

                <section className="profile">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-2">

                            </div>
                            <div className="col-lg-8">
                                <div className="profile-creation text-center">
                                    <figure>
                                        <h3 className={"mb-5 "}>
                                            <span className={"pdlStyleHeading mr-3"}>Loading</span>
                                            <PulseLoader color={'#9e721c'} loading={true} margin={3} size={13}/>
                                        </h3>
                                    </figure>
                                    <p className={"pdlStyleParagraph"}>
                                    </p>
                                </div>

                            </div>
                            <div className="col-lg-2"></div>
                        </div>
                    </div>
                </section>
            </div>
        </section>
    );
}

const mapStateToProps = (state) => {
    return {
        loader: state.Site.loader,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Loading);