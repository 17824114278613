import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import PDLBorder from "../../components/PDLBorder";
import Logo from "../../components/Logo";
import AuthHeader from "../../components/AuthHeader";
import PlayerBottom from '../../assets/img/playerbottom.png';
import PlayerTop from '../../assets/img/playertop.png';
import {
    filterItemsByCategoryRequest,
    getItemRequest,
    removeGemGlyphsItemRequest,
    updateItemRequest
} from "../../redux/Inventory/action";
import {getUserCharacterRequest, setLoader} from "../../redux/Site/action";
import Loader from "../NotFound/Loader";
import {Link, useNavigate, useParams} from "react-router-dom";
import NotFound from "../NotFound/NotFound";
import '../../assets/css/HallView.css';
import bountyBg from "../../assets/img/player-back.png";
import {getCharacterDetailRequest} from "../../redux/UserCharacter/action";
import {site_web_url} from "../../redux/apis";
import domtoimage from 'dom-to-image';
import {Button} from "react-bootstrap";



const HallView = (props) => {

    const params = useParams();
    const navigate = useNavigate();
    const [type , setType] = useState(1);

    const get = async () => {
        props.setLoader(true);
        await props.getCharacterDetail(params?.id);
        props.setLoader(false);
    }

    const convertToJpg = () => {
        const boxImage = document.getElementById('printNode');
        domtoimage.toJpeg(boxImage, { quality: 0.95 })
            .then(function (dataUrl) {
                console.log(dataUrl);
                let link = document.createElement('a');
                link.download = 'download.jpeg';
                link.href = dataUrl;
                link.click();
                // $('#saveasImage').html(`Save as JPG`);
            });
    }

    // useEffect(() => {
    //     return () => {
    //     }
    // }, []);

    useEffect(() => {
        get();
    }, [params?.id]);

    if (props.loader) return <Loader/>

    if (!props.details) return <NotFound/>

    return (
        <section className="fram-sec">
            <div className="frame-wrapper">

                {/* Border*/}
                <PDLBorder/>
                {/*Logo*/}
                <Logo/>

                {/*Menus*/}
                <div className="row">

                    <div className="col-md-12">
                        <div className="Menus text-right">
                            <AuthHeader/>
                        </div>
                    </div>
                </div>
                {/*Menus*/}

                <section className="guild_detail_sec">
                    <div className="container">
                        <div className="guild_detail_box">
                            <div className="player-img">
                                <figure>
                                    <img src={props?.details?.logo_url} alt="" className="img-fluid" />
                                </figure>
                            </div>
                            <div className="player-detail">
                                <h4 className="name">Player Name <span>{props?.details?.character?.character?.title}</span></h4>
                                <h4 className="name">Player Level <span>{props?.details?.character?.level}</span></h4>
                            </div>
                        </div>
                        <div className="button-group">
                            <a onClick={()=>navigate('/user/search/trade')}
                               className={"btnn"}>
                                Trade
                            </a>
                            <a onClick={()=>navigate('/gift')}
                               className={"btnn"}>
                                Gift
                            </a>
                        </div>
                    </div>
                </section>

            </div>

        </section>
    );
}

const mapStateToProps = (state) => {
    return {
        details: state.Site.userCharacter,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setLoader : (bool) => dispatch(setLoader(bool)),
        filterItemsByCategory : (id) => dispatch(filterItemsByCategoryRequest(id)),
        getCharacterDetail: (id) => dispatch(getUserCharacterRequest(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HallView);