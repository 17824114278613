import React from 'react';
import {connect} from "react-redux";
import Logo from '../assets/img/playheading.png';


const PlayLogo = () => {

    return (
        <>
            <img src={Logo}
                 className="animate__animated  animate__fadeInDownBig img-fluid" alt=""/>
        </>
);
}

const mapStateToProps = () => {
        return {

        }
    }

const mapDispatchToProps = () => {

    }

export default connect(mapStateToProps)(PlayLogo);