import {
} from "../constants";
import {headers, headersWithToken} from "../headers";
import {
    finishedApi, sendGiftApi,
} from "../apis";
import axios from "axios";
import {setAlert} from "../Alert/action";
import {responseError} from "../errorHandler";

export const sendGiftRequest = (data) => {
    return async (dispatch,getState) => new Promise(async (resolve, reject)=>{
        try{
            let token = getState().Auth.token;
            let response = await axios.post(sendGiftApi,data,headersWithToken(token));
            dispatch(setAlert([
                {
                    message : response.data.message,
                    color : 'success',
                }
            ]));
            return resolve(true);
        }catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
            return reject(false);
        }
    });
}


