import {
    LOGIN,
    LOGOUT,
    SET_CATEGORY,
    SET_CHARACTERS,
    SET_LOADER,
    SET_PAGES,
    SET_SEARCH_USERS, SET_SINGLE_HISTORY_TRADE, SET_TRADE,
    SET_USER,
    WELCOME
} from "../constants";
import {headers, headersWithToken} from "../headers";
import {
    addItemInTrade, addItemInTradeApi,
    categoryAPi,
    charactersAPi, finishedApi,
    getTradeApi, getTradeHistoryApi,
    loginAPi, markAsCompleteApi,
    newTrade,
    newTradeApi,
    pagesAPi,
    searchUserApi,
    signUpAPi, tradeCancelApi, updateTradeItemQtyApi
} from "../apis";
import axios from "axios";
import {setAlert} from "../Alert/action";
import {responseError} from "../errorHandler";
import {resetTime} from "../TradeRequest/action";

export const setUsers = (data) => {
    return {
        type : SET_SEARCH_USERS,
        payload : data
    }
}

export const getSearchUserRequest = (name) => {
    return async (dispatch,getState) => {
        try{
            let token = getState().Auth.token;
            let response = await axios.get(searchUserApi(name),headersWithToken(token));
            dispatch(setUsers(response.data.response.data));
        }catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    }
}

export const setSingleHistoryTrade = (data) => {
    return {
        type : SET_SINGLE_HISTORY_TRADE,
        payload : data
    }
}

export const getTradeHistory = (id) => {
    return async (dispatch,getState) => {
        try{
            let token = getState().Auth.token;
            let response = await axios.get(getTradeHistoryApi(id),headersWithToken(token));
            dispatch(setSingleHistoryTrade(response.data.response.data?.history));
            console.log(response.data.response.data?.history);
        }catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    }
}

export const setTrade = (data) => {
    return {
        type : SET_TRADE,
        payload : data
    }
}

export const getTradeMakeRequest = (id) => {
    return async (dispatch,getState) => {
        try{
            let token = getState().Auth.token;
            let response = await axios.get(getTradeApi(id),headersWithToken(token));
            console.log(response.data.response.data.trade);
            dispatch(setTrade(response.data.response.data.trade));
            if(!response.data.response.data.trade?.status) dispatch(resetTime(180));
        }catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    }
}

export const tradeMakeRequest = (data) => {
    return async (dispatch,getState) => new Promise(async (resolve, reject)=>{
        try{
            let token = getState().Auth.token;
            let response = await axios.post(newTradeApi,data,headersWithToken(token));
            dispatch(setTrade(response.data.response.data.trade));
            dispatch(resetTime(180));
            return resolve(response.data.response.data.trade?.id);
        }catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
            return reject(false);
        }
    })
}

export const tradeCancelRequest = (id) => {
    return async (dispatch,getState) => new Promise(async (resolve, reject)=>{
        try{
            let token = getState().Auth.token;
            let response = await axios.get(tradeCancelApi(id),headersWithToken(token));
            dispatch(setTrade(null));
            dispatch(setAlert([
                {
                    message : 'trade cancelled.',
                    color : 'info',
                }
            ]));
            return resolve(response.data.response.data.trade?.id);
        }catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
            return reject(false);
        }
    })
}


export const addItemInTradeRequest = (data) => {
    return async (dispatch,getState) => {
        try{
            let token = getState().Auth.token;
            let response = await axios.post(addItemInTradeApi,data,headersWithToken(token));
            dispatch(setTrade(response.data.response.data.trade));
        }catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    }
}

export const markAsCompleteRequest = (data) => {
    return async (dispatch,getState) => {
        try{
            let token = getState().Auth.token;
            let response = await axios.post(markAsCompleteApi,data,headersWithToken(token));
            dispatch(setTrade(response.data.response.data.trade));
        }catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    }
}

export const finishedRequest = (data) => {
    return async (dispatch,getState) => new Promise(async (resolve, reject)=>{
        try{
            let token = getState().Auth.token;
            let response = await axios.post(finishedApi,data,headersWithToken(token));
            console.log(response.data);
            dispatch(setAlert([
                {
                    message : response.data.message,
                    color : 'success',
                }
            ]));
            return resolve(true);
        }catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
            return reject(false);
        }
    });
}

export const updateTradeQtyRequest = (data) => {
    return async (dispatch,getState) => new Promise(async (resolve, reject)=>{
        try{
            let token = getState().Auth.token;
            let response = await axios.post(updateTradeItemQtyApi,data,headersWithToken(token));
            console.log(response.data.response.data);
            return resolve(data);
        }catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
            return reject(false);
        }
    });
}


