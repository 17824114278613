import React, {useEffect} from 'react';
import {connect} from "react-redux";
import PDLBorder from "../../components/PDLBorder";
import Logo from "../../components/Logo";
import '../../assets/css/herosandvillans.css';
import AuthHeader from "../../components/AuthHeader";
import Rulebook from '../../assets/img/Rulebook.png';
import {
    getArchiveRequest,
    getCharactersRequest,
    getHerosRequest,
    getPagesRequest,
    setLoader
} from "../../redux/Site/action";
import NotFound from "../NotFound/NotFound";
import Loading from "../NotFound/Loader";
import inventorybg from "../../assets/img/inventorybg.png";
import roomborder from "../../assets/img/roomborder.png";


const Heros = (props) => {

    const getPageRequest = async () => {
        await props.setLoader(true);
        await props.getHeros();
        await props.setLoader(false);
    }

    useEffect(()=>{
        getPageRequest();

        return () => {

        }
    },[]);


    if(props.loader) return (<Loading/>);

    // if(!props.archives.length > 0) return (<NotFound />);


    return (
        <section className="fram-sec" style={{
            backgroundImage : `url('${inventorybg}')`,
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat',
            padding: '3rem 2rem',
            margin: '4.5rem 2rem',
            position: 'relative',
        }}>
            <div className="frame-wrapper">

                {/* Border*/}
                <PDLBorder/>
                {/*Logo*/}
                <Logo/>

                {/*Menus*/}
                <div className="row">
                    {/*<div className="col-md-7">*/}

                    {/*</div>*/}
                    <div className="col-md-12">
                        <div className="Menus text-right">
                            <AuthHeader/>
                        </div>
                    </div>
                </div>
                {/*Menus*/}

                <section className="herosandvillans">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 text-center">
                                <h4>Heroes And Villains</h4>
                            </div>
                        </div>
                        {
                            props.heros.map((val,key)=>(
                                key % 2 === 0 ?
                                    <div className="row pt-5">
                                        <div className="col-lg-2">
                                            <div className="romm-img-box">
                                                <div className="room-border-images">
                                                    <img src={roomborder}/>
                                                </div>
                                                <div className="roominner-image">
                                                    <figure>
                                                        <img src={val?.image_url} className="img-fluid mt-3 ms-3 p-0" alt=""/>
                                                    </figure>
                                                </div>
                                            </div>


                                        </div>
                                        <div className="col-lg-8 bor my-auto">
                                            <div className="content-wrapper">
                                                <h3>{val?.title}</h3>
                                                <p>{val?.content}</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-2">

                                        </div>
                                    </div>
                                    :
                                    <div className="row pt-5">
                                        <div className="col-lg-2"></div>
                                        <div className="col-lg-8 bor my-auto">
                                            <div className="content-wrapper text-right">
                                                <h3>{val?.title}</h3>
                                                <p>{val?.content}</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-2">
                                            <div className="romm-img-box">
                                                <div className="room-border-image">
                                                    <img src={roomborder}/>
                                                </div>
                                                <div className="roominner-image">
                                                    <figure>
                                                        <img src={val?.image_url} className="img-fluid mt-3 p-0 ml-3" alt=""/>
                                                    </figure>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                            ))
                        }
                    </div>
                </section>

            </div>
        </section>
    );
}

const mapStateToProps = (state) => {
    return {
        archives : state.Site.archives,
        heros : state.Site.heros,
        loader : state.Site.loader,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getHeros : () => dispatch(getHerosRequest()),
        setLoader : (bool) => dispatch(setLoader(bool)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Heros);