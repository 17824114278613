import React, {useEffect} from 'react';
import {connect} from "react-redux";
import '../assets/css/warrior2.css';
import IntractiveImg from "../assets/img/intractive.png";

const Intractive = (props) => {

    return (
        <>
            {
                props.islogin ?
                    <section className="intractive-sec">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="intractive-sec-img " style={{
                                        position : 'relative',
                                    }}>
                                        <div className="intractive-content text-center">
                                            <h4>Interactive Quest</h4>
                                        </div>
                                        <figure>
                                            <img src={IntractiveImg} className="img-fluid" alt="" />
                                        </figure>
                                        <p className="intractive-content"  style={{
                                            color: '#fff',
                                            fontFamily : 'aniron',
                                            fontSize : '16px',
                                            position : 'absolute',
                                            top : 110,
                                            // bottom : 0,
                                            margin : '0px 100px',
                                        }}
                                           dangerouslySetInnerHTML={{ __html: props.setting?.interactive_quest}}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    :
                    <></>

            }
        </>
);
}

const mapStateToProps = (state) => {
    return {
        islogin: state.Auth.islogin,
        setting: state.Site.setting,
    }
}

const mapDispatchToProps = () => {

}

export default connect(mapStateToProps)(Intractive);