import {
    SET_AUCTION_ITEMS,
    SET_AUCTION_LIST_ITEMS,
    SET_MY_AUCTION_ITEMS, SET_VIEW_AUCTION_ITEM_BIDS, SET_VIEW_AUCTION_ITEM_STATUS, SET_VIEW_AUCTION_ITEMS,
} from "../constants";
import { headersWithToken} from "../headers";
import {
    createAuctionItemAPi, getAuctionItemsAPi,
    getAuctionItemsForAuctionAPi,
    myAuctionItemsAPi, myBidsAuctionAPi, placeABidAPi, viewAuctionItemAPi
} from "../apis";
import axios from "axios";
import {setAlert} from "../Alert/action";
import {responseError} from "../errorHandler";
import {getProfileRequest} from "../Auth/action";


export const setItems = (data) => {
    return {
        type: SET_AUCTION_ITEMS,
        payload: data
    }
}
export const setItemsForAuctionList = (data) => {
    return {
        type: SET_AUCTION_LIST_ITEMS,
        payload: data
    }
}

export const getItemsRequest = (link = null,search=null) => {
    return async (dispatch, getState) => {
        try {
            link = (link) ? link : getAuctionItemsAPi+(search? search : '');
            let token = getState().Auth.token;
            let response = await axios.get(link, headersWithToken(token));
            dispatch(setItems(response.data.response.data));
        } catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    }
}
export const getItemsForAuctionListRequest = (link = null, search = null) => {
    return async (dispatch, getState) => {
        try {
            link = (link) ? link : getAuctionItemsForAuctionAPi + (search ? search : '');
            let token = getState().Auth.token;
            let response = await axios.get(link, headersWithToken(token));
            dispatch(setItemsForAuctionList(response.data.response.data));
        } catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    }
}

export const setItem = (data) => {
    return {
        type: SET_MY_AUCTION_ITEMS,
        payload: data
    }
}

export const myAuctionRequest = () => {
    return async (dispatch, getState) => {
        try {
            let token = getState().Auth.token;
            let response = await axios.get(myAuctionItemsAPi, headersWithToken(token));
            dispatch(setItem( response.data.response.data ));
        } catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    }
}

export const myBidsAuctionsRequest = () => {
    return async (dispatch, getState) => {
        try {
            let token = getState().Auth.token;
            let response = await axios.get(myBidsAuctionAPi, headersWithToken(token));
            dispatch(setItem( response.data.response.data ));
        } catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    }
}

export const setViewItem = (data) => {
    return {
        type: SET_VIEW_AUCTION_ITEMS,
        payload: data
    }
}

export const setViewItemStatus = (data) => {
    return {
        type: SET_VIEW_AUCTION_ITEM_STATUS,
        payload: data
    }
}

export const viewAuctionRequest = (id) => {
    return async (dispatch, getState) => {
        try {
            let token = getState().Auth.token;
            let response = await axios.get(viewAuctionItemAPi(id), headersWithToken(token));
            dispatch(setViewItem( response.data.response.data ));
        } catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
        }
    }
}


export const createAuctionItemRequest = (data) => {
    return async (dispatch, getState) => new Promise(async (resolve, reject)=>{
        try {
            let token = getState().Auth.token;
            let response = await axios.post(createAuctionItemAPi,data, headersWithToken(token));
            dispatch(setAlert([{message : 'auction item successfully created' ,color : 'success'}]));
            return resolve(true);
        } catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
            return reject(false);
        }
    });
}

export const setNewBids = (data) => {
    return {
        type: SET_VIEW_AUCTION_ITEM_BIDS,
        payload: data
    }
}

export const placeABidRequest = (data) => {
    return async (dispatch, getState) => new Promise(async (resolve, reject)=>{
        try {
            let token = getState().Auth.token;
            let response = await axios.post(placeABidAPi,data, headersWithToken(token));
            await dispatch(getProfileRequest());
            dispatch(setNewBids(response?.data?.response?.data));
            dispatch(setAlert([{message : 'bid added successfully' ,color : 'success'}]));
            return resolve(true);
        } catch (e) {
            let error = responseError(e);
            dispatch(setAlert(error));
            return reject(false);
        }
    });
}