import Pusher from 'pusher-js';
import {site_url} from "./apis";

export const pusher = (token) => {
    Pusher.logToConsole = false;
    return new Pusher('ddc0401c8e54a6d7f1d1', {
        cluster : 'ap2',
        authEndpoint: `${site_url}/broadcasting/auth`,
        auth : {
            headers : {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json',
            },
        },
    })
}
