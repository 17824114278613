import React, {useEffect, useState} from 'react';
import {connect, useDispatch, useSelector} from "react-redux";
import Logo from "../../components/Logo";
import '../../assets/css/guild-hall.css';
import AuthHeader from "../../components/AuthHeader";
import {Link, useParams} from "react-router-dom";
import {
    addItemInTrade,
} from "../../redux/Trade/action";
import {getImageFromStorage, showLoader} from "../../redux/helpers";
import PDLBorder from "../../components/PDLBorder";
import {acceptGuildRequest, cancelGuildRequest, getGuildRequest, leavePlayerRequest} from "../../redux/Guild/action";
import {getItemsRequest} from "../../redux/Inventory/action";
import guildText from '../../assets/img/guild-text.png';
import inventorybg from '../../assets/img/inventorybg.png';
import PortalisBack from '../../assets/img/portalis-back.png';
import CastleTxt from '../../assets/img/gate-txt.png';
import CrastleBack from '../../assets/img/crastle-back.png';
import GateTxt from '../../assets/img/gate-txt.png';
import GateBack from '../../assets/img/gate-house-back.png';
import CourtTxt from '../../assets/img/court-text.png';
import CourtBack from '../../assets/img/court-yard.png';
import ChapelTxt from '../../assets/img/chapel-txt.png';
import ChapelBack from '../../assets/img/chapel-back.png';
import DungeonTxt from '../../assets/img/dungeon-txt.png';
import DungeonBack from '../../assets/img/dungeon-back.png';
import SolorTxt from '../../assets/img/solar-txt.png';
import SolorBack from '../../assets/img/solar-back.png';
import ThroneTxt from '../../assets/img/throne-txt.png';
import ThroneBack from '../../assets/img/throne-back.png';


const Hall = (props) => {

    const params = useParams();
    const dispatch = useDispatch();
    const guild = useSelector(state => state?.Guild?.guild);
    const {selected_character} = useSelector((state) => state?.Auth);


    const get = async () => {
        showLoader('flex');
        await props.getGuild(params.id);
        await props.getItems();
        showLoader('none');
    }

    const kickPlayer = async (playerId) => {
        showLoader('flex');
        await dispatch(leavePlayerRequest({
            character_id: playerId,
            guild_id: params.id,
        }));
        showLoader('none');
    }

    useEffect(() => {
        get();
    }, [params.id])

    return (
        <div className={"fram-sec"}>
            <div className="frame-wrapper-hall" style={
                {
                    backgroundImage: `url('${(guild?.guilt_image) ? getImageFromStorage(guild?.guilt_image) : inventorybg}')`
                }}>
                {/* Border*/}
                <PDLBorder/>
                {/*Logo*/}
                <Logo/>

                {/*Menus*/}
                <div className="row">
                    {/*<div className="col-md-7">*/}

                    {/*</div>*/}
                    <div className="col-md-12">
                        <div className="Menus text-right">
                            <AuthHeader/>
                        </div>
                    </div>
                </div>

                <section className="guild_sec">
                    <div className="container">
                        <div className="heading-wrapper">
                            <div className="button-group">
                                <Link to={`/guild/${params.id}`} className="btnn">Back</Link>
                            </div>
                            <div className="title">
                                <figure><img src={guildText} alt="" className="img-fluid"/></figure>
                            </div>
                        </div>
                        <div className="all-guild">
                            <div className="row">
                                {
                                    guild?.member?.map((val, key) => (
                                        <div key={key} className="col-lg-3 col-md-6">
                                            <div className="guild-box">
                                                <div className="img-box" style={{position: "relative"}}>
                                                    <figure>
                                                        <img src={val?.character?.logo_url} alt=""
                                                             className="img-fluid"/>
                                                    </figure>
                                                    {
                                                        (
                                                            parseInt(guild?.leader_id) === parseInt(selected_character) ||
                                                            parseInt(guild?.co_leader_id) === parseInt(selected_character)
                                                        ) &&
                                                        parseInt(guild?.leader_id) !== parseInt(val?.character_id)
                                                        &&
                                                        <div className={"bg-danger"} style={{
                                                            top: 0,
                                                            position: 'absolute',
                                                            right: 0,
                                                            backgroundColor: '#e3bf4',
                                                            padding: '2px 10px',
                                                        }} onClick={() => kickPlayer(val?.character_id)}>
                                                            <i className={"text-white fa fa-arrow-circle-left fa-1x"}></i>
                                                        </div>
                                                    }
                                                </div>
                                                <Link to={'/character/hall/' + val?.character_id}>
                                                    <div className="txt">
                                                        <h4>Player Name <span>{val?.character?.name}</span></h4>
                                                        <h4>Player level <span>{val?.character?.level}</span></h4>
                                                        <h4>Type <span>{
                                                            (
                                                                ()=>{
                                                                    if(val?.type === 0) return 'Leader'
                                                                    else if(val?.type === 1) return 'Co-Leader'
                                                                    else return 'Player'
                                                                }
                                                            )()}</span></h4>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </div>
    );
}


const mapStateToProps = (state) => {
    return {
        user: state.Auth.user,
        guild: state.Guild.guild,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getGuild: (id) => dispatch(getGuildRequest(id)),
        acceptGuild: (data) => dispatch(acceptGuildRequest(data)),
        cancelGuild: (data) => dispatch(cancelGuildRequest(data)),
        getItems: (url = null, search = null) => dispatch(getItemsRequest(url, search)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Hall);