import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import PDLBorder from "../../components/PDLBorder";
import Logo from "../../components/Logo";
import AuthHeader from "../../components/AuthHeader";
import PlayerBottom from '../../assets/img/playerbottom.png';
import PlayerTop from '../../assets/img/playertop.png';
import {doEnhanceRequest, getItemRequest, updateItemRequest} from "../../redux/Inventory/action";
import {setLoader} from "../../redux/Site/action";
import Loader from "../NotFound/Loader";
import {Link, useNavigate, useParams} from "react-router-dom";
import NotFound from "../NotFound/NotFound";
import '../../assets/css/inventory-detail.css';
import {showLoader} from "../../redux/helpers";
import {setAlert} from "../../redux/Alert/action";


const EnhanceItemsDetails = (props) => {

    const params = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [boolArr, setBoolArr] = useState([]);
    const [activeItem, setActveItem] = useState(0);
    const [item, setItem] = useState(null);

    const get = async () => {
        props.setLoader(true);
        await props.getItem(params?.id);
        props.setLoader(false);
    }

    const buy = () => {
        setLoading(true);
        props.buyItem({item_id: params?.id})
            .then(() => {
                setLoading(false);
                console.log("/inventory");
                navigate('/inventory');
            })
            .catch(() => {
                setLoading(false);
            });
    }

    const findItem = (id) => {
        let item = props.match_inventory.find((val) => val?.item_id === id);
        if (item) {
            return true;
        } else {
            return false;
        }
    }

    const selectedAble = (id) => {
        console.log(id);
        let item = props.match_inventory.find((val) => val?.item_id === id);
        console.log(item);
        if (item) {
            if (activeItem === id) {
                setActveItem(0);
                setItem(null);
            } else {
                setActveItem(id);
                setItem(item);
            }
        }
    }

    const minusStats = (type,item) => {
        let result = 0;
        if (type === "base") {
            let totalBase = 0;
            item?.power_items.map((val) => totalBase += parseFloat(val?.base));
            result = parseFloat(item?.base) - totalBase;
        }



        else if (type === "health") {
            let totalHealth = 0;
            // item?.power_items.map((val) => totalHealth += parseFloat(val?.health));
            // result = parseFloat(item?.health) - totalHealth;

            if (item?.power_items && item.power_items.length > 0) {
                item?.power_items.map((val) => totalHealth += parseInt(val?.health.replace(/,/g, '')));
            }
            result = parseInt(item?.health.replace(/,/g, '')) - totalHealth;
        }

        else if (type === "damage") {
            let totalDamage = 0;
            if (item?.power_items && item.power_items.length > 0) {
                item?.power_items.map((val) => totalDamage += parseInt(val?.damage.replace(/,/g, '')));
            }
            result = parseInt(item?.damage.replace(/,/g, '')) - totalDamage;
        }

        else if (type === "armor_pen") {
            let totalArmorPen = 0;
            // item?.power_items.map((val) => totalArmorPen += parseFloat(val?.armor_pen));
            // result = parseFloat(item?.armor_pen) - totalArmorPen;
            if (item?.power_items && item.power_items.length > 0) {
                item?.power_items.map((val) => totalArmorPen += parseInt(val?.armor_pen.replace(/,/g, '')));
            }
            result = parseInt(item?.armor_pen.replace(/,/g, '')) - totalArmorPen;
        }

        else if (type === "armor_pen_resist") {
            let totalArmorPenReist = 0;
            // item?.power_items.map((val) => totalArmorPenReist += parseFloat(val?.armor_pen_resist));
            // result = parseFloat(item?.armor_pen_resist) - totalArmorPenReist;

            if (item?.power_items && item.power_items.length > 0) {
                item?.power_items.map((val) => totalArmorPenReist += parseInt(val?.armor_pen_resist.replace(/,/g, '')));
            }
            result = parseInt(item?.armor_pen_resist.replace(/,/g, '')) - totalArmorPenReist;
        }
        return result;
    }

    const powerStatsRemover = (type, item) => {

        let result = { value: 0, isHighest: false };
        let stats = {
            // base : parseFloat(minusStats('base',item)),
            // health: parseFloat(minusStats('health', item)),
            // damage: parseFloat(minusStats('damage', item)),
            // armor_pen: parseFloat(minusStats('armor_pen', item)),
            // armor_pen_resist: parseFloat(minusStats('armor_pen_resist', item)),

            base: minusStats('base', item),
            health: minusStats('health', item),
            damage: minusStats('damage', item),
            armor_pen: minusStats('armor_pen', item),
            armor_pen_resist: minusStats('armor_pen_resist', item),
        }
        let highestKey = Object.keys(stats).reduce((a, b) => stats[a] > stats[b] ? a : b);
        console.log('highestKey: ', highestKey);

        if (type === "base") {
            let totalBase = 0;
            // item?.power_items.map((val) => totalBase += parseFloat(val?.base));
            // result.value = parseFloat(item?.base) - totalBase;
            // result.isHighest = (highestKey === "base")? true : false;
            item?.power_items.map((val) => totalBase += parseInt(val?.base.replace(/,/g, '')));
            result.value = (parseInt(item?.base.replace(/,/g, '')) - totalBase).toLocaleString();
            result.isHighest = (highestKey === "base") ? true : false;


        }

        else if (type === "health") {

            let totalHealth = 0;
            // item?.power_items.map((val) => totalHealth += parseFloat(val?.health));
            // result.value = parseFloat(item?.health) - totalHealth;
            // result.isHighest = (highestKey === "health")? true : false;
            item?.power_items.map((val) => totalHealth += parseInt(val?.health.replace(/,/g, '')));
            result.value = (parseInt(item?.health.replace(/,/g, '')) - totalHealth).toLocaleString();
            result.isHighest = (highestKey === "health") ? true : false;

        }

        else if (type === "damage") {
            let totalDamage = 0;
            // item?.power_items.map((val) => totalDamage += parseFloat(val?.damage));
            // result.value = parseFloat(item?.damage) - totalDamage;
            // result.isHighest = (highestKey === "damage")? true : false;

            item?.power_items.map((val) => totalDamage += parseInt(val?.damage.replace(/,/g, '')));
            result.value = (parseInt(item?.damage.replace(/,/g, '')) - totalDamage).toLocaleString();
            result.isHighest = (highestKey === "damage") ? true : false;

        }

        else if (type === "armor_pen") {
            let totalArmorPen = 0;
            // item?.power_items.map((val) => totalArmorPen += parseFloat(val?.armor_pen));
            // result.value = parseFloat(item?.armor_pen) - totalArmorPen;
            // result.isHighest = (highestKey === "armor_pen")? true : false;
            item?.power_items.map((val) => totalArmorPen += parseInt(val?.armor_pen.replace(/,/g, '')));
            result.value = (parseInt(item?.armor_pen.replace(/,/g, '')) - totalArmorPen).toLocaleString();
            result.isHighest = (highestKey === "armor_pen") ? true : false;

        }

        else if (type === "armor_pen_resist") {
            let totalArmorPenReist = 0;
            item?.power_items.map((val) => totalArmorPenReist += parseFloat(val?.armor_pen_resist));
            // result.value = parseFloat(item?.armor_pen_resist) - totalArmorPenReist;
            // result.isHighest = (highestKey === "armor_pen_resist")? true : false;
            item?.power_items.map((val) => totalArmorPenReist += parseInt(val?.armor_pen_resist.replace(/,/g, '')));
            result.value = (parseInt(item?.armor_pen_resist.replace(/,/g, '')) - totalArmorPenReist).toLocaleString();
            result.isHighest = (highestKey === "armor_pen_resist") ? true : false;

        }
        console.log(type,result);
        return result;

    }



    const enhanceNowHandler = () => {
        // let checkItems = [];
        // props.item?.shop?.enhance_items.map((val, key) => {
        //     checkItems.push(findItem(val?.item_id));
        // });
        // if(checkItems.findIndex((val)=>val == "false") <= -1){ //false
        // if (checkItems.includes(false)) { //false
        if (activeItem === 0) {
            props.setAlert([
                {message: 'Please select a least one relic', color: 'warning'}
            ]);
        } else {
            showLoader('flex');
            props.upgradeItem({
                inventory_item_id: props.item?.shop?.id,
                inventory_id: props.item?.id,
                relic_id: activeItem,
            })
                .then((id) => {
                    showLoader('none');
                    navigate('/inventory/' + id);
                })
                .catch((e) => {
                    showLoader('none');
                });
        }
    }

    useEffect(() => {
        get();
        return () => {

        }
    }, []);

    useEffect(() => {
        console.log(props.item);
    }, [props.item]);

    if (props.loader) return <Loader/>

    if (!props.item) return <NotFound/>

    return (
        <section className="fram-sec">
            <div className="frame-wrapper">

                {/* Border*/}
                <PDLBorder/>
                {/*Logo*/}
                <Logo/>

                {/*Menus*/}
                <div className="row">
                    {/*<div className="col-md-7">*/}

                    {/*</div>*/}
                    <div className="col-md-12">
                        <div className="Menus text-right">
                            <AuthHeader/>
                        </div>
                    </div>
                </div>
                {/*Menus*/}
                <section className="inventory-detailed">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-5">
                                <div className="forTop-border py-1">
                                    <figure><img className="w-100" src={PlayerTop} alt=""/></figure>
                                </div>
                                <div className="Player-statusDv">
                                    <div className="head">
                                        <h3 className="title">
                                            Upgrade Item Stats
                                        </h3>
                                    </div>
                                    <ul className="status-list">
                                        {/*<li>*/}
                                        {/*    <h4>Level</h4>*/}
                                        {/*    <span>{props.item?.shop?.level}</span>*/}
                                        {/*</li>*/}
                                        {/*<br/>*/}
                                        <li>
                                            <h4>Health</h4>
                                            <span>{powerStatsRemover("health", props.item).value} + <span
                                                className={"text-success"}>
                                                {powerStatsRemover("health", props.item).isHighest ? (item?.shop?.relic_value || 0) : 0}%
                                                {/*{props.item?.shop?.type_health === "percent" && '%'}*/}
                                            </span> </span>
                                        </li>
                                        <br/>
                                        <li>
                                            <h4>Base</h4>
                                            <span>{powerStatsRemover("base", props.item).value} + <span
                                                className={"text-success"}>
                                                {powerStatsRemover("base", props.item).isHighest ? (item?.shop?.relic_value || 0) : 0}%
                                                {/*{props.item?.shop?.type_base === "percent" && '%'}*/}
                                            </span> </span>
                                        </li>
                                        <br/>

                                        <li>
                                            <h4>Damage</h4>
                                            <span>{powerStatsRemover("damage", props.item).value} + <span
                                                className={"text-success"}>
                                                {powerStatsRemover("damage", props.item).isHighest ? (item?.shop?.relic_value || 0) : 0}%
                                                {/*{props.item?.shop?.type_damage === "percent" && '%'}*/}
                                                </span>
                                            </span>
                                        </li>
                                        <br/>

                                        <li>
                                            <h4>Armor Pen</h4>
                                            <span>{powerStatsRemover("armor_pen", props.item).value} +
                                                <span className={"text-success"}>
                                                    {powerStatsRemover("armor_pen", props.item).isHighest ? (item?.shop?.relic_value || 0) : 0}%
                                                    {/*{props.item?.shop?.type_armor_pen === "percent" && '%'}*/}
                                                </span>
                                            </span>
                                        </li>
                                        <br/>

                                        <li>
                                            <h4>Armor Pen Resist</h4>
                                            <span className={"d-flex"}>
                                                {powerStatsRemover("armor_pen_resist", props.item).value} +
                                                <span
                                                    className={"text-success"}>
                                                    {powerStatsRemover("armor_pen_resist", props.item).isHighest ? (item?.shop?.relic_value || 0) : 0}%
                                                    {/*{props.item?.shop?.type_armor_pen_resist === "percent" && '%'}*/}
                                                </span>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="forBottom-border py-1">
                                    <figure><img className="w-100" src={PlayerBottom} alt=""/></figure>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="product-details">
                                    <div className="heading">
                                        <h1 className="title">{props.item?.shop?.title}</h1>
                                    </div>
                                    <div className="image-with-btn">
                                        <div className="img-box">
                                            <figure><img width={'100%'} src={props.item?.shop?.image_url} alt=""/>
                                            </figure>
                                            {/*<div className="">*/}
                                            {/*    <span className="pdlStyleParagraph">1/{props.item?.qty}</span>*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>
                                    <div className="description">
                                        <p>{props.item?.shop?.description}</p>
                                    </div>
                                    <div className="row my-5">
                                        {
                                            props.item?.shop?.enhance_items.map((val, key) => {
                                                return <div key={key} className="col-lg-2">
                                                    {/*  ()=>navigate('/shop/'+val?.item_id)   */}
                                                    <div onClick={() => selectedAble(val?.item_id)}
                                                         className={`${!findItem(val?.item_id) && 'border-0'}
                                                         ${(activeItem === val?.item_id) ? '' : 'border-0'}
                                                         inventory-itemBox customImageBox`}
                                                         style={{
                                                             position: 'relative'
                                                         }}>
                                                        <figure><img width={'100%'} src={val?.require_item?.image_url}
                                                                     alt=""/></figure>
                                                        {
                                                            !findItem(val?.item_id)
                                                            &&
                                                            <div style={{
                                                                position: 'absolute',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                flexDirection: 'row',
                                                                background: 'rgb(88 88 88 / 50%)',
                                                                left: 0,
                                                                right: 0,
                                                                top: 0,
                                                                bottom: 0,
                                                            }}>
                                                                <h1 className={"text-white"}>
                                                                    <i className={"fa fa-lock"}
                                                                       style={{color: '#cbcbcb'}}></i>
                                                                </h1>
                                                            </div>
                                                        }
                                                    </div>

                                                </div>
                                            })
                                        }
                                        {/*{*/}
                                        {/*    [...Array(4 - props.item?.shop?.enhance_items?.length )].map((val,key)=>{*/}
                                        {/*        return <div key={key} className="col-lg-3">*/}
                                        {/*            <div className="inventory-itemBox customImageBox">*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*    })*/}
                                        {/*}*/}
                                    </div>


                                    <div className="button-group my-2">
                                        <Link to={'/inventory/' + props.item?.id}
                                              className="btn customButton">Back</Link>
                                        <button type={'button'} onClick={enhanceNowHandler} className="btn">Enhance
                                            Now
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </section>
    );
}

const mapStateToProps = (state) => {
    return {
        item: state.Inventory.item,
        loader: state.Site.loader,
        match_inventory: state.Inventory.match_inventory,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setLoader: (bool) => dispatch(setLoader(bool)),
        setAlert: (data) => dispatch(setAlert(data)),
        getItem: (id) => dispatch(getItemRequest(id)),
        upgradeItem: (data) => dispatch(doEnhanceRequest(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EnhanceItemsDetails);